var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nav-items"
  }, [_c('li', {
    staticClass: "nav-item nav-item"
  }, _vm._l(_vm.classRoom, function (item) {
    return _c('div', {
      key: item.name
    }, [item.authorityTag ? _c('router-link', {
      staticClass: "nav-link",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]) : _vm._e()], 1);
  }), 0), _vm.profile_base.is_enabled_vip_product && _vm.getCanOrderClass ? _c('li', {
    staticClass: "nav-item dropdown"
  }, [_c('a', {
    staticClass: "nav-link dropdown-toggle",
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleDropdown('startClass');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('bookClass')))]), _c('div', {
    staticClass: "dropdown-menu",
    class: [_vm.state === 'startClass' ? ' d-block' : 'd-none']
  }, [_c('div', {
    staticClass: "dropdown-sort",
    class: [_vm.state ? 'mt-1' : 'mt-5']
  }, [_vm._v(_vm._s(_vm.$t('book')))]), _vm._l(_vm.bookLists, function (item) {
    return _c('div', {
      key: item.name
    }, [item.authorityTag ? _c('router-link', {
      staticClass: "dropdown-item",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]) : _vm._e()], 1);
  }), _vm._l(_vm.setRecordLists, function (item) {
    return _c('div', {
      key: item.name
    }, [_c('div', {
      staticClass: "dropdown-sort"
    }, [_vm._v(_vm._s(_vm.$t('setRecord')))]), item.authorityTag ? _c('router-link', {
      staticClass: "dropdown-item",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]) : _vm._e()], 1);
  })], 2)]) : _vm._e(), _c('li', {
    staticClass: "nav-item dropdown"
  }, [_c('a', {
    staticClass: "nav-link dropdown-toggle",
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleDropdown('classRecord');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('classRecord')))]), _c('div', {
    staticClass: "dropdown-menu",
    class: [_vm.state === 'classRecord' ? ' d-block' : 'd-none']
  }, [_vm._l(_vm.classRecordLists, function (item) {
    return _c('div', {
      key: item.name
    }, [item.authorityTag && item.name !== 'learnRecords' ? _c('router-link', {
      staticClass: "dropdown-item",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]) : _vm._e(), item.authorityTag && item.name === 'learnRecords' ? _c('a', {
      staticClass: "dropdown-item",
      attrs: {
        "href": `${_vm.newJrUrl}${item.path}`
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]) : _vm._e()], 1);
  }), _vm._m(0), _vm._m(1), _vm._m(2)], 2)]), _c('li', {
    staticClass: "nav-item"
  }, _vm._l(_vm.mgm, function (item) {
    return _c('div', {
      key: item.name
    }, [item.authorityTag ? _c('router-link', {
      staticClass: "nav-link",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]) : _vm._e()], 1);
  }), 0), _c('li', {
    staticClass: "nav-item"
  }, _vm._l(_vm.guide, function (item) {
    return _c('div', {
      key: item.name
    }, [item.authorityTag ? _c('router-link', {
      staticClass: "nav-link",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]) : _vm._e()], 1);
  }), 0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": "https://cws.cavesbooks.com.tw/index.aspx?id=h%2fyJTTRu%2bLjpHeEYZ6gbKA%3d%3d",
      "target": "_blank"
    }
  }, [_vm._v("P21音檔")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": "https://cws.cavesbooks.com.tw/index.aspx?id=Ootb88ICU73n%2b9F3YSL3ig%3d%3d",
      "target": "_blank"
    }
  }, [_vm._v("R21音檔")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": "https://www.cavesbooks.com.tw/EC/book_fair.aspx#3191",
      "target": "_blank"
    }
  }, [_vm._v("魔法故事屋")])]);

}]

export { render, staticRenderFns }