import { changeCamelCase } from '@/utils/changFormat';
import lioshutanApi from '@/api';

const types = {
  SET_CHECKSTATE: 'SET_CHECKSTATE',
  SET_VIP_CONTRACT: 'SET_VIP_CONTRACT',
  SET_SUPPLEMENTARY_AGREEMENT: 'SET_SUPPLEMENTARY_AGREEMENT',
  SET_RECORDDATA: 'SET_RECORDDATA',
};

const state = {
  confirmStatusInfo: {},
  vipContractAdditionTerm: {},
  vipAdditionTermRecord: {},
};

const mutations = {
  [types.SET_CHECKSTATE](state, res) {
    state.confirmStatusInfo = res;
  },

  [types.SET_VIP_CONTRACT](state, data) {
    state.vipContractAdditionTerm = data;
  },

  [types.SET_RECORDDATA](state, data) {
    state.vipAdditionTermRecord = data;
  },
};

const actions = {
  async getCheckAdditionTerm({ commit }){
    const response = await lioshutanApi.supplementaryAgreement.getCheckAdditionTerm();
    const res = changeCamelCase(response.data.data);
    commit('SET_CHECKSTATE', res);
  },

  async getVipContractAdditionTerm({ commit }, vipContractAdditionTerm){
    const response = await lioshutanApi.supplementaryAgreement.getVipContractAdditionTerm(vipContractAdditionTerm);
    const res = changeCamelCase(response.data.data);
    commit('SET_VIP_CONTRACT', res.vipContractAdditionTerm);
  },

  async sendResults({ state, commit }, choose) {
    if (choose.choice === 'approved' || choose.choice === 'rejected') {
      await lioshutanApi.supplementaryAgreement.patchVipProductAdditionTermModify({
        vipProductId: state.confirmStatusInfo.vipProductId,
        vipContractAdditionTermStatus: choose.choice,
      });
    }
  },

  async handleModalClose({ commit, state }) {
    await lioshutanApi.supplementaryAgreement.patchVipProductAdditionTermModify({
      vipProductId: state.confirmStatusInfo.vipProductId,
      vipContractAdditionTermStatus: 'waiting',
    });
  },

  async getVipAdditionTermRecord({ commit }){
    const response = await lioshutanApi.supplementaryAgreement.getVipAdditionTermRecord();
    const res = changeCamelCase(response.data.data);
    commit('SET_RECORDDATA', res);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
