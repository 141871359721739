import axios from '@/api/utils/axios-helper.js';
export default class Classroom{
  /**
   * 取得該參數下所有課堂
   * @param {Object} params 參數
   * @returns {Promise<Array>} 課堂資訊
   */
  async getClassroom(data) {
    const response = await axios({
      url: '/v3/taichi-external/student/classroom',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: data,
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得該參數下顧問所有課堂
   * @param {Object} params 參數
   * @returns {Promise<Array>} 課堂資訊
   */
  async getConsultantClassroom(data) {
    const response = await axios({
      url: '/v3/taichi-external/consultant/class/consultant-classroom',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: data,
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得測試教室連結
   */
  async getSystemTestingUrl() {
    const response = await axios({
      url: '/v3/taichi-external/user/meeting/test-platform',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }

  /**
   * 檢查學員上課必填資料
   */
  async checkStudentSetting(data) {
    const response = await axios({
      url: '/v3/human-resource/check-student-setting',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: data,
      responseType: 'json',
    });
    return response;
  }

  /**
   * 取得該參數下platform ID
   * @param {Object} params 參數
   * @returns {Promise<Object>} platform
   */
  async getPlatformId(platform){
    const response = await axios({
      url: `/v3/convenient/platform-basic/${platform}`,
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得該參數下platform 等級範圍
   * @param {Object} params 參數
   * @returns {Promise<Object>} platform
   */
  async getPlatformLevel(id){
    const response = await axios({
      url: `/v3/convenient/platform-level-range/${id}`,
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
}
