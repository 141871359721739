<template lang="pug">
li.nav-item.dropdown
  a.nav-link.dropdown-toggle(@click="toggleDropdown('vip-account')")
    //- img.img-avatar(:src='profile_base.photo' alt='photo')
    avatar(:avatarUrl="profile_base.photo" :avatarRole="'student'")
    span.ml-2.d-lg-down-none {{profile_base.chinese_name}}
  transition(name='fade')
    .dropdown-menu.dropdown-menu-right.d-block(v-if="state === 'vip-account'")
      detail(:role="student")
</template>

<script type="text/javascript">
import detail from '@/components/vip/account/detail';
import dropdowControlMixin from '@/components/nav/headerNav/mixin/dropdowControlMixin';
import commonConstants from '@/constants/common';
import lioshutanApi from '@/api';
import avatar from '@/components/avatar/index.vue';

export default {
  name: 'VipAccount',

  components: {
    detail,
    avatar,
  },

  mixins: [dropdowControlMixin],

  data() {
    return {
      student: commonConstants.ROLE.STUDENT,
      profile_base: {
        english_name: 'English Name',
        photo: '',
      },
    };
  },

  created(){
    this.getStudentPorfile();
  },

  methods: {
    getStudentPorfile(){
      lioshutanApi.vip.getBaseProfile().then((result) => {
        this.profile_base = result.data.profile_base;
      });
    },
  },
};
</script>
<style>
.img-avatar{
  max-width: 35px;
}
</style>
