var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home-container time-line container-fluid py-5",
    attrs: {
      "id": "timeline"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "d-block d-md-none text-center my-3"
  }, [_c('img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.file.timelineMobile
    }
  })]), _c('div', {
    staticClass: "d-none d-md-block text-center w-100 mx-auto my-5 py-4"
  }, [_c('img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.file.timeline
    }
  })])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('p', {
    staticClass: "area-english-title text-lioshutan text-center"
  }, [_vm._v(":: Flexible Booking Cancellation ::")]), _c('h2', {
    staticClass: "area-title text-lioshutan"
  }, [_vm._v("彈性訂、退課")]), _c('h2', {
    staticClass: "area-title text-lioshutan"
  }, [_vm._v("保有最大學習機動性！")])]);

}]

export { render, staticRenderFns }