<template lang="pug">
footer.lay-footer
  .container.lay-footer-container
    .row.reg-footer-nav(v-if="role === 'student' || role === 'parent'")
      .col-12.col-md-4.are-footer-company
        .panel-footer-logo
          a.lnk-footer-logo(:href='wuwow.url' :title='wuwow.name' target='_blank')
            img.w-75(:src='wuwow.img_url' :alt='wuwow.name' :title='wuwow.name')
        p.txt-footer-address
          span(v-html='company_info.address')
        p.txt-footer-contact
          | email:
          span.ml-1(v-html='company_info.email')
          br
          | contact:
          a.ml-1(:href="'tel:' + company_info.phone") {{ company_info.phone }}
        //- ul.ul-footer-social
        //-   li.li-footer-social(v-for='media in media_datas' :key='media.name')
        //-     a.lnk-footer-social(:href='media.url')
        //-       img.img-footer-social(:src='media.img_url' :alt='media.name' target='_blank')
      nav.col-12.col-md-8.are-footer-menu.p-0
        ul.ul-footer-menu
          li.li-footer-menu(v-for='nav in footer_nav' :key='nav.name')
            span.txt-footer-menu {{ $t(nav.name) }}
            ul.ul-footer-submenu
              li.li-footer-submenu(v-for='list in nav.list' :key='list.name')
                router-link.lnk-footer-submenu(:to='{ path: list.url}') {{ $t(list.name) }}
    .row.reg-footer-copyright
      .are-footer-copyright
        | Copyright &copy; 2017 LIOSHUTAN Digital Learning, Inc. All rights reserved.
      .are-footer-link
        a.lnk-footer-link(href='/privacy' target='_blank') 隱私權政策
</template>

<script>
import footerList from '@/views/layout/footer/footerList';
import companyConstant from '@/constants/company';
import { mapState } from 'vuex';
import commonConstants from '@/constants/common';
import lioshutanApi from '@/api';

export default {
  name: 'Vfooter',
  data() {
    return {
      student: commonConstants.ROLE.STUDENT,
      parent: commonConstants.ROLE.PARENT,
      wuwow: {
        name: 'wuwow',
        url: '/',
        img_url: 'https://cdn2.wuwow.tw/images/wuwowjr-logo-footer.svg',
      },
      company_info: {
        address:
          companyConstant.INFO.ADDRESS_1 + '<br/>' + companyConstant.INFO.ADDRESS_2,
        email: companyConstant.INFO.CONTACT_EMAIL,
        phone: companyConstant.INFO.CONTACT_PHONE,
      },
      media_datas: [
        {
          name: companyConstant.MEDIA.FB.NAME,
          url: companyConstant.MEDIA.FB.URL,
          img_url: require('./../../../assets/footer-fb.png'),
        },
        {
          name: companyConstant.MEDIA.MEDIUM.NAME,
          url: companyConstant.MEDIA.MEDIUM.URL,
          img_url: require('./../../../assets/footer-medium.png'),
        },
        {
          name: companyConstant.MEDIA.INSTAGRAM.NAME,
          url: companyConstant.MEDIA.INSTAGRAM.URL,
          img_url: require('./../../../assets/footer-ig.png'),
        },
        {
          name: companyConstant.MEDIA.YOUTUBE.NAME,
          url: companyConstant.MEDIA.YOUTUBE.URL,
          img_url: require('./../../../assets/footer-yt.png'),
        },
      ],
      footer_nav: [],
    };
  },

  computed: {
    ...mapState({
      role: state => state.auth.role || localStorage.getItem('account_role'),
    }),
  },

  async created() {
    this.footer_nav = footerList[this.role];
    this.getVipPointSuggest();
  },

  methods: {
    async getVipPointSuggest() {
      if (localStorage.getItem('wuwow_token')) {
        const res = await lioshutanApi.vip.getContractElapsedTime();
        this.$store.dispatch('vipAccount/setPlatform', res.data.platform);
        if (res.data.platform) {
          const { now } = res.data.platform;
          if (now.id !== 2 || !now.contractStatus) {
            this.footer_nav = footerList[this.role].filter((item) => item.name !== 'layout.book_class');
          }
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped></style>

