<template lang="pug">
.bg-lioshutan.py-4
  //- 手機用
  .d-block.d-md-none.py-4
    .text-center
      a.text-decoration-none(@click="changeCharacter(CHARACTER.CHILDREN)")
        h5.mb-4.text-light 學員登入
      a.text-decoration-none(@click="changeCharacter(CHARACTER.PARENT)")
        h5.mb-4.text-light 家長登入
      a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
        h5.mb-4.text-light 合作提案
      a.text-decoration-none(:href="joinUsUrl" target="_blank")
        h5.mb-4.text-light 夥伴招募
      a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
        h5.mb-4.text-light 成為老師
    .text-light.text-center.contact
      h5 聯絡我們
      a.text-light(href="tel:0800885315") Tel: 0800-885315
      a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
      .mt-4.d-flex.justify-content-center
        a.d-flex.flex-column.align-items-center.text-light(href="https://www.facebook.com/WUWOW.Junior" ,target="_blank")
          img.mr-1.init(:src="file.fbSVG" width="50" height="50")
          img.mr-1.hover(:src="file.fbHoverSVG" width="50" height="50")
          p wuwow jr
        //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
        //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
    a(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light.py-5
      img.w-50.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
      h6 WUWOW
      h6 最有效的線上英文
    a(href="/").d-flex.flex-column.align-items-center.text-light
      img.w-50.jr-logo.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
      h6 WUWOW Junior
      h6 專為孩子設計的線上英文

  //- 給平板的尺寸
  .d-none.d-md-block.d-lg-none
    .container
      .row.py-4
        .col-sm-6
          .text-center
            a.text-decoration-none(@click="changeCharacter(CHARACTER.CHILDREN)")
              h5.mb-4.text-light 學員登入
            a.text-decoration-none(@click="changeCharacter(CHARACTER.PARENT)")
              h5.mb-4.text-light 家長登入
            a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
              h5.mb-4.text-light 合作提案
            a.text-decoration-none(:href="joinUsUrl" target="_blank")
              h5.mb-4.text-light 夥伴招募
            a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
              h5.mb-4.text-light 成為老師
          .text-light.text-center.contact
            h5 聯絡我們
            a.text-light(href="tel:0800885315") Tel: 0800-885315
            a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
            .mt-4.d-flex.justify-content-center
              a.d-flex.flex-column.align-items-center.text-light(href="https://www.facebook.com/WUWOW.Junior" ,target="_blank")
                img.mr-1.init(:src="file.fbSVG" width="50" height="50")
                img.mr-1.hover(:src="file.fbHoverSVG" width="50" height="50")
                p wuwow jr
              //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
              //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
        .col-sm-6
          a(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light.mb-3
            img.w-75.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
            h6 WUWOW
            h6 最有效的線上英文
          a(href="/").d-flex.flex-column.align-items-center.text-light
            img.w-75.jr-logo.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
            h6 WUWOW Junior
            h6 專為孩子設計的線上英文

  //- 電腦用
  .d-none.d-md-none.d-lg-block.container
    .d-flex.flex-row.justify-content-around.py-5
      div
        a.text-decoration-none(@click="changeCharacter(CHARACTER.CHILDREN)")
          h5.mb-4.text-light 學員登入
        a.text-decoration-none(@click="changeCharacter(CHARACTER.PARENT)")
          h5.mb-4.text-light 家長登入
        a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
          h5.mb-4.text-light 合作提案
        a.text-decoration-none(:href="joinUsUrl" target="_blank")
          h5.mb-4.text-light 夥伴招募
        a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
          h5.mb-4.text-light 成為老師
      .text-light.contact
        h5 聯絡我們
        a.text-light(href="tel:0800885315") Tel: 0800-885315
        a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
        .mt-4.d-flex.justify-content-left
          a.d-flex.flex-column.align-items-center.text-light(href="https://www.facebook.com/WUWOW.Junior" ,target="_blank")
            img.mr-1.init(:src="file.fbSVG" width="50" height="50")
            img.mr-1.hover(:src="file.fbHoverSVG" width="50" height="50")
            p wuwow jr
          //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
          //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
      a.w-25(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light
        img.w-75.h-75.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
        h6 WUWOW
        h6 最有效的線上英文
      a.w-25(href="/").d-flex.flex-column.align-items-center.text-light
          img.w-75.h-75.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
          h6 WUWOW Junior
          h6 專為孩子設計的線上英文
</template>

<script>
import { v4 } from 'uuid';

import {
  mapMutations,
} from 'vuex';
import {
  CHARACTER as CHARACTER_STORE,
  MODAL as MODAL_STORE,
} from '@/store/index';
import {
  SET_CHARACTER,
} from '@/store/modules/character';

import {
  SET_IS_OPEN,
} from '@/store/modules/modal';

import modalMixin from '@/mixins/modal';

import CHARACTER from '@/constants/character';

import { uuid as PARENT_UUID } from '@/views/home/parent/loginModal/index.vue';
import { uuid as CHILDREN_UUID } from '@/views/home/loginModal/index.vue';

export const uuid = v4();

import fbSVG from '@/assets/icon_fb.svg';
import fbHoverSVG from '@/assets/icon_fb_hover.svg';
import lineSVG from '@/assets/icon_line.svg';
import lineHoverSVG from '@/assets/icon_line_hover.svg';
import messengerSVG from '@/assets/icon_messenger.svg';
import messengerHoverSVG from '@/assets/icon_messenger_hover.svg';
import companyConstants from '@/constants/company';
import logo from '@/assets/wuwow_logo.svg';

import 'bootstrap/dist/css/bootstrap.css';

export default {
  mixins: [
    modalMixin,
  ],

  data: () => ({
    CHARACTER,
    modal: {
      PARENT_UUID,
      CHILDREN_UUID,
    },
    file: {
      fbSVG,
      fbHoverSVG,
      lineSVG,
      lineHoverSVG,
      messengerSVG,
      messengerHoverSVG,
      logo,
    },
    cooperationUrl: companyConstants.INFO.COOPERATION_URL,
    joinUsUrl: companyConstants.INFO.JOIN_US_URL,
    pmRecruitmentUrl: companyConstants.INFO.PM_RECRUITMENT_URL,
  }),

  created() {
    this.initModal(uuid, {
      status: false,
    });
  },

  methods: {
    ...mapMutations({
      changeCharacter(commit, character) {
        commit(`${CHARACTER_STORE}/${SET_CHARACTER}`, character);
        const target = {
          [CHARACTER.PARENT]: this.modal.PARENT_UUID,
          [CHARACTER.CHILDREN]: this.modal.CHILDREN_UUID,
        }[character];

        this.close();

        commit(`${MODAL_STORE}/${SET_IS_OPEN}`, {
          target,
          status: true,
        });
      },
    }),
  },
};
</script>
<style lang="scss" scoped>
a{
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

.contact a{
  display: block;
  font-size: 1.09375rem
}

/* 切換 hover 時不同圖示 */
*:has(.hover, .init) {
    >.hover {
      display: none;
    }

    &:hover {
      >.init {
        display: none;
      }

      >.hover {
        display: inherit;
      }
    }
  }

@media screen and ( max-width: 992px ){
  .jr-logo {
    height: 100px;
  }
}
</style>
