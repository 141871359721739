<template lang="pug">
  .nav-items.d-inline-flex
    li.nav-item.px-2
      router-link.nav-link(:to="{ path: '/console/profiles' }") {{ $t('profile') }}
    li.nav-item.px-2
      a.nav-link.dropdown-toggle(
        href='javascript:;'
        @click="toggleDropdown('schedule')"
      ) {{ $t('schedule') }}
      .dropdown-menu(:class="[state === 'schedule' ?' d-block' :'d-none']")
        router-link.dropdown-item(v-for="item in scheduleLists" :key="item.name" :to="{ path: item.path }") {{ $t(item.name) }}
    li.nav-item.px-2
      a.nav-link.dropdown-toggle(
        href='javascript:;'
        @click="toggleDropdown('class')"
      ) {{ $t('class') }}
      .dropdown-menu(:class="[state === 'class' ?' d-block' :'d-none']")
        router-link.dropdown-item(v-for="item in classLists" :key="item.name" :to="{ path: item.path }")
          | {{ $t(item.name) }}
          b-badge(v-if="item.name === 'vipClassRequest' && notReplyAskConsultant > 0" variant="danger") {{ notReplyAskConsultant }}
    li.nav-item.px-2
      a.nav-link.dropdown-toggle(
        href='javascript:;'
        @click="toggleDropdown('training')"
      ) {{ $t('training') }}
      .dropdown-menu(:class="[state === 'training' ?' d-block' :'d-none']")
        training-list
    //- junior新增的頁面nav

</template>

<script>
import i18n from '@/components/nav/lang';
import dropdowControlMixin from '@/components/nav/headerNav/mixin/dropdowControlMixin.vue';
import navList from '@/components/nav/navList';
import trainingList from '@/components/nav/headerNav/consultantHeaderNav/trainingList';
import lioshutanApi from '@/api';
import sweetalert from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default {
  name: 'ConsultantHeaderNav',

  components: {
    trainingList,
  },

  mixins: [dropdowControlMixin],

  i18n,

  data() {
    return {
      scheduleLists: navList.consultant.scheduleLists,
      classLists: navList.consultant.classLists,
      notReplyAskConsultant: 0,
      consultantPaymentCheck: false,
    };
  },

  // 顧問被詢問課程每次換router都須重撈資料 not-reply 之後會改成socket推
  watch: {
    $route: {
      async handler() {
        this.getNotReplyAskConsultant();
        this.queryConsultantPaymentCheck();
      },
    },
  },

  created(){
    // this.getNotReplyAskConsultant();
  },

  methods: {
    async getNotReplyAskConsultant(){
      const result = await lioshutanApi.consultant.getNotReplyAskConsultant();
      this.notReplyAskConsultant = result.data.data.no_reply_amount;
      this.$store.dispatch('consultant/setVipNoReplyRequestsAmount', this.notReplyAskConsultant);
    },
    // 檢查顧問如果沒有填寫帳戶資訊的話 顯示警告並導頁至顧問profile
    async queryConsultantPaymentCheck(){
      const result = await lioshutanApi.consultant.queryConsultantPaymentCheck();
      this.consultantPaymentCheck = result.data.data.exist;
      if (!this.consultantPaymentCheck && window.location.pathname !== '/console/profiles') {
        await sweetalert.fire({
          title: 'Please submit your bank information before your start teaching!',
          icon: 'warning',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 3000,
        });
        window.location.href = '/console/profiles';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.router-link-active {
  font-weight: 500;
}
.dropdown-item .badge {
    right: 0;
    margin-top: 7px;
}
</style>
