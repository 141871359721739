<template lang="pug">
b-modal(v-model="show" size="lg" centered hide-header hide-footer @change="initDataAndClose")
  template(v-if="step === 1")
    .d-flex.flex-column.align-items-center
      .w-100
        .container
          .d-flex.flex-column.align-items-center.justify-content-center
            button.btn.btn-link.jr-home-main-color.p-0.position-absolute(style="top: -30px; left: 0;" @click="backToParentPage") < 返回帳號選擇
            h3.jr-home-main-color.my-3 新增孩子帳號
            p 請輸入您的孩子 wuwow Jr 帳號
            .w-75
              .form-group.mx-auto
                input.form-control.mb-3(v-model="student.account" placeholder="輸入孩子的手機號碼")
                input.form-control.mb-3(v-model="student.password" type="password" placeholder="密碼")
                button.btn.btn-primary.btn-block(@click="sendFindBindStudentInfoRequest") 完成

  template(v-if="step === 2")
    .d-flex.flex-column.align-items-center
      h3.my-4.jr-home-main-color 確認資料
      .w-50.mobile-w-100
        .container
          button.btn.btn-link.jr-home-main-color.p-0.position-absolute(style="top: -30px; left: 0;" @click="step = 1") < 返回上一步
          .bg-light.rounded.my-3.mx-2.d-flex.flex-column.align-items-center.justify-content-center
            .ratio.ratio-1x1.bg-dark.d-flex.flex-column.justify-content-center.align-items-center.rounded(style="width: 130px;")
              img.w-100.rounded(:src="studentInfo.photo" :alt="studentInfo.chineseName")
            h5.mb-0.mt-3 {{ studentInfo.chineseName }}
            h6.mt-2.text-dark Level {{ studentInfo.level }}
            .w-100
              .form-group.w-100.mt-3.mb-5
                select.form-control.mb-3(v-model="form.relation")
                  option(value="" disabled selected hidden) 請選擇關係
                  option(
                    v-for="(data, index) in relationCollection" :key="index"
                    :value="data"
                  ) {{ $t(`relation_of_.${data}`) }}
                input.form-control(
                  v-if="form.relation === RELATION.OTHER"
                  v-model="form.other"
                  placeholder="例：伯父、叔叔、阿姨、姑姑等等.."
                )
              button.btn.btn-primary.btn-block(
                :disabled="isRelationOrOtherEmpty"
                @click="sendCreateRelationRequest"
              ) 完成

</template>

<script>
import RELATION from '@/constants/relation';

import {
  mapState,
} from 'vuex';
import { v4 } from 'uuid';
import sweetalert from 'sweetalert2';

import modalMixin from '@/mixins/modal';

export const uuid = v4();

import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';

export default {
  mixins: [
    modalMixin,
  ],

  data: () => ({
    RELATION,
    relationCollection: [],
    form: {
      id: 0,
      relation: '',
      other: '',
    },
    studentInfo: {
      chineseName: '',
      englishName: '',
      photo: '',
      id: 0,
      level: 0,
    },
    step: 1,
    student: {
      account: '',
      password: '',
    },
  }),
  computed: {
    isRelationOrOtherEmpty() {
      const isRelationEmpty = this.form.relation === '';
      const isOtherEmpty = this.form.relation === RELATION.OTHER && this.form.other === '';
      return isRelationEmpty || isOtherEmpty;
    },
    ...mapState({
      token: (state) => state.auth.token,
    }),
  },

  created() {
    this.initModal(uuid, {
      status: false,
    });
  },

  methods: {
    initDataAndClose() {
      this.close();
      this.initData();
    },
    initData() {
      this.relationCollection = [];
      this.form = {
        id: 0,
        relation: '',
      };
      this.studentInfo = {
        chineseName: '',
        englishName: '',
        photo: '',
        id: 0,
        level: 0,
      };
      this.step = 1;
      this.student = {
        account: '0930678789',
        password: 'lst123456',
      };
    },
    backToParentPage() {
      this.close();
    },
    async sendCreateRelationRequest() {
      const formData = new FormData();
      formData.append('relation_other', this.form.other);
      formData.append('relation', this.form.relation);
      formData.append('id', this.form.id);

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/create-relation`,
          formData,
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
        console.log(response);
      } catch (error) {
        await sweetalert({
          title: '錯誤',
          type: 'error',
        });
        return;
      }

      this.close();
      this.initData();

      try {
        await sweetalert({
          title: '<strong class="text-primary">大功告成</strong><br><br><img src="https://cdn2.wuwow.tw/wuwow-junior/correct.png"><br>',
          html: '<br>您已新增完成',
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 3000,
        });
      } catch (error) {
        // TODO: 垃圾套件 不知道為啥會噴錯
      }

      this.$emit('when-create-success');
    },
    async sendFindBindStudentInfoRequest() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/register/bind-student`,
          {
            account: this.student.account,
            password: this.student.password,
          }
        );

        if (!response.data.data.status) {
          await sweetalert({
            title: '查無此小孩',
            type: 'warning',
          });
          return;
        }

        this.form.id = response.data.data.student.id;
        this.relationCollection = response.data.data.relationEnum;

        this.studentInfo.chineseName = response.data.data.student.chinese_name;
        this.studentInfo.englishName = response.data.data.student.english_name;
        this.studentInfo.photo = response.data.data.student.photo;
        this.studentInfo.id = response.data.data.student.id;
        this.studentInfo.level = response.data.data.student.level;

        this.step = 2;
      } catch (error) {
        await sweetalert({
          title: '錯誤',
          type: 'error',
        });
        return;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '@/styles/parent/modal.scss';
@media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
  .mobile-w-100 {
    width: 100% !important;
  }
}
.btn-primary:hover {
  color: #fff;
  background-color: #005BBA;
  border-color: #005BBA;
}
input::placeholder {
  color: #D1D1D1;
}
</style>
<style lang="scss">
  .swal2-container {
    background-image: url(https://cdn2.wuwow.tw/wuwow-junior/vip-background-web.svg);
    background-size: cover;
    .swal2-modal {
      // background: rgba(255, 255, 255, 0.75) !important;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 0px 14px rgba(208, 208, 208, 0.25);
      border-radius: 15px;
    }
    .swal2-success-circular-line-right {
      background: #ffffff00;
    }
  }
</style>
