import consultant from '@/api/src/consultant';
import classroom from '@/api/src/classroom';
import common from '@/api/src/common';
import notification from '@/api/src/notification';
import vip from '@/api/src/vip';
import suitable from '@/api/src/suitable';
import bookingClass from '@/api/src/bookingClass';
import parent from '@/api/src/parent';
import vipMgm from '@/api/src/vipMgm';
import personnel from '@/api/src/personnel';
import faq from '@/api/src/faq';

import summerCamp from '@/api/src/summerCamp';

import supplementaryAgreement from '@/api/src/supplementaryAgreement';
import renewalReminder from '@/api/src/renewalReminder';

export default {
  vip: new vip(),
  consultant: new consultant(),
  classroom: new classroom(),
  common: new common(),
  notification: new notification(),
  suitable: new suitable(),
  bookingClass: new bookingClass(),
  parent: new parent(),
  vipMgm: new vipMgm(),
  personnel: new personnel(),
  faq: new faq(),
  summerCamp: new summerCamp(),
  supplementaryAgreement: new supplementaryAgreement(),
  renewalReminder: new renewalReminder(),
};
