const TOKEN_KEY = 'wuwow_token';
const FUNCTION_KEY = 'functions';
const LANGUAGE_KEY = 'language';
const ACCOUNT_ROLE_KEY = 'account_role';
const VIP_ID_KEY = 'vipId';
const WUWOW_JUNIOR_VISITED = 'wuwow_junior_visited';
const PUSHER_INFO = 'pusher_info';

export default {
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },
  set token(token) {
    localStorage.setItem(TOKEN_KEY, token);
  },
  get token() {
    return localStorage.getItem(TOKEN_KEY) ?? '';
  },

  removeFunctions() {
    localStorage.removeItem(FUNCTION_KEY);
  },
  set functions(token) {
    localStorage.setItem(FUNCTION_KEY, token);
  },
  get functions() {
    const localStorageFunction = localStorage.getItem(FUNCTION_KEY);
    const afterParse = JSON.parse(localStorageFunction);
    return afterParse ?? [];
  },

  removeLanguage() {
    localStorage.removeItem(LANGUAGE_KEY);
  },
  set language(lang) {
    localStorage.setItem(LANGUAGE_KEY, lang);
  },
  get language() {
    return localStorage.getItem(LANGUAGE_KEY);
  },

  removeRole() {
    localStorage.removeItem(ACCOUNT_ROLE_KEY);
  },
  set role(role) {
    localStorage.setItem(ACCOUNT_ROLE_KEY, role);
  },
  get role() {
    return localStorage.getItem(ACCOUNT_ROLE_KEY);
  },

  removeVipId() {
    localStorage.removeItem(VIP_ID_KEY);
  },
  set vipId(vipId) {
    localStorage.setItem(VIP_ID_KEY, vipId);
  },
  get vipId() {
    return localStorage.getItem(VIP_ID_KEY);
  },

  set wuwowJuniorVisited(value) {
    localStorage.setItem(WUWOW_JUNIOR_VISITED, value);
  },
  get wuwowJuniorVisited() {
    return localStorage.getItem(WUWOW_JUNIOR_VISITED);
  },

  removePusherInfo() {
    localStorage.removeItem(PUSHER_INFO);
  },
  set pusherInfo(info) {
    localStorage.setItem(PUSHER_INFO, JSON.stringify(info));
  },
  get pusherInfo() {
    const pusherInfo = JSON.parse(localStorage.getItem(PUSHER_INFO));
    let setResult = true;
    Object.values(pusherInfo).forEach((value) => {
      if (!value || value === ''){
        setResult = false;
      }
    });
    // 若 pusher info都有值才回傳資訊, 此專案沒有model 所以直接在取得的地方做檢查 By Asa
    return setResult ? pusherInfo : false;
  },
};
