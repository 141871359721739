var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "size": "lg",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "change": _vm.initDataAndClose
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.step === 1 ? [_c('button', {
    staticClass: "btn btn-link jr-home-main-color p-0 position-absolute",
    staticStyle: {
      "top": "-30px",
      "left": "0"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("< 返回帳號選擇")]), _c('div', {
    staticClass: "rounded my-3 mx-2 d-flex flex-column align-items-center justify-content-center"
  }, [_c('h3', {
    staticClass: "jr-home-main-color mt-3 mb-5"
  }, [_vm._v("確認資料")]), _c('div', {
    staticClass: "ratio ratio-1x1 bg-dark d-flex flex-column justify-content-center align-items-center rounded",
    staticStyle: {
      "width": "130px"
    }
  }, [_vm.payload.kid.student.photo ? _c('img', {
    staticClass: "w-100 rounded",
    attrs: {
      "src": _vm.payload.kid.student.photo,
      "alt": _vm.payload.kid.student.chinese_name
    }
  }) : _c('img', {
    staticClass: "w-100",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/kidpic.png",
      "alt": _vm.payload.kid.student.chinese_name
    }
  })]), _c('h5', {
    staticClass: "mb-0 mt-3 jr-text-dark-color"
  }, [_vm._v(_vm._s(_vm.payload.kid.student.chinese_name))]), _c('h6', {
    staticClass: "text-dark mt-2"
  }, [_vm._v("Level " + _vm._s(_vm.payload.kid.student.level))]), _c('div', {
    staticClass: "tablet-w-50 mobile-w-100"
  }, [_c('div', {
    staticClass: "form-group w-100 mb-5 mt-3"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.relation,
      expression: "relation"
    }],
    staticClass: "form-control mb-3",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.relation = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": "",
      "hidden": ""
    }
  }, [_vm._v("請選擇關係")]), _vm._l(_vm.relationCollection, function (data, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": data
      }
    }, [_vm._v(_vm._s(_vm.$t(`relation_of_.${data}`)))]);
  })], 2), _vm.relation === _vm.RELATION.OTHER ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.other,
      expression: "other"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "例：伯父、叔叔、阿姨、姑姑等等.."
    },
    domProps: {
      "value": _vm.other
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.other = $event.target.value;
      }
    }
  }) : _vm._e()]), _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.isOtherEmpty
    },
    on: {
      "click": _vm.sendChangeRelationRequest
    }
  }, [_vm._v("完成")]), _c('button', {
    staticClass: "jr-home-main-color btn btn-link btn-block",
    on: {
      "click": function ($event) {
        _vm.step = 2;
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "trash"
    }
  }), _vm._v(" 移除帳號")], 1)])])] : _vm._e(), _vm.step === 2 ? [_c('h3', {
    staticClass: "mt-4 jr-home-main-color text-center"
  }, [_vm._v("確定要移除此帳號嗎？")]), _c('button', {
    staticClass: "btn btn-link jr-home-main-color p-0 position-absolute",
    staticStyle: {
      "top": "-30px",
      "left": "0"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("< 返回帳號選擇")]), _c('div', {
    staticClass: "rounded my-3 mx-2 d-flex flex-column align-items-center justify-content-center",
    staticStyle: {
      "height": "300px"
    }
  }, [_c('div', {
    staticClass: "ratio ratio-1x1 bg-dark d-flex flex-column justify-content-center align-items-center rounded",
    staticStyle: {
      "width": "130px"
    }
  }, [_vm.payload.kid.student.photo ? _c('img', {
    staticClass: "w-100 rounded",
    attrs: {
      "src": _vm.payload.kid.student.photo,
      "alt": _vm.payload.kid.student.chinese_name
    }
  }) : _c('img', {
    staticClass: "w-100",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/kidpic.png",
      "alt": _vm.payload.kid.student.chinese_name
    }
  })]), _c('h5', {
    staticClass: "mb-0 mt-2 jr-text-dark-color"
  }, [_vm._v(_vm._s(_vm.payload.kid.student.chinese_name))]), _c('h6', {
    staticClass: "mt-1 text-dark"
  }, [_vm._v("Level " + _vm._s(_vm.payload.kid.student.level))]), _c('div', {
    staticClass: "text-center mt-4 w-75"
  }, [_c('button', {
    staticClass: "w-25 btn btn-outline-primary mr-3 px-0",
    on: {
      "click": function ($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v("取消")]), _c('button', {
    staticClass: "w-25 btn btn-primary ml-3 px-0",
    on: {
      "click": _vm.sendDeleteRelationRequest
    }
  }, [_vm._v("確認")])])])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }