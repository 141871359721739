<template lang="pug">
  b-modal#webSocketInfoModal(
    :ref="modalRef"
    :title="$t('infoModalTitle')"
    :ok-title="$t('enter')"
    :cancel-title="$t('cancel')"
    centered
    @ok="accessClassroom"
    header-bg-variant="primary"
  )
   h4.text-center
    | {{ $t('enterClassNotice') }}
    font-awesome-icon.text-warning.ml-2(icon="lightbulb")

</template>

<script>
import i18n from '@/components/notifyModal/lang.js';
import socketConstants from '@/constants/socket.js';

export default {
  name: 'WebSocketInfoModal',

  props: {
    socketData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  i18n,

  data(){
    return {
      modalRef: socketConstants.MODAL_REF.ACCESS_CLASSROOM,
    };
  },

  methods: {
    accessClassroom(){
      if (this.socketData.action === socketConstants.EVENT.ACCESS_CLASSROOM) {
        const classRoomUrl = `${process.env.VUE_APP_BASE_API}/webex/access_webex_classroom?ci=${this.socketData.type_data.ci}&access_token=${this.socketData.type_data.act}`;
        window.open(classRoomUrl);
      }
    },
  },
};
</script>
