import axios from '@/api/utils/axios-helper.js';

export default class VipMgm {
  /**
   * 取得推薦方法
   * @returns {Promise<Object>} 回傳
   */
  async getVipRecommendInfo() {
    const res = await axios({
      url: '/v3/taichi-external/student/mgm/info',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return res;
  }
  /**
   * 取得推薦紀錄
   * @param ac: record
   * @returns {Promise<Object>} 回傳
   */
  async getVipRecommendList(params) {
    const res = await axios({
      url: '/v3/taichi-external/student/mgm/lists',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return res;
  }
  /**
   * 抽獎券紀錄
   * @param ac: tickets
   * @returns {Promise<Object>} 回傳
   */
  async getVipTicketList(params) {
    const res = await axios({
      url: '/v3/taichi-external/student/mgm/ticket-lists',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return res;
  }
  /**
   * 取得獎品紀錄
   * @returns {Promise<Object>} 回傳學員專屬業務(業務名稱、業務推薦碼)
   */
  async getVipAwardList(params) {
    const res = await axios({
      url: '/v3/taichi-external/student/mgm/prize-lists',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return res;
  }
  /**
   * 取得vip分享資訊
   * @returns {Promise<Object>} 回傳
   */
  async getVipShareContents(params) {
    const result = await axios({
      url: '/v3/marketing/share-contents',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return result;
  }
  /**
   * 取得獎品紀錄
   * @returns {Promise<Object>} 回傳
   */
  async editVipShareContents(params) {
    const apiParams = {
      platform: 'wuwow_junior',
      codename: params.recommendCode,
      content: params.content,
    };
    const result = await axios({
      url: `/v3/marketing/share-content/${params.recommendCode}`,
      method: 'put',
      data: apiParams,
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return result;
  }
  /**
   * 取得八週年慶抽獎資訊
   * @returns {Promise<Object>} 回傳
   */
  async getAnniversaryRecommendInfo() {
    const result = await axios({
      url: '/v3/taichi-external/student/mgm/anniversary-info',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return result;
  }
  /**
   * 取得獎品紀錄
   * @returns {Promise<Object>} 回傳
   */
  async getAnniversaryTicketList() {
    const result = await axios({
      url: '/v3/taichi-external/student/mgm/anniversary-ticket-lists',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return result;
  }
}
