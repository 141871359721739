<template lang="pug">
div
  ul.ul-personal-menu
    li.li-personal-menu(v-for="item in accountLists" :key="item.name")
      router-link.lnk-personal-menu(:to="{ path: item.path }")
        | {{ $t('layout.' + item.name) }}
      a.text-center.lnk-personal-menu(href='javascript:;' @click='logout')
        | {{ $t('logout' )}}
</template>
<script>
import { logout } from '@/composable/auth';
import navList from '@/components/nav/navList';
export default {
  name: 'ParentAccountLink',

  data() {
    return {
      accountLists: navList.parent.accountLists,
    };
  },

  methods: {
    async logout() {
      logout();
    },
  },
};
</script>
