import storage from '@/utils/localStorage';

const types = {
  SET_CURRENT_REMOVE_INFO_DATA: 'SET_CURRENT_REMOVE_INFO_DATA',
  SET_VIP_ID: 'SET_VIP_ID',
};

const state = {
  currentRemoveInfoData: {},
  vipId: storage.vipId,
};

const mutations = {
  [types.SET_CURRENT_REMOVE_INFO_DATA]: (state, data) => {
    state.currentRemoveInfoData = data;
  },
  [types.SET_VIP_ID]: (state, vipId) => {
    state.vipId = vipId;
    storage.vipId = vipId;
  },
};

const actions = {
  async setCurrentRemoveInfoData({ commit }, data) {
    await commit(types.SET_CURRENT_REMOVE_INFO_DATA, data);
  },
  setVipId({ commit }, vipId) {
    commit(types.SET_VIP_ID, vipId);
  },
  removeVipId({ commit }) {
    commit(types.SET_VIP_ID, null);
    storage.removeVipId();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
