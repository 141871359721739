import storage from '@/utils/localStorage';

const types = {
  SET_TOKEN: 'SET_TOKEN',
  SET_ROLE: 'SET_ROLE',
};

const state = {
  token: storage.token,
  role: storage.role,
};

const mutations = {
  [types.SET_TOKEN]: (state, token) => {
    state.token = token;
    storage.token = token;
  },
  [types.SET_ROLE]: (state, role) => {
    state.role = role;
    storage.role = role;
  },
};

const actions = {
  setToken({ commit }, token) {
    commit(types.SET_TOKEN, token);
  },
  removeToken({ commit }) {
    commit(types.SET_TOKEN, null);
    storage.removeToken();
  },
  setRole({ commit }, role) {
    commit(types.SET_ROLE, role);
  },
  removeRole({ commit }) {
    commit(types.SET_ROLE, null);
    storage.removeRole();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
