export default {
  CURRENT_PLATFORM: 2,
  GENDER: {
    MALE: 'male', // 男生
    FEMALE: 'female', // 女生
  },
  RELATION: {
    FATHER: 'father', // 爸爸
    MOTHER: 'mother', // 媽媽
    UNCLE: 'uncle', // 伯叔舅丈
    AUNT: 'aunt', // 伯嬸舅姑姨
    GRANDFATHER: 'grandfather', // 祖父
    GRANDMOTHER: 'grandmother', // 祖母
  },
  LANGUAGE: {
    TW: 'tw',
    EN: 'en',
  },
  ROLE: {
    STUDENT: 'student',
    PARENT: 'parent',
    TEACHER: 'teacher',
    CENTER: 'center',
  },
  EDUCATION: {
    BACHELOR: 'bachelor',
    MASTER: 'master',
    DOCTOR: 'doctor',
  },
  MEDIA_SOURCE: {
    NAME: 'jr',
  },
};
