<template lang="pug">
b-modal(
  :visible="supplementaryAgreement"
  size="lg"
  header-text-variant="black"
  header-bg-variant="primary"
  :hide-footer="true"
  hide-header-close
)
  template(v-slot:modal-header)
    h5(class="modal-title") 增補協議條款
    button(type="button" class="close" @click="handleClose")
      span(aria-hidden="true") x
  div(class="title")
    h3 {{ vipContractAdditionTerm.title }}
  div(class="content ckeditor-table-style")
    p(v-html="vipContractAdditionTerm.waitingDirections")
  div(class="reply-choices")
    div(class="mx-1")
      input(type="radio" value="approved" v-model="choice" id="agreeBtn" class="mr-1")
      label(class="form-check-label" for="agreeBtn")  同意
    div(class="mx-1")
      input(type="radio" value="rejected" v-model="choice" id="rejectBtn" class="mr-1")
      label(class="form-check-label" for="rejectBtn") 拒絕
  div(class="mx-1 mt-3 ckeditor-table-style")
    p(v-show="choice === 'approved'" class="" v-html="vipContractAdditionTerm.approvedDirections")
    p(v-show="choice === 'rejected'" class="" v-html="vipContractAdditionTerm.rejectedDirections")
  div(v-if="vipContractAdditionTerm.note !== '' && choice === 'approved'" class="mx-1 mt-3 ckeditor-table-style")
    h2(class="agree-gift-content") 同意後贈送好禮
    p(v-html="vipContractAdditionTerm.note")
  div(class="submit-btn")
    button(@click="handleSendResults" :disabled="!choice") 送出

</template>

<script>
import { mapState, mapActions } from 'vuex';
import i18n from '@/components/parents/profileInfo/lang';

export default {
  name: 'RemoveParentInfo',

  i18n,

  data() {
    return {
      supplementaryAgreement: false,
      choice: null,
      identity: localStorage.getItem('account_role'),
    };
  },

  computed: {
    ...mapState('supplementaryAgreement', [
      'confirmStatusInfo',
      'vipContractAdditionTerm',
    ]),
  },

  created() {
    this.getCheckStatus();
  },

  methods: {
    ...mapActions('supplementaryAgreement', [
      'getCheckAdditionTerm',
      'getVipContractAdditionTerm',
      'sendResults',
      'handleModalClose',
    ]),
    // 取得是否有增補協議
    async getCheckStatus() {
      await this.getCheckAdditionTerm(); // 先呼叫 Vuex action 更新 confirmStatus
      // 打完增補協議後，如果是家長，就不用再執行下面的動作
      if (this.identity === 'parent') {
        return;
      }
      // 接著檢查 this.confirmStatus 的值
      if (this.confirmStatusInfo.checkStatus === true) {
        await this.getVipContractAdditionTerm(this.confirmStatusInfo.vipContractAdditionTerm);
        this.supplementaryAgreement = true;
      }
    },
    // 送出結果 同意approved , 拒絕rejected
    async handleSendResults() {
      await this.sendResults({ choice: this.choice });
      this.supplementaryAgreement = false;
    },
    // 使用者點擊X後，回傳 waiting 給後端，客戶下次重整時會在打開彈窗
    handleClose() {
      this.handleModalClose();
      this.supplementaryAgreement = false;
    },
  },

};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  overflow: hidden;
  padding: 8px;
  border: 1px solid #555755;
  border-radius: 10px;
  min-height: 500px;
}

.reply-choices {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.submit-btn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  button {
    padding: 0.25rem 0.75rem;
    background-color: #fff;
    border: 1px solid #82c1ea;
    border-radius: 10px;

    &:hover {
      color: #FFF;
      background-color: #82c1ea;
    }
  }

  button:disabled {
    background-color: dimgrey;
    cursor: no-drop;
    color: linen;
    opacity: 0.5;
  }
}

.agree-gift-content {
  font-size: 1rem;
  font-weight: bold;
}
</style>

<style lang="scss">
/* 以下為ckeditor樣式調整 */
.ckeditor-table-style {
  ol {
    list-style: decimal;
    margin: revert;
    padding: revert;
  }

  ul {
    list-style: disc;
    margin: revert;
    padding: revert;
  }

  a {
    color: #4c41c1;
  }

  table {
    width: 100%;
  }

  td {
    border: 1px solid #bfbfbf;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 21px;
  }

  h3 {
    font-size: 16.38px;
  }

  h4 {
    font-size: 14px;
  }
}</style>
