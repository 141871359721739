export default {
  VIP: {
    MGM_RECOMMEND_URL: 'https://www.wuwow.tw/event/regular_mgmevent/',
    CREDIT_CARD_URL: '/spgateway/credit-card-bind-paid',
    TO_PAY_URL: '/2018_double_eleven/taichi-first-paid',
  },
  CONSULTANT_TRIANING: {
    WUWOW_MEETING_CENTER_DOWNLOAD_WINDOWS_URL: 'https://cdn.wuwow.tw/others/Windows_Meeting.exe',
    WUWOW_MEETING_CENTER_DOWNLOAD_MAC_URL: 'https://apps.apple.com/app/id1523443736',
    SELF_TRAINING_CLASSROOM_CONSULTANT_URL: process.env.VUE_APP_BASE_API + '/meeting/test/access_training_room?ur=3&ty=training',
    SELF_TRAINING_CLASSROOM_VIP_URL: process.env.VUE_APP_BASE_API + '/meeting/test/access_training_room?ur=3&ty=training',
    TRAINING_CLASSROOM_URL: process.env.VUE_APP_BASE_API + '/meeting/test/training_room?ty=training1',
    QUESTIONNAIRE_URL: 'https://forms.gle/So5zbEyrtY4bMPSw8',
  },
  CLASSROOM: {
    ENTER_CLOUD_CLASSROOM_PATH: '/webex/access_webex_classroom',
  },
  IMAGE: {
    CROWN: 'https://cdn2.wuwow.tw/pictures/analysis/crown.png',
  },
};
