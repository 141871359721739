export const IS_OPEN = 'isOpen';
export const PAYLOAD = 'payload';

export const SET_IS_OPEN = 'setIsOpen';
export const SET_PAYLOAD = 'setPayload';
export const ADD_TARGET = 'addTarget';

const state = {
  [IS_OPEN]: {},
  [PAYLOAD]: {},
};

const mutations = {
  [SET_IS_OPEN]: (state, { target, status }) => {
    state[IS_OPEN][target] = status;
  },
  [SET_PAYLOAD]: (state, { target, payload }) => {
    state[PAYLOAD][target] = payload;
  },
  [ADD_TARGET]: (state, { key, status = false, payload = null }) => {
    state[IS_OPEN] = {
      ...state[IS_OPEN],
      [key]: status,
    };

    state[PAYLOAD] = {
      ...state[PAYLOAD],
      [key]: payload,
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
