<template lang="pug">
.row.nav-bar
  //- 漢堡條(手機/平板才顯示)
  button.bar-btn.jr-main-bg.col-2.navbar-toggler.mobile-sidebar-toggler.d-lg-none.mr-auto.h-100(@click="toggleSideBar()")
    font-awesome-icon(icon="bars")
  //- LOGO
  .col-lg-2.col-8.d-flex.px-0.h-100
    a.logo(:href="`${newJrUrl}/student`")
      img.d-none.d-lg-flex.logo-img.w-100.h-100(src="https://cdn2.wuwow.tw/images/white_logo_header.png" class="img-logo" alt="Wuwow")
      img.d-flex.d-lg-none.logo-img.h-100(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg" class="img-logo" alt="Wuwow")
  //- NAV選項(手機/平板不顯示)
  header-nav.col-lg-8.d-none.d-lg-flex.header-nav(:role="role")
  //- 個人資料
  .col-lg-2.col-2.nav-account
    vip-account(v-if="role === student")
    parent-account(v-if="role === parent")
    consultant-account(v-if="role === teacher || role === center")
</template>

<script>
import consultantAccount from '@/components/consultant/account';
import headerNav from '@/components/nav/headerNav';
import vipAccount from '@/components/vip/account';
import parentAccount from '@/components/parent/account/index.vue';
import { mapState } from 'vuex';
import commonConstants from '@/constants/common';

export default {
  name: 'VHeader',

  components: {
    headerNav,
    consultantAccount,
    vipAccount,
    parentAccount,
  },

  data() {
    return {
      student: commonConstants.ROLE.STUDENT,
      teacher: commonConstants.ROLE.TEACHER,
      center: commonConstants.ROLE.CENTER,
      parent: commonConstants.ROLE.PARENT,
      newJrUrl: new URL(window.location).origin,
    };
  },

  computed: {
    ...mapState({
      role: state => state.auth.role,
    }),
  },

  watch: {
    // 技術債
    $route(){
      const element = document.body;
      element.classList.remove('sidebar-mobile-show');
    },
  },

  methods: {
    toggleSideBar(){
      // 技術債
      const element = document.body;
      if (element.classList.contains('sidebar-mobile-show')){
        element.classList.remove('sidebar-mobile-show');
      } else {
        element.classList.add('sidebar-mobile-show');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #dee2e6;
  border: 2px solid #82c1ea;
}

.member-overlap-item {
  margin-right: -10px;
}

.group {
  min-height: 40px;
  height: 66px;
  line-height: 66px;
}
</style>
