import axios from 'axios';
import storage from '@/utils/localStorage';
import commonConstants from '@/constants/common';
// import { removeToken } from './auth';

const METHOD = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PATCH: 'patch',
};
// Create axios instance
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 0, // Request timeout
});

// Request intercepter
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('wuwow_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    if (storage.role === commonConstants.ROLE.PARENT){
      insertParentdata(config);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 家長須帶入學員id
function insertParentdata(config){
  const param = {
    vipId: storage.vipId,
  };
  switch (config.method){
    case METHOD.GET:
      if (config.params === undefined){
        config.params = param;
      } else {
        Object.assign(config.params, param);
      }
      break;
    case METHOD.POST:
      if (config.data instanceof FormData){
        const form = config.data;
        form.append('vipId', param.vipId);
        config.data = form;
      } else {
        Object.assign(config.data, param);
      }
      break;
    case METHOD.DELETE:
      config.data = param;
      break;
    case METHOD.PATCH:
      if (config.data instanceof FormData){
        const form = config.data;
        form.append('vipId', param.vipId);
        config.data = form;
      } else {
        Object.assign(config.data, param);
      }
      break;
    default:
      break;
  }
}

// response pre-processing
instance.interceptors.response.use(
  (response) => {
    if (response.status === 204) {
      return Promise.reject('is Empty');
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401 && document.location.pathname !== '/') {
      if (!(process.env.VUE_APP_ALLOW_UNAUTHORIZED === 'true')) {
        // removeToken();
        // 如果因為401被踢出去 紀錄當前頁面至localStorage 登入時導回此頁
        localStorage.setItem('wuwow-junior-link', document.location.pathname);
        // document.location.href = '/';
        document.location.href = '/?login=true'; // api 401時，導回首頁並顯示登入介面彈窗
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
