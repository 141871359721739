<template lang="pug">
ul.nav
  li.nav-item
    router-link.nav-link(:to="{ path: '/dojo/classRoom' }") {{ $t('auditClass')}}
  li.nav-item(v-if="getCanOrderClass")
    .nav-link {{ $t('bookClass') }}
    ul.subnav
      span.subnav-link.subnav-sort {{ $t('book') }}
      li.subnav-item(v-for="item in bookLists" :key="item.name")
        router-link.dropdown-item.subnav-link(:to="{ path: item.path }") {{ $t(item.name) }}
      span.subnav-link.subnav-sort {{ $t('setRecord') }}
      li.subnav-item(v-if="item.authorityTag", v-for="item in setRecordLists" :key="item.name")
        router-link.dropdown-item.subnav-link(:to="{ path: item.path }") {{ $t(item.name) }}
  li.nav-item
    .nav-link {{ $t('classRecord') }}
    ul.subnav
      li.subnav-item(v-for="item in classRecordLists" :key="item.name")
        //- router-link.dropdown-item.subnav-link(:to="{ path: item.path }") {{ $t(item.name) }}
        router-link.dropdown-item.subnav-link(v-if="item.authorityTag && item.name !== 'learnRecords'"  :to="{ path: item.path }") {{ $t(item.name) }}
        a.dropdown-item.subnav-link(v-if="item.authorityTag && item.name === 'learnRecords'" :href="`${newJrUrl}${item.path}`") {{ $t(item.name) }}
  li.nav-item
    router-link.nav-link(:to="{ path: '/dojo/vip_mgm' }") {{ $t('vipMgm')}}
  li.nav-item
    router-link.nav-link(:to="{ path: '/dojo/guide' }") {{ $t('guide')}}
  //- 為了防止正常目錄在手機瀏覽器被蓋住(By 4年前寫的人)
  li(style="height: 40px;")
    p

</template>

<script>
import i18n from '@/components/nav/lang';
import navList from '@/components/nav/navList';
import lioshutanApi from '@/api';
export default {
  name: 'ParentLeftNav',

  props: {
  },

  i18n,

  data() {
    return {
      newJrUrl: new URL(window.location).origin,

      bookLists: navList.parent.bookLists,

      setRecordLists: navList.parent.setRecordLists,

      classRecordLists: navList.parent.classRecordLists,
    };
  },

  computed: {
    getCanOrderClass() {
      return this.$store.getters['vipAccount/getCanOrderClass'];
    },
  },

  async created() {
    await this.getVipPointSuggest();
  },

  methods: {
    async getVipPointSuggest() {
      if (localStorage.getItem('wuwow_token')){
        const res = await lioshutanApi.vip.getContractElapsedTime();
        await this.$store.dispatch('vipAccount/setPlatform', res.data.platform);
      }
    },
  },
};
</script>
