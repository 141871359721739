import axios from '@/api/utils/axios-helper.js';

export default class Parent {
  /**
   * 取得家長個人資料
   * @returns {Promise<Array>} 家長資訊 > relation: 關係, chinese_name: 中文姓名, english_name: 英文姓名,
   * email: 信箱, phone: 手機號碼, gender: 性別
   */
  async getParentInfo() {
    const response = await axios({
      url: '/v3/human-resource/parent/profile',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
 * 編輯家長個人資料
 * @returns {Promise<Array>} 家長資訊 > relation: 關係, chinese_name: 中文姓名, english_name: 英文姓名,
 * email: 信箱, phone: 手機號碼, gender: 性別
 */
  async editParentInfo(data) {
    const response = await axios({
      url: '/v3/human-resource/parent/profile',
      method: 'post',
      headers: { 'content-type': 'multipart/form-data' },
      data: data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得學員家長資訊
   * @returns {Promise<Array>} 家長資訊 > relation: 關係, chinese_name: 中文姓名, english_name: 英文姓名,
   * email: 信箱, cell_phone_number: 手機號碼, gender: 性別
   */
  async getInfo() {
    const response = await axios({
      url: '/v3/taichi-external/student/parent/list',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 新增學員家長資訊
   * @param {Object} re: 關係, cn: 中文姓名, en: 英文姓名, em: 信箱, cpn: 手機號碼, ge: 性別
   * @returns {Promise<Array>} 新增成功或失敗透過http code判斷
   */
  async addInfo(data) {
    const response = await axios({
      url: '/v3/taichi-external/student/parent/info',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 新增學員家長資訊
   * @param {Object} re: 關係, cn: 中文姓名, en: 英文姓名, em: 信箱, cpn: 手機號碼, ge: 性別
   * @returns {Promise<Array>} 新增成功或失敗透過http code判斷
   */
  async editInfo(data) {
    const response = await axios({
      url: '/v3/taichi-external/student/parent/info',
      method: 'patch',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 刪除學員家長資訊
   * @param {Object} vpi: 家長資訊id
   * @returns {Promise<Object>} 200: success, 400: Bad Request
   */
  async removeInfo(data) {
    const response = await axios({
      url: '/v3/taichi-external/student/parent/info',
      method: 'delete',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response;
  }

  /**
 * 取得家長變更密碼用的 token ，用於變更密碼連結的 query string
 * @param {Object} token: 變更密碼用的 token
 * @returns {Promise<Object>} 200: success, 400: Bad Request
 */

  async getChangePasswordToken() {
    const response = await axios({
      url: 'v3/human-resource/parent/reset-password-token',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
}
