<template lang="pug">
#training.training.mt-5.home-container
  .row.mx-0
    .col-12.d-flex.justify-content-center.mb-4
      h2.area-title.title.text-lioshutan.rounded-pill.px-4.py-2 精選師資陣容
    .col-12.col-md-8.col-lg-12.mx-auto
      .row
        .square.p-3.col-12.col-lg-4
          .square-content.ratio.ratio-1x1
            img.content-img.w-100(:src="file.training1")
            .content-title.d-flex.align-items-center.justify-content-center
              h3.area-content-title.m-0.text-lioshutan 英文教學專業認證
            .content-text.mb-0.d-flex.align-items-center
              .area-text 全師資擁有 TESOL / TEFL 至少其一的國際教學證照。
        .square.p-3.col-12.col-lg-4
          .square-content.ratio.ratio-1x1
            img.content-img.w-100(:src="file.training2")
            .content-title.d-flex.align-items-center.justify-content-center
              h3.area-content-title.m-0.text-lioshutan  1 年以上的兒美教學經驗
            .content-text.mb-0.d-flex.align-items-center
              .area-text 全師資都擁有至少一年以上的兒美教學經驗。
        .square.p-3.col-12.col-lg-4
          .square-content.ratio.ratio-1x1
            img.content-img.w-100(:src="file.training3")
            .content-title.d-flex.align-items-center.justify-content-center
              h3.area-content-title.m-0.text-lioshutan 定期追蹤回訓
            .content-text.mb-0.d-flex.align-items-center
              .area-text 依照課程評分系統，我們將對顧問進行回訓，穩定每位老師的教學品質。
  //- //- 給手機用的尺寸
  //- .d-block.d-sm-none
  //-   .d-flex.justify-content-center.my-4
  //-     h2.d-inline-block.bg-light.text-lioshutan.rounded-pill.px-4.py-2 精選師資陣容
  //-   .row
  //-     .col-sm-4.ratio.ratio-1x1.my-2
  //-       .px-5.w-100.h-100.bg-light.rounded.shadow.d-flex.flex-column.align-items-center.justify-content-center
  //-         img.my-2.w-100.two-to-one(:src="file.training1")
  //-         h3.my-2.text-lioshutan 英文教學專業認證
  //-         p.my-2 全師資擁有 TESOL / TEFL 至少其一的國際教學證照。
  //-     .col-sm-4.ratio.ratio-1x1.my-2
  //-       .px-5.w-100.h-100.bg-light.rounded.shadow.d-flex.flex-column.align-items-center.justify-content-center
  //-         img.my-2.w-100.two-to-one(:src="file.training2")
  //-         h3.my-2.text-lioshutan 1 年以上的兒美教學經驗
  //-         p.my-2 全師資都擁有至少一年以上的兒美教學經驗。
  //-     .col-sm-4.ratio.ratio-1x1.my-2
  //-       .px-5.w-100.h-100.bg-light.rounded.shadow.d-flex.flex-column.align-items-center.justify-content-center
  //-         img.my-2.w-100.two-to-one(:src="file.training3")
  //-         h3.my-2.text-lioshutan 定期追蹤回訓
  //-         p.my-2 依照課程評分系統，我們將對顧問進行回訓，穩定每位老師的教學品質。

  //- //- 給平板的尺寸
  //- .d-none.d-md-block.d-lg-none
  //-   .d-flex.justify-content-center.my-4
  //-     h2.d-inline-block.bg-light.text-lioshutan.rounded-pill.px-4.py-2 精選師資陣容
  //-   .row
  //-     .col-sm-4.ratio.ratio-1x1.my-2
  //-       .px-3.w-100.h-100.bg-light.rounded.shadow.d-flex.flex-column.align-items-center.justify-content-center
  //-         img.my-1.w-100.two-to-one(:src="file.training1")
  //-         h5.my-1.text-lioshutan 英文教學專業認證
  //-         p.my-2 全師資擁有 TESOL / TEFL 至少其一的國際教學證照。
  //-     .col-sm-4.ratio.ratio-1x1.my-2
  //-       .px-3.w-100.h-100.bg-light.rounded.shadow.d-flex.flex-column.align-items-center.justify-content-center
  //-         img.my-1.w-100.two-to-one(:src="file.training2")
  //-         h5.my-1.text-lioshutan 1 年以上的兒美教學經驗
  //-         p.my-2 全師資都擁有至少一年以上的兒美教學經驗。
  //-     .col-sm-4.ratio.ratio-1x1.my-2
  //-       .px-3.w-100.h-100.bg-light.rounded.shadow.d-flex.flex-column.align-items-center.justify-content-center
  //-         img.my-1.w-100.two-to-one(:src="file.training3")
  //-         h5.my-1.text-lioshutan 定期追蹤回訓
  //-         p.my-2 依照課程評分系統，我們將對顧問進行回訓，穩定每位老師的教學品質。

  //- //- 給電腦的尺寸
  //- .d-none.d-sm-block.d-md-none.d-lg-block
  //-   .d-flex.justify-content-center.my-3
  //-     h2.d-inline-block.bg-light.text-lioshutan.rounded-pill.px-4.py-2 精選師資陣容
  //-   .row
  //-     .col-sm-4.ratio.ratio-1x1.my-2
  //-       .px-5.w-100.h-100.bg-light.rounded.shadow.d-flex.flex-column.align-items-center.justify-content-center
  //-         img.my-2.w-100.two-to-one(:src="file.training1")
  //-         h3.my-2.text-lioshutan 英文教學專業認證
  //-         p.my-2 全師資擁有 TESOL / TEFL 至少其一的國際教學證照。
  //-     .col-sm-4.ratio.ratio-1x1.my-2
  //-       .px-5.w-100.h-100.bg-light.rounded.shadow.d-flex.flex-column.align-items-center.justify-content-center
  //-         img.my-2.w-100.two-to-one(:src="file.training2")
  //-         h3.my-2.text-lioshutan 1 年以上的兒美教學經驗
  //-         p.my-2 全師資都擁有至少一年以上的兒美教學經驗。
  //-     .col-sm-4.ratio.ratio-1x1.my-2
  //-       .px-5.w-100.h-100.bg-light.rounded.shadow.d-flex.flex-column.align-items-center.justify-content-center
  //-         img.my-2.w-100.two-to-one(:src="file.training3")
  //-         h3.my-2.text-lioshutan 定期追蹤回訓
  //-         p.my-2 依照課程評分系統，我們將對顧問進行回訓，穩定每位老師的教學品質。
</template>

<script>
import englishBG from '@/assets/blue_bg2.png';
import blueBG from '@/assets/blue_bg1.png';

import design1 from '@/assets/design_1.png';
import design2 from '@/assets/design_2.png';
import design3 from '@/assets/design_3.png';

import calendarSVG from '@/assets/icon_calendar.svg';
import classSVG from '@/assets/icon_class.svg';
import clockSVG from '@/assets/icon_clock.svg';
import serviceSVG from '@/assets/icon_service.svg';

import training1 from '@/assets/training_1.png';
import training2 from '@/assets/training_2.png';
import training3 from '@/assets/training_3.png';

import 'bootstrap/dist/css/bootstrap.css';

export default {
  data: () => ({
    file: {
      englishBG,
      calendarSVG,
      classSVG,
      clockSVG,
      serviceSVG,
      training1,
      training2,
      training3,
      blueBG,
      design1,
      design2,
      design3,
    },
  }),
};
</script>

<style scoped>
.timeline-fixed-width {
  height: 190px;
}
.timeline-fixed-size {
  aspect-ratio: 1.5 / 1;
}
.w-20 {
  width: 20%;
}
.w-0 {
  width: 0;
}
.border-dashed {
  border: 1px blue dashed;
}
.one-to-two {
  aspect-ratio: 1 / 1.6;
}
.two-to-one {
  aspect-ratio: 2 / 1;
}
</style>
<style lang="scss" scoped>
.training {
  .title {
    margin: 0 auto;
    background-color: white;
  }
  .square {
    .square-content {
      padding: 50px;
      background-color: white;
      border-radius: 7px;
      box-shadow: 0px 3px 6px #00000029;
      .content-img {
        height: 55%;
      }
      .content-title {
        height: 15%;
        text-align: center;
      }
      .content-text {
        height: 30%;
      }
    }
  }
  @media screen and ( max-width: 1600px ){
    .square {
      .square-content {
        padding: 15px;
      }
    }
  }
  @media screen and ( max-width: 991px ){
    .square {
      .square-content {
        padding: 50px;
      }
    }
  }
  @media screen and ( max-width: 414px ){
    .square {
      .square-content {
        padding: 25px;
      }
    }
  }
}
</style>
