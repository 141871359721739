var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "are-personal"
  }, [_c('div', {
    staticClass: "blk-personal-info"
  }, [_c('div', [_vm._v("學員帳號")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-skeleton', {
    attrs: {
      "type": "avatar"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('span', {
    staticClass: "txt-personal-name"
  }, [_c('b-skeleton')], 1), _c('br'), _c('b-skeleton')], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  })], 1), _c('p', {
    staticClass: "txt-personal-info mt-4"
  }), _c('hr'), _c('b-skeleton'), _c('b-skeleton'), _c('hr'), _c('b-skeleton'), _c('b-skeleton'), _c('b-skeleton'), _c('b-skeleton')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }