export default {
  EVENT: {
    ACCESS_CLASSROOM: 'access_classroom',
  },
  TYPE: {
    NOTIFY: 'notify',
  },
  MODAL_REF: {
    ACCESS_CLASSROOM: 'accessClassRoomModal',
  },
};

