var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('add-children-modal', {
    on: {
      "when-create-success": _vm.whenCreateSuccess
    }
  }), _c('edit-children-modal', {
    on: {
      "when-delete-success": _vm.whenDeleteSuccess,
      "when-change-success": _vm.whenChangeSuccess
    }
  }), _vm.showMessage ? [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center mt-5"
  }, [_c('div', {
    staticClass: "message text-center bg-light rounded-pill w-75"
  }, [_c('p', {
    staticClass: "my-2"
  }, [_vm._v(_vm._s(_vm.message))])])])] : _vm._e(), _vm._m(0), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-4 col-md-3 col-6 px-0"
  }, [_c('div', {
    staticClass: "kids-card"
  }, [_c('div', {
    staticClass: "bg-light rounded d-flex flex-column justify-content-center align-items-center",
    staticStyle: {
      "height": "300px",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.openAddChildrenModal
    }
  }, [_vm._m(1), _c('div', {
    staticClass: "card-title jr-main-color"
  }, [_vm._v("新增帳號")])])])]), _vm._l(_vm.children, function (kid, index) {
    return _c('div', {
      key: `kid_${index}`,
      staticClass: "col-sm-4 col-md-3 col-6 px-0"
    }, [_c('div', {
      staticClass: "kids-card"
    }, [_c('div', {
      staticClass: "bg-light rounded d-flex flex-column align-items-center justify-content-center",
      staticStyle: {
        "height": "300px",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.enterPlatform(kid);
        }
      }
    }, [_c('div', {
      staticClass: "ratio ratio-1x1 bg-dark d-flex flex-column justify-content-center align-items-center w-75"
    }, [kid.student.photo ? _c('img', {
      staticClass: "w-100",
      attrs: {
        "src": kid.student.photo,
        "alt": kid.student.chinese_name
      }
    }) : _c('img', {
      staticClass: "w-100",
      attrs: {
        "src": "https://cdn2.wuwow.tw/images/kidpic.png",
        "alt": kid.student.chinese_name
      }
    })]), _c('div', {
      staticClass: "card-title mb-0 mt-3 jr-main-color"
    }, [_vm._v(_vm._s(kid.student.chinese_name))]), _c('h6', {
      staticClass: "text-dark mt-2"
    }, [_vm._v("Level " + _vm._s(kid.student.level))])])]), _c('div', {
      staticClass: "text-center"
    }, [_c('button', {
      staticClass: "btn-edit btn border-0",
      on: {
        "click": function ($event) {
          return _vm.openEditChildrenModal(kid);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "pen"
      }
    }), _vm._v(" 編輯帳號")], 1)])]);
  })], 2)])], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center my-5"
  }, [_c('h3', {
    staticClass: "jr-home-main-color"
  }, [_vm._v("想關注哪位孩子的學習？")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-3 d-flex justify-content-center align-items-center w-50 ratio ratio-1x1",
    staticStyle: {
      "border-width": "5px !important",
      "border-color": "black !important"
    }
  }, [_c('img', {
    staticClass: "add-kid",
    attrs: {
      "src": require("../../assets/login/add_kid_border.png")
    }
  }), _c('img', {
    staticClass: "add-kid",
    attrs: {
      "src": require("../../assets/login/add_kid_icon.png")
    }
  })]);

}]

export { render, staticRenderFns }