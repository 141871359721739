<template lang="pug">
main(class="main")
  div(class="main-box" id="敦煌教材")
    div(class="class-title")
      span(class="first-title")
        | ::Textbook ::
        br
        | 課程教材
    div(class="main-content")
      div(class="main-left")
        div(class="main-left-content")
          div(class="left-icon")
            img(src="@/assets/material_2023/Dunhuang.svg")
            img(src="@/assets/material_2023/close.svg" class="close-icon")
            img(src="@/assets/material_2023/Caves.svg")
          div(class="line")
          div(class="left-content")
            span 與深耕70年外語教育的敦煌教育集團合作，選用 CAVES KIDS 教材，主題內容多元豐富，涵蓋歷史、科技、藝術...，課後提供仿照劍橋兒童英檢題型及練習音檔，按部就班紮實學英文，同時跨領域培養孩子們 21世紀所需的知識能力與素養。
      div(class="main-right")
        div(class="main-right-content")
          img(@click="youtubeModal(materialVideo)" src="@/assets/material_2023/video.svg" class="main-right-img")
    .bookcaseWrap
      .bookcase-first
        h3
        ul.cursor-pointer(@click="openMaterialModal('modalContentTwo')" class="bookcase-books-top")
          li
            img(:src="require('@/assets/material_2023/book1.jpg')" class="bookcase-book-top")
          li
            img(:src="require('@/assets/material_2023/book2.jpg')" class="bookcase-book-top")
          li
            img(:src="require('@/assets/material_2023/book3.jpg')" class="bookcase-book-top")
          li
            img(:src="require('@/assets/material_2023/book4.jpg')" class="bookcase-book-top")
          li
            img(:src="require('@/assets/material_2023/book5.jpg')" class="bookcase-book-top")
          li
            img(:src="require('@/assets/material_2023/book6.jpg')" class="bookcase-book-top")
      .bookcase-two
        h3
        ul.cursor-pointer(@click="openMaterialModal('modalContentOne')" class="bookcase-books-bottom")
          li
            img(:src="require('@/assets/material_2023/book2-1.jpg')" class="bookcase-book-bottom")
          li
            img(:src="require('@/assets/material_2023/book2-2.jpg')" class="bookcase-book-bottom")
          li
            img(:src="require('@/assets/material_2023/book2-3.jpg')" class="bookcase-book-bottom")
          li
            img(:src="require('@/assets/material_2023/book2-4.jpg')" class="bookcase-book-bottom")

  transition()
    .reasonModal(v-if='material',@click.self='material=false')
      .material-content
        .material-dialog
          span.reason-close(@click='material=false')
            //-  close
            svg(width='30' height='30' viewbox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z' fill='white')
          template(v-if="materialContent === 'modalContentOne'")
            img(:src="require('@/assets/material_2023/modalContent-1.png')")
          template(v-else)
            img(:src="require('@/assets/material_2023/modalContent-2.svg')")
  youtube-modal(:src="youtubeUrl")
</template>

<script>
import { MODAL } from '@/store';
import youtubeModal, { uuid as youtubeModalUUID } from '@/views/home/youtubeModal/index.vue';
import { PAYLOAD } from '@/store/modules/modal';
import { IS_OPEN, SET_IS_OPEN, SET_PAYLOAD } from '@/store/modules/modal';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'TextBox',
  components: { youtubeModal },
  data() {
    return {
      material: false,
      materialContent: '',
      materialVideo: 'https://www.youtube.com/embed/2nrvlIn9vFo',
      // for youtube modal
      youtubeModalUUID,
    };
  },
  computed: {
    ...mapState(MODAL, [IS_OPEN]),
    ...mapState(MODAL, [
      PAYLOAD,
    ]),
    youtubeModalIsOpen() {
      return this[IS_OPEN][youtubeModalUUID];
    },
    youtubeUrl() {
      return this[PAYLOAD][youtubeModalUUID];
    },
  },
  methods: {
    openMaterialModal(content) {
      this.materialContent = content;
      this.material = true;
    },
    youtubeModal(url) {
      this.setIsOpen({
        target: youtubeModalUUID,
        status: !this.youtubeModalIsOpen,
      });

      this.setPayload({
        target: youtubeModalUUID,
        payload: url,
      });
    },
    ...mapMutations(MODAL, [SET_IS_OPEN, SET_PAYLOAD]),
  },
};

</script>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin rwd-font($size: 12px, $size-md: 12px, $size-xl: 12px) {
  font-size: $size;

  @media (min-width: 768px) {
    font-size: $size-md;
  }

  @media (min-width: 1280px) {
    font-size: $size-xl;
  }
}

@mixin rwd-lh($lh: 1, $lh-md: 1, $lh-xl: 1) {
  line-height: $lh;

  @media (min-width: 768px) {
    line-height: $lh-md;
  }

  @media (min-width: 1280px) {
    line-height: $lh-xl;
  }
}

.main {
  width: 100wh;
  padding: 100px 0px 80px 0px;
  gap: 100px;
}

.main-box {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.class-title {
  display: block;
  color: #82C1EA;
  // font-family: Noto Sans TC;
  font-size: 24px;
  font-weight: 350;
  line-height: 36px;
  letter-spacing: 4.78000020980835px;
  text-align: center;
  margin-bottom: 50px
}

.main-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.main-left {
  width: 45%;
  display: flex;
  justify-content: end;
}

.main-right {
  width: 55%;
  display: flex;
  justify-content: start;
}

.main-right:hover {
  cursor: pointer;
}

.main-right-img {
  width: 100%;
}

.main-left-content {
  width: 460px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.close-icon {
  margin-right: 2rem;
  margin-left: 2rem;
}

.left-icon {
  display: flex;
  justify-content: center;
}

.left-content {
  // font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.06em;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;

}

.line {
  border-bottom: 2px #82C1EA solid;
}

//- sam
.bookcaseWrap {
  max-width: 967px;
  margin: 53px auto 0 auto;

  @include md {
    margin: 60px auto 0 auto;
    padding: 0 40px;
  }

  .bookcase-first {
    h3 {
      background: url('~@/assets/material_2023/reading-title.png') no-repeat center / cover;
      width: 324px;
      height: 30px;
      margin: 0 auto 2vw auto;

      @media (min-width: 1280px) {
        margin: 0 auto 4vw auto;
      }

      @include xl {
        margin: 0 auto 10px auto;
      }
    }

    >ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 10%;

      @include md {
        padding: 0 60px;
        max-width: 80%;
        margin: 0 auto;
      }

      @media (min-width: 1280px) {
        max-width: 100%;
      }

      >li {
        text-align: center;
        width: 50%;
        padding: 0 14px;
        margin-bottom: 14px;

        @include md {
          width: 33.333%;
          padding: 0 24px;
          margin-bottom: 24px;
        }

        @media (min-width: 1280px) {
          width: 16.6667%;
          padding: 0 12px;
          margin-bottom: 0;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }

    background: url('~@/assets/material_2023/phone-top.svg') no-repeat center / contain;
    aspect-ratio: 347 / 581;
    padding-top: 12vw;
    box-shadow: 0px 0px 24px 0px rgba(130, 193, 234, 0.70);

    @include md {
      margin: 0 -12px;
      padding-top: 10vw;
      background: url('~@/assets/material_2023/flatbed-top.svg') no-repeat center / contain;
      aspect-ratio: 688 / 516;
    }

    @media (min-width: 1280px) {
      margin: 0 auto;
      padding-top: 46px;
      width: 100%;
      background: url('~@/assets/material_2023/computer-top.svg') no-repeat center / cover;
      aspect-ratio: 967 / 280;
    }
  }

  .bookcase-two {
    h3 {
      background: url('~@/assets/material_2023/phonics-title.png') no-repeat center / cover;
      width: 341px;
      height: 45px;
      margin: 0 auto 2vw auto;

      @include md {
        margin: 0 auto 6% auto;
      }

      @media (min-width: 1280px) {
        margin: 0 auto 4% auto;
      }

      @include xl {
        margin: 0 auto 10px auto;
      }
    }

    >ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 10%;

      @include md {
        padding: 0 60px;
        margin: 0 auto;
      }

      @include xl {
        max-width: 88%;
        height: 150px;
      }

      li {
        text-align: center;
        width: 50%;
        padding: 0 14px;
        margin-bottom: 14px;

        @include md {
          width: 25%;
          padding: 0 12px;
          margin-bottom: 0;
        }

        @include xl {
          width: 20%;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }

    width: 100%;
    padding-top: 36px;
    background: url('~@/assets/material_2023/phone-bottom.svg') no-repeat center / contain;
    aspect-ratio: 347 / 457;
    width: 100%;
    box-shadow: 0px 0px 24px 0px rgba(130, 193, 234, 0.70);

    @include md {
      background: url('~@/assets/material_2023/flatbed-bottom.svg') no-repeat center / cover;
      aspect-ratio: 688 / 363;
    }

    @media (min-width: 1280px) {
      background: url('~@/assets/material_2023/computer-bottom.svg') no-repeat center / cover;
      aspect-ratio: 967 / 280;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.plants-img-box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.plants-img {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

//- RWD
@media(max-width: 1260px) {
  .main-box {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .main-left-content {
    width: 100%;
    max-width: 712px;
  }

  .main-content {
    flex-direction: column;
    justify-content: center;
  }

  .main-left {
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .main-right {
    width: 100%;
    justify-content: center;
  }

  .left-content {
    text-align: center;
  }

}

// 彈窗
.reasonModal {
  position: fixed;
  min-width: 375px;
  z-index: 1030;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 44px;

  background-color: #4f4f4fe6;
  /* background-image: radial-gradient(#ffffff00  35%, #4c4c4c 70%); */
  /* background-image: radial-gradient(circle, #ffffff00 50%, #4c4c4c 70%); */
  /* backdrop-filter: blur(10px); */
  overflow-y: scroll;

  /* vue transition */
  &.v-enter-active,
  &.v-leave-active {
    transition: opacity 0.35s ease-out, transform 0.35s;
  }

  &.v-enter,
  &.v-leave-to {
    opacity: 0;
    transform: scale(1.2);
  }

  &.v-enter-to,
  &.v-leave {
    opacity: 1;
    transform: scale(1);
  }

  /* scroll bar */
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #ffa756;
  }

  .reason-close {
    position: absolute;
    top: -40px;
    right: -40px;
  }

  .reason-close:hover path {
    fill: #ffa756;
  }

  .reason-close:hover,
  .reason-close:focus {
    color: #ffa756;
    text-decoration: none;
    cursor: pointer;
  }

  .reason-content {
    margin-top: 50px;
    text-align: center;
    height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reason-content img {
    width: 100%;
    max-width: 700px;
  }

  .reason-dialog {
    padding: 20px 20px 40px 20px;
    border-bottom: 10px solid #75CDEC;
    box-shadow: 0px 0px 20px rgba(216, 200, 182, 0.3);
    border-radius: 30px;
    position: relative;
    background: linear-gradient(180deg, #FFFFFF 85.42%, #E7F9FF 100%);
    min-height: 185px;

    @include md {
      height: 375px;
    }

    .leaf {
      position: absolute;
      bottom: 0;
      background: url('~@/assets/event/trial/title-leaft.png') no-repeat center / 50% 50%;

      &-M {
        width: 50px;
        height: 50px;
        bottom: -14px;
        left: 20px;
      }

      &-S {
        width: 40px;
        height: 40px;
        right: 18%;
        bottom: -10px;
      }

      &-L {
        width: 70px;
        height: 70px;
        right: 22%;
        bottom: -20px;
      }
    }

    h3 {
      font-weight: 700;
      margin-bottom: 0;
      @include rwd-font(16px, 20px, 20px);
      @include rwd-lh(22px, 24px, 24px);
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }

    .dec {
      margin: 20px 0;
      width: 100%;
    }

    img {
      width: 70px;

      @media (min-width: 768px) {
        width: 130px;
      }
    }
  }

}
.material-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.material-dialog {
  position: relative;
  max-width: 341px;
  img {
    display: block;
    width: 100%;
  }
  @include md {
    max-width: 554px;
  }
  @media (min-width: 1024px)  {
    max-width: 993px;
  }
}

.bookcase-books-top:hover {
  .bookcase-book-top {
    transition: transform 0.4s ease; /* 添加過渡效果，讓移動更平滑 */
    transform: translateY(-10px); /* 向上移動 10px */
    box-shadow: 0px 0px 20px 0px #FFFFFFCC;
  }
}

.bookcase-books-bottom:hover {
  .bookcase-book-bottom {
    transition: transform 0.4s ease; /* 添加過渡效果，讓移動更平滑 */
    transform: translateY(-10px); /* 向上移動 10px */
    box-shadow: 0px 0px 20px 0px #FFFFFFCC;
  }
}
</style>
