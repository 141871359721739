import axios from '../utils/axios-helper.js';

export default class BookingClassApplication{
  async getClassPastApplication(params) {
    const res = await axios({
      url: '/v3/taichi-external/class/apply',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
      params: params,
    });
    return res;
  }
  async bookingClass(data) {
    const response = await axios({
      url: '/class/apply',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data',
      },
      responseType: 'json',
      data: data,
    });
    return response.data.data;
  }
  async getTopicList() {
    const res = await axios({
      url: '/suitable-analysis/interested_topic',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return res.data.data;
  }
  async getTopicMaterialList(params) {
    const res = await axios({
      url: '/class/interested-topic/material',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
      params: params,
    });
    return res.data;
  }
  async getConsultantList(params) {
    const res = await axios({
      url: '/consultant/available',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
      params: params,
    });
    return res.data.data;
  }
  async cancelBookClass(classApllicationId) {
    const response = await axios({
      url: '/class/apply/' + classApllicationId,
      method: 'delete',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得該日期顧問未開放時段
   * @param {Object} params 參數
   * @returns {Promise<Array>} 可詢問時間
   */
  async requireBookClassTime(params) {
    const response = await axios({
      url: '/v3/taichi-external/student/ask-consultant/apply-timeslot',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 申請詢問顧問未開放訂課時間
   * @param {Object} params 參數
   * @returns {Promise<Array>} 送出成功與否
   */
  async requireConsultantClass(data) {
    const response = await axios({
      url: '/v3/taichi-external/student/ask-consultant/apply',
      method: 'post',
      headers: {
        'content-type': 'multipart/form-data',
      },
      responseType: 'json',
      data: data,
    });
    return response;
  }
  /**
   * 取得單日上課時段
   * @param {Object} days: YYYY-MM-DD, platform, splitSection : 切割時間(true)
   * @returns {Promise<Array>} 送出成功與否
   */
  async getClassTimeslots(params) {
    const response = await axios({
      url: '/v3/educational/lesson/booking-timeslots',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
      params: params,
    });
    return response;
  }
}

