import { v4 } from 'uuid';
import {
  mapMutations,
} from 'vuex';
import {
  SET_IS_OPEN,
} from '@/store/modules/modal';
import {
  MODAL as MODAL_STORE,
} from '@/store/index';
import {
  uuid as FORGET_PASSWORD_MODAL_UUID,
} from '@/views/home/parent/forgotPasswordModal/index.vue';
import modalMixin from '@/mixins/modal';

export const uuid = v4();

export default {
  mixins: [
    modalMixin,
  ],

  created() {
    this.initModal(uuid, {
      status: false,
      payload: null,
    });
  },

  mounted(){
    if (this.$route.query.action === 'forgotPassword'){
      this.openForgotPasswordModal();
    }
  },

  methods: {
    ...mapMutations({
      openForgotPasswordModal(commit) {
        this.close();

        commit(`${MODAL_STORE}/${SET_IS_OPEN}`, {
          target: FORGET_PASSWORD_MODAL_UUID,
          status: true,
        });
      },
    }),
  },
};
