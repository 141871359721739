export default {
  messages: {
    tw: {
      chineseName: '中文姓名',
      englishName: '英文姓名',
      gender: '性別',
      phone: '手機號碼',
      email: '電子信箱',
      password: '密碼',
      confirmPassword: '密碼確認',
    },
    en: {
      chineseName: 'Chinese Name',
      englishName: 'English Name',
      gender: 'Gender',
      phone: 'Phone',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
    },
  },
};
