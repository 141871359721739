<template lang="pug">
//- 需放div才不會被function誤認為兩個root節點
div
  b-skeleton-wrapper(:loading="loading")
    template(#loading)
      slot(name="skeleton-template")
    slot(name="origin-template")
</template>

<script type="text/javascript">

export default {
  name: 'SkeletonTemplate',

  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  // data(){
  //   return{
  //     loading: false,
  //   };
  // },

  // methods:{
  //   startLoading() {
  //     this.loading = true;
  //   },
  //   endLoading() {
  //     this.loading = false;
  //   },
  // }
};
</script>
