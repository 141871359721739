import lioshutanApi from '@/api';

const types = {
  ADD_VIP_ACCOUNT_BASE_PROFILE: 'ADD_VIP_ACCOUNT_BASE_PROFILE',
  ADD_VIP_ACCOUNT_COMPLETE_PROFILE: 'ADD_VIP_ACCOUNT_COMPLETE_PROFILE',
  HAS_GET_PROFILE: 'HAS_GET_PROFILE',
  SET_VIP_ACCOUNT_PLATFORM: 'SET_VIP_ACCOUNT_PLATFORM',
};

const state = {
  hasGetProfile: false,

  vipAccountBaseProfile: {
    level: 0,
    chinese_name: '中文名字',
    english_name: 'English Name',
    nickname: '',
    gender: 'F',
    photo: '',
    user_account: '',
    user_id: 0,
  },

  vipAccountCompleteProfile: {
    profile: {
      chinese_name: '中文名字',
      english_name: 'English Name',
      email: 'email',
      work_phone: '',
      home_phone: '',
      birthday: '0001-01-01',
      age: 0,
      nickname: '',
      gender: 'F',
      photo: '',
    },
    recommend: {
      recommend_code: '',
    },
    address: {
      country: '',
      city: '',
      postal_code: '',
      province: '',
      region: '',
    },
    education: {
      identity: '',
      education: '',
    },
    experience: {
      position: '',
      position_note: '',
      industry: '',
      industry_note: '',
    },
    social_media: {
      bind: {
        line: false,
        line_notify: false,
        fb: '',
      },
    },
    notification: {
      notify_booking: 0,
      line_notify_booking: 0,
      notify_on_demand: 0,
      line_notify_on_demand: 0,
    },
    platform: {
      history: [],
      now: {
        contractStatus: false,
        id: 0,
      },
    },
  },

  baseProfile: {},

  customizedClassroomRule: {},
};

const mutations = {
  [types.ADD_VIP_ACCOUNT_BASE_PROFILE]: (state, data) => {
    state.vipAccountBaseProfile = data;
  },

  [types.ADD_VIP_ACCOUNT_COMPLETE_PROFILE]: (state, data) => {
    state.vipAccountCompleteProfile = data;
  },

  [types.HAS_GET_PROFILE]: (state, data) => {
    state.hasGetProfile = data;
  },

  setBaseProfile(state, data) {
    state.baseProfile = data;
  },

  setCustomizedClassroomRule(state, data) {
    state.customizedClassroomRule = data;
  },

  [types.SET_VIP_ACCOUNT_PLATFORM]: (state, data) => {
    state.vipAccountCompleteProfile.platform = data;
  },
};

const actions = {
  async addVipAccountBaseProfile({ commit }, data) {
    await commit(types.ADD_VIP_ACCOUNT_BASE_PROFILE, data);
  },

  async addVipAccountCompleteProfile({ commit }, data) {
    await commit(types.ADD_VIP_ACCOUNT_COMPLETE_PROFILE, data);
  },

  async getVipAccountBaseProfile({ commit }) {
    const result = await lioshutanApi.vip.getBaseProfile();
    commit('setBaseProfile', result.data.profile_base);
    commit('setCustomizedClassroomRule', result.data.customized_classroom_rule);
  },

  setHasGetProfile({ commit }, data) {
    commit(types.HAS_GET_PROFILE, data);
  },

  setPlatform({ commit }, data){
    commit(types.SET_VIP_ACCOUNT_PLATFORM, data);
  },

};

const getters = {
  getCanOrderClass(state) {
    if (state.vipAccountCompleteProfile.platform) {
      return state.vipAccountCompleteProfile.platform?.now?.id === 2 && state.vipAccountCompleteProfile.platform?.now?.contractStatus;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
