import swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

import { alertHtmlMessage } from '@/utils/sweetAlert.js';
const LOGIN_STATUS = {
  NONE: 'none',
  WRONG: 'wrong',
  DETECTED: 'detected',
  IGNORE: 'ignore',
  SUCCESS: 'success',
  PASSWORD: 'password',
};

export function loginFailedHandle(loginStatus){
  switch (loginStatus){
    case LOGIN_STATUS.PASSWORD:
      swal.fire({
        icon: 'error',
        title: '',
        text: '密碼至少 8 位數，且需包含英文及數字',
        confirmButtonText: '再試一次',
        footer: '<a href="/?action=forgotPassword">忘記密碼?</a>',
      });
      break;
    case LOGIN_STATUS.WRONG:
      swal.fire({
        icon: 'error',
        title: '',
        text: '密碼錯誤請再試一次',
        confirmButtonText: '再試一次',
        footer: '<a href="/?action=forgotPassword">忘記密碼?</a>',
      });
      break;
    case LOGIN_STATUS.NONE:
      alertHtmlMessage('', 'error', '查無此帳號，請重新確認', '再試一次');
      break;
    case LOGIN_STATUS.DETECTED:
      swal.fire({
        icon: 'warning',
        title: '',
        text: '已偵測到本組帳密為 WUWOW 帳戶，是否帶您進入WUWOW？',
        confirmButtonText: '再試一次',
        cancelButtonText: '登入WUWOW',
        showCancelButton: true,
      }).then((result) => {
        if (result.isDismissed){
          redirectWuwowLogin();
        }
      });
      break;
    default:
      alertHtmlMessage('', 'error', '登入錯誤', '再試一次');
      break;
  }
}

function redirectWuwowLogin() {
  window.location.href = `${process.env.VUE_APP_BASE_EXTERNAL_URL}login`;
}
