<template lang="pug">
.layout-lioshutan
  v-header
  .app-body
    v-left
    main.main
      .container
        router-view
  v-footer
  div(v-if="islogin")
    check-student-setting
    operation-timed-out
    socket-handle
    pusher-handle
    SupplementaryAgreement
    RenewalReminder
</template>
<!-- renewalReminder -->
<script>
import header from './header';
import leftSidebar from './leftSidebar';
import footer from './footer';
import checkStudentSetting from '@/components/class/checkStudentSetting/index.vue';
import operationTimedOut from '@/components/operationTimedOut/index.vue';
import socketHandle from '@/components/socketHandle/index.vue';
import pusherHandle from '@/components/pusherHandle/index.vue';
import SupplementaryAgreement from '@/components/modal/supplementaryAgreement/index.vue';
import RenewalReminder from '@/components/modal/renewalReminder/index.vue';
import storage from '@/utils/localStorage';
import { mapState } from 'vuex';

export default {
  name: 'Dashboard',
  components: {
    'v-header': header,
    'v-left': leftSidebar,
    'v-footer': footer,
    checkStudentSetting,
    operationTimedOut,
    socketHandle,
    pusherHandle,
    SupplementaryAgreement,
    RenewalReminder,
  },

  data(){
    return {
      showDefaultLayout: true,
      hideLayoutPage: [
        '/parent',
      ],
    };
  },

  computed: {
    ...mapState('auth', [
      'token',
    ]),
    islogin(){
      return storage.token !== '' && storage.token !== 'null' && window.location.pathname !== '/';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/styles/layout/layout.scss";
</style>
