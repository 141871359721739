<template lang="pug">
.position-absolute(v-if="show")
  //- 給手機用的尺寸
  .d-block.d-md-none
    .fixed-top.vh-100.vw-100.d-flex.justify-content-center.align-items-center#login-container
      .position-absolute.vw-100.vh-100(
        @click="closeModal"
      )
      .login-modal.rounded-7.position-relative
        .text-center
          .w-100.h-25.gradient-bg.position-absolute.text-center#login-content
            p.text-light.position-absolute 從雲端出發，任何時間、任何地點都能上課
          img.w-100.rounded-top-7(:src="file.parentLoginPic")
        .text-center.mt-4
          h3.jr-home-main-color.my-3 家長登入
          p 登入 WUWOW Jr  打開世界的一扇窗！
        .mx-4
          .input-group.mb-3
            .input-group-prepend
              span.input-group-text.h-100
                font-awesome-icon(icon="user")
            input.form-control(v-model="loginForm.account" type="text" placeholder="輸入信箱帳號")

          .input-group.mb-3
            .input-group-prepend
              span.input-group-text.h-100
                font-awesome-icon(icon="key")
            input.form-control(v-model="loginForm.password" :type="passwordType" placeholder="密碼")
            font-awesome-icon.showPasswordIcon.position-absolute(:icon="showPasswordIconName" @click="togglePasswordVisibility")

          .d-flex.justify-content-end.mb-3
            a.jr-home-main-color(@click="openForgotPasswordModal") 忘記密碼？
          button.btn-block.border-0.rounded.btn-primary.text-light.py-2.mb-3(@click="login" :disabled="logining")
            span(v-if="!logining")
              | 立即登入
            div(v-else-if="logining")
              b-spinner.mr-2(small)
              span 登入中...
          button.btn.btn-link.btn-block.text-center.border-0.p-0.jr-home-main-color(@click="openRegisterParentAccountModal") 還沒有家長帳號？
          <i data-v-3cb02076="" id="showPassword" aria-hidden="true" class="showPasswordIcon position-absolute fa fa-plus"></i>
        //-   //- p.text-center 點擊我 申請免費試讀
        //- .teacher-icon
        //-   img.w-75(src="@/assets/teacher.png")

  //- 給平板的尺寸
  .d-none.d-md-block.d-lg-none
    .fixed-top.vh-100.vw-100.d-flex.justify-content-center.align-items-center#login-container
      .position-absolute.vw-100.vh-100(
        @click="closeModal"
      )
      .login-modal.rounded-7.position-relative
        .login-modal-top.text-center.position-relative
          img.w-100.h-100.rounded-top-7(:src="file.parentLoginPic")
          .w-100.h-50.gradient-bg.position-absolute.text-center#login-content
            p.text-light.mb-3.h5 從雲端出發，任何時間、任何地點都能上課
        .text-center.mt-4
          h3.jr-home-main-color.my-3 家長登入
          p 登入 WUWOW Jr  打開世界的一扇窗！
        .mx-auto.w-50
          .input-group.mb-3
            .input-group-prepend
              span.input-group-text.h-100
                font-awesome-icon(icon="user")
            input.form-control(v-model="loginForm.account" type="text" placeholder="輸入信箱帳號")

          .input-group.mb-3
            .input-group-prepend
              span.input-group-text.h-100
                font-awesome-icon(icon="key")
            input.form-control(v-model="loginForm.password" :type="passwordType" placeholder="密碼")
            font-awesome-icon.showPasswordIcon.position-absolute(:icon="showPasswordIconName" @click="togglePasswordVisibility")

          .d-flex.justify-content-end.mb-3
            a.jr-home-main-color(@click="openForgotPasswordModal") 忘記密碼？
          button.btn-block.border-0.rounded.btn-primary.text-light.py-2.mb-3(@click="login" :disabled="logining")
            span(v-if="!logining")
              | 立即登入
            div(v-else-if="logining")
              b-spinner.mr-2(small)
              span 登入中...
          button.jr-home-main-color.btn.btn-link.btn-block.text-center.border-0.p-0(@click="openRegisterParentAccountModal") 還沒有家長帳號？
            //- p.text-center 點擊我 申請免費試讀
        //- .teacher-icon
        //-     img(src="@/assets/teacher.png")

  //- 給電腦的尺寸
  .d-none.d-md-none.d-lg-block
    .fixed-top.vh-100.vw-100.d-flex.justify-content-center.align-items-center#login-container
      .position-absolute.vw-100.vh-100.z-index-1(
        @click="closeModal"
      )
      .login-modal.rounded-7.d-flex
        .h-100.w-65.rounded-left-7.position-relative
          img.w-100.h-100.rounded-left-7(:src="file.parentLoginPic")
          .gradient-bg.h-25.w-100.position-absolute.fixed-bottom.text-center.rounded-bottom-left#login-content.desktop
            p.text-light.h5 從雲端出發，任何時間、任何地點都能上課
        .h-100.w-35.px-3.position-relative
          .text-center.mt-5
            h3.jr-home-main-color.mt-3.mb-4 家長登入
            p 登入 WUWOW Jr  打開世界的一扇窗！
          .mt-5.w-75.mx-auto
            .input-group.mb-3
              .input-group-prepend
                span.input-group-text.h-100
                  font-awesome-icon(icon="user")
              input.form-control(v-model="loginForm.account" type="text" placeholder="輸入信箱帳號" @keyup.enter="login")

            .input-group.mb-3
              .input-group-prepend
                span.input-group-text.h-100
                  font-awesome-icon(icon="key")
              input.form-control(v-model="loginForm.password" :type="passwordType" placeholder="密碼" @keyup.enter="login")
              font-awesome-icon.showPasswordIcon.position-absolute(:icon="showPasswordIconName" @click="togglePasswordVisibility")

            .d-flex.justify-content-end.mb-3
              a.jr-home-main-color.forget-btn(@click="openForgotPasswordModal") 忘記密碼？
            button.btn-block.border-0.rounded.btn-primary.text-light.py-2.mb-3.mt-5(@click="login" :disabled="logining")
              span(v-if="!logining")
                | 立即登入
              div(v-else-if="logining")
                b-spinner.mr-2(small)
                span 登入中...
            button.btn.btn-link.btn-block.jr-home-main-color.text-center.border-0.p-0(@click="openRegisterParentAccountModal") 還沒有家長帳號？
            //- p.text-center 點擊我 申請免費試讀
          //- a.teacher-icon(href="https://www.w3schools.com")
          //-   img(src="@/assets/teacher.png")
//- b-modal(v-model="show" size="lg" centered hide-header hide-footer @change="close")
//-   .row
//-     .col-5
//-       .modal-img.w-100
//-         p  123456
//-     .col-7
//-       p 123456
//-   .form-group
//-     input.form-control(v-model="loginForm.account" placeholder="Account")
//-     input.form-control(v-model="loginForm.password" type="password" placeholder="Password")
//-     button.btn.bg-lioshutan(@click="login") Login
//- button.btn.btn-link.btn-block.text-center.btn-primary.border-0.p-0(@click="openRegisterParentAccountModal") 還沒有家長帳號？
//-   button.btn.btn-link.text-danger.p-0(@click="openForgotPasswordModal") Forgot！！
//-   button.btn.btn-link.text-danger.p-0(@click="openRegisterParentAccountModal") 還沒有帳號？
</template>

<script>
import { v4 } from 'uuid';
import lioshutanApi from '@/api';
import {
  mapMutations,
} from 'vuex';
import modalMixin from '@/mixins/modal';
import {
  SET_IS_OPEN,
} from '@/store/modules/modal';
import {
  MODAL as MODAL_STORE,
} from '@/store/index';
import {
  CHARACTER as CHARACTER_STORE, SET_CHARACTER,
} from '@/store/modules/character';

import CHARACTER from '@/constants/character';

import {
  uuid as FORGET_PASSWORD_MODAL_UUID,
} from '@/views/home/parent/forgotPasswordModal/index.vue';

import {
  uuid as REGISTER_PARENT_ACCOUNT_MODAL_UUID,
} from '@/views/home/parent/registerModal/index.vue';

export const uuid = v4();

import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import parentLoginPic from '@/assets/login/parent_login_pic.png';
import gradient from '@/assets/login/gradient.png';

import { alertFailedMessage } from '@/utils/sweetAlert.js';
import { loginFailedHandle } from '@/utils/auth';
import { setUseLangByRole } from '@/utils/lang';
import commonConstants from '@/constants/common';
import httpCodeConstants from '@/constants/httpCode';

// const ROLE = { STUDENT: 'student', TEACHER: 'teacher', CENTER: 'center' };

export default {
  mixins: [
    modalMixin,
  ],
  data: () => ({
    logining: false,
    loginForm: {
      account: '',
      password: '',
    },

    // Toggle Password Visibility
    isShowPassword: false,
    passwordType: 'password',
    showPasswordIconName: 'eye-slash',

    file: {
      parentLoginPic,
      gradient,
    },
  }),

  created() {
    this.initModal(uuid, {
      status: false,
    });
  },

  methods: {
    ...mapMutations({
      setParentCharacter(commit) {
        commit(`${CHARACTER_STORE}/${SET_CHARACTER}`, CHARACTER.PARENT);
      },
      openForgotPasswordModal(commit) {
        this.close();

        commit(`${MODAL_STORE}/${SET_IS_OPEN}`, {
          target: FORGET_PASSWORD_MODAL_UUID,
          status: true,
        });
      },
      openRegisterParentAccountModal(commit) {
        this.close();

        commit(`${MODAL_STORE}/${SET_IS_OPEN}`, {
          target: REGISTER_PARENT_ACCOUNT_MODAL_UUID,
          status: true,
        });
      },
    }),

    togglePasswordVisibility() {
      this.isShowPassword = !this.isShowPassword;
      this.passwordType = this.isShowPassword ? 'text' : 'password';
      this.showPasswordIconName = this.isShowPassword ? 'eye' : 'eye-slash';
    },

    async login() {
      if (this.loginForm.password.length < 8) {
        loginFailedHandle('password');
        return;
      }
      this.logining = true;
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API}/parent-login`,
          {
            email: this.loginForm.account,
            password: this.loginForm.password,
          }
        );
        if (response.status === httpCodeConstants.SUCCESS){
          this.setParentCharacter();
          this.$store.dispatch('auth/setToken', response.data.token);
          const accountRole = await this.setAccountAuthorityToLocalStorage();
          setUseLangByRole(accountRole);
          this.logining = false;
          this.$router.push({ path: '/parent' }); // 導頁至選擇小孩的頁面
        } else {
          this.logining = false;
          alertFailedMessage('登入失敗');
        }
      } catch (error) {
        this.logining = false;
        const loginStatus = error.response.data.login_status;
        loginFailedHandle(loginStatus);
      }
    },
    closeModal() {
      this.close();
    },

    async checkUserLogin(){
      const params = {
        platform: commonConstants.CURRENT_PLATFORM,
      };
      try {
        const response = await lioshutanApi.common.checkLogin(params);
        if (!response.data.data.check_result){
          alertFailedMessage(this.$t('wrongPlatform'));
          return false;
        } else {
          return true;
        }
      } catch (error) {
        alertFailedMessage(this.$t('platformCheckError'));
      }
    },

    async setAccountAuthorityToLocalStorage() {
      const accountAuthority = await lioshutanApi.vip.getAccountAuthority();
      // const accountAuthorityArray = accountAuthority.data.data.authority;
      const accountRole = accountAuthority.data.data.role;
      this.$store.dispatch('auth/setRole', accountRole);
      return accountRole;
    },
  },

};
</script>
<style lang="scss" scoped>
.modal-img {
  background-image: url(~@/assets/login/parent_login_pic.png);
  width: 500px;
  height: 500px;
}
</style>
<style lang="scss" scoped>
// .text-lioshutan {
//   color: #006bda !important;
// }
.btn-primary {
  background-color: #006bda;
}
.rounded-7 {
  border-radius: 7px;
}
.rounded-top-7 {
  border-radius: 7px 7px 0 0;
}
.rounded-left-7 {
  border-radius: 7px 0 0 7px;
}
.gradient-bg {
  top: 0;
  background: linear-gradient(0deg, rgba(212, 187, 231, 0) 0%, rgba(212, 187, 231, 0.9) 51.56%, #C0E0F4 100%);
  border-radius: 7px 0 0 0;
  p {
    top: 32px;
    position: absolute;
    left: 55px;
  }
}
#login-content::after {
  content: "";
  // background: linear-gradient(180deg, rgba(212, 187, 231, 0) 0%, rgba(212, 187, 231, 0.9) 51.56%, #C0E0F4 100%);
  // background-image: url(~@/assets/login/gradient.png);
  width: 100%;
  height: 25vh;
  bottom: 0;
  left: 0;
  position: absolute;
}

.desktop#login-content::after {
  content: "";
  // background: linear-gradient(180deg, rgba(212, 187, 231, 0) 0%, rgba(212, 187, 231, 0.9) 51.56%, #C0E0F4 100%);
  // background-image: url(~@/assets/login/gradient.png);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 25vh;
  // top: 0;
  // bottom: 0;
  // left: 0;
  position: absolute;
  border-radius: 0 0 0 7px;
}

#login-container::after {
  content: "";
  background-color: rgba(96, 96, 96, 0.699);;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -100;
}

.login-banner {
  background-image: url(~@/assets/login-banner.png);
}

.z-index-1 {
  z-index: -1;
}
@keyframes fadein {
  0% {
    width: 70%;
    // transform: translateX(0px);
  }
  100% {
    width: 0%;
    // transform: translateX(-120px);
  }
}
.teacher-icon {
  position: absolute;
  bottom: -23px;
  right: 13px;
  // img {
  //   animation: fadein 2s ease;
  // }
  &::after {
    content: "";
    background-color: #FFFFFF;
    width: 0;
    height: 70%;
    position: absolute;
    right: 90px;
    top: 0;
    animation: fadein 2s ease;
  }
}

$max: 20;
@for $i from 1 through $max {
  .vw-#{$i * 5} {
    width: $i * 5vw;
  }
  .w-#{$i * 5} {
    width: $i * 5%;
  }
  .vh-#{$i * 5} {
    height: $i * 5vh;
  }
  .h-#{$i * 5} {
    height: $i * 5%;
  }
}

$teacher: 100px;

.teacher {
  bottom: -10px;
  right: -20px;
  height: $teacher;
  width: $teacher;

  background-image: url(~@/assets/teacher.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.login-modal {
  background-color: white;
  color: #8B8B8B;
  .input-group-text {
    background-color: white;
    color: #D1D1D1;
  }
  .form-control {
    border-left: none;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #959595;
  }
  .forget-btn {
    cursor: pointer;
  }
}
.showPasswordIcon {
  cursor: pointer;
  top:50%;
  right:10px;
  font-size:15px;
  transform: translateY(-50%);
  z-index: 10;
}
@media screen and (min-width: 1200px) {
  .login-modal {
    width: 1048px;
    height: 500px;
  }
}
@media screen and (max-width: 1199px) {
  .login-modal {
    width: 900px;
    height: 465px;
  }
}
@media screen and (max-width: 991px) {
  .login-modal {
    width: 500px;
    height: 700px;
    .login-modal-top {
      height: 350px;
    }
    .gradient-bg {
      border-radius: 7px 7px 0 0;
      p {
        top: 32px;
        position: absolute;
        left: 55px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .login-modal {
    width: 343px;
    height: 600px;
    .login-modal-top {
      height: 307px;
    }
    .gradient-bg {
      p {
        top: 32px;
        position: absolute;
        left: 15px;
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .login-modal {
    .teacher-icon {
      bottom: -10px;
      right: -10px;
      display: flex;
      justify-content: end;
    }
  }
}
</style>
