/**
 * set params with key and corresponding value
 *
 * @param {Object} [key] param key
 * @param {Object} [resource] param value
 */
export function setParams(keys, resource) {
  if (keys instanceof Object && resource instanceof Object){
    const params = {};
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(resource)) {
      if (keys[key] !== undefined){
        params[keys[key]] = value;
      }
    }
    return params;
  } else {
    return {};
  }
}

/**
 * set fromdata with key and corresponding value
 *
 * @param {Object} [key]
 * @param {Object} [values]
 */
export function setFormdata(keys, values) {
  if (keys instanceof Object && values instanceof Object){
    const formdata = new FormData();
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(values)) {
      if (keys[key] !== undefined){
        formdata.append(keys[key], value);
      }
    }
    return formdata;
  } else {
    return {};
  }
}

/**
 * get pdf file download
 *
 * @param {File} [key]
 * @return {File} download pdf file
 */
export function downloadPdfFile(file, fileName) {
  // TODO MOFIDY 此部分先以舊的寫法串接，等候端完成後再進行修改(改為直接給Url) By Asa
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName + '.pdf';
  link.dispatchEvent(new MouseEvent('click'));
}

/**
 * get url with params
 *
 * @param {String, Object || String || Array} [path, params]
 * @return {String} url
 */
export function setUrlQuery(domain, path, params){
  const url = new URL(`${domain}${path}`);
  const searchParams = new URLSearchParams(params);
  url.search = searchParams;
  return url.href;
}
