import companyConstants from '@/constants/company';

export default {
  messages: {
    tw: {
      checkApiFailed: `檢查學員資料發生錯誤，請聯絡服務人員(${companyConstants.INFO.CONTACT_PHONE})，防止訂課異常`,
      profileNotFilled: '請務必完成填寫【個人資訊】，再進行課程，<br>點選【OK】後畫面將轉移至個人資料編輯頁面，<br>填寫完成後點選【儲存】。',
      notice: '溫馨提醒',
    },
    en: {
      checkApiFailed: `There is an error in checking the student information, please contact the service staff to prevent abnormal course booking(${companyConstants.INFO.CONTACT_PHONE})`,
      profileNotFilled: 'Please be sure to fill in [Personal Information] before proceeding with the course.<br>Click [OK] and the screen will transfer to the personal information editing page. After filling in, click [Save].',
      notice: 'Reminder',
    },
  },
};
