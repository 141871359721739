import axios from '@/api/utils/axios-helper.js';

export default class SupplementaryAgreement {
  /**
   *先打這支api確認需不需要增補條款+vipContractAdditionTerm
   */
  async getCheckAdditionTerm() {
    const response = await axios({
      url: '/v3/human-resource/check-addition-term',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }

  /**
   * 拿 vipContractAdditionTerm 換增補協議條款
   */
  async getVipContractAdditionTerm(id) {
    const response = await axios({
      url: `v3/human-resource/vip-contract-addition-term/${id}`,
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }

  /**
   * 拿 vipContractAdditionTerm 換增補協議條款
   */
  async patchVipProductAdditionTermModify(data) {
    const response = await axios({
      url: '/v3/human-resource/vip-product-addition-term-modify',
      method: 'PATCH',
      data,
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }

  /**
   * 取得增補協議歷程
   */
  async getVipAdditionTermRecord() {
    const response = await axios({
      url: '/v3/human-resource/vip-addition-term-record',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
}
