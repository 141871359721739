<template lang="pug">
.qa-class.pt-4
  .container
    .d-flex.flex-column.align-items-center.py-5
      p.area-english-title.text-lioshutan :: FAQ ::
      h2.area-title.text-lioshutan.mb-4 常見 Q&A
      //- 手機用
      .d-block.d-md-none.w-100.my-2(
        v-for="(item, index) in qa" :key="`mobile_${index}`"
        @click="qa[index].visible = !qa[index].visible"
      )
        .question-content.p-4(:class="item.visible ? 'question-content-show':''")
          .d-flex.justify-content-between.align-items-center
            div
              h5.question-title.d-inline.mx-1.text-lioshutan {{ `Q${index + 1}.` }}
              h5.question-title.d-inline.mx-1 {{ item.question }}
            font-awesome-icon(:icon="`caret-${qa[index].visible ? 'up' : 'down'}`")
          b-collapse(v-model="item.visible")
            .mt-4
              span.question-text(v-html="item.answer")

      //- 給平板的尺寸
      .d-none.d-md-block.d-lg-none.w-100.my-2(
        v-for="(item, index) in qa" :key="`tablet_${index}`"
        @click="qa[index].visible = !qa[index].visible"
      )
        .question-content.p-4(:class="item.visible ? 'question-content-show':''")
          .d-flex.justify-content-between.align-items-center
            div
              h5.question-title.d-inline.mx-1.text-lioshutan {{ `Q${index + 1}.` }}
              h5.question-title.d-inline.mx-1 {{ item.question }}
            font-awesome-icon(:icon="`caret-${qa[index].visible ? 'up' : 'down'}`")
          b-collapse(v-model="item.visible")
            .mt-3
              span(v-html="item.answer")

      //- 電腦用
      .d-none.d-md-none.d-lg-block.my-2(
        v-for="(item, index) in qa" :key="`desktop_${index}`"
        @click="qa[index].visible = !qa[index].visible"
      )
        .question-content.p-4(:class="item.visible ? 'question-content-show':''")
          .d-flex.justify-content-between.align-items-center
            div
              h5.question-title.d-inline.mx-1.text-lioshutan {{ `Q${index + 1}.` }}
              h5.question-title.d-inline.mx-1 {{ item.question }}
            font-awesome-icon(:icon="`caret-${qa[index].visible ? 'up' : 'down'}`")
          b-collapse(v-model="item.visible")
            .mt-3
              span.question-text(v-html="item.answer")
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';

export default {
  data: () => ({
    qa: [
      {
        visible: false,
        question: '上課時候我需要在孩子身邊嗎？',
        answer: '是不需要的噢！不過若是 4～8 歲、或是性格比較害羞的小孩，建議開始學習的頭一兩周課程，家長可以在旁邊陪伴、觀察，待孩子習慣後，再讓孩子自主進行課程。',
      },
      {
        visible: false,
        question: '孩子上課容易分心，該怎麼辦？',
        answer: 'WUWOW Jr 老師的教學方式對抓住孩子注意力下了許多功夫，若孩子仍有分心的情況出現，建議讓孩子開啟視訊鏡頭，老師將比較容易掌握到孩子的學習狀態，適時的透過對話、肢體語言掌握孩子的注意力。',
      },
      {
        visible: false,
        question: '孩子英文連 ABC 都還沒學會，也可以上課嗎？',
        answer: 'WUWOW Jr 的老師皆有超過一年以上的幼教經驗，並採用 TPR 的肢體互動教學方法，搭配 Pre-class 無基礎專屬的教材設計，讓孩子能夠從基礎的 ABC 字母、發音開始學習，順利銜接上 Level1 的正式課程。',
      },
      {
        visible: false,
        question: '25分鐘課程，孩子能學到東西嗎？',
        answer: '孩子的注意力相較成人更容易被影響，長時間的課程其實並非對多數孩子最好的學習方式，比起一堂課學的多，我們更希望孩子學的長久，也在學習中喜歡上英文。<br><br>在 WUWOW Jr 25 分鐘的課程設計中，我們建立了一個無壓的學習環境，防止孩子對學英文產生排斥、焦慮，若孩子希望可以一次學習較久時間，也可以透過連續排定多堂課達成噢！',
      },
      {
        visible: false,
        question: '上課前需要幫孩子準備什麼嗎？',
        answer: '1. 安靜的房間<br>2. 智慧型手機、平板、筆電、桌上型電腦擇一即可。<br>3. 準備有線耳罩式耳機麥克風為佳。<br>4. 桌上型電腦需準備外接式視訊鏡頭，並確保孩子於課程中開啟視訊，讓老師更能掌握孩子的學習狀況。<br>5. 穩定的網路',
      },
      {
        visible: false,
        question: '老師是如何篩選的呢？',
        answer: 'WUWOW Jr 的老師來自世界各地英美語系國家，除了簽約的各國專業老師之外，我們也和專業的語言中心合作，確保師資的水準，顧問皆持有國際英文教學執照，並具有一年以上幼教經驗。',
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
.qa-class {
  background-color: #fdf7eb;
  font-size: 18px;
  .subtitle {
    font-size: 20px;
    letter-spacing: 4px;
  }
  .question-content {
    width: 900px;
    color: #707070;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      background-color: #F4F4F4;
    }
    .question-title {
      font-size: 25px;
    }
    .question-text {
      line-height: 30px;
      color: #7B8387;
      font-size: 20px;
    }
    svg {
      font-size: 30px;
    }
  }
  .question-content-show {
    background-color: #F4F4F4;
  }
  @media screen and ( max-width: 998px ){
    .subtitle {
      font-size: 15px;
      letter-spacing: 3px;
    }
    .question-content {
      width: 700px;
      .question-title {
        font-size: 18px;
      }
    }
  }
  @media screen and ( max-width: 768px ){
    .question-content {
      width: 100%;
    }
  }
  @media screen and ( max-width: 414px ){
    .subtitle {
      font-size: 15px;
      letter-spacing: 3px;
    }
    .question-content {
      width: 100%;
      .question-title {
        font-size: 18px;
      }
      .question-text {
        font-size: 16px;
      }
    }
  }
}
</style>
