<template lang="pug">
//- TODO 進行其他操作時要消失
.sidebar
  nav.sidebar-nav(style="max-height: 80vh; min-height: 0;")
    vip-left-nav(v-if="role === student")
    parent-left-nav(v-if="role === parent")
    consultant-left-nav(v-else-if="role === teacher")
    center-left-nav(v-else-if="role === center")
</template>

<script>
import vipLeftNav from '@/components/nav/leftNav/vipLeftNav/index.vue';
import parentLeftNav from '@/components/nav/leftNav/parentLeftNav/index.vue';
import consultantLeftNav from '@/components/nav/leftNav/consultantLeftNav/index.vue';
import centerLeftNav from '@/components/nav/leftNav/centerLeftNav/index.vue';
import { mapState } from 'vuex';
import commonConstants from '@/constants/common';

export default {
  name: 'LeftSidebar',

  components: {
    vipLeftNav,
    parentLeftNav,
    consultantLeftNav,
    centerLeftNav,
  },

  data() {
    return {
      student: commonConstants.ROLE.STUDENT,
      teacher: commonConstants.ROLE.TEACHER,
      center: commonConstants.ROLE.CENTER,
      parent: commonConstants.ROLE.PARENT,
    };
  },

  computed: {
    ...mapState({
      role: state => state.auth.role,
    }),
  },

};
</script>
