<template lang="pug">
  .nav-items
    li.nav-item
      div(v-for="item in classRoom" :key="item.name")
        router-link.nav-link(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name)}}
    li.nav-item.dropdown(v-if="getCanOrderClass")
      a.nav-link.dropdown-toggle(
        href='javascript:;'
        @click="toggleDropdown('startClass')"
      ) {{ $t('bookClass')}}
      .dropdown-menu(:class="[state === 'startClass' ?' d-block' :'d-none']")
        .dropdown-sort(:class="[state?'mt-1' :'mt-5']") {{ $t('book')}}
        div(v-for="item in bookLists" :key="item.name")
          router-link.dropdown-item(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name) }}
        .dropdown-sort {{ $t('setRecord')}}
        div(v-for="item in setRecordLists" :key="item.name")
          router-link.dropdown-item(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name) }}
    li.nav-item.dropdown
      a.nav-link.dropdown-toggle(
        href='javascript:;'
        @click="toggleDropdown('classRecord')"
      ) {{ $t('classRecord')}}
      .dropdown-menu(:class="[state === 'classRecord' ?' d-block' :'d-none']")
        div(v-for="item in classRecordLists" :key="item.name")
          router-link.dropdown-item(v-if="item.authorityTag && item.name !== 'learnRecords'" :to="{ path: item.path }") {{ $t(item.name) }}
          a.dropdown-item(v-if="item.authorityTag && item.name === 'learnRecords'" :href="`${newJrUrl}${item.path}`") {{ $t(item.name) }}
    li.nav-item
      div(v-for="item in mgm" :key="item.name")
        router-link.nav-link(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name)}}
    li.nav-item
      div(v-for="item in guide" :key="item.name")
        router-link.nav-link(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name)}}
</template>

<script>
import i18n from '@/components/nav/lang';
import navList from '@/components/nav/navList';
import dropdowControlMixin from '@/components/nav/headerNav/mixin/dropdowControlMixin.vue';
import lioshutanApi from '@/api';
export default {
  name: 'ParentHeaderNav',

  components: {},

  mixins: [dropdowControlMixin],

  i18n,

  data() {
    return {
      newJrUrl: new URL(window.location).origin,

      classRoom: navList.parent.classRoom,

      bookLists: navList.parent.bookLists,

      setRecordLists: navList.parent.setRecordLists,

      classRecordLists: navList.parent.classRecordLists,

      mgm: navList.parent.mgm,

      guide: navList.parent.guide,
    };
  },
  computed: {
    getCanOrderClass() {
      return this.$store.getters['vipAccount/getCanOrderClass'];
    },
  },

  async mounted() {
    await this.getVipPointSuggest();
  },
  methods: {
    async getVipPointSuggest() {
      if (localStorage.getItem('wuwow_token')){
        const res = await lioshutanApi.vip.getContractElapsedTime();
        this.$store.dispatch('vipAccount/setPlatform', res.data.platform);
      }
    },
  },
};
</script>

