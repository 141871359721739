import Vue from 'vue';
import VueI18n from 'vue-i18n';
import storage from '@/utils/localStorage';
import allMessages from '@/lang/messages';

Vue.use(VueI18n);
const language = storage.language;
export function getLanguage(language = 'tw') {
  const locales = Object.keys(getMessages(language));
  locales.forEach(locale => {
    if (language === locale){
      return locale;
    }
  });
  return language;
}

export function getMessages() {
  return allMessages;
}

const i18n = new VueI18n({
  // set locale
  // options: en | tw
  locale: getLanguage(language),
  // set locale messages
  messages: getMessages(),
  silentTranslationWarn: true, // 去除警告warning
});

export default i18n;
