<template lang="pug">
div
  | {{ $t('class.remain_class') }}&#xFF1A;
  p.float-right.remain-class
    span.txt-personal-sessions {{ vip_account_info.elapsed_time.balance_sessions }}&nbsp;
    span {{ $t('class.class') }}
  div.mt-3
    | {{ $t('contract.contract') }}
    br
    span.contract-time-text {{ vip_account_info.elapsed_time.contract_start }}
    span.contract-time-text.contract-time-end.float-right {{ vip_account_info.elapsed_time.contract_end }}
</template>
<script>

export default {
  name: 'ContractPeriod',

  props: {
    // eslint-disable-next-line
    vip_account_info: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.remain-class {
  font-size: 13px
}
</style>
