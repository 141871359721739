import axios from '@/api/utils/axios-helper.js';

export default class VipAccount {
  /**
   * 取得角色uuid
   * @returns {Promise<Object>} 回傳角色uuid
   */
  async getUserUuid() {
    const response = await axios({
      url: '/v3/taichi-external/user/uuid',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }

  /**
   * 取得角色可使用功能與頁面
   * @returns {Promise<Object>} 回傳角色可使用功能與頁面
   */
  async getAccountAuthority() {
    const response = await axios({
      url: '/v3/authority-service',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }

  /**
   * 取得學員基本資料
   * @returns {Promise<Object>} 回傳學員基本資料(姓名、性別、等級、)
   */
  async getBaseProfile() {
    const response = await axios({
      url: '/v3/taichi-external/student/account/base-profile',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得學員詳細資料
   * @returns {Promise<Object>} 回傳學員詳細資料
   */
  async getCompleteProfile() {
    const response = await axios({
      url: '/v3/taichi-external/student/account/complete-profile',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得學員專屬業務
   * @param type: 修改類型(interested_topic), parameter<array>: 所有選取的適性
   * @returns {Promise<Object>} 回傳學員專屬業務(業務名稱、業務推薦碼)
   */
  async getOwnBD() {
    const response = await axios({
      url: '/v3/taichi-external/student/own-bd',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 編輯學員資料
   * @param email, education, country, province, postal_code, region, city, gender, birthday, learning_target,
   * @param recipient, recipient_phone, remarks, home_phone, work_phone
   * @returns {Promise<Object>} 回傳修改成功失敗依照http code判斷
   */
  async updateProfile(data) {
    const response = await axios({
      url: '/v3/taichi-external/student/account/update-profile',
      method: 'post',
      headers: { 'content-type': 'multipart/form-data' },
      data: data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得學員Q&A
   * @returns {Promise<Object>} 回傳學員Q&A
   */
  async getFaqContent(params) {
    const response = await axios({
      url: '/v3/message/faq',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得學員個人資料進度分析資訊
   * @returns {Promise<Object>} 回傳學員分析資訊(姓名、等級、剩餘堂數、總上課數、評鑑回饋率、30天平均等級...)
   */
  async getAnalysisOutline() {
    const response = await axios({
      url: '/v3/taichi-external/student/learning-progress/analysis-outline',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得學員進度分析資訊
   * @returns {Promise<Object>} 回傳學員分析資訊(聽、說、讀、寫、單字、文法平均等級)
   */
  async getLearningAnalysis() {
    const response = await axios({
      url: '/v3/taichi-external/student/learning-progress/learning-analysis',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得學員教材分析資訊
   * @returns {Promise<Object>} 回傳學員適性分析資訊
   */
  async getMaterialAnalysis() {
    const response = await axios({
      url: '/v3/taichi-external/student/learning-progress/material-analysis',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得學員升等上課資訊
   * @returns {Promise<Object>} 回傳學員升等上課資訊
   */
  async getLevelClassesCount() {
    const response = await axios({
      url: '/v3/taichi-external/student/learning-progress/level-classes-count',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得學員經驗與是否可升級挑戰
   * @returns {Promise<Object>} 回傳學員經驗與是否可升級挑戰
   */
  async getChallengeInfo() {
    const response = await axios({
      url: '/v3/taichi-external/student/learning-progress/challenge-info',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得學員學習熱度資訊
   * @returns {Promise<Object>} 回傳學員學習熱度資訊
   * @returns {Promise<Object>} (訂課、出席、未出席、取消訂課、單月最高上課堂數、已上微班級堂數、已上百家書苑堂數、已上一對一堂數)
   */
  async getLearningPassion() {
    const response = await axios({
      url: '/v3/taichi-external/student/learning-progress/learning-passion',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得升等證書
   * @returns {Promise<Blob>} 回傳學員升等證書檔案
   */
  async getLevelCertificate(data) {
    const response = await axios({
      url: '/v3/taichi-external/student/learning-progress/level-certificate',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: data,
      responseType: 'blob',
    });
    return response;
  }
  /**
   * 取得顧問分析
   * @param class_applications_id: 教室id
   * @returns {Promise<Object>} 回傳顧問分析內容
   */
  async getConsultantAnalysis(params) {
    const response = await axios({
      url: '/v3/taichi-external/student/analysis',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得教室影片
   * @param class_applications_id: 教室id
   * @returns {Promise<Object>} 回傳下載影片判斷是否可下載
   */
  async getClassVideo(params) {
    const response = await axios({
      url: `class/classroom-video/${params.classroomId}/download-url`,
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params.data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得上課檔案
   * @param uuid: 上課檔案id, vi: vip id
   * @returns {Promise<Object>} 回傳下載影片判斷是否可下載
   */
  async getClassAttachFile(params) {
    const response = await axios({
      url: '/class/attach/' + params.uuid + '/download-url',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: { vi: params.vip_id },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 送出評鑑內容
   * @param overall_level: 我對於本堂課的整體滿意程度 ,advice_rating: 顧問有即時給予我糾正與回饋, suggestion_advice_rating: 顧問有給予適當回饋, overall_time_rating: 顧問的教學內容時間分配與掌握
   * @param suggestion_overall_time_rating: 顧問的教學內容時間分配與掌握, consultant_rating: 顧問平均分數, express_time_rating:我有被分配到適當的表達時間
   * @param suggestion_express_time_rating: 我有被分配到適當的表達時間, material_rating: 教材滿意度, material_rating: 教材滿意度
   * @param suggestion_material_rating: 教材滿意度, communication_rating: 通訊狀況滿意度, suggestion_communication_rating: 通訊狀況滿意度, feedback: 與客服的悄悄話
   * @param hb_suggestion: 給顧問的建議, upgrade_application: 之前會帶的舊參數(帶0即可)
   * @returns {Promise<Object>} 回傳送出成功失敗依照http code判斷
   */
  async sendEvaluationData(data) {
    const response = await axios({
      url: '/v3/taichi-external/student/evaluation/',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得課後評鑑未填寫評鑑
   * @returns {Promise<Array>} 回傳課後評鑑未填寫評鑑
   */
  async getUnEvaluationClass() {
    const response = await axios({
      url: '/v3/taichi-external/student/un-evaluation-class',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得學員每周上課堂數
   * @returns {Promise<Array>} 回傳學員每周上課堂數
   */
  async getVipClassRecordsWeekly() {
    const response = await axios({
      url: '/v3/taichi-external/student/class-records-weekly',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 1.該平台(WUWOW , JR)已無使用權限的情況（退費 / 移轉 / 未購買 / 使用完畢[期限已到 , 堂數用完] ）
   * ps: .如果 已無使用權限的學員 從軌跡查詢紀錄，也無法使用顧問名字超連結登入查看行事曆，超連結功能會消失
   * 2 .如登入錯平台的學員則給予對應的提示
   * ps: WUWOW學員登入JR 已偵測到本組帳密為 WUWOW 帳戶，是否帶您進入 WUWOW ? 再次登入 / 登入WUWOW
   * ps.登入WUWOW 會跳至另一個平台登入入口
   * JR學員登入 WUWOW 已偵測到本組帳密為 JR 帳戶，是否帶您進入 JR ? 再次登入 / 登入JR
   * ps.登入JR 會跳至另一個平台登入入口
   * @returns {Promise<Array>} 回傳學員每周上課堂數
   * 'https://api.wuwow.tw/vip/vip_point_suggest'
   */
  async getVipPointSuggest() {
    const response = await axios({
      url: '/vip/vip_point_suggest',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
}
