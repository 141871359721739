<template lang="pug">
.reserve-class#reserve-class(
  :style="`\
    background-image: url(${file.girlBG});\
    background-repeat: no-repeat;\
    background-position: center;\
    background-size: cover;\
  `"
)
  //- 手機用
  .d-block.d-md-none
    .text-center.mx-5.text-lioshutan
      p :: Let's start ::
      h3 免費為孩子
      h3 預約一堂試聽課程

    .mx-1
      .from-class.position-relative.mx-3.p-4
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.studentChineseName" placeholder="孩子姓名")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.studentEnglishName" placeholder="孩子英文名稱")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.studentAge" placeholder="孩子年齡" type="number")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.parentName" placeholder="家長姓名")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.parentPhone" placeholder="家長電話")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.parentEmail" placeholder="家長email")
        b-row.form-check-box.mb-3
          b-col(cols="2")
            input.form-check-input(v-model="formdata.privacyPolicyCheck" type="checkbox" id="privacyPolicyCheck")
          b-col(cols="10")
            label.form-check-label 我同意WUWOW線上英文
              a(:href="privacyUrl" target="_blank" for="privacyPolicyCheck") 隱私權政策
        .d-flex.justify-content-center.align-items-center
          button.text-light.form-btn.border-0.rounded-pill.py-2.px-4.mt-3(@click="submitForm()") 預約試聽

  //- 給平板的尺寸
  .d-none.d-md-block.d-lg-none
    .d-flex.flex-row.justify-content-center.align-items-center.py-5
      .mx-5.text-lioshutan(style="width: 350px;")
        p.area-english-title :: Let's start ::
        h1 免費為孩子
        h1 預約一堂試聽課程

    .mx-1
      .w-75.from-class.position-relative.mx-3.p-4.mx-auto
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.studentChineseName" placeholder="孩子姓名")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.studentEnglishName" placeholder="孩子英文名稱")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.studentAge" placeholder="孩子年齡" type="number")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.parentName" placeholder="家長姓名")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.parentPhone" placeholder="家長電話")
        .d-flex.justify-content-between.align-items-center.my-5
          input.w-100(v-model="formdata.parentEmail" placeholder="家長email")
        b-row.form-check-box.mb-3
          b-col(cols="1")
            input.form-check-input(v-model="formdata.privacyPolicyCheck" type="checkbox" id="privacyPolicyCheck")
          b-col(cols="11")
            label.form-check-label 我同意WUWOW線上英文
              a(:href="privacyUrl" target="_blank" for="privacyPolicyCheck") 隱私權政策
        .d-flex.justify-content-center.align-items-center
          button.text-light.form-btn.border-0.rounded-pill.py-1.px-3.mt-3(@click="submitForm()") 預約試聽

  //- 電腦用
  .d-none.d-md-none.d-lg-block
    .d-flex.flex-row.justify-content-center.align-items-center.py-5
      .mx-5.text-lioshutan(style="width: 30%;margin-bottom: 220px;")
        p.area-english-title :: Let's start ::
        h1.reserve-title 免費為孩子
        h1.reserve-title 預約一堂試聽課程

      .behind-card
        .behind-card-content.position-relative.mx-3
          .d-flex.justify-content-between.align-items-center.mb-4
            label.m-0.mr-2 孩子姓名
            input(v-model="formdata.studentChineseName")
          .d-flex.justify-content-between.align-items-center.mb-4
            label.m-0.mr-2 孩子英文名稱
            input(v-model="formdata.studentEnglishName")
          .d-flex.justify-content-between.align-items-center.mb-4
            label.m-0.mr-2 孩子年齡
            input(v-model="formdata.studentAge" type="number")
          .d-flex.justify-content-between.align-items-center.mb-4
            label.m-0.mr-2 家長姓名
            input(v-model="formdata.parentName")
          .d-flex.justify-content-between.align-items-center.mb-4
            label.m-0.mr-2 家長電話
            input(v-model="formdata.parentPhone")
          .d-flex.justify-content-between.align-items-center.mb-4
            label.m-0.mr-2 家長email
            input(v-model="formdata.parentEmail")
          b-row.form-check-box.mt-5
            b-col(cols="2")
              input.form-check-input(v-model="formdata.privacyPolicyCheck" type="checkbox" id="privacyPolicyCheck")
            b-col(cols="10")
              label.form-check-label 我同意WUWOW線上英文
                a(:href="privacyUrl" target="_blank" for="privacyPolicyCheck") 隱私權政策
          .d-flex.justify-content-center.align-items-center
            button.send-btn.btn.form-btn.rounded-pill.px-3(@click="submitForm()") 預約試聽

</template>

<script>
import girlBG from '@/assets/girl_bg.jpg';
import 'bootstrap/dist/css/bootstrap.css';
import lioshutanApi from '@/api';
import commonConstants from '@/constants/common';
import { checkEmailFormat, checkPhoneFormat } from '@/utils/verify';
import { alertFailedMessage } from '@/utils/sweetAlert.js';
import httpCodeConstants from '@/constants/httpCode';

export default {
  data: () => ({
    file: {
      girlBG,
    },
    formdata: {
      studentChineseName: '',
      studentEnglishName: '',
      studentAge: '',
      parentName: '',
      parentPhone: '',
      parentEmail: '',
      privacyPolicyCheck: false,
    },
    pageMediaSource: commonConstants.MEDIA_SOURCE.NAME,
    privacyUrl: '/privacy',
    thankYouUrl: '/thank-you',
  }),

  methods: {
    async submitForm() {
      const fieldChecked = this.fieldCheck(this.formdata.parentEmail, this.formdata.parentPhone, this.formdata.privacyPolicyCheck);
      if (fieldChecked) {
        const mediaSource = this.getMediaSource();
        const noteInfo = this.getNoteInfo();
        const params = {
          name: this.formdata.studentChineseName,
          phone: this.formdata.parentPhone, // jr丟入家長的電話
          email: this.formdata.parentEmail, // jr丟入家長的email
          note: noteInfo, // 其餘資訊總和
          media_source: mediaSource,
        };
        const result = await lioshutanApi.common.setMediaSource(params);
        if (result.status === httpCodeConstants.SUCCESS) {
          this.$router.push({ path: this.thankYouUrl }); // 通往感謝頁
        }
      }
    },

    getMediaSource() {
      // m = {媒體來源名稱}
      if (this.$route.query.m) {
        this.pageMediaSource += `_${this.$route.query.m}`;
      }
      return this.pageMediaSource;
    },

    getNoteInfo() {
      // (iChannel)廠商需要接gid
      let merchantIChannelsGid = '';
      const queryGid = this.$route.query.gid;
      if (queryGid) {
        merchantIChannelsGid = `gid=${queryGid} |`;
      }
      const noteInfo = `${merchantIChannelsGid} 學員中文姓名: ${this.formdata.studentChineseName} | 學員英文姓名: ${this.formdata.studentEnglishName} | 學員年齡: ${this.formdata.studentAge} | 學員家長姓名: ${this.formdata.parentName}`;
      return noteInfo;
    },

    fieldCheck(email, phone, privacyPolicyCheck) {
      let fulfilled = true;
      Object.values(this.formdata).forEach((data) => {
        if (data === '') {
          fulfilled = false;
        }
      });
      if (!fulfilled) {
        alertFailedMessage('似乎還有些欄位尚未填寫完畢');
        return false;
      }
      if (!checkEmailFormat(email)) {
        alertFailedMessage('可以再幫我確認一次您的電子郵件帳號嗎');
        return false;
      }
      if (!checkPhoneFormat(phone)) {
        alertFailedMessage('可以再幫我確認一次您的電話號碼嗎');
        return false;
      }
      if (!privacyPolicyCheck) {
        alertFailedMessage('可以再幫我確認一下隱私權政策及學習約定條款嗎');
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
input {
  /* background-color: red; */
  display: block;
  border: none;
  border-bottom: 1px solid #393939;
}

input:focus {
  outline: none;
}

*::placeholder {
  /* modern browser */
  color: #393939;
}

.form-btn {
  background-color: #45a2df;
}

.behind-card {
  position: relative;
  z-index: 5;
}

/* .behind-card::after {
  background: transparent linear-gradient(341deg, var(--unnamed-color-88c2e8) 0%, #89C3E8 14%, #BFE1F0 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(341deg, #88C2E8 0%, #89C3E8 14%, #BFE1F0 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transform: rotate(7deg);
  border-radius: 20px;
} */

.behind-card-content {
  width: 600px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 80px 60px;
  font-size: 20px;
  color: #393939;
  opacity: 0.7;
}
</style>
<style lang="scss" scoped>
.from-class {
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  color: #393939;
  opacity: 0.7;
}

.reserve-class {
  padding: 150px 0;

  .reserve-title {
    font-size: 50px;
  }

  .subtitle {
    font-size: 20px;
    letter-spacing: 4px;
  }
}

.behind-card-content {
  input {
    width: 300px;
  }

  .form-check-input {
    width: 20px;
    height: 20px;
    right: 10px;
  }

  .send-btn {
    color: white;
    font-size: 20px;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 30px;

    &:hover {
      background-color: #3DA0E8;
      color: white;
    }
  }
}

@media screen and (max-width: 1600px) {
  .reserve-class {
    .reserve-title {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .behind-card-content {
    width: 500px;

    input {
      width: 200px;
    }
  }

  .form-check-box {
    text-align: left;

    .form-check-input {
      right: 5px
    }
  }

  .reserve-class {
    .reserve-title {
      font-size: 35px;
    }
  }
}

@media screen and (max-width: 414px) {
  .subtitle {
    font-size: 15px;
    letter-spacing: 3px;
  }
}

/* TODO 填單區域兒童節排版 */
#reserve-class{
  .children-day{
    width: 100%;
    margin-bottom: 0;
  }
  background-image: url('~@/assets/bg-children-day-mobile.png');
  @media (min-width: 992px){
  background-image: url('https://cdn3.wuwow.tw/arts/events/bg-children-day-desktop.png');
}
}

</style>
