<template lang="pug">
b-modal(v-model="show" size="lg" centered hide-header hide-footer @change="close")
  .row.mx-0(v-if="currentStep === 1")
    .col-12.col-lg-8.p-0
      .position-relative.h-100.w-100
        .gradient-bg.w-100.h-25.position-absolute.text-center#login-content
          p.text-light.h5 從雲端出發，任何時間、任何地點都能上課
        img.h-100.w-100.parent-part-img(:src="file.parentLoginPic")
    .col-12.col-lg-4.py-4
      .parent-register-box.h-100
        h3.mt-0.mb-3.parent-register-title 家長註冊
        a.back-login-link.jr-home-main-color(@click="changeCharacter(CHARACTER.PARENT)") &lt; 返回登入
        br
        .register-note.mt-2 要有孩子帳號才可以註冊喔！
        .form-group.register-form-group
          label.mt-2 中文姓名
          input.form-control(v-model="form.chineseName" placeholder="輸入中文姓名")
          label.mt-3  英文姓名
          input.form-control(v-model="form.englishName" placeholder="輸入英文姓名")
          .form-group.mt-3.d-flex.align-items-center
            label.mr-3 性別
            .form-check.form-check-inline
              input.form-check-input(id="register_male" type="radio" value="male" v-model="form.gender")
              label.form-check-label(for="register_male") 男
            .form-check.form-check-inline
              input.form-check-input(id="register_female" type="radio" value="female" v-model="form.gender")
              label.form-check-label(for="register_female") 女
          label  手機號碼
          input.form-control(v-model="form.phone" placeholder="輸入常用手機號碼")
          label.mt-3  電子信箱
            span.remind-text.jr-home-main-color.ml-1 *為登入之帳號
          input.form-control(v-model="form.email" placeholder="輸入信箱帳號" autocomplete="off")
          label.mt-3  密碼
          input.form-control(v-model="form.password" type="password" placeholder="輸入密碼" autocomplete="off")
          div(v-if="form.password")
            .password-alert.my-1(:class="[passwordLengthChecked ? 'password-alert-check' : '']")
              font-awesome-icon.mr-2(icon="check")
              | 密碼設定長度至少為8個字元的字串
            .password-alert.my-1(:class="[passwordFormatChecked ? 'password-alert-check' : '']")
              font-awesome-icon.mr-2(icon="check")
              | 必須英數混和
          label.mt-3 再次輸入密碼
          input.form-control(v-model="form.confirmPassword" type="password" placeholder="再次輸入密碼")
          div(v-if="form.confirmPassword")
            .password-alert.my-1(:class="[passwordChecked ? 'password-alert-check' : '']")
              font-awesome-icon.mr-2(icon="check")
              | 密碼需相同
          button.btn.btn-primary.w-100.mt-4(@click="checkIsEmailAndPhoneRegister") 立 即 登 入
  .row(v-if="currentStep === 2")
    font-awesome-icon.go-back-icon.m-2(icon="arrow-left" @click="goBack(1)")
    .add-kid-account-box.py-3
      h3.parent-register-title.mb-4 新增小孩帳號
      .add-kid-account-subtitle.mb-4 請輸入您的孩子 wuwow Jr 帳號
      .form-group
        .input-group.mb-3
          .input-group-prepend
            span.input-group-text.h-100
              font-awesome-icon(icon="user")
          input.form-control(v-model="student.account" type="text" placeholder="帳號")
        .input-group.mb-3
          .input-group-prepend
            span.input-group-text.h-100
              font-awesome-icon(icon="lock")
          input.form-control(v-model="student.password" type="password" placeholder="密碼")
        button.btn.btn-primary.w-100.mt-3(@click="sendFindBindStudentInfoRequest") 完 成
  .row(v-if="currentStep === 3")
    font-awesome-icon.go-back-icon.m-2(icon="arrow-left" @click="goBack(2)")
    .confirm-relation-box.pt-3.pb-5
      h3.parent-register-title.mb-5 確認資料
      img.mb-4(v-if="studentInfo.photo" :src="studentInfo.photo")
      img.mb-4(v-else src="https://cdn2.wuwow.tw/images/kidpic.png")
      .student-name.mb-2 {{ studentInfo.chinese_name }} {{ studentInfo.english_name }}
      .student-level.mb-3 Level {{ studentInfo.level }}
      .input-group.mb-3
        .input-group-prepend
          span.input-group-text.h-100
            | 關係 |
        select.form-control(v-model="form.relation")
          option(value="" disabled selected hidden) 請選擇關係
          option(
            v-for="(relation, index) in relationCollection" :key="index"
            :value="relation"
          )
            | {{ $t(`relation_of_.${relation}`) }}
      input.form-control(
        v-if="form.relation === RELATION.OTHER"
        v-model="form.other"
        placeholder="例：伯父、叔叔、阿姨、姑姑等等.."
      )
      button.btn.btn-primary.w-100.mt-5(
        :disabled="isRelationOrOtherEmptyOrIsLock"
        @click="sendCreateParentAccountRequest"
      ) 完 成
  .row(v-if="currentStep === 4")
    font-awesome-icon.go-back-icon.m-2(icon="arrow-left" @click="goBack(3)")
    .confirm-email-box.pt-3.pb-5
      h3.parent-register-title.mb-5 信箱驗證
      .subtitle.mb-4 請前往信箱收取驗證信件
      .form-group
        .confirm-email-input-box
          input.confirm-email-input.form-control(v-model="form.email" placeholder="輸入註冊時信箱帳號")
          .confirm-email-input-count (180s)
        .d-flex.mt-2
          .info-icon.mr-2 i
          .danger-color 如果尚未收到信件請前往查看垃圾信件匣
        button.btn.request-btn.w-100.mt-5(
          :disabled="isCountDownSecondNotEqualZero"
          @click="sendResendVerifyMailRequest"
        ) {{ `重 新 驗 證 (${countDownSecond}s)` }}
        .resend-note.mt-3 尚未收到信箱驗證？請點擊上方按鈕重新發送驗證
</template>

<script>
import RELATION from '@/constants/relation';

import { v4 } from 'uuid';

import { mapMutations } from 'vuex';

import modalMixin from '@/mixins/modal';
import {
  CHARACTER as CHARACTER_STORE,
  MODAL as MODAL_STORE,
} from '@/store/index';
import CHARACTER from '@/constants/character';

import {
  SET_CHARACTER,
} from '@/store/modules/character';

import {
  SET_IS_OPEN,
} from '@/store/modules/modal';
import { uuid as PARENT_UUID } from '@/views/home/parent/loginModal/index.vue';
import { uuid as CHILDREN_UUID } from '@/views/home/loginModal/index.vue';

export const uuid = v4();

import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import i18n from '@/views/home/parent/registerModal/lang';
import commonConstants from '@/constants/common';
import parentLoginPic from '@/assets/login/parent_login_pic.png';
import { alertFailedMessage, alertHtmlMessage } from '@/utils/sweetAlert.js';
import httpCodeConstants from '@/constants/httpCode';

const STEP = { FIRST: 1, SECOND: 2, THIRD: 3, FOURTH: 4 };

export default {
  mixins: [
    modalMixin,
  ],

  i18n,

  data: () => ({
    CHARACTER,
    modal: {
      PARENT_UUID,
      CHILDREN_UUID,
    },
    lock: {
      createAccount: false,
    },
    countDownSecond: 60,
    RELATION,
    currentStep: STEP.FIRST,
    relationCollection: [],
    student: {
      account: '',
      password: '',
    },
    form: {
      id: 0,
      chineseName: '',
      englishName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      relation: '',
      other: '',
      gender: '',
    },
    genderOptions: [
      { text: '男', value: commonConstants.GENDER.MALE },
      { text: '女', value: commonConstants.GENDER.FEMALE },
    ],
    studentInfo: {},
    file: {
      parentLoginPic,
    },
  }),

  computed: {
    isCountDownSecondNotEqualZero() {
      return this.countDownSecond !== 0;
    },
    isRelationOrOtherEmptyOrIsLock() {
      const isRelationEmpty = this.form.relation === '';
      const isOtherEmpty = this.form.relation === RELATION.OTHER && this.form.other === '';
      return isRelationEmpty || isOtherEmpty || this.lock.createAccount;
    },
    passwordLengthChecked() {
      const passwordLimitLength = 8;
      return this.form.password.length >= passwordLimitLength;
    },
    passwordFormatChecked() {
      const regex = /^(?!\d+$)(?![A-Za-z]+$)[a-zA-Z0-9]+$/;
      return regex.test(this.form.password);
    },
    passwordChecked() {
      return this.form.password === this.form.confirmPassword;
    },
  },

  created() {
    // 從學員帳號導航列下拉選單轉址過來，預設開啟
    if (this.$route.path === '/register-parent-account') {
      this.initModal(uuid, {
        status: true,
      });
    } else {
      this.initModal(uuid, {
        status: false,
      });
    }
  },

  methods: {
    ...mapMutations({
      changeCharacter(commit, character) {
        commit(`${CHARACTER_STORE}/${SET_CHARACTER}`, character);
        const target = {
          [CHARACTER.PARENT]: this.modal.PARENT_UUID,
          [CHARACTER.CHILDREN]: this.modal.CHILDREN_UUID,
        }[character];

        this.close();

        commit(`${MODAL_STORE}/${SET_IS_OPEN}`, {
          target,
          status: true,
        });
      },
    }),

    goBack(step) {
      this.currentStep = step;
    },

    startCountDown() {
      const intervalId = setInterval(() => {
        console.log(this.countDownSecond);
        if (this.countDownSecond === 0) {
          clearInterval(intervalId);
          return;
        }

        this.countDownSecond--;
      }, 1000);
    },

    async checkIsEmailAndPhoneRegister() {
      const statusReject = 'rejected';
      const statusFulFilled = 'fulfilled';
      let errorMessage = '';
      try {
        const checkResult = this.registerFieldCheck();
        if (checkResult) {
          const [
            emailCheck,
            phoneCheck,
          ] = await Promise.allSettled([
            this.sendCheckEmailIsExistRequest(),
            this.sendCheckPhoneIsExistRequest(),
          ]);
          if (
            emailCheck.status === statusFulFilled &&
            phoneCheck.status === statusFulFilled
          ) {
            this.currentStep = STEP.SECOND;
          } else {
            if (phoneCheck.status === statusReject) {
              errorMessage += '手機號碼驗證有誤 ';
            }
            if (emailCheck.status === statusReject) {
              errorMessage += '電子郵件驗證有誤 ';
            }
            alertFailedMessage(errorMessage);
          }
        }
      } catch (error) {
        alertFailedMessage(error);
      }
    },

    registerFieldCheck() {
      const checkFields = [
        'chineseName',
        'englishName',
        'gender',
        'phone',
        'email',
        'password',
        'confirmPassword',
      ];
      let emptyFields = '';
      const alertTitle = '<h3>未填寫欄位</h3>';
      checkFields.forEach((field) => {
        if (this.form[field] === '') {
          emptyFields += `${this.$t(field)} <br>`;
        }
      });
      if (emptyFields === '') {
        return true;
      }
      alertHtmlMessage(alertTitle, 'error', emptyFields, '確定');
      return false;
    },

    async sendCheckEmailIsExistRequest() {
      const bodyFormData = new FormData();

      bodyFormData.append('email', this.form.email);
      return await axios.post(
        `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/register/check-email`,
        bodyFormData
      );
    },
    async sendCheckPhoneIsExistRequest() {
      const bodyFormData = new FormData();

      bodyFormData.append('phone', this.form.phone);
      return await axios.post(
        `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/register/check-phone`,
        bodyFormData
      );
    },
    async sendCreateParentAccountRequest() {
      this.lock.createAccount = true;

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/register/create`,
          {
            id: this.form.id,
            email: this.form.email,
            password: this.form.password,
            confirm_password: this.form.confirmPassword,
            phone: this.form.phone,
            relation: this.form.relation,
            relation_other: this.form.other,
            gender: this.form.gender,
            chinese_name: this.form.chineseName,
            english_name: this.form.englishName,
          }
        );
        if (response.status === httpCodeConstants.SUCCESS) {
          this.currentStep = STEP.FOURTH;

          this.countDownSecond = 60;
          this.startCountDown();
        } else {
          alertFailedMessage('請選擇關係');
        }
      } catch (error) {
        alertFailedMessage('資料送出有誤');
      }
    },
    async sendFindBindStudentInfoRequest() {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/register/bind-student`,
        {
          account: this.student.account,
          password: this.student.password,
        }
      );
      const responseStatus = response.data.data.status;
      const studentInfo = response.data.data.student;
      const relation = response.data.data.relationEnum;

      if (responseStatus) {
        this.form.id = studentInfo.id;
        this.studentInfo = studentInfo;
        this.relationCollection = relation;
        this.currentStep = STEP.THIRD;
      } else {
        alertFailedMessage('請再次確認學員帳號密碼是否正確');
      }
    },
    async sendResendVerifyMailRequest() {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/register/forget-password`,
        {
          email: this.form.email,
        }
      );
      console.log(response);

      this.countDownSecond = 60;
      this.startCountDown();
    },
  },

};
</script>
<style lang="scss" scoped>
$danger-color: #FF8970;
@mixin register-title{
  color: var( --home-main-color );
  font-weight: 900;
  // font-size: 20px;
  margin-top: 20px;
  letter-spacing: 4px;
  text-align: center;
}
.btn-primary {
  background-color: #006bda;
}
.danger-color {
  color: $danger-color;
}
::v-deep .input-group-prepend{
  .input-group-text{
    background: none;
    color: var( --text-light-color );
  }

}
::v-deep .modal-lg {
  max-width: 1040px;
  max-height: 500px;
  .modal-content {
    border-radius: 10px;
  }
}
::v-deep .modal-body {
  padding: 0;
}

.parent-part-img {
  border-radius: 10px 0 0 10px;
}
.gradient-bg {
  background: linear-gradient(0deg, rgba(212, 187, 231, 0) 0%, rgba(212, 187, 231, 0.9) 51.56%, #C0E0F4 100%);
  border-radius: 10px 0 0 0;
  p {
    top: 32px;
    position: absolute;
    left: 55px;
  }
}

.go-back-icon{
  transition: 0.4s all ease;
  cursor: pointer;
  position: absolute;
  left: 10px;
  top: 10px;
}

.go-back-icon:hover{
  color: #E5E5E5;
}

.parent-register-box{
  width: 100%;
  padding: 0px 20px 0px 10px;
  max-height: 530px;
  overflow-y: auto;
  .form-control {
    border: 1px solid #EDECEA;
  }
  .parent-register-title{
    @include register-title
  }
  .back-login-link{
    float: left;
    cursor: pointer;
  }
  .register-note{
    width: 100%;
    color: var( --text-dark-color );
    background: rgba(130, 193, 234, 0.2);
    border-radius: 15px;
    padding: 3px 30px 3px 20px;
    font-size: 14px;
    text-align: left;
    letter-spacing: 1px;
  }
  .register-form-group{
    label{
      margin: 0;
    }
    .regiter-radio-group{
      position: relative;
      top: -23px;
      left: 40px;
      margin-bottom: -15px;
      ::v-deep .custom-control-label{
        display: contents;
        span{
          position: relative;
          top: 2px;
        }
      }
      ::v-deep .custom-control-label:before{
        background-color: #E5E5E5;
      }
    }
    .password-alert{
      font-size: 14px;
      color: var( --text-light-color );
      transition: 0.4s all ease;
    }
    .password-alert-check{
      color: #7ECE94;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #E2E2E2;
    }
    .remind-text {
      font-size: 12px;
    }
  }
}
.parent-register-box {
  // 將滾動條改變樣式
  &::-webkit-scrollbar{
    width: 7px;
  }
  &::-webkit-scrollbar-button {
    background: transparent;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #EDECEA;
  }
  &::-webkit-scrollbar-track {
    box-shadow: transparent;
  }
}
.add-kid-account-box{
  margin: 0 auto;
  width: 300px;
  .parent-register-title{
    @include register-title
  }
  .add-kid-account-subtitle{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: var( --text-dark-color );
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #E2E2E2;
  }
  .input-group {
    .form-control{
      border-left: 2px !important;
    }
  }
}

.confirm-relation-box{
  margin: 0 auto;
  width: 300px;
  text-align: center;
  .parent-register-title{
    @include register-title
  }
  img{
    max-width: 150px;
  }
  .student-name{
    font-size: 20px;
    font-weight: 400;
    color: var( --text-dark-color );
  }
  .student-level{
    font-size: 16px;
    color: var( --text-dark-color );
  }
  .input-group {
    .form-control{
      border-left: 2px !important;
    }
  }
}

.confirm-email-box{
  margin: 0 auto;
  width: 300px;
  text-align: center;
  .parent-register-title{
    @include register-title
  }
  .subtitle {
    letter-spacing: 1px;
    text-shadow: 0px 4px 4px #8080806e;
  }
  .confirm-email-input-box{
    margin-bottom: -20px;
    .confirm-email-input{
      background: #daedf9;
      border: none;
      color: var( --text-dark-color );
      &::placeholder {
        color: var( --text-dark-color );
      }
    }
    .confirm-email-input-count{
      color: var( --text-dark-color );
      position: relative;
      top: -27px;
      right: -120px;
    }
  }
  .info-icon {
    width: 16px;
    height: 16px;
    border: 1px solid ;
    border-radius: 50%;
    color: $danger-color;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    margin-top: 3px;
  }
  .request-btn {
    color: var( --home-main-color );
    border-color: var( --home-main-color );
    font-weight: bold;
  }
  .resend-note{
    font-size: 13px;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #E2E2E2;
  }
}
@media screen and ( max-width: 1200px ) {
  ::v-deep .modal-lg {
    max-width: 950px;
    max-height: 400px;
  }
}
@media screen and ( max-width: 992px ) {
  .parent-part-img{
    width: 100%;
    position: unset;
    margin: 0 auto;
    border-radius: 10px 10px 0 0;
  }
  .gradient-bg {
    border-radius: 10px 10px 0 0;
  }
  .parent-register-box {
    max-height: 300px;
  }
  ::v-deep .modal-lg {
    max-width: 500px;
  }
}
@media screen and ( max-width: 767px ) {
  ::v-deep .modal-lg {
    max-width: 363px;
    margin: 0 auto;
  }
  .gradient-bg {
    p {
      top: 22px;
      left: 15px;
      font-size: 14px;
    }
  }
}
</style>
