<template lang="pug">
ul.nav
  li.nav-item
    router-link.nav-link(:to="{ path: '/console/profiles' }")
      font-awesome-icon.mr-2(:icon="['far', 'user']")
      | {{ $t('profile')}}
  li.nav-item
    span.nav-link
      font-awesome-icon.mr-2(:icon="['far', 'calendar']")
      | {{ $t('schedule') }}
    ul.subnav
      li.subnav-item(v-for="item in scheduleLists" :key="item.name")
        router-link.dropdown-item.subnav-link(:to="{ path: item.path }") {{ $t(item.name) }}
  li.nav-item
    router-link.nav-link(:to="{ path: '/console/consultant/classRecords' }")
      font-awesome-icon.mr-2(icon="list")
      | {{ $t('classRecords') }}
  li.nav-item
    router-link.nav-link(:to="{ path: '/console/classroom' }")
      font-awesome-icon.mr-2(icon="cloud")
      | {{ $t('cloudClassroom')}}
  li.nav-item
    router-link.nav-link(:to="{ path: '/console/classroom/vip-requests' }")
      font-awesome-icon.mr-3(icon="child")
      | {{ $t('classRequest')}}
      b-badge(v-if="vipNoReplyRequestsAmount > 0" variant="danger") {{ vipNoReplyRequestsAmount }}

  li.nav-item
    span.nav-link
      font-awesome-icon.mr-2(:icon="['far', 'comment']")
      | {{ $t('training') }}
    ul.subnav
      li.subnav-item(v-for="item in trainingItems" :key="item.item")
        a.subnav-link(:href="item.path" target="_blank") {{ item.item}}
  //- 為了防止正常目錄在手機瀏覽器被蓋住(By 4年前寫的人)
  li(style="height: 40px;")
    p

</template>

<script>
import { mapState } from 'vuex';
import url from '@/constants/url';
import i18n from '@/components/nav/lang';
import navList from '@/components/nav/navList';

export default {
  name: 'VipLeftNav',

  props: {
  },

  i18n,

  data() {
    return {
      notReplyAskConsultant: 0,

      scheduleLists: navList.consultant.scheduleLists,

      trainingItems: [
        {
          item: 'WUWOW Meeting Center Download (Windows)', allowTag: true, path: url.CONSULTANT_TRIANING.WUWOW_MEETING_CENTER_DOWNLOAD_WINDOWS_URL,
        },
        {
          item: 'WUWOW Meeting Center Download (MAC)', allowTag: true, path: url.CONSULTANT_TRIANING.WUWOW_MEETING_CENTER_DOWNLOAD_MAC_URL,
        },
        {
          item: 'Self-Training Classroom (As a Consultant)', allowTag: true, path: url.CONSULTANT_TRIANING.SELF_TRAINING_CLASSROOM_CONSULTANT_URL + '&ui={user_id}',
        },
        {
          item: 'Self-Training Classroom (As a VIP)', allowTag: true, path: url.CONSULTANT_TRIANING.SELF_TRAINING_CLASSROOM_VIP_URL + '&ui={user_id}',
        },
        {
          item: 'Training Classroom', allowTag: true, path: url.CONSULTANT_TRIANING.TRAINING_CLASSROOM_URL + '&ui={user_id}',
        },
        {
          item: 'Questionnaire', allowTag: true, path: url.CONSULTANT_TRIANING.QUESTIONNAIRE_URL,
        },
      ],
    };
  },

  computed: {
    ...mapState({
      vipNoReplyRequestsAmount: state => state.consultant.vipNoReplyRequestsAmount,
    }),
  },
};
</script>
