<script>
import { v4 as uuidv4 } from 'uuid';

const loadingContainerUUID = uuidv4();

export default {
  data: () => ({
    [loadingContainerUUID]: {
      // loading component
      target: null,
      // 程序容器
      process: [],
    },
  }),

  methods: {
    /**
     * 給予當前程序一個標籤，並將當前程序的標籤加入到程序容器中
     * 並判斷程序容器是否都執行完畢，都執行完畢就開啟loading
     *
     * @returns {string} 標籤
     */
    startLoading() {
      // 產生uuid標籤，確保標籤無法重複
      const tag = uuidv4();

      // 判斷是否所有的程序都執行完畢
      if (!this[loadingContainerUUID].process.length) {
        // 啟動loading
        this[loadingContainerUUID].target = this.$loading.show();
      }

      // 將標籤放入程序容器中
      this[loadingContainerUUID].process.push(tag);

      // 將標籤傳出
      return tag;
    },
    /**
     * 將當前的程序標籤設定為執行完畢，並判斷是否所有程序都執行完畢後關閉loading
     */
    endLoading(tag) {
      // 將當前的程序標籤設定執行完畢
      this[loadingContainerUUID].process = this[loadingContainerUUID].process.filter(data => data !== tag);

      // 判斷是否所有程序都執行完畢
      if (!this[loadingContainerUUID].process.length) {
        // 關閉loading
        this[loadingContainerUUID].target['hide']();
      }
    },
  },
};
</script>
