<template lang="pug">
#feature.feature
  .text-center.mb-5
    p.area-english-title.text-light :: Features ::
    h2.area-title.text-light WUWOW Jr 學習優勢

  //- 圈圈們
  .feature-content.home-container
    .row
      .circle.col-12.col-lg-5.ratio.ratio-1x1.d-flex.justify-content-center.align-items-center
        .ratio.ratio-1x1.text-center.w-75
          .circle-icon.d-flex.justify-content-center.align-items-center
            img.h-75(:src="file.calendarSVG")
          .circle-title.d-flex.justify-content-center.align-items-end
            h3.text-lioshutan.mb-0 最高彈性的訂課方針
          .circle-text.pt-2
            p.pt-0 不論是想定期安排課程，或即時挑選時間，我們提供最高彈性的排課自由，更可於課前15分鐘免費取消。
      .circle.col-12.col-lg-5.ratio.ratio-1x1.d-flex.justify-content-center.align-items-center
        .ratio.ratio-1x1.text-center.w-75
          .circle-icon.d-flex.justify-content-center.align-items-center
            img.w-100.h-100(:src="file.classSVG")
          .circle-title.d-flex.justify-content-center.align-items-end
            h3.text-lioshutan.mb-0 全真人１對１教學
          .circle-text.pt-2
            p.pt-0 全課程皆採 1對1 教學，牢牢抓住孩子注意力，讓學習的每一分鐘都能夠得到效果。
      .circle.col-12.col-lg-5.ratio.ratio-1x1.d-flex.justify-content-center.align-items-center
        .ratio.ratio-1x1.text-center.w-75
          .circle-icon.d-flex.justify-content-center.align-items-center
            img.w-100.h-100(:src="file.clockSVG")
          .circle-title.d-flex.justify-content-center.align-items-end
            h3.text-lioshutan.mb-0 25 分鐘無壓學習
          .circle-text.pt-2
            p.pt-0 把握 25 分鐘黃金注意力時間，讓孩子在沒有負擔的學習中培養出興趣。比起一次學很多，我們更希望孩子能學得長久。
      .circle.col-12.col-lg-5.ratio.ratio-1x1.d-flex.justify-content-center.align-items-center
        .ratio.ratio-1x1.text-center.w-75
          .circle-icon.d-flex.justify-content-center.align-items-center
            img.w-100.h-100(:src="file.serviceSVG")
          .circle-title.d-flex.justify-content-center.align-items-end
            h3.text-lioshutan.mb-0 最即時的真人客服
          .circle-text.pt-2
            p.pt-0 好評價來自於我們的用心服務。每堂課程進行時，我們的客服人員將在一旁待命，隨時為您處理所有疑難雜症。
  //- 手機用
  //- .d-block.d-sm-none
  //-   .container
  //-     .row
  //-       .col-md-6.my-3
  //-         .ratio.ratio-1x1
  //-           .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-             style="background-color: #fdf7eb;"
  //-           )
  //-             .ratio.ratio-1x1(style="width: 50px;")
  //-               img.w-100.h-100(:src="file.calendarSVG")
  //-             h5.text-lioshutan 最高彈性的訂課方針
  //-             p.mx-3.my-2 不論是想定期安排課程，或即時挑選時間，我們提供最高彈性的排課自由，更可於課前15分鐘免費取消。
  //-       .col-md-6.my-3
  //-         .ratio.ratio-1x1
  //-           .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-             style="background-color: #fdf7eb;"
  //-           )
  //-             .ratio.ratio-1x1(style="width: 50px;")
  //-               img.w-100.h-100(:src="file.classSVG")
  //-             h5.text-lioshutan 全真人１對１教學
  //-             p.mx-3.my-2 全課程皆採 1對1 教學，牢牢抓住孩子注意力，讓學習的每一分鐘都能夠得到效果。
  //-       .col-md-6.my-3
  //-         .ratio.ratio-1x1
  //-           .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-             style="background-color: #fdf7eb;"
  //-           )
  //-             .ratio.ratio-1x1(style="width: 50px;")
  //-               img.w-100.h-100(:src="file.clockSVG")
  //-             h5.text-lioshutan 25 分鐘無壓學習
  //-             p.mx-3.my-2 把握 25 分鐘黃金注意力時間，讓孩子在沒有負擔的學習中培養出興趣。比起一次學很多，我們更希望孩子能學得長久。
  //-       .col-md-6.my-3
  //-         .ratio.ratio-1x1
  //-           .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-             style="background-color: #fdf7eb;"
  //-           )
  //-             .ratio.ratio-1x1(style="width: 50px;")
  //-               img.w-100.h-100(:src="file.serviceSVG")
  //-             h5.text-lioshutan 最即時的真人客服
  //-             p.mx-3.my-2 好評價來自於我們的用心服務。每堂課程進行時，我們的客服人員將在一旁待命，隨時為您處理所有疑難雜症。

  //- 給平板的尺寸
  //- .d-none.d-md-block.d-lg-none
  //-   .container
  //-     .row
  //-       .col-md-6.my-3
  //-         .ratio.ratio-1x1
  //-           .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-             style="background-color: #fdf7eb;"
  //-           )
  //-             .ratio.ratio-1x1(style="width: 50px;")
  //-               img.w-100.h-100(:src="file.calendarSVG")
  //-             h5.text-lioshutan 最高彈性的訂課方針
  //-             p.mx-3.my-2 不論是想定期安排課程，或即時挑選時間，我們提供最高彈性的排課自由，更可於課前15分鐘免費取消。
  //-       .col-md-6.my-3
  //-         .ratio.ratio-1x1
  //-           .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-             style="background-color: #fdf7eb;"
  //-           )
  //-             .ratio.ratio-1x1(style="width: 50px;")
  //-               img.w-100.h-100(:src="file.classSVG")
  //-             h5.text-lioshutan 全真人１對１教學
  //-             p.mx-3.my-2 全課程皆採 1對1 教學，牢牢抓住孩子注意力，讓學習的每一分鐘都能夠得到效果。
  //-       .col-md-6.my-3
  //-         .ratio.ratio-1x1
  //-           .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-             style="background-color: #fdf7eb;"
  //-           )
  //-             .ratio.ratio-1x1(style="width: 50px;")
  //-               img.w-100.h-100(:src="file.clockSVG")
  //-             h5.text-lioshutan 25 分鐘無壓學習
  //-             p.mx-3.my-2 把握 25 分鐘黃金注意力時間，讓孩子在沒有負擔的學習中培養出興趣。比起一次學很多，我們更希望孩子能學得長久。
  //-       .col-md-6.my-3
  //-         .ratio.ratio-1x1
  //-           .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-             style="background-color: #fdf7eb;"
  //-           )
  //-             .ratio.ratio-1x1(style="width: 50px;")
  //-               img.w-100.h-100(:src="file.serviceSVG")
  //-             h5.text-lioshutan 最即時的真人客服
  //-             p.mx-3.my-2 好評價來自於我們的用心服務。每堂課程進行時，我們的客服人員將在一旁待命，隨時為您處理所有疑難雜症。

  //- 電腦用
  //- .d-none.d-sm-block.d-md-none.d-lg-block
  //-   .container#feature
  //-     .d-flex.flex-column.align-items-center
  //-       .w-75
  //-         .container
  //-           .row
  //-             .col-md-6.mb-4
  //-               .ratio.ratio-1x1
  //-                 .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-                   style="background-color: #fdf7eb;"
  //-                 )
  //-                   .ratio.ratio-1x1(style="width: 50px;")
  //-                     img.w-100.h-100(:src="file.calendarSVG")
  //-                   h5.text-lioshutan 最高彈性的訂課方針
  //-                   p.mx-3.my-2 不論是想定期安排課程，或即時挑選時間，我們提供最高彈性的排課自由，更可於課前15分鐘免費取消。
  //-             .col-md-6.mb-4
  //-               .ratio.ratio-1x1
  //-                 .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-                   style="background-color: #fdf7eb;"
  //-                 )
  //-                   .ratio.ratio-1x1(style="width: 50px;")
  //-                     img.w-100.h-100(:src="file.classSVG")
  //-                   h5.text-lioshutan 全真人１對１教學
  //-                   p.mx-3.my-2 全課程皆採 1對1 教學，牢牢抓住孩子注意力，讓學習的每一分鐘都能夠得到效果。
  //-             .col-md-6.mb-4
  //-               .ratio.ratio-1x1
  //-                 .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-                   style="background-color: #fdf7eb;"
  //-                 )
  //-                   .ratio.ratio-1x1(style="width: 50px;")
  //-                     img.w-100.h-100(:src="file.clockSVG")
  //-                   h5.text-lioshutan 25 分鐘無壓學習
  //-                   p.mx-3.my-2 把握 25 分鐘黃金注意力時間，讓孩子在沒有負擔的學習中培養出興趣。比起一次學很多，我們更希望孩子能學得長久。
  //-             .col-md-6.mb-4
  //-               .ratio.ratio-1x1
  //-                 .w-100.h-100.rounded-circle.d-flex.flex-column.justify-content-center.align-items-center.px-3(
  //-                   style="background-color: #fdf7eb;"
  //-                 )
  //-                   .ratio.ratio-1x1(style="width: 50px;")
  //-                     img.w-100.h-100(:src="file.serviceSVG")
  //-                   h5.text-lioshutan 最即時的真人客服
  //-                   p.mx-3.my-2 好評價來自於我們的用心服務。每堂課程進行時，我們的客服人員將在一旁待命，隨時為您處理所有疑難雜症。
</template>

<script>
import calendarSVG from '@/assets/icon_calendar.svg';
import classSVG from '@/assets/icon_class.svg';
import clockSVG from '@/assets/icon_clock.svg';
import serviceSVG from '@/assets/icon_service.svg';

import 'bootstrap/dist/css/bootstrap.css';

export default {
  data: () => ({
    file: {
      calendarSVG,
      classSVG,
      clockSVG,
      serviceSVG,
    },
  }),
};
</script>
<style lang="scss" scoped>
.feature {
  background-image: url("~@/assets/blue_bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -10px;
  padding-top: 70px;
  .subtitle {
    font-size: 20px;
    letter-spacing: 4px;
  }
  .feature-content {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .row {
      width: 1030px;
      justify-content: center;
      .circle {
        margin: 20px;
        background-color: #FDF7EB;
        border-radius: 50%;
        .circle-icon {
          height: 25%;
        }
        .circle-title {
          height: 15%;
        }
        .circle-text {
          text-align: left;
          height: 60%;
          font-size: 19px;
          color: #707070;
        }
      }
    }
  }
}
@media screen and ( max-width: 998px ){
  .feature {
    background-position: center;
    .feature-content {
      .row {
        width: 450px;
        .circle {
          .circle-text {
            display: flex;
            align-items: center;
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media screen and ( max-width: 414px ){
  .feature {
    background-image: -webkit-linear-gradient(top, #89C3E8, #8ec9ee);
    .feature-content {
      .row {
        width: 90%;
        .circle {
          .circle-text {
            font-size: 16px;
          }
        }
      }
    }
    .subtitle {
      font-size: 15px;
      letter-spacing: 3px;
    }
  }
}
@media screen and ( max-width: 320px ){
  .feature {
    .feature-content {
      .row {
        .circle {
          .circle-title {
            h3 {
              font-size: 20px;
            }
          }
          .circle-text {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
