import axios from '@/api/utils/axios-helper.js';

export default class vipRequests {
  /**
    * 取得學生詢問列表
    * @returns {Promise<Object>} teach.interested_topic_ids: 取得學生詢問列表
    */
  async queryStudentRequestsList(query) {
    const response = await axios({
      url: '/v3/taichi-external/consultant/ask-consultant/students-request-list',
      method: 'get',
      params: query,
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }

  /**
   * 同意學員的詢問
   * @param askConsultantTimeslotIds[]:
   * @returns {Promise<Object>} 回傳新增成功失敗依照http code判斷
   */
  async approveAskConsultant(id) {
    const data = {
      askConsultantTimeslotIds: id,
    };
    const response = await axios({
      url: '/v3/taichi-external/consultant/ask-consultant/approve',
      method: 'post',
      data,
    });
    return response;
  }

  /**
   * 拒絕學員的詢問
   * @param askConsultantTimeslotIds[]:
   * @returns {Promise<Object>} 回傳新增成功失敗依照http code判斷
   */
  async rejectAskConsultant(id) {
    const data = {
      askConsultantTimeslotIds: id,
    };
    const response = await axios({
      url: '/v3/taichi-external/consultant/ask-consultant/reject',
      method: 'post',
      data,
    });
    return response;
  }

  /**
   * 顧問被請求未處理數量
   * @returns {Promise<Object>} 回傳需處理數量
   */
  async getNotReplyAskConsultant() {
    const response = await axios({
      url: '/v3/taichi-external/consultant/ask-consultant/not-reply',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
}
