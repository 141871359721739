export default {
  messages: {
    tw: {
      infoModalTitle: '新訊息',
      enter: '進入',
      cancel: '取消',
      enterClassNotice: '進入教室通知',
    },
    en: {
      infoModalTitle: 'New Message',
      enter: 'Enter',
      cancel: 'Cancle',
      enterClassNotice: 'Classroom entry notification',
    },
  },
};
