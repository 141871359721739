import pusher from 'pusher-js';
import storage from '@/utils/localStorage';
import restfulApi from '@lioshutan/restful-package';

// 顯示log
pusher.logToConsole = false;
// 宣告key

export async function connectPusher() {
  const pusherInfo = storage.pusherInfo;
  if (pusherInfo) {
    const PUSHER_APP_KEY = pusherInfo.key;
    const PUSHER_CLUSTER = pusherInfo.cluster;
    const authEndpointUrl = `${process.env.VUE_APP_BASE_API}/api/broadcasting/auth`;
    const bearerToken = `Bearer ${storage.token}`;
    // new puser
    const globalPusher = new pusher(
      PUSHER_APP_KEY,
      {
        cluster: PUSHER_CLUSTER,
        authEndpoint: authEndpointUrl,
        auth: { headers: { Authorization: bearerToken }},
      }
    );
    return globalPusher;
  }
  return null;
}

export async function getPusherChannel(localPusher, channel) {
  if (localPusher){
    const pusherChannel = await localPusher.getChannel(channel);
    return pusherChannel;
  } else {
    return null;
  }
}

export async function getPusherInfo(localPusher) {
  const result = await localPusher.getPusherAppInfo();
  const pusherInfo = result.data.data;
  storage.pusherInfo = pusherInfo;
}

export async function getLocalPusher(){
  const localPusher = await restfulApi
    .setDNS(process.env.VUE_APP_BASE_API)
    .pusher(storage.token);
  return localPusher;
}
