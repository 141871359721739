const types = {
  SET_SPECIFIC_CLASSROOM_BASIC_INFO: 'SET_SPECIFIC_CLASSROOM_BASIC_INFO',
  SET_SPECIFIC_CLASSROOM_ANALYSIS_DATA: 'SET_SPECIFIC_CLASSROOM_ANALYSIS_DATA',
};

const state = {
  specificClassroomBasicInfo: {
    class: {
      id: '',
      room_id: '',
      time: '',
    },
    consultant: {
      name: '',
      image: '',
    },
    material: {
      title: '',
    },
    evaluation: {},
  },

  specificClassroomAnalysisData: {
    ca_id: 0,
    vip_id: 0,
    confirm_status: 0,
    vocabulary_level: 0,
    suggestion_of_vocabulary: null,
    grammar_level: 0,
    suggestion_of_grammar: null,
    listening_level: 0,
    suggestion_of_listening: null,
    speaking_level: 0,
    suggestion_of_speaking: null,
    reading_level: 0,
    suggestion_of_reading: null,
    pronunciation_level: 0,
    suggestion_of_pronunciation: null,
    average_level: 0,
    suggestion_of_consultant: '',
    recommend_upgrade: null,
    upgrade_level: 0,
    created_at: '',
    updated_at: '',
    material_title: '',
    vip_english_name: '',
    hb_english_name: '',
    material: {
      interested_topics: [
        {
          id: 0,
          title: '',
        },
      ],
    },
  },
};

const mutations = {
  [types.SET_SPECIFIC_CLASSROOM_BASIC_INFO]: (state, specificClassroomBasicInfo) => {
    state.specificClassroomBasicInfo = specificClassroomBasicInfo;
  },

  [types.SET_SPECIFIC_CLASSROOM_ANALYSIS_DATA]: (state, specificClassroomAnalysisData) => {
    state.specificClassroomAnalysisData = specificClassroomAnalysisData;
  },
};

const actions = {
  async setSpecificClassroomBasicInfo({ commit }, data) {
    const specificClassroomBasicInfo = {
      class: {
        id: data.class_applications,
        room_id: data.class_room.id,
        time: data.class_time,
      },
      consultant: {
        name: data.class_room.consultants.english_name,
        image: data.class_room.consultants.image,
      },
      material: {
        title: data.class_room.material.title,
      },
      evaluation: {
        ...data.class_room.evaluation,
      },
    };
    await commit(types.SET_SPECIFIC_CLASSROOM_BASIC_INFO, specificClassroomBasicInfo);
  },

  async setSpecificClassroomAnalysisData({ commit }, data) {
    const specificClassroomAnalysisData = data;
    await commit(types.SET_SPECIFIC_CLASSROOM_ANALYSIS_DATA, specificClassroomAnalysisData);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
