<template lang="pug">
  div
</template>
<script>
import { logout, teacherLogout } from '@/composable/auth';
import storage from '@/utils/localStorage';
import commonConstants from '@/constants/common';
import i18n from '@/components/operationTimedOut/lang';
import sweetalert from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

export default {
  name: 'OperationTimedOut',

  i18n,

  data() {
    return {
      lastOperationTime: this.getCurrentTime(),
      currentTime: this.getCurrentTime(),
      currentTimeOut: '',
      webListenEvent: 'mousemove', // 網頁讀取操作事件
      oneSecond: 1000, // 單位毫秒
      oneMinute: 60, // 單位秒
      overTime: 180, // 單位分
    };
  },

  created(){
    this.beginOperation();
    this.timeOut(this.overTime);
  },

  beforeDestroy() {
    this.stopOperation();
    clearTimeout(this.currentTimeOut);
  },

  methods: {
    timeOut(overTime = 10){
      this.currentTimeOut = setTimeout(() => {
        const timeOut = overTime * this.oneMinute * this.oneSecond;
        this.currentTime = this.getCurrentTime();
        // 目前時間 - 最後操作時間 大於 時限
        if (this.currentTime - this.lastOperationTime > timeOut){
          clearTimeout(this.currentTimeOut);
          this.showOperationOverTimeAlert();
        } else {
          this.timeOut(overTime);
        }
      }, this.oneSecond);
    },

    beginOperation(){
      document.addEventListener(this.webListenEvent, this.resetLastOperationTime());
    },

    stopOperation(){
      document.removeEventListener(this.webListenEvent, this.resetLastOperationTime());
    },

    resetLastOperationTime(){
      this.lastOperationTime = this.getCurrentTime();
    },

    showOperationOverTimeAlert(){
      const alertType = 'info';
      sweetalert({
        title: this.$t('operationDelay'),
        text: this.$t('operationDelayMessage', { overtime: this.overTime }),
        type: alertType,
        allowOutsideClick: false,
      }).then(() => {
        this.roleLogout();
      });
    },

    getCurrentTime(){
      return new Date().getTime();
    },

    roleLogout(){
      (storage.role === commonConstants.ROLE.TEACHER) ? teacherLogout() : logout();
    },
  },
};
</script>
