import axios from '@/api/utils/axios-helper.js';
export default class RenewalReminder{
  async getVipContractRenewalReminde(params) {
    const response = await axios({
      url: '/v3/human-resource/student/contract/vip-contract-renewal-reminder',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params,
      responseType: 'json',
    });
    return response.data;
  }

  async patchVipContractRenewalReminde(data, id) {
    const response = await axios({
      url: `/v3/human-resource/student/contract/vip-contract-renewal-reminder/${id}`,
      method: 'patch',
      data,
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
}
