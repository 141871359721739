<template lang="pug">
.avatar
  //- 預設頭像
  img.w-100(:src="defaultAvaterUrl" v-if="avatarUrl === ''")
  //- api傳回來的url
  img.w-100.h-100(:src="avatarUrl" v-else)
</template>

<script type="text/javascript">
export default {
  name: 'Avatar',

  props: {
    avatarUrl: {
      // type: String,
      default: '',
    },
    // 從上一層傳進來寫死的使用的角色(ex: parent, student)
    avatarRole: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      defaultAvaterUrl: '',
    };
  },

  created() {
    if (this.avatarRole === 'parent') {
      this.defaultAvaterUrl = 'https://cdn2.wuwow.tw/images/parentspic.png';
    } else {
      this.defaultAvaterUrl = 'https://cdn2.wuwow.tw/images/kidpic.png';
    }
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  img {
    border-radius: 50%;
  }
}
</style>
