export default {
  student: [
    {
      name: 'layout.book_class',
      list: [
        {
          name: '依照日期時間訂課',
          url: '/dojo/book_class',
        },
        {
          name: '指定顧問訂課',
          url: '/dojo/consultants',
        },
        {
          name: '規律訂課',
          url: '/dojo/schedule/fixed',
        },
        {
          name: 'layout.book_class_record',
          url: '/dojo/bookClassRecord',
        },
      ],
    },
    {
      name: 'layout.wuwow_learning',
      list: [
        {
          name: 'layout.classroom',
          url: '/dojo/classRoom',
        },
        {
          name: 'layout.class_evaluation',
          url: '/dojo/evaluations',
        },
        {
          name: 'layout.class_record',
          url: '/dojo/classRecords',
        },
        {
          name: 'layout.progress_analysis',
          url: '/dojo/account/progressAnalysis',
        },
      ],
    },
    {
      name: 'layout.support_service',
      list: [
        {
          name: 'layout.common_problem',
          url: '/dojo/faq',
        },
        {
          name: 'layout.self_system_testing',
          url: '/dojo/account/selfTest',
        },
        {
          name: 'layout.notification_push',
          url: '/dojo/account/profile#notification_push',
        },
        {
          name: 'layout.contact_us',
          url: '/dojo/contact-us',
        },
      ],
    },
    {
      name: 'layout.vip_area',
      list: [
        {
          name: 'layout.my_contract',
          url: '/dojo/account/contracts',
        },
        {
          name: 'layout.profile',
          url: '/dojo/account/profile',
        },
        {
          name: 'parentZone',
          url: '/dojo/parents/profile',
        },
        {
          name: 'layout.my_notification',
          url: '/dojo/notification',
        },
      ],
    },
  ],
  parent: [
    {
      name: 'layout.book_class',
      list: [
        {
          name: '依照日期時間訂課',
          url: '/dojo/book_class',
        },
        {
          name: '指定顧問訂課',
          url: '/dojo/consultants',
        },
        {
          name: '規律訂課',
          url: '/dojo/schedule/fixed',
        },
        {
          name: 'layout.book_class_record',
          url: '/dojo/bookClassRecord',
        },
      ],
    },
    {
      name: 'layout.wuwow_learning',
      list: [
        {
          name: 'layout.classroom',
          url: '/dojo/classRoom',
        },
        {
          name: 'layout.class_record',
          url: '/dojo/classRecords',
        },
        {
          name: 'layout.progress_analysis',
          url: '/dojo/account/progressAnalysis',
        },
      ],
    },
    {
      name: 'layout.support_service',
      list: [
        {
          name: 'layout.common_problem',
          url: '/dojo/faq',
        },
        {
          name: 'layout.self_system_testing',
          url: '/dojo/account/selfTest',
        },
        {
          name: 'layout.notification_push',
          url: '/dojo/account/profile#notification_push',
        },
        {
          name: 'layout.contact_us',
          url: '/dojo/contact-us',
        },
      ],
    },
    {
      name: 'layout.vip_area',
      list: [
        {
          name: 'layout.studentContract',
          url: '/dojo/account/contracts',
        },
        {
          name: 'parentZone',
          url: '/dojo/parents/profile',
        },
        {
          name: 'layout.my_notification',
          url: '/dojo/notification',
        },
      ],
    },
  ],
};
