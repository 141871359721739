import moment from 'moment';

/**
 * @des 取得 YYYY-MM-DD HH:mm:ss 時間
 * @param {String} time
 * @returns {String} 回傳 YYYY-MM-DD HH:mm:ss 格式
 */
export function getDateWithTimeSecond(time) {
  return moment(time).format('YYYY-MM-DD HH:mm:ss');
}

/**
 * @des 取得 YYYY-MM-DD HH:mm 時間
 * @param {String} time
 * @returns {String} 回傳 YYYY-MM-DD HH:mm 格式
 */
export function getDateWithTimeMinute(time) {
  return moment(time).format('YYYY-MM-DD HH:mm');
}

/**
 * @des 取得 YYYY-MM-DD 時間
 * @param {String} time
 * @returns {String} 回傳 YYYY-MM-DD格式
 */
export function getDate(time) {
  return moment(time).format('YYYY-MM-DD');
}

/**
 * @des 取得時段內所有日期
 * @param {String} time
 * @returns {String} 回傳 ['start YYYY-MM-DD'...'end YYYY-MM-DD']
 */
export function getDateBetween(start, end) {
  const result = [];
  const startTime = new Date(start);
  const endTime = new Date(end);
  // eslint-disable-next-line
  while (endTime - startTime >= 0) {
    const year = startTime.getFullYear();
    let month = startTime.getMonth();
    month = month < 9 ? '0' + (month + 1) : month + 1;
    const day = startTime.getDate().toString().length === 1 ? '0' + startTime.getDate() : startTime.getDate();
    result.push(year + '-' + month + '-' + day);
    startTime.setDate(startTime.getDate() + 1);
  }
  return result;
}
