<template lang="pug">
  socket-info-modal(ref="web-socket-info-modal" :socketData="pusherData")
</template>

<script>
import socketInfoModal from '@/components/notifyModal/webSocketInfoModal/index.vue';
import socketConstants from '@/constants/socket';
import commonConstants from '@/constants/common';
import storage from '@/utils/localStorage';
import lioshutanApi from '@/api';
import { getLocalPusher, getPusherInfo, connectPusher, getPusherChannel } from '@/utils/pusher';

export default {
  name: 'PusherHandle',

  components: {
    socketInfoModal,
  },

  data() {
    return {
      classPusher: null,
      pusherData: null,
      localPusher: null,
    };
  },

  async created() {
    storage.token !== '' && storage.token !== 'null' ? this.setUpPusher() : '';
  },

  beforeDestroy() {
    this.localPusher.disconnection();
  },

  methods: {
    async setUpPusher(){
      this.localPusher = await getLocalPusher();
      await getPusherInfo(this.localPusher);
      this.localPusher.setApiPusher(await connectPusher());
      await this.setPusherEvent();
    },

    async setPusherEvent(){
      const onlineSubscribeChannel = 'presence-online-subscribe-all-platform';
      const uuid = await this.getUserUUid();
      const lstWuwowChannel = `private-lst-wuwow-${uuid}`;
      const WUWOW_ACCESS_CLASSROOM_EVENT = 'wuwow-access-classroom';
      const currentUrlPath = window.location.pathname;
      const checkUrlPath = [
        // '/console/classroom',
        // '/dojo/classRoom',
      ];

      if (checkUrlPath.includes(currentUrlPath)) {
        await getPusherChannel(this.localPusher, onlineSubscribeChannel);
      }

      if (storage.role === commonConstants.ROLE.STUDENT || storage.role === commonConstants.ROLE.PARENT){
        const pusherLstWuwowChannel = await getPusherChannel(this.localPusher, lstWuwowChannel);
        if (pusherLstWuwowChannel){
          // 取得事件訊息
          await this.localPusher.getEventMessage(pusherLstWuwowChannel, WUWOW_ACCESS_CLASSROOM_EVENT, this.getMessage);
        }
      }
    },

    getMessage(data) {
      const accessClassroomModal = this.$refs['web-socket-info-modal'].$refs[socketConstants.MODAL_REF.ACCESS_CLASSROOM];
      accessClassroomModal.hide();
      this.pusherData = data.message;
      accessClassroomModal.show();
    },

    async getUserUUid() {
      const result = await lioshutanApi.vip.getUserUuid();
      const uuid = result.data.data.uuid;
      return uuid;
    },
  },
};
</script>
