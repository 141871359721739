var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "nav"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('span', {
    staticClass: "nav-link"
  }, [_vm._v(_vm._s(_vm.$t('center')))]), _c('ul', {
    staticClass: "subnav"
  }, _vm._l(_vm.centerLists, function (item) {
    return _c('li', {
      key: item.name,
      staticClass: "subnav-item"
    }, [_c('router-link', {
      staticClass: "dropdown-item",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))])], 1);
  }), 0)]), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticStyle: {
      "height": "40px"
    }
  }, [_c('p')]);

}]

export { render, staticRenderFns }