import { alertFailedMessage } from '@/utils/sweetAlert';
import i18n from '@/lang/index';

/**
 * @des 判斷物件為空
 * @param {Object}
 * @returns {Boolean} 回傳Boolean
 */
export function isObjectEmpty(object) {
  return Object.keys(object).length === 0;
}

/**
 * @des email格式檢查
 * @param {String} email
 * @returns {Boolean} 回傳驗證成功與否
 */
export function checkEmailFormat(email) {
  const emailRegex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return emailRegex.test(email);
}

/**
 * @des phone格式檢查
 * @param {Number} phone
 * @returns {Boolean} 回傳驗證成功與否
 */
export function checkPhoneFormat(phone) {
  const phoneRegex = /^09[0-9]{8}$/;
  return phoneRegex.test(phone);
}

/**
 * @des url格式檢查
 * @param {String} url
 * @returns {Boolean} 回傳驗證成功與否
 * 參考 : https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
 */
export function checkValidURLFormat(url) {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(url);
}

/**
 * @des input 是否為空, check 可用來當作其他判斷 ex 長度, 格式
 * @param {String || Number}
 * @returns {String} 回傳成功或是失敗 border class
 */
export function getFiledInputedClass(checked = 'ignore') {
  if (checked === 'ignore'){ // 不須顯示
    return '';
  }
  return checked ? 'border-success' : 'border-danger';
}

/**
 * @des 檢查密碼 新密碼是否相同
 * @param {string}  newPassword, confirmPassword
 * @returns {Bolean} 回傳成功或是失敗
 */
export function passwordChangeCheck(newPassword, confirmPassword) {
  if (newPassword === confirmPassword ||
    newPassword === '') {
    return true;
  } else if (newPassword !== confirmPassword) {
    alertFailedMessage(i18n.tc('pwNotMatch'));
    return false;
  }
}

/**
 * @des 檢查需填寫欄位
 * @param {object} 檢查的物件 檢查的欄位
 * @returns {Bolean} 回傳成功或是失敗
 */
export function checkRequireFileds(checkedObject, checkFields){
  let allFill = true;
  Object.entries(checkFields).forEach(([key, value]) => {
    value.forEach((filed) => {
      if (checkedObject[key][filed] === '' || checkedObject[key][filed] === null){
        allFill = false;
      }
    });
  });
  return allFill;
}
