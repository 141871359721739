<script>
export default {
  data() {
    return {
      state: '',
    };
  },

  watch: {
    $route(){
      this.state = '';
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    toggleDropdown(type) {
      if (this.state === type) {
        this.state = '';
      } else {
        this.state = type;
      }
    },

    close(element) {
      if (!this.$el.contains(element.target)) {
        this.state = '';
      }
    },
  },
};
</script>
