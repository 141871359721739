<template lang="pug">
  .are-personal
    .blk-personal-info
      div 學員帳號
      b-row
        b-col(cols="3")
          b-skeleton(type="avatar")
        b-col(cols="7")
          span.txt-personal-name
            b-skeleton
          br
          b-skeleton
        b-col(cols="2")
      p.txt-personal-info.mt-4
      hr
      b-skeleton
      b-skeleton
      hr
      b-skeleton
      b-skeleton
      b-skeleton
      b-skeleton

</template>

<script type="text/javascript">

export default {
  name: 'VipAccountDetailSkeleton',

};
</script>
<style>
.skeleton-box{
  margin: 0 auto;
}
</style>
