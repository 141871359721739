<template lang="pug">
  skeleton(:loading="loading")
    template(slot="skeleton-template")
      skeleton-template
    template(slot="origin-template")
      .are-personal
        .blk-personal-info
          .student-account.mb-2 學員帳號
          b-row
            b-col(cols="3")
              avatar(:avatarUrl="vip_account_info.profile_base.photo" :avatarRole="'student'")
            b-col(cols="7")
              span.txt-personal-name {{ vip_account_info.profile_base.english_name }}
              br
              span.txt-personal-level(:class="userLevelClass") LV. {{ vip_account_info.profile_base.level }}
            b-col(cols="2")
              b-button.change-account-btn(v-if="role === parent" variant="outline-primary" @click="changeStudent") 切換
          p.txt-personal-info.mt-4
            contract-period(:vip_account_info="vip_account_info")
        feedback-quota(:vip_account_info="vip_account_info")
        my-account-link(:role="role")
        a.lnk-personal-logout( v-if="role === student" href='javascript:;' @click='logout')
          | {{ $t('logout' )}}
</template>

<script type="text/javascript">
import { logout } from '@/composable/auth';
import contractPeriod from '@/components/vip/account/contractPeriod/index.vue';
import feedbackQuota from '@/components/vip/account/feedbackQuota/index.vue';
import myAccountLink from '@/components/vip/account/myAccountLink/index.vue';
import lioshutanApi from '@/api';
import commonConstants from '@/constants/common';
import skeleton from '@/components/skeleton/index.vue';
import skeletonTemplate from '@/components/vip/account/detail/skeleton';
import avatar from '@/components/avatar/index.vue';
import {
  mapState,
} from 'vuex';

export default {
  name: 'VipAccountDetail',

  components: {
    contractPeriod,
    feedbackQuota,
    myAccountLink,
    skeleton,
    skeletonTemplate,
    avatar,
  },

  props: {
    role: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      student: commonConstants.ROLE.STUDENT,
      parent: commonConstants.ROLE.PARENT,
      vip_account_info: {
        profile_base: {
          english_name: 'English Name',
          photo: '',
          level: 0,
        },
        elapsed_time: {
          balance_sessions: 0,
          contract_start: '0001-01-01',
          contract_end: '9999-12-31',
        },
        feedback_quota: {
          amount: 0,
          day_amount: 0,
          month_amount: 0,
        },
      },
    };
  },

  async created(){
    this.setAccountBaseInfo();
  },

  computed: {
    ...mapState({
      hasGetProfile: (state) => state.vipAccount.hasGetProfile,
    }),
    userLevelClass() {
      return {
        'txt-personal-level-1': (this.vip_account_info.profile_base.level < 4),
        'txt-personal-level-2': (this.vip_account_info.profile_base.level > 3 && this.vip_account_info.profile_base.level < 7),
        'txt-personal-level-3': (this.vip_account_info.profile_base.level > 6 && this.vip_account_info.profile_base.level < 10),
        'txt-personal-level-4': (this.vip_account_info.profile_base.level > 9),
      };
    },
  },

  methods: {
    async logout() {
      logout();
    },
    async setAccountBaseInfo() {
      this.startLoading();
      const baseProfileResult = await lioshutanApi.vip.getBaseProfile();
      this.vip_account_info.profile_base = baseProfileResult.data.profile_base;
      this.$store.dispatch('vipAccount/addVipAccountBaseProfile', this.vip_account_info.profile_base);
      const contractElapsedTimeResult = await lioshutanApi.vip.getContractElapsedTime();
      this.vip_account_info.elapsed_time = contractElapsedTimeResult.data.elapsed_time;
      this.$store.dispatch('vipContract/addVipContractElapsedTime', this.vip_account_info.elapsed_time);
      const contractFeedbackQuotaResult = await lioshutanApi.vip.getContractFeedbackQuota();
      this.vip_account_info.feedback_quota = contractFeedbackQuotaResult.data.feedback_quota;
      this.$store.dispatch('vipContract/addVipContractFeedbackQuota', this.vip_account_info.feedback_quota);
      this.$store.dispatch('vipAccount/setHasGetProfile', true);
      this.endLoading();
    },
    changeStudent(){
      this.$router.push({ path: '/parent' });
    },
    startLoading(){
      this.loading = true;
    },

    endLoading(){
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.student-account {
  font-size: 16px;
}
</style>
