export default {
  FATHER: 'father',
  MOTHER: 'mother',
  MATERNAL_GRANDFATHER: 'maternal_grandfather',
  MATERNAL_GRANDMOTHER: 'maternal_grandmother',
  PATERNAL_GRANDFATHER: 'paternal_grandfather',
  PATERNAL_GRANDMOTHER: 'paternal_grandmother',
  BROTHER: 'brother',
  SISTER: 'sister',
  OTHER: 'other',
};

