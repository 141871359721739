<template lang="pug">
b-modal(v-model="show" size="lg" centered hide-footer @change="close")
  .choice-title
    p.jr-light-color.text-center 請選擇 登入/註冊 角色
  .d-flex.justify-content-center.mt-4
    a.p-3.m-2.login-role-img(@click="changeCharacter(CHARACTER.CHILDREN)")
      p.text-center 學員
      img.w-100(:src="file.studentLoginImg")
    a.p-3.m-2.login-role-img(@click="changeCharacter(CHARACTER.PARENT)")
      p.text-center 家長
      img.w-100(:src="file.parentLoginImg")
</template>

<script>
import { v4 } from 'uuid';

import {
  mapMutations,
} from 'vuex';
import {
  CHARACTER as CHARACTER_STORE,
  MODAL as MODAL_STORE,
} from '@/store/index';
import {
  SET_CHARACTER,
} from '@/store/modules/character';

import {
  SET_IS_OPEN,
} from '@/store/modules/modal';

import modalMixin from '@/mixins/modal';

import CHARACTER from '@/constants/character';

import { uuid as PARENT_UUID } from '@/views/home/parent/loginModal/index.vue';
import { uuid as CHILDREN_UUID } from '@/views/home/loginModal/index.vue';

export const uuid = v4();

import 'bootstrap/dist/css/bootstrap.css';

import studentLoginImg from '@/assets/login/student_login_btn_pic.png';
import parentLoginImg from '@/assets/login/parent_login_btn_pic.png';
import background1 from '@/assets/login/background1.png';
import background2 from '@/assets/login/background2.png';
import background3 from '@/assets/login/background3.png';

export default {
  mixins: [
    modalMixin,
  ],

  data: () => ({
    CHARACTER,
    modal: {
      PARENT_UUID,
      CHILDREN_UUID,
    },
    file: {
      studentLoginImg,
      parentLoginImg,
      background1,
      background2,
      background3,
    },
  }),

  created() {
    this.initModal(uuid, {
      status: false,
    });
  },

  methods: {
    ...mapMutations({
      changeCharacter(commit, character) {
        commit(`${CHARACTER_STORE}/${SET_CHARACTER}`, character);
        const target = {
          [CHARACTER.PARENT]: this.modal.PARENT_UUID,
          [CHARACTER.CHILDREN]: this.modal.CHILDREN_UUID,
        }[character];

        this.close();

        commit(`${MODAL_STORE}/${SET_IS_OPEN}`, {
          target,
          status: true,
        });
      },
    }),
  },

};
</script>
<style lang="scss" scoped>
%box-border-radius {
  border-radius: 15px;
}
.choice-title {
  position: absolute;
  width: 100%;
  top: -36px;
  font-size: 24px;
}
.login-role-img {
  @extend %box-border-radius;
  cursor: pointer;
  border: 1px solid #fff0;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-image: url(~@/assets/login/background4.png);
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 15px;
  }
  &:hover:after {
    background-image: none;
  }
  &:hover {
    display:inline-block;
    border: 1px solid var( --light-color );
    text-shadow: 0px 0px 10px #FFFFFF;
  }
  p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    color: white;
    letter-spacing: 0.3em;
  }
  img {
    @extend %box-border-radius;
  }
}
/deep/ .modal-content {
  background-color: #fff0!important;
  border: none;
  .close {
    color: white;
    border: 1px solid white;
    border-radius: 50%;
    padding: 0;
    width: 30px;
    height: 30px;
    padding-bottom: 4px;
    opacity: 1;
    z-index: 1;
  }
}
/deep/ .modal {
  // background-color: aqua;
  background-image: url(~@/assets/login/background4.png),url(~@/assets/login/background3.png),url(~@/assets/login/background2.png),url(~@/assets/login/background1.png);
  background-position: center, right bottom, center, center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: cover, auto, cover, cover;
}
</style>

// <style lang="scss">
// // 為了調整modal的css待但不影響其他頁面
// [data-v-74425164] .modal-content {
//   background-color: #fff0!important;
//   border: none;
//   .close {
//     color: white;
//     border: 2px solid white;
//     border-radius: 50%;
//     padding: 0;
//     width: 30px;
//     height: 30px;
//     padding-bottom: 4px;
//     opacity: 1;
//   }
// }
// </style>
