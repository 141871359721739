import Vue from 'vue';
import Router from 'vue-router';
import routers from './routers';

Vue.use(Router);

const constantRoutes = routers;

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.VUE_APP_BASE_PATH,
  routes: constantRoutes,
});

const router = createRouter();

export default router;
