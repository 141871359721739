import axios from '@/api/utils/axios-helper.js';

export default class Faq {
  async getFaqTypeList(params) {
    const response = await axios({
      url: '/v3/message/category',
      method: 'get',
      // query string 需預設平台、codename 搜尋欄位
      params: {
        ...params,
      },

    });
    return response;
  }

  async getFaqList(params) {
    const response = await axios({
      url: '/v3/message/faq-items',
      method: 'get',
      params: {
        ...params,
      },

    });
    return response;
  }
}
