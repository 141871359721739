import {
  mapMutations, mapState,
} from 'vuex';
import {
  MODAL,
} from '@/store';
import {
  IS_OPEN,
  ADD_TARGET,
  SET_IS_OPEN,
  SET_PAYLOAD,
} from '@/store/modules/modal';

export default {

  data: () => ({
    uuid: '',
  }),

  computed: {
    ...mapState(MODAL, [
      IS_OPEN,
    ]),
    show: {
      get() {
        return this[IS_OPEN][this.uuid];
      },
      set() {
        // do nothing,
      },
    },
  },

  methods: {
    /**
     *
     * @param {string} uuid
     * @param {{ status: boolean, payload: T }}
     */
    initModal(uuid, { status = false, payload = null }) {
      this.uuid = uuid;

      this[ADD_TARGET]({
        key: this.uuid,
        status,
        payload,
      });
    },

    ...mapMutations(MODAL, [
      SET_IS_OPEN,
      SET_PAYLOAD,
    ]),

    ...mapMutations(MODAL, [
      ADD_TARGET,
    ]),

    close() {
      this[SET_IS_OPEN]({
        target: this.uuid,
        status: false,
      });
    },
  },

};
