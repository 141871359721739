<template lang="pug">
b-modal(v-model="show" size="lg" centered hide-header hide-footer @change="initData")
  div(v-show="modalType === 1")
    h2.my-5.main-color 忘記密碼
    .form-group.mx-auto.text-left
      p.mb-2 信箱帳號
      input.form-control(v-model.trim="email" placeholder="輸入註冊時信箱帳號")
      .d-flex.mt-2
        .info-icon.mr-2 i
        .danger-color 密碼重設連結僅在15分鐘內有效，
          br
          |若尚未收到信件請前往查看垃圾信件匣
    .my-5
      button.w-25.btn.btn-outline-primary.mx-2(@click="initData") 取消
      button.w-25.btn.btn-primary.mx-2(@click="sendChangePasswordRequest") 完成
  div(v-show="modalType === 2")
    h2.my-5.main-color 重設密碼
    .form-group.mx-auto
      span 密碼重設信件已送至下方信箱，請點擊信件中的連結重設密碼
      .d-flex.mt-2
        .info-icon.mr-2 i
        .danger-color 密碼重設連結僅在15分鐘內有效，
      .w-100.p-2.show-email.mt-4 {{email}}
</template>

<script>
import { v4 } from 'uuid';

import modalMixin from '@/mixins/modal';
import { checkEmailFormat } from '@/utils/verify';
import wuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';
import { alertFailedMessage } from '@/utils/sweetAlert.js';

export const uuid = v4();

import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';

export default {
  mixins: [
    modalMixin,
    wuwowLoadingMixin,
  ],

  data: () => ({
    email: '',
    modalType: 1, // 1:忘記密碼 2:重設密碼(忘記密碼成功後)
  }),

  created() {
    this.initModal(uuid, {
      status: false,
    });
  },

  methods: {
    async sendChangePasswordRequest() {
      if (!this.email) {
        alertFailedMessage('信箱必填');
        return;
      }
      if (!checkEmailFormat(this.email)) {
        alertFailedMessage('信箱格式錯誤');
        return;
      }
      const tag = this.startLoading();
      try {
        const identityEnums = {
          children: 'student',
          parent: 'related_party',
        };

        const requestData = {
          identity: identityEnums[this.$store.state.character.character],
          platform: 'wuwow_junior',
          email: this.email,
        };

        const response = await axios({
          url: `${process.env.VUE_APP_BASE_API}/v3/lst-oauth/sign-up/forget-password`,
          method: 'post',
          data: requestData,
        });

        if (response.data.messages.content !== 'OK') {
          throw new Error(response);
        }

        this.modalType = 2;
        this.$swal({
          title: '<strong>密碼重設信件已寄出</strong><br><br><img src="https://cdn2.wuwow.tw/wuwow-junior/correct.png"><br>',
          html: `
          <br>密碼重設信件已送至信箱<br>${this.email}
          <br><br>請點擊信件中的連結重設密碼<br>密碼重設連結僅在15分鐘內有效`,
          showConfirmButton: true,
          allowOutsideClick: true,
          timer: 8000,
        });
      } catch (error) {
        console.log(error);
        alertFailedMessage(error);
      } finally {
        this.endLoading(tag);
      }
    },
    initData() {
      this.close();
      this.modalType = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/parent/modal.scss';

/deep/.btn {
  &:hover {
    color: #FFFFFF;
    // background-color: aqua;
    background-color: #0069d9;
  }
}

.show-email {
  background-color: #e1e6f5;
  border-radius: 30px;
}

.swal2-container {
  background-image: url(https://cdn2.wuwow.tw/wuwow-junior/vip-background-web.svg);
  background-size: cover;

  .swal2-modal {
    // background: rgba(255, 255, 255, 0.75) !important;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 14px rgba(208, 208, 208, 0.25);
    border-radius: 15px;
  }

  .swal2-success-circular-line-right {
    background: #ffffff00;
  }
}
</style>
