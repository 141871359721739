<template lang="pug">
.first-class-check
</template>

<script>
import lioshutanApi from '@/api';
import commonConstants from '@/constants/common';
import httpCodeConstants from '@/constants/httpCode';
import { alertFailedMessage, alertHtmlMessage } from '@/utils/sweetAlert';
import i18n from '@/components/class/checkStudentSetting/lang.js';
import storage from '@/utils/localStorage';

export default {
  name: 'FirstClassCheck',
  i18n,
  async created(){
    storage.token !== '' && storage.token !== 'null' ? await this.checkStudentSetting() : '';
  },
  watch: {
    $route: {
      async handler(route) {
        if (window.location.pathname !== '/' && window.location.pathname !== '/dojo/account/profile/edit') {
          this.checkStudentSetting();
        }
      },
    },
  },
  methods: {
    async checkStudentSetting(){
      if (storage.role === commonConstants.ROLE.STUDENT){
        const params = {
          platform: commonConstants.CURRENT_PLATFORM,
        };
        try {
          const result = await lioshutanApi.classroom.checkStudentSetting(params);
          const checkResult = result.data.data.result;
          if (result.status === httpCodeConstants.SUCCESS){
            return checkResult ? '' : this.directToProfilePage();
          }
        } catch (error) {
          alertFailedMessage(this.$t('checkApiFailed'));
        }
      }
    },

    async directToProfilePage(){
      await alertHtmlMessage(this.$t('notice'), 'info', this.$t('profileNotFilled'), 'OK');
      this.$router.push({ path: '/dojo/account/profile/edit' });
    },
  },
};
</script>
