// import lioshutanApi from '@/api';
// const types = {
//   VIP_RECOMMEND_LIST: 'VIP_RECOMMEND_LIST',
//   VIP_RECOMMEND_INFO: 'VIP_RECOMMEND_INFO',
//   VIP_TICKET_LIST: 'VIP_TICKET_LIST',
//   VIP_AWARD_LIST: 'VIP_AWARD_LIST',
//   VIP_SHARE_CONTENTS: 'VIP_SHARE_CONTENTS',
//   ANNIVERSARY_RECOMMEND_INFO: 'ANNIVERSARY_RECOMMEND_INFO',
//   ANNIVERSARY_TICKET_LIST: 'ANNIVERSARY_TICKET_LIST',
// };

const state = {
  vipRecommendList: {},
  vipRecommendInfo: {
    winning_rate: '0',
    get_ticket: '0',
    current_period_tickets: '0',
    count_referrer_month: '0',
    countdown_to_draw: '0',
    count_referrer: '0',
  },
  vipMgmUrl: 'https://www.wuwow.tw/event/regular_mgmevent/',
  vipMediaShareUrl: {
    lineLink: 'http://line.naver.jp/R/msg/text/?WUWOW 學員活動 - WU朋引伴，作伙來 %0D%0Ahttps%3A%2F%2Fwww.wuwow.tw%2Fevent%2Fregular_mgmevent',
    fbDataHref: 'https://www.wuwow.tw/event/regular_mgmevent',
    fbLink: 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.wuwow.tw%2Fevent%2Fregular_mgmevent',
  },
  vipTicketList: {},
  vipAwardList: {},
  vipShareContents: [],
  anniversaryRecommendInfo: {},
  anniversaryTicketList: {},
};

const getters = {

  getVipRecommendList(state) {
    return state.vipRecommendList;
  },

  getVipRecommendInfo(state) {
    return state.vipRecommendInfo;
  },

  getVipMgmUrl(state) {
    return state.vipMgmUrl;
  },

  getVipMediaShareUrl(state) {
    return state.vipMediaShareUrl;
  },

  getVipTicketList(state) {
    return state.vipTicketList;
  },

  getVipAwardList(state) {
    return state.vipAwardList;
  },

  getVipShareContents(state) {
    return state.vipShareContents;
  },
  getAnniversaryRecommendInfo(state) {
    return state.anniversaryRecommendInfo;
  },

  getAnniversaryTicketList(state) {
    return state.anniversaryTicketList;
  },
};

const actions = {
  getVipRecommendList({ commit }, params){

  },

  async getVipTicketList({ commit }, params){
    // lioshutanApi.vip.getVipTicketList(params).then((res) => {
    //   console.log(res);
    // });
  },

  getVipAwardList({ commit }, params){

  },

  sortAward(data){

  },

  async getVipShareContents({ commit }) {

  },
};

export default {
  state,
  getters,
  actions,
  // mutations
};
