<template lang="pug">
.scroll-top.position-fixed.m-2
  a(href="#top")
    .rounded-circle.bg-secondary.text-light.d-flex.justify-content-center.align-items-center.h2(
      style="height: 50px; width: 50px;"
    )
      font-awesome-icon(icon="arrow-up")
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';

export default {

};
</script>
<style lang="scss" scoped>
.scroll-top {
  right: 50px;
  bottom: 10px;
}
@media only screen and (max-width: 1600px) {
  .scroll-top {
    right: 15px;
    bottom: 10px;
  }
}
</style>

