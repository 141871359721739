var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "size": "lg",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "change": _vm.initDataAndClose
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.step === 1 ? [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center justify-content-center"
  }, [_c('button', {
    staticClass: "btn btn-link jr-home-main-color p-0 position-absolute",
    staticStyle: {
      "top": "-30px",
      "left": "0"
    },
    on: {
      "click": _vm.backToParentPage
    }
  }, [_vm._v("< 返回帳號選擇")]), _c('h3', {
    staticClass: "jr-home-main-color my-3"
  }, [_vm._v("新增孩子帳號")]), _c('p', [_vm._v("請輸入您的孩子 wuwow Jr 帳號")]), _c('div', {
    staticClass: "w-75"
  }, [_c('div', {
    staticClass: "form-group mx-auto"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.student.account,
      expression: "student.account"
    }],
    staticClass: "form-control mb-3",
    attrs: {
      "placeholder": "輸入孩子的手機號碼"
    },
    domProps: {
      "value": _vm.student.account
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.student, "account", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.student.password,
      expression: "student.password"
    }],
    staticClass: "form-control mb-3",
    attrs: {
      "type": "password",
      "placeholder": "密碼"
    },
    domProps: {
      "value": _vm.student.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.student, "password", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "btn btn-primary btn-block",
    on: {
      "click": _vm.sendFindBindStudentInfoRequest
    }
  }, [_vm._v("完成")])])])])])])])] : _vm._e(), _vm.step === 2 ? [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('h3', {
    staticClass: "my-4 jr-home-main-color"
  }, [_vm._v("確認資料")]), _c('div', {
    staticClass: "w-50 mobile-w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('button', {
    staticClass: "btn btn-link jr-home-main-color p-0 position-absolute",
    staticStyle: {
      "top": "-30px",
      "left": "0"
    },
    on: {
      "click": function ($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v("< 返回上一步")]), _c('div', {
    staticClass: "bg-light rounded my-3 mx-2 d-flex flex-column align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "ratio ratio-1x1 bg-dark d-flex flex-column justify-content-center align-items-center rounded",
    staticStyle: {
      "width": "130px"
    }
  }, [_c('img', {
    staticClass: "w-100 rounded",
    attrs: {
      "src": _vm.studentInfo.photo,
      "alt": _vm.studentInfo.chineseName
    }
  })]), _c('h5', {
    staticClass: "mb-0 mt-3"
  }, [_vm._v(_vm._s(_vm.studentInfo.chineseName))]), _c('h6', {
    staticClass: "mt-2 text-dark"
  }, [_vm._v("Level " + _vm._s(_vm.studentInfo.level))]), _c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "form-group w-100 mt-3 mb-5"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.relation,
      expression: "form.relation"
    }],
    staticClass: "form-control mb-3",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "relation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": "",
      "hidden": ""
    }
  }, [_vm._v("請選擇關係")]), _vm._l(_vm.relationCollection, function (data, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": data
      }
    }, [_vm._v(_vm._s(_vm.$t(`relation_of_.${data}`)))]);
  })], 2), _vm.form.relation === _vm.RELATION.OTHER ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.other,
      expression: "form.other"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "例：伯父、叔叔、阿姨、姑姑等等.."
    },
    domProps: {
      "value": _vm.form.other
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "other", $event.target.value);
      }
    }
  }) : _vm._e()]), _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.isRelationOrOtherEmpty
    },
    on: {
      "click": _vm.sendCreateRelationRequest
    }
  }, [_vm._v("完成")])])])])])])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }