const types = {
  SET_BOOK_CLASS_DATA_UPDATE: 'SET_BOOK_CLASS_DATA_UPDATE',
  SET_BOOK_CANLENDAR_DATA_UPDATE: 'SET_BOOK_CANLENDAR_DATA_UPDATE',
};

const state = {
  bookClassDataUpdate: false,
  bookcanlendarDataUpdate: false,
};

const mutations = {
  [types.SET_BOOK_CLASS_DATA_UPDATE]: (state, bookClassDataUpdate) => {
    state.bookClassDataUpdate = bookClassDataUpdate;
  },
  [types.SET_BOOK_CANLENDAR_DATA_UPDATE]: (state, bookcanlendarDataUpdate) => {
    state.bookcanlendarDataUpdate = bookcanlendarDataUpdate;
  },
};

const actions = {
  async setBookClassDataUpdate({ commit }, data) {
    await commit(types.SET_BOOK_CLASS_DATA_UPDATE, data);
  },
  async setBookcanlendarDataUpdate({ commit }, data) {
    await commit(types.SET_BOOK_CANLENDAR_DATA_UPDATE, data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
