import swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import i18n from '@/lang/index';

const TYPE = { // 訊息狀態
  SUCCESS: 'success', // 成功
  ERROR: 'error', // 錯誤
  INFO: 'info', // 訊息
  WARNING: 'warning', // 警告
};

export function alertMessage(title, type, text) {
  return swal.fire({
    title: title,
    icon: type,
    text: text,
    confirmButtonText: 'OK',
  });
}

export function alertSuccessMessage(text) {
  return swal.fire({
    title: i18n.tc('success'),
    icon: TYPE.SUCCESS,
    text: text,
    confirmButtonText: 'OK',
  });
}

export function alertFailedMessage(text) {
  return swal.fire({
    title: i18n.tc('failed'),
    icon: TYPE.ERROR,
    text: text,
    confirmButtonText: 'OK',
  });
}

export function alertInfoMessage(text) {
  return swal.fire({
    title: i18n.tc('notice'),
    icon: TYPE.INFO,
    text: text,
    confirmButtonText: 'OK',
  });
}

export function alertHtmlMessage(title, type, text, buttonText) {
  return swal.fire({
    title: title,
    icon: type,
    html: text,
    confirmButtonText: buttonText,
  });
}

export function alertWarningMessage(title, text) {
  return swal.fire({
    title: title,
    icon: TYPE.WARNING,
    text: text,
    confirmButtonText: 'OK',
  });
}
