<template lang="pug">
.home-container.time-line.container-fluid.py-5#timeline
  .d-flex.flex-column.align-items-center
    p.area-english-title.text-lioshutan.text-center :: Flexible Booking Cancellation ::
    h2.area-title.text-lioshutan 彈性訂、退課
    h2.area-title.text-lioshutan 保有最大學習機動性！

  //- 手機用
  .d-block.d-md-none.text-center.my-3
    img.w-100(:src="file.timelineMobile")

  //- 電腦用
  .d-none.d-md-block.text-center.w-100.mx-auto.my-5.py-4
    img.w-100(:src="file.timeline")
</template>

<script>
import timeline from '@/assets/timeline/booking-pc.png';
import timelineMobile from '@/assets/timeline/booking-mobile.png';

import 'bootstrap/dist/css/bootstrap.css';

export default {
  data: () => ({
    file: {
      timeline,
      timelineMobile,
    },
  }),
};
</script>

<style scoped>
.timeline-container:hover .timeline-content:not(:hover) {
  background-color: #e2e2e2 !important;
  transition: 0.6s;
}

.timeline-fixed-width {
  height: 190px;
}
.timeline-fixed-size {
  aspect-ratio: 1.5 / 1;
}
.w-0 {
  width: 0;
}
.border-dashed {
  border: 1px blue dashed;
}
</style>
<style lang="scss" scoped>
.time-line {
  .subtitle {
    font-size: 20px;
    letter-spacing: 4px;
  }
  @media screen and ( max-width: 414px ){
    .subtitle {
      font-size: 15px;
      letter-spacing: 3px;
    }
  }
}
</style>
