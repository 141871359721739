var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ul', {
    staticClass: "ul-personal-menu"
  }, _vm._l(_vm.accountLists, function (item) {
    return _c('li', {
      key: item.name,
      staticClass: "li-personal-menu"
    }, [_c('router-link', {
      staticClass: "lnk-personal-menu",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t('layout.' + item.name)))]), _c('a', {
      staticClass: "text-center lnk-personal-menu",
      attrs: {
        "href": "javascript:;"
      },
      on: {
        "click": _vm.logout
      }
    }, [_vm._v(_vm._s(_vm.$t('logout')))])], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }