<template lang="pug">
#top.bg-home
  navigation

  advertise

  service-introduction

  #linkToVideoLocation
  //- course-record-video

  main(class="main")
    div(class="main-box")
      div(class="class-title")
        | ::Class Recording ::
        br
        span(class="first-title")
          | 學員課程實錄
    div(class="main-content")
      .tabs
        ul
          li(@click="selectVideo(item)" :class="{active: item.name === currentVideo.name}" v-for="item in videos" :key="item.name")
            img(:src="item.img")
            div
              h3 {{ item.name }}
              time {{ item.time }}
      .videoWrap
        .videoContent
          video(v-if="videoShow" muted playsinline controls)
            source(:src="currentVideo.file" type="video/mp4")
          //- img(src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fHww")
  text-book

  arcs-modal.bg-img
  trp-modal

  consultants

  timeline

  recommend

  question-and-answer

  reserve-class

  lioshutan-footer

  youtube-modal(:src="youtubeUrl")

  scroll-to-top-button

  login-modal
  choice-character-modal
  parent-login-modal
  forgot-password-modal
  register-parent-account-modal
</template>

<script>
import { mapState } from 'vuex';

import {
  PAYLOAD,
} from '@/store/modules/modal';
import {
  MODAL,
} from '@/store';

// 用戶執行登出操作回到首頁後，要打開登入彈窗
import { uuid as CHOICE_CHARACTER_UUID } from './choiceCharacterModal/index.vue';

import lioshutanFooter from '@/components/footer/index.vue';
import scrollToTopButton from '@/components/scrollToTopButton/index.vue';
import timeline from './timeline/index.vue';
import questionAndAnswer from './q&a/index.vue';
import consultants from './consultants/index.vue';
import reserveClass from './reserveClass/index.vue';
import navigation from './navigation/index.vue';
import advertise from './advertise/index.vue';
import serviceIntroduction from './serviceIntroduction/index.vue';
import courseRecordVideo from './courseRecordVideo/index.vue';
import textBook from './textBook/index.vue';
import loginModal from './loginModal/index.vue';
import forgotPasswordModal from './parent/forgotPasswordModal/index.vue';
import registerParentAccountModal from './parent/registerModal/index.vue';

import trpModal from './tprModal/index.vue';
import arcsModal from './arcsModal/index.vue';
import choiceCharacterModal from './choiceCharacterModal/index.vue';
import parentLoginModal from './parent/loginModal/index.vue';

import recommend from '@/views/home/recommend/index.vue';

import youtubeModal, {
  uuid as youtubeModalUUID,
} from './youtubeModal/index.vue';
import 'bootstrap/dist/css/bootstrap.css';

import forgotPasswordModalMixin from '@/mixins/forgotPasswordModal';
import commonConstants from '@/constants/common';
import lioshutanApi from '@/api';
import storage from '@/utils/localStorage';
import moment from 'moment';
import { clearLocalStorage } from '@/composable/auth';

import Toby from '@/assets/videos/Toby.mp4';
// import Anna from '@/assets/videos/Anna.mp4';
import EllaChenFile from '@/assets/videos/EllaChen.mp4';
import Dora from '@/assets/videos/Dora.mp4';
import TobyImg from '@/assets/videos/TobyImg.jpg';
// import AnnaImg from '@/assets/videos/AnnaImg.jpg';
import EllaChenImg from '@/assets/videos/EllaChenImg.jpg';
import DoraImg from '@/assets/videos/DoraImg.jpg';

export default {

  components: {
    timeline,
    questionAndAnswer,
    consultants,
    reserveClass,
    navigation,
    advertise,
    serviceIntroduction,
    courseRecordVideo,
    textBook,
    recommend,
    lioshutanFooter,
    trpModal,
    arcsModal,
    scrollToTopButton,
    youtubeModal,
    loginModal,
    choiceCharacterModal,
    parentLoginModal,
    forgotPasswordModal,
    registerParentAccountModal,
  },
  mixins: [
    forgotPasswordModalMixin,
  ],

  data() {
    return {
      videoShow: true,
      currentVideo: {
        name: 'Ella Chen',
        file: EllaChenFile,
      },
      videos: [
        {
          file: EllaChenFile,
          name: 'Ella Chen',
          time: '2024/03/18',
          img: EllaChenImg,
        },
        {
          file: Dora,
          name: 'Dora',
          time: '2024/03/05',
          img: DoraImg,
        },
        {
          file: Toby,
          name: 'Toby',
          time: '2024/03/01',
          img: TobyImg,
        },
      ],
      modal: {
        CHOICE_CHARACTER_UUID,
      },
    };
  },

  computed: {
    ...mapState(MODAL, [
      PAYLOAD,
    ]),
    youtubeUrl() {
      return this[PAYLOAD][youtubeModalUUID];
    },
  },

  created() {
    // 如果是打開家長註冊 modal的網址，不要執行 clearLocalStorage及addVisitAmount()，否則打不開
    if (this.$route.path !== '/register-parent-account') {
      clearLocalStorage();
      this.addVisitAmount();
    }
  },
  mounted() {
    this.scrollToHashElement();
    this.isShowLoginDialog();
  },

  methods: {
    selectVideo(video) {
      this.currentVideo.name = video.name;
      this.currentVideo.file = video.file;
      this.videoShow = false;
      this.$nextTick(() => {
        this.videoShow = true;
      });
    },
    async waitImgLoaded() {
      const imges = [...document.querySelectorAll('#app img')];
      const vm = this;
      return await Promise.all(imges.map(image => new Promise((resolve) => {
        vm.$once('hook:beforeDestroy', () => {
          image.removeEventListener('load', resolve);
          image.removeEventListener('error', resolve);
        });
        image.addEventListener('load', resolve);
        image.addEventListener('error', resolve);
      })
      ));
    },
    async addVisitAmount(){
      // 若學員登入日期不同則累計+1
      const currentDate = moment().format('yyyy-MM-DD');
      if (storage.wuwowJuniorVisited !== currentDate){
        const params = {
          platform: commonConstants.CURRENT_PLATFORM,
        };
        await lioshutanApi.common.addVisitAmount(params);
        storage.wuwowJuniorVisited = currentDate;
      }
    },
    async scrollToHashElement() {
      // 取得 Url Tag
      const hash = window.location.hash;
      // 確定真的有 Tag 才處理
      if (hash) {
        const id = hash.replace('#', '');
        await this.$nextTick(); // 等待 DOM 更新
        const element = document.getElementById(id);
        if (element) {
          await this.waitImgLoaded();
          element.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    },
    isShowLoginDialog(){
      const params = new URLSearchParams(window.location.search);
      if (params.get('login')){
        this.setIsOpen({
          target: this.modal.CHOICE_CHARACTER_UUID,
          status: true,
        });

        // 清除用來判斷是否是登出操作的 'login' 參數
        params.delete('login');
        const newQueryString = params.toString();
        const newUrl = newQueryString ? `?${newQueryString}` + window.location.hash : window.location.pathname + window.location.hash;
        history.pushState(null, '', newUrl); // 更新URL不重新載入
      }
    },
  },
};
</script>

<style>
.bg-home {
  background-color: white;
}
.ratio {
  position: relative;
  width: 100%;
}

.ratio-1x1 {
  aspect-ratio: 1 / 1;
}

.text-lioshutan {
  color: #88c2e8;
}
.bg-lioshutan {
  background-color: #88c2e8;
}
.bg-img {
  background-image: -webkit-linear-gradient(top, #ecf5f8, white);
}
@media screen and (min-width: 1200px) {
  .home-container {
    max-width: 1556px;
    margin: 0 auto;
  }
  .area-english-title {
    /* 如 :: Comparison :: */
    letter-spacing: 4.78px;
    font-size: 24px;
  }
  .area-title {
    /* 如 WUWOW Jr 學習優勢 */
    letter-spacing: 2px;
    font-size: 38px;
  }
  .area-text {
    font-size: 22px;
  }
  .area-content-title {
    font-size: 32px;
  }
}
@media screen and  ( max-width: 1600px ) {
  .home-container {
    max-width: 1200px;
    margin: 0 auto;
  }
}
@media screen and  ( max-width: 1199px ) {
  .home-container {
    max-width: 980px;
    margin: 0 auto;
  }
  .area-english-title {
    letter-spacing: 4.78px;
    font-size: 20px;
  }
  .area-text {
    font-size: 20px;
  }
}
@media screen and ( max-width: 991px ){
  .home-container {
    max-width: 760px;
    margin: 0 auto;
  }
  .bg-img {
    background-image: none;
  }
  .area-english-title {
    font-size: 20px;
  }
  .area-title {
    font-size: 30px;
  }
  .area-text {
    font-size: 20px;
  }
  .area-content-title {
    font-size: 30px;
  }
}
@media screen and ( max-width: 767px ){
  .home-container {
    max-width: 575px;
    margin: 0 auto;
  }
}
@media screen and ( max-width: 414px ){
  .home-container {
    max-width: 370px;
    margin: 0 auto;
  }
  .bg-img {
    background-image: none;
  }
  .area-english-title {
    font-size: 16px;
  }
  .area-title {
    font-size: 24px;
  }
  .area-text {
    font-size: 16px;
  }
  .area-content-title {
    font-size: 24px;
  }
}

/* 這裡是為了配合banner的錨點連結，讓 header不會擋到影片標題 */
#linkToVideoLocation {
  padding-top: 200px;
}
</style>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 425px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin rwd-font($size: 12px, $size-md: 12px, $size-xl: 12px) {
  font-size: $size;

  @media (min-width: 768px) {
    font-size: $size-md;
  }

  @media (min-width: 1280px) {
    font-size: $size-xl;
  }
}

@mixin rwd-lh($lh: 1, $lh-md: 1, $lh-xl: 1) {
  line-height: $lh;

  @media (min-width: 768px) {
    line-height: $lh-md;
  }

  @media (min-width: 1280px) {
    line-height: $lh-xl;
  }
}

.class-title {
  text-align: center;
  color: #88c2e8;
  letter-spacing: 4.78px;
  margin-bottom: 40px;
  @include rwd-font(16px, 20px, 24px);
}
.first-title {
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 2px;
  margin-top: 16px;
  display: inline-block;
  @include rwd-font(28px, 30px, 38px);
}
.main-box {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
  @include md {
    padding: 0 40px;
  };
}
.main-content {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include lg {
    flex-direction: row;
    justify-content: center;
    gap: 0;
  }
}
.tabs {
  overflow: auto;
  &::-webkit-scrollbar  {
    display: none;
  }
  @include sm {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @include lg {
    margin-right: 40px;
  }
  @include xl {
    margin-right: 142px;
  }
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    @include lg {
      flex-direction: column;
    }
  }
  li {
    min-width: 148px;
    flex-shrink: 0;
    display: flex;
    padding: 10px 16px;
    border-bottom: 4px solid #DDDDDD;
    cursor: pointer;
    &:hover {
      border-bottom: 4px solid #82C1EA;
      background: linear-gradient(0deg, rgba(33, 186, 219, 0.2) 4.17%, rgba(33, 186, 219, 0) 100%);
      h3 {
        color: #82C1EA;
      }
      @include lg {
        border-bottom: none;
        border-left: 4px solid #82C1EA;
        background: linear-gradient(45deg, rgba(33, 186, 219, 0.2) 4.17%, rgba(33, 186, 219, 0) 100%);
      }
    }
    &.active {
      border-bottom: 4px solid #82C1EA;
      background: linear-gradient(0deg, rgba(33, 186, 219, 0.2) 4.17%, rgba(33, 186, 219, 0) 100%);
      h3 {
        color: #82C1EA;
      }
      @include lg {
        border-bottom: none;
        border-left: 4px solid #82C1EA;
        background: linear-gradient(45deg, rgba(33, 186, 219, 0.2) 4.17%, rgba(33, 186, 219, 0) 100%);
      }
    }
    @include lg {
      min-width: 242px;
      border-bottom: none;
      border-left: 4px solid #DDDDDD;
      padding: 20px 24px;
    }
    h3 {
      color: #676767;
      font-weight: 700;
      @include rwd-font(14px, 14px, 18px);
      @include rwd-lh(21px, 21px, 1.5);
      margin-bottom: 0;
      @include lg {
        font-weight: 600;
      }
    }
    time {
      color: #676767;
      font-weight: 600;
      @include rwd-font(12px, 12px, 16px);
      @include rwd-lh(18px, 18px, 21.79px);
      margin-bottom: 0;
      @include lg {
        font-weight: 400;
      }
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
      @include lg {
        width: 54px;
        height: 54px;
        margin-right: 20px;
      }
    }
  }
}
.videoWrap {
  padding: 0 16px;
  @include md {
    padding: 0 40px;
  }
  @include lg {
    padding: 0;
    width: 60%;
  }
}
.videoContent {
  border-radius: 20px;
  border: 10px solid #82C1EA;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  video {
    width: 100%;
  }
}
</style>
