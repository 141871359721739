const types = {
  SET_PROFILE_DATAS: 'SET_PROFILE_DATAS',
  SET_EVALUATION_SCORE_DATAS: 'SET_EVALUATION_SCORE_DATAS',
  SET_PAYMENT_DATAS: 'SET_PAYMENT_DATAS',
  SET_QUALIFICATIONS_DATAS: 'SET_QUALIFICATIONS_DATAS',
  SET_DEVICES_DATAS: 'SET_DEVICES_DATAS',
  SET_LOCATIONS_SPEED_DATAS: 'SET_LOCATIONS_SPEED_DATAS',
  SET_CURRENT_PAYMENT_DATAS: 'SET_CURRENT_PAYMENT_DATAS',
  SET_VIP_REQUESTS_SEARCH_PARAMS: 'SET_VIP_REQUESTS_SEARCH_PARAMS',
  SET_VIP_REQUESTS_SEARCH: 'SET_VIP_REQUESTS_SEARCH',
  SET_VIPNO_REPLY_REQUESTS_AMOUNT: 'SET_VIPNO_REPLY_REQUESTS_AMOUNT',
};

const state = {
  baseProfileDatas: {},
  evaluationScoreDatas: {},
  paymentDatas: [],
  qualificationsDatas: [],
  devicesDatas: [],
  locationsSpeedDatas: [],
  currentPaymentDatas: {},
  vipRequestsSearchParams: {},
  vipRequestsSearch: false,
  vipNoReplyRequestsAmount: 0,
};

const mutations = {
  [types.SET_PROFILE_DATAS]: (state, data) => {
    state.baseProfileDatas = data;
  },

  [types.SET_EVALUATION_SCORE_DATAS]: (state, data) => {
    state.evaluationScoreDatas = data;
  },

  [types.SET_PAYMENT_DATAS]: (state, data) => {
    state.paymentDatas = data;
  },

  [types.SET_QUALIFICATIONS_DATAS]: (state, data) => {
    state.qualificationsDatas = data;
  },

  [types.SET_DEVICES_DATAS]: (state, data) => {
    state.devicesDatas = data;
  },

  [types.SET_LOCATIONS_SPEED_DATAS]: (state, data) => {
    state.locationsSpeedDatas = data;
  },

  [types.SET_CURRENT_PAYMENT_DATAS]: (state, data) => {
    state.currentPaymentDatas = data;
  },

  [types.SET_VIP_REQUESTS_SEARCH_PARAMS]: (state, data) => {
    state.vipRequestsSearchParams = data;
  },

  [types.SET_VIP_REQUESTS_SEARCH]: (state, data) => {
    state.vipRequestsSearch = data;
  },

  [types.SET_VIPNO_REPLY_REQUESTS_AMOUNT]: (state, data) => {
    state.vipNoReplyRequestsAmount = data;
  },
};

const actions = {
  async setBaseProfileDatas({ commit }, data) {
    await commit(types.SET_PROFILE_DATAS, data);
  },

  async setEvaluationScoreDatas({ commit }, data) {
    await commit(types.SET_EVALUATION_SCORE_DATAS, data);
  },

  async setPaymentDatas({ commit }, data) {
    await commit(types.SET_PAYMENT_DATAS, data);
  },

  async setCurrentPaymentDatas({ commit }, data) {
    await commit(types.SET_CURRENT_PAYMENT_DATAS, data);
  },

  async setQualificationsDatas({ commit }, data) {
    await commit(types.SET_QUALIFICATIONS_DATAS, data);
  },

  async setDevicesDatas({ commit }, data) {
    await commit(types.SET_DEVICES_DATAS, data);
  },

  async setLocationsSpeedDatas({ commit }, data) {
    await commit(types.SET_LOCATIONS_SPEED_DATAS, data);
  },

  async setVipRequestsSearchParams({ commit }, data) {
    await commit(types.SET_VIP_REQUESTS_SEARCH_PARAMS, data);
  },

  async setVipRequestsSearch({ commit }, data) {
    await commit(types.SET_VIP_REQUESTS_SEARCH, data);
  },

  async setVipNoReplyRequestsAmount({ commit }, data) {
    await commit(types.SET_VIPNO_REPLY_REQUESTS_AMOUNT, data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
