<template lang="pug">
#arcs.py-5
  //- 手機用
  .d-block.d-sm-none
    .home-container
      .d-flex.flex-column.align-items-center.mx-3
        h3.text-lioshutan.mb-2 ARCS 動機學習模型
        p.text-lioshutan.mb-4 by John Keller
        p WUWOW Jr 的設計理念，主旨在於提升孩子的學習動機，因此我們採用了美國約翰凱勒 (John Keller) 教授系統化的 ARCS 動機模型，ARCS 指的是 Attention (注意) Relevance (相關) Confidence (信心)、Satisfaction (滿足) 四個要素，模型強調想要引起學習者的動機，必須配合此四要素的運用，才能達到激勵學生學習的作用。
      .d-flex.flex-column.align-items-center.my-5
        h3.text-lioshutan ARCS 如何應用於課程設計中？
      .d-flex.justify-content-center
        img.w-75(:src="file.arcsMobile")
  //- 電腦用
  .d-none.d-sm-block
    .home-container.mb-5.p-5
      .d-flex.flex-column.align-items-center
        .w-100.w-xl-50.d-flex.flex-column.align-items-center
          h2.area-title.text-lioshutan.mb-2 ARCS 動機學習模型
          h5.area-text.text-lioshutan.mb-4 by John Keller
          p.area-text.text-center WUWOW Jr 的設計理念，主旨在於提升孩子的學習動機，因此我們採用了美國約翰凱勒 (John Keller) 教授系統化的 ARCS 動機模型，ARCS 指的是 Attention (注意) Relevance (相關) Confidence (信心)、Satisfaction (滿足) 四個要素，模型強調想要引起學習者的動機，必須配合此四要素的運用，才能達到激勵學生學習的作用。
      .d-flex.flex-column.align-items-center.my-3
        h2.area-title.text-lioshutan.my-xl-5.my-4 ARCS 如何應用於課程設計中？
      .d-flex.flex-column.align-items-center
        img.w-100(:src="file.arcs")
</template>

<script>
import { v4 } from 'uuid';
import arcs from '@/assets/ARCS.png';
import arcsMobile from '@/assets/ARCS_ph.png';
import modalMixin from '@/mixins/modal';

import 'bootstrap/dist/css/bootstrap.css';

export const uuid = v4();

export default {
  mixins: [
    modalMixin,
  ],

  data: () => ({
    file: {
      arcs,
      arcsMobile,
    },
  }),

  created() {
    this.initModal(uuid, {
      status: false,
      payload: null,
    });
  },

};
</script>
