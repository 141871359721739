var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row nav-bar"
  }, [_c('button', {
    staticClass: "bar-btn jr-main-bg col-2 navbar-toggler mobile-sidebar-toggler d-lg-none mr-auto h-100",
    on: {
      "click": function ($event) {
        return _vm.toggleSideBar();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "bars"
    }
  })], 1), _c('div', {
    staticClass: "col-lg-2 col-8 d-flex px-0 h-100"
  }, [_c('a', {
    staticClass: "logo",
    attrs: {
      "href": `${_vm.newJrUrl}/student`
    }
  }, [_c('img', {
    staticClass: "d-none d-lg-flex logo-img w-100 h-100 img-logo",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/white_logo_header.png",
      "alt": "Wuwow"
    }
  }), _c('img', {
    staticClass: "d-flex d-lg-none logo-img h-100 img-logo",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg",
      "alt": "Wuwow"
    }
  })])]), _c('header-nav', {
    staticClass: "col-lg-8 d-none d-lg-flex header-nav",
    attrs: {
      "role": _vm.role
    }
  }), _c('div', {
    staticClass: "col-lg-2 col-2 nav-account"
  }, [_vm.role === _vm.student ? _c('vip-account') : _vm._e(), _vm.role === _vm.parent ? _c('parent-account') : _vm._e(), _vm.role === _vm.teacher || _vm.role === _vm.center ? _c('consultant-account') : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }