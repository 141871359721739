var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trp-modal py-5"
  }, [_c('div', {
    staticClass: "d-none d-md-block mt-xl-5"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('h2', {
    staticClass: "area-title text-lioshutan"
  }, [_vm._v("TPR 肢體回應教學法")]), _c('h5', {
    staticClass: "area-text mb-3 text-lioshutan"
  }, [_vm._v("TOTAL PHYSICAL RESPONSE")]), _c('div', {
    staticClass: "d-flex flex-column align-items-center mx-3"
  }, [_c('p', {
    staticClass: "w-75 w-xl-50 area-text text-center my-5"
  }, [_vm._v("TPR 是由加州聖荷西州立大學心理學教授 James Asher 所創， 以行為學派為基礎的學習理論，把語言的學習視為一種刺激與反應的聯結。 主張兒童主要是經由肢體動作而非抽象的語言型式習得母語。")]), _c('img', {
    staticClass: "w-75 w-xl-50",
    attrs: {
      "src": _vm.file.tpr
    }
  }), _c('p', {
    staticClass: "w-75 w-xl-50 area-text text-center my-5"
  }, [_vm._v("在實際語言教學上，老師會特別注重肢體的表達，就像是幼幼台的大哥哥大姊姊們一樣，利用誇張的手勢、表情以及嘴形，再加上教材中大量的圖片影像，抓住孩子的注意力，讓孩子更容易保持學習的專注。")])])])]), _c('div', {
    staticClass: "d-block d-md-none"
  }, [_c('div', {
    staticClass: "w-100 px-3"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('h3', {
    staticClass: "text-lioshutan"
  }, [_vm._v("TPR 肢體回應教學法")]), _c('h5', {
    staticClass: "mb-3 text-lioshutan"
  }, [_vm._v("TOTAL PHYSICAL RESPONSE")]), _c('div', {
    staticClass: "d-flex flex-column align-items-center mx-3"
  }, [_c('p', {
    staticClass: "mt-2 mb-4 area-text"
  }, [_vm._v("TPR 是由加州聖荷西州立大學心理學教授 James Asher 所創， 以行為學派為基礎的學習理論，把語言的學習視為一種刺激與反應的聯結。 主張兒童主要是經由肢體動作而非抽象的語言型式習得母語。")]), _c('img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.file.tpr
    }
  }), _c('p', {
    staticClass: "my-4 area-text"
  }, [_vm._v("在實際語言教學上，老師會特別注重肢體的表達，就像是幼幼台的大哥哥大姊姊們一樣，利用誇張的手勢、表情以及嘴形，再加上教材中大量的圖片影像，抓住孩子的注意力，讓孩子更容易保持學習的專注。")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }