<template lang="pug">
li.nav-item.dropdown
  a.nav-link.dropdown-toggle(@click="switchMenu()")
    img.img-avatar(:src="consultantProfile.picture" alt="User Image")
    span.d-md-down-none {{ consultantProfile.english_name }}
  .dropdown-menu.dropdown-menu-right(:class="{'d-block': showMenu}")
    .dropdown-list(@click="logout")
      span Logout
</template>

<script type="text/javascript">
import lioshutanApi from '@/api';
import { teacherLogout } from '@/composable/auth';

export default {
  name: 'ConsultantAccount',

  components: {},

  data() {
    return {
      showMenu: false,
      consultantProfile: {},
    };
  },

  created(){
    this.getConsultantProfileRes();
    document.addEventListener('click', this.close);
  },

  beforeDestroy(){
    document.removeEventListener('click', this.close);
  },

  methods: {
    async getConsultantProfileRes(){
      const consultantProfileRes = await lioshutanApi.consultant.getConsultantProfile();
      this.consultantProfile = consultantProfileRes.data.data.user;
    },

    switchMenu() {
      this.showMenu = !this.showMenu;
    },

    async logout() {
      teacherLogout();
    },

    close(element) {
      if (!this.$el.contains(element.target)) {
        this.showMenu = false;
      }
    },
  },
};
</script>
<style scoped>
.dropdown-list{
  margin: 10px;
  cursor: pointer;
  transition: 0.4s all ease;
  text-align: center;
}
.dropdown-list:hover{
  text-decoration: none;
  color: var( --main-color );
}
</style>
