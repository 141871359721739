<template lang="pug">
  div(style="display: inline-flex;")
    li.nav-item.dropdown.px-3
      a.nav-link.dropdown-toggle(
        ref='javascript:;'
        @click="toggleDropdown('center')"
      ) {{ $t('center') }}
      .dropdown-menu(:class="[state === 'center' ?' d-block' :'d-none']")
        router-link.dropdown-item(v-for="item in centerLists" :key="item.name" :to="{ path: item.path }") {{ $t(item.name) }}

</template>

<script>
import i18n from '@/components/nav/lang';
import dropdowControlMixin from '@/components/nav/headerNav/mixin/dropdowControlMixin.vue';
import navList from '@/components/nav/navList';

export default {
  name: 'CenterHeaderNav',

  components: {

  },

  mixins: [dropdowControlMixin],

  i18n,

  data() {
    return {
      centerLists: navList.center.centerLists,
    };
  },

  mounted(){
  },

  methods: {
  },
};
</script>
