<template lang="pug">
#app
  home(v-if="currentPage === hideLayoutPage.home")
  parent(v-else-if="currentPage === hideLayoutPage.parentPage")
  thank-you(v-else-if="currentPage === hideLayoutPage.thankYouPage")
  login(v-else-if="currentPage === hideLayoutPage.login")
  router-view(v-else-if="currentPage === hideLayoutPage.registerParentAccount")
  //- 新聞頁、活動頁排版
  router-view(v-else-if="currentPage.split('/').includes('news')")
  router-view(v-else-if="currentPage.split('/').includes('event')")
  router-view(v-else-if="currentPage.split('/').includes('program-contract')")
  router-view(v-else-if="currentPage.split('/').includes('reviews')")
  layout(v-else)
</template>

<script>
import layout from '@/views/layout/index.vue';
import home from '@/views/home/index.vue';
import parent from '@/views/parentPage/index.vue';
import thankYou from '@/views/home/thankYou/index.vue';
import login from '@/views/login/index.vue';

export default {
  name: 'App',
  components: {
    layout,
    home,
    parent,
    thankYou,
    login,
  },

  data() {
    return {
      hideLayoutPage: {
        home: '/',
        parentPage: '/parent',
        thankYouPage: '/thank-you',
        login: '/login',
        reviews: '/reviews',
        // 在首頁打開家長註冊 modal
        registerParentAccount: '/register-parent-account',
      },
      currentPage: '',
    };
  },

  watch: {
    $route: {
      async handler(to, from) {
        this.resetConsultantListLocalStorage(to, from);
        this.currentPage = to.path;
      },
    },
  },
  methods: {
    resetConsultantListLocalStorage(to, from) {
      const toName = to.name;
      const fromDel = ['requireConsultantSchedule', 'ConsultantDetail', 'consultants'].includes(toName);
      const consultant = window.localStorage.getItem('consultant') || '';
      if (!consultant || fromDel) {
        return;
      }
      window.localStorage.removeItem('consultant');
    },
  },
};
</script>

<style lang="scss">
@import "./../public/css/style.css";
@import "@/styles/transition.scss";

@font-face {
  font-family: 'jrFont';
  src: url('./../public/font/NotoSansTC-Regular.otf') format("opentype");
}

// 畫面到達滑順
html {
  scroll-behavior: smooth;
}

// 隱藏右側滾輪
body::-webkit-scrollbar {
  display: none;
}

.app {
  font-family: 'jrFont';

  // 會用到的Class先寫這
  .jr-light-color {
    color: var(--light-color);
  }

  .jr-main-color {
    color: var(--main-color);
  }

  .jr-text-dark-color {
    color: var(--text-dark-color);
  }

  .jr-text-light-color {
    color: var(--text-light-color);
  }

  .jr-main-bg {
    background-color: var(--main-color);
    color: var(--light-color);
  }

  .jr-light-bg {
    background-color: var(--light-color);
  }

  .jr-home-main-color {
    color: var(--home-main-color) !important;
  }
}

.jr-rounded-top {
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
}

.jr-rounded-bottom {
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
}

/* 背景改成漸層 */
.layout-lioshutan {
  background-image: url(https://cdn2.wuwow.tw/wuwow-junior/vip-background-web.svg);
  background-size: cover;

  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* 引用的方法 color: var( --main-color );*/
:root {
  --main-color: #82c1ea;
  /* var( --main-color ) */
  --light-color: #FFFFFF;
  /* 有背景時的字 var( --light-color ) */
  --main-dark-color: #007DB1;
  /* 沒有背景時的字 var( --main-dark-color ) */
  --card-border-radius: 15px;
  /* border-radius: var( --card-border-radius ); */
  --text-dark-color: #596368;
  /* var( --text-dark-color ) */
  --text-light-color: #7B8387;
  /* var( --text-light-color ) */
  --home-main-color: #006BDA;
  /* var( --home-main-color ) */
}

// 調整 sweet alert 容器 padding，彈出視窗在手機板出現破版
.swal2-container.swal2-center {
  @media (max-width:767px) {
    padding: 35px;
  }
}
</style>
