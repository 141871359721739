<template lang="pug">
main(class="main")
  div(class="main-box" id="一對一教學課程實錄")
    div(class="class-title")
      span(class="first-title")
        | ::Class Recording ::
        br
        | 一對一教學課程實錄
    div(class="main-content")
      div(class="main-center")
        div(class="main-center-content")
          img(@click="youtubeModal(courseRecord)" src="@/assets/trial/courseRecordVideo.png" class="main-center-img")
  youtube-modal(:src="youtubeUrl")
</template>

<script>
import { MODAL } from '@/store';
import youtubeModal, { uuid as youtubeModalUUID } from '@/views/home/youtubeModal/index.vue';
import { PAYLOAD } from '@/store/modules/modal';
import { IS_OPEN, SET_IS_OPEN, SET_PAYLOAD } from '@/store/modules/modal';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'CourseRecordVideo',
  components: { youtubeModal },
  data() {
    return {
      courseRecord: 'https://www.youtube.com/embed/xMkXhu6Oo7o',
      // for youtube modal
      youtubeModalUUID,
    };
  },
  computed: {
    ...mapState(MODAL, [IS_OPEN]),
    ...mapState(MODAL, [
      PAYLOAD,
    ]),
    youtubeModalIsOpen() {
      return this[IS_OPEN][youtubeModalUUID];
    },
    youtubeUrl() {
      return this[PAYLOAD][youtubeModalUUID];
    },
  },
  methods: {
    youtubeModal(url) {
      this.setIsOpen({
        target: youtubeModalUUID,
        status: !this.youtubeModalIsOpen,
      });

      this.setPayload({
        target: youtubeModalUUID,
        payload: url,
      });
    },
    ...mapMutations(MODAL, [SET_IS_OPEN, SET_PAYLOAD]),
  },
};

</script>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin rwd-font($size: 12px, $size-md: 12px, $size-xl: 12px) {
  font-size: $size;

  @media (min-width: 768px) {
    font-size: $size-md;
  }

  @media (min-width: 1280px) {
    font-size: $size-xl;
  }
}

@mixin rwd-lh($lh: 1, $lh-md: 1, $lh-xl: 1) {
  line-height: $lh;

  @media (min-width: 768px) {
    line-height: $lh-md;
  }

  @media (min-width: 1280px) {
    line-height: $lh-xl;
  }
}

.main {
  width: 100wh;
  padding: 100px 0px 0px 0px;
  gap: 100px;
}

.main-box {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.class-title {
  display: block;
  color: #82C1EA;
  // font-family: Noto Sans TC;
  font-size: 24px;
  font-weight: 350;
  line-height: 36px;
  letter-spacing: 4.78000020980835px;
  text-align: center;
  margin-bottom: 50px
}

.main-content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.main-center {
  max-width: 860px;
  width: 90%;
  display: flex;
  justify-content: center;
}

.main-center:hover {
  cursor: pointer;
}

.main-center-img {
  width: 100%;
}

.close-icon {
  margin-right: 2rem;
  margin-left: 2rem;
}
.cursor-pointer {
  cursor: pointer;
}
//- RWD
@media(max-width: 1260px) {
  .main-box {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .main-content {
    flex-direction: column;
    justify-content: center;
  }
}
</style>
