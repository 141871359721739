export default {
  messages: {
    tw: {
      parentsZone: '家長專區',
      parentsInfo: '家長資料',
      chineseName: '中文姓名',
      englishName: '英文姓名',
      gender: '性別',
      phoneNumber: '手機號碼',
      email: '電子信箱',
      removeParentInfo: '刪除家長資料',
      removeSuccess: '刪除成功',
      removeFailed: '刪除失敗',
      addSuccess: '新增成功',
      editSuccess: '編輯成功',
      handleFailed: '處理失敗',
    },
    en: {
      parentsZone: 'Parents Zone',
      parentsInfo: 'Parents Info',
      chineseName: 'Chinese Name',
      englishName: 'English Name',
      gender: 'Gender',
      phoneNumber: 'Phone Number',
      email: 'E-mail',
      removeParentInfo: 'Remove Parent Info',
      removeSuccess: 'Remove Successfully',
      removeFailed: 'Remove Failed',
      addSuccess: 'Add Successfully',
      editSuccess: 'Edit Successfully',
      handleFailed: 'Handle Failed',
    },
  },
};
