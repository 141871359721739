import storage from '@/utils/localStorage';
import commonConstants from '@/constants/common';
import i18n from '@/lang/index';
/**
 * @des 依照不同腳色使用不同語系
 * @param {String} role: 角色
 */
export function setUseLangByRole(role){
  switch (role) {
    case commonConstants.ROLE.STUDENT:
    case commonConstants.ROLE.PARENT:
      storage.language = commonConstants.LANGUAGE.TW;
      i18n.locale = commonConstants.LANGUAGE.TW;
      break;
    case commonConstants.ROLE.TEACHER:
    case commonConstants.ROLE.CENTER:
      storage.language = commonConstants.LANGUAGE.EN;
      i18n.locale = commonConstants.LANGUAGE.EN;
      break;
    default:
      storage.language = commonConstants.LANGUAGE.TW;
      i18n.locale = commonConstants.LANGUAGE.TW;
      break;
  }
}
