<template lang="pug">
div
  p.txt-personal-menu-title(v-if="role !== 'parent'") {{ $t('my') }}{{ $t('account') }}
  ul.ul-personal-menu

    //- 將家長與學生連結清單分開，家長的要過濾
    li.li-personal-menu(v-if="role === 'parent'",v-for="item in filterAccountList" :key="item.name")
      router-link.lnk-personal-menu(:to="{ path: item.path }")
        span() {{ $t('layout.parent_' + item.name) }}

    //- 學生狀態，增加家長註冊選項
    li.li-personal-menu(v-if="role === 'student'",v-for="item in accountLists" :key="item.name")
      //- 新視窗打開首頁，並且打開家長註冊 modal
      router-link.lnk-personal-menu(:to="{ path: item.path }" :target="item.target === 'blank'? '_blank': null")
        span() {{ $t('layout.' + item.name) }}
</template>
<script>
import navList from '@/components/nav/navList';
export default {
  name: 'MyAccountLink',

  props: {
    role: {
      type: String,
    },
  },

  data() {
    return {
      accountLists: navList.vip.accountLists,
    };
  },

  computed: {
    filterAccountList: function() {
      // 過濾為家長身分用連結清單
      return this.accountLists.filter((item) => {
        return item.name !== 'register_parent_account';
      });
    },
  },
};
</script>
