<template lang="pug">
#consultants.my-5.consultants
  div.d-flex.justify-content-center
    p.area-english-title.text-lioshutan :: Consultants ::
  .pb-5.bg-img
    //- 手機用
    .d-block.d-md-none
      .container
        .d-flex.flex-column.align-items-center.py-3
          //- h5.text-lioshutan :: Consultants ::
          h2.text-lioshutan 英文顧問團隊
          h4.text-lioshutan Meet our teachers!
          .w-75
            carousel(:perPage="1")
              slide(v-for="item in exampleData")
                .p-2
                  .slide-card.d-flex.flex-column.align-items-center.py-4.text-center
                    .w-75
                      .ratio.ratio-1x1
                        img.w-100.h-100.rounded(:src="item.image")
                    h4.text-lioshutan.my-3 {{ item.name }}
                    p.m-0 {{ item.nationality }}
                      img.ml-1.nationality-img(:src="item.nationalityImg")
                    .w-100.px-3
                      hr
                    button.btn.btn-link.text-decoration-none.text-dark.my-1(
                      @click="test(item.introduction)"
                    )
                      | 導師介紹
                      font-awesome-icon(icon="video")
    //- 平板用
    .d-none.d-md-block.d-lg-none
      .home-container
        .d-flex.flex-column.align-items-center.py-3
          //- h5.text-lioshutan :: Consultants ::
          h2.area-title.text-lioshutan 英文顧問團隊
          h4.area-text.text-lioshutan Meet our teachers!
          .consultants-carousel.mt-4
            carousel(:perPage="3")
              slide(v-for="item in exampleData")
                .p-2
                  .w-100.slide-card.d-flex.flex-column.align-items-center.py-4.text-center
                    .w-75
                      .ratio.ratio-1x1
                        img.w-100.h-100(:src="item.image")
                    p.slide-card-title.text-lioshutan.my-3 {{ item.name }}
                    p.area-text.m-0 {{ item.nationality }}
                      img.ml-1.nationality-img(:src="item.nationalityImg")
                    .w-100.px-3
                      hr
                    button.slide-card-btn.btn.text-decoration-none.my-1(
                      @click="test(item.introduction)"
                    )
                      | 導師介紹
                      font-awesome-icon.ml-1(icon="video")
    //- 電腦用
    .d-none.d-md-none.d-lg-block
      .home-container
        .d-flex.flex-column.align-items-center.py-3
          //- h5.text-lioshutan :: Consultants ::
          h2.area-title.text-lioshutan 英文顧問團隊
          h4.area-text.text-lioshutan Meet our teachers!
          .consultants-carousel.mt-4
            carousel(:perPage="4")
              slide(v-for="item in exampleData")
                .p-2
                  .w-100.slide-card.d-flex.flex-column.align-items-center.py-4.text-center
                    .w-75
                      .ratio.ratio-1x1
                        img.w-100.h-100(:src="item.image")
                    p.slide-card-title.text-lioshutan.my-3 {{ item.name }}
                    p.area-text.m-0 {{ item.nationality }}
                      img.ml-1.nationality-img(:src="item.nationalityImg")
                    .w-100.px-3
                      hr
                    button.slide-card-btn.btn.text-decoration-none.my-1(
                      @click="test(item.introduction)"
                    )
                      | 導師介紹
                      font-awesome-icon.ml-1(icon="video")
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

import worldBG from '@/assets/teachers_bg.png';

import teacher1 from '@/assets/teachers/teacher_1.png';
import teacher2 from '@/assets/teachers/teacher_2.png';
import teacher3 from '@/assets/teachers/teacher_3.png';
import teacher4 from '@/assets/teachers/teacher_4.png';
import teacher6 from '@/assets/teachers/teacher_6.png';
import teacher7 from '@/assets/teachers/teacher_7.png';
import teacher8 from '@/assets/teachers/teacher_8.png';
import teacher9 from '@/assets/teachers/teacher_9.png';
import teacher10 from '@/assets/teachers/teacher_10.png';
import teacher11 from '@/assets/teachers/teacher_11.png';

import american from '@/assets/national_flag/american.svg';
// import brazilian from '@/assets/national_flag/brazilian.svg';
// import british from '@/assets/national_flag/british.svg';
import filipino from '@/assets/national_flag/filipino.svg';
import jamaicans from '@/assets/national_flag/jamaicans.svg';
import southAfrican from '@/assets/national_flag/south_african.svg';
import taiwanese from '@/assets/national_flag/taiwanese.svg';
import { MODAL } from '@/store';
import {
  IS_OPEN,
  SET_IS_OPEN,
  SET_PAYLOAD,
} from '@/store/modules/modal';
import { mapMutations, mapState } from 'vuex';

import { uuid as youtubeModalUUID } from '../youtubeModal/index.vue';

import 'bootstrap/dist/css/bootstrap.css';

export default {
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    exampleData: [
      {
        name: 'Niki Lacson',
        image: teacher10,
        nationality: 'American',
        nationalityImg: american,
        introduction: 'https://www.youtube.com/embed/QMcCjeVvjyA?autoplay=1',
      },
      {
        name: 'Ebie Botha',
        image: teacher4,
        nationality: 'South African',
        nationalityImg: southAfrican,
        introduction: 'https://www.youtube.com/embed/XiMuS12z92g?autoplay=1',
      },
      {
        name: 'Lance Tseng',
        image: teacher8,
        nationality: 'Taiwanese',
        nationalityImg: taiwanese,
        introduction: 'https://www.youtube.com/embed/YGtAt-y1JNs?autoplay=1',
      },
      {
        name: 'Heather Helm',
        image: teacher7,
        nationality: 'South African',
        nationalityImg: southAfrican,
        introduction: 'https://www.youtube.com/embed/MEeqvPm_eIE?autoplay=1',
      },
      {
        name: 'Gregg Tan',
        image: teacher6,
        nationality: 'Filipino',
        nationalityImg: filipino,
        introduction: 'https://www.youtube.com/embed/1kEhI-V0bK4?autoplay=1',
      },
      {
        name: 'Chantoy Livermore',
        image: teacher2,
        nationality: 'Jamaican',
        nationalityImg: jamaicans,
        introduction: 'https://www.youtube.com/embed/gJlraGD_WIw?autoplay=1',
      },
      {
        name: 'Danielle Peters',
        image: teacher3,
        nationality: 'South African',
        nationalityImg: southAfrican,
        introduction: 'https://www.youtube.com/embed/Q6ciwNSr7q0?autoplay=1',
      },
      {
        name: 'Mapitso Hlanyare',
        image: teacher9,
        nationality: 'South African',
        nationalityImg: southAfrican,
        introduction: 'https://www.youtube.com/embed/ihZFTGPOBjM?autoplay=1',
      },
      {
        name: 'Rose Tiansay',
        image: teacher11,
        nationality: 'Filipino',
        nationalityImg: filipino,
        introduction: 'https://www.youtube.com/embed/8EFuwe41r6U?autoplay=1',
      },
    ],
    youtubeModalUUID,
    file: {
      worldBG,
      teacher1,
      teacher2,
      teacher3,
      teacher4,
      teacher6,
      teacher7,
      teacher8,
      teacher9,
      teacher10,
      teacher11,
    },
  }),
  computed: {
    ...mapState(MODAL, [
      IS_OPEN,
    ]),
    youtubeModalIsOpen() {
      return this[IS_OPEN][youtubeModalUUID];
    },
  },
  methods: {
    ...mapMutations(MODAL, [
      SET_IS_OPEN,
      SET_PAYLOAD,
    ]),
    test(url) {
      this.setIsOpen({
        target: youtubeModalUUID,
        status: !this.youtubeModalIsOpen,
      });

      this.setPayload({
        target: youtubeModalUUID,
        payload: url,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: 20px;
  letter-spacing: 4px;
}
.consultants {
  .slide-card {
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    // height: 400px;
    border-radius: 7px;
    .slide-card-title {
      font-size: 23px;
    }
    .slide-card-btn {
      font-size: 22px;
      color: #707070;
    }
    .nationality-img {
      width: 20px;
    }
  }
  .bg-img {
    background-image: url("~@/assets/teachers_bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .consultants-carousel {
    width: 90%;
  }
}
@media screen and ( max-width: 1200px ){
  .consultants {
    .slide-card {
      .slide-card-title {
        font-size: 20px;
      }
      .slide-card-btn {
        font-size: 20px;
        color: #707070;
      }
    }
  }
}
@media screen and ( max-width: 414px ){
  .subtitle {
    font-size: 15px;
    letter-spacing: 3px;
  }
}
</style>
