<template lang="pug">
div.ul-personal-menu
  p.txt-personal-menu-title(style='cursor: pointer' @mouseover='switchGiveBackInfo()')
    | {{ $t('contract.feedback_record') }}
  ul.px-4.mb-1.mt-3(v-if='show_give_back_info === true')
    p.give-back-info
      | {{ $t('contract.extra_lessons') }}:
      span.float-right.mr-2 {{ vip_account_info.feedback_quota.amount }} {{ $t('class.class') }}
      br
      br
      | {{ $t('contract.extra_deadline') }}:
      span.float-right.mr-2 {{ vip_account_info.feedback_quota.month_amount }} {{ $t('month') }} {{ vip_account_info.feedback_quota.day_amount }}
        | {{ $t('contract.day') }}
</template>
<script>

export default {
  name: 'FeedbackQuota',

  props: {
    // eslint-disable-next-line
    vip_account_info: {
      type: Object,
    },
  },

  data() {
    return {
      show_give_back_info: false,
    };
  },
  methods: {
    switchGiveBackInfo() {
      this.show_give_back_info = !this.show_give_back_info;
    },
  },
};
</script>
<style lang="scss" scoped>
.give-back-info {
  font-size: 13px;
  // margin-left: 25px;
  color: #6a655e;
}
</style>
