<template lang="pug">
  b-nav(v-if="waitData")
    vip-header-nav(v-if="role === student")
    parent-header-nav(v-else-if="role === parent")
    consultant-header-nav(v-else-if="role === teacher")
    center-header-nav(v-else-if="role === center")
</template>

<script>
import vipHeaderNav from '@/components/nav/headerNav/vipHeaderNav/index.vue';
import parentHeaderNav from '@/components/nav/headerNav/parentHeaderNav/index.vue';
import consultantHeaderNav from '@/components/nav/headerNav/consultantHeaderNav/index.vue';
import centerHeaderNav from '@/components/nav/headerNav/centerHeaderNav/index.vue';
import commonConstants from '@/constants/common';
import lioshutanApi from '@/api';

export default {
  name: 'HeaderNav',

  components: {
    vipHeaderNav,
    parentHeaderNav,
    consultantHeaderNav,
    centerHeaderNav,
  },

  props: {
    role: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      student: commonConstants.ROLE.STUDENT,
      parent: commonConstants.ROLE.PARENT,
      teacher: commonConstants.ROLE.TEACHER,
      center: commonConstants.ROLE.CENTER,
      waitData: false,
    };
  },
  async mounted() {
    this.waitData = false;
    await this.getVipPointSuggest();
    this.waitData = true;
  },
  methods: {
    async getVipPointSuggest() {
      if (localStorage.getItem('wuwow_token')){
        const res = await lioshutanApi.vip.getContractElapsedTime();
        this.$store.dispatch('vipAccount/setPlatform', res.data.platform);
      }
    },
  },
};
</script>
