const types = {
  SET_CURRENT_TIME: 'SET_CURRENT_TIME',
  SET_BANK_OPTIONS: 'SET_BANK_OPTIONS',
};

const state = {
  currentTime: '0000-00-00 00:00:00',
  bankOptions: [],
  allBranchBankOptions: [],
};

const mutations = {
  [types.SET_CURRENT_TIME]: (state, time) => {
    state.currentTime = time;
  },

  [types.SET_BANK_OPTIONS]: (state, options) => {
    state.bankOptions = options.bank_code;
    state.allBranchBankOptions = options.bank_branch_code;
  },
};

const actions = {
  async setCurrentTime({ commit }, time) {
    await commit(types.SET_CURRENT_TIME, time);
  },

  async setBankOptions({ commit }, data) {
    await commit(types.SET_BANK_OPTIONS, data);
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
