<template lang="pug">
#recommend.pt-4
  p.text-lioshutan.area-english-title.text-center :: Recommendation ::
  h2.text-lioshutan.area-title.text-center 快樂學習 好評推薦
  h4.area-description.text-center 所有 WUWOW Jr 的孩子們，每天最期待的事—展開英文學習！

  //- 採 mobile first 768px 992px 切換
  .home-container
    carousel.p-2(:perPage="1",:perPageCustom="[[768, 3],[992, 4]]" :autoplay="true" :autoplayTimeout="5000" :speed="1000" :loop="true" :autoplayHoverPause="true" :navigationEnabled="true")
      slide.justify-content-center(v-for="(card, index) in cardList")
        .slider-card
          //- 卡片縮圖
          .thumb.text-center
            span.tag {{card.tag}}
            //- 圖片
            img(:src="card.thumb" :alt="`${card.topic}的預覽圖片`")
            //- 播放按鈕
            button.play(v-if="card.video",@click="youtubeModal(card.embed)")
              //- 播放 SVG
              svg(width='40' height='40' viewbox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg')
                circle(cx='20' cy='20' r='20' fill='#82C1EA')
                path(d='M28.6638 19.4617C29.3305 19.8466 29.3305 20.8089 28.6638 21.1938L16.9097 27.98C16.2431 28.3649 15.4097 27.8838 15.4097 27.114L15.4097 13.5415C15.4097 12.7717 16.2431 12.2906 16.9097 12.6755L28.6638 19.4617Z' fill='white')
              //- 播放 SVG

            //- Youtube 內嵌影片
            //- .embed-responsive.embed-responsive-21by9
            //-   iframe(v-if="card.video",width='254' height='160', :src='card.thumb', title='YouTube video player',frameborder='0', allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' ,allowfullscreen='')

          //- 卡片內容
          .card-body
            .info.text-center
              p.topic {{card.topic}}
              .detail
                P {{card.name}}
                p(v-if="card.age") &ensp;{{card.age}}歲
            .card-title.d-flex.justify-content-center.align-items-center: h5 {{card.title}}
            p.content {{card.content}}
            .control.d-flex

              //- 圖片模式
              a.link.d-flex.ml-auto.align-items-center(:href="card.link", target='_blank')
                span 觀看更多
                //- 箭頭 SVG
                svg(width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg')
                  circle(cx='10' cy='10' r='9.3' fill='white' stroke='#C7C7C7' stroke-width='1.4')
                  path(d='M4.87818 9.58436C4.98848 9.47405 5.13809 9.41208 5.29408 9.41208H12.1086L9.58301 6.88766C9.47257 6.77722 9.41052 6.62743 9.41052 6.47124C9.41052 6.31505 9.47257 6.16526 9.58301 6.05481C9.69346 5.94437 9.84325 5.88232 9.99944 5.88232C10.1556 5.88232 10.3054 5.94437 10.4159 6.05481L13.9449 9.58383C13.9997 9.63847 14.0431 9.70337 14.0728 9.77483C14.1024 9.84628 14.1177 9.92289 14.1177 10.0003C14.1177 10.0776 14.1024 10.1542 14.0728 10.2257C14.0431 10.2971 13.9997 10.362 13.9449 10.4167L10.4159 13.9457C10.3612 14.0004 10.2963 14.0438 10.2248 14.0734C10.1534 14.103 10.0768 14.1182 9.99944 14.1182C9.9221 14.1182 9.84552 14.103 9.77407 14.0734C9.70262 14.0438 9.6377 14.0004 9.58301 13.9457C9.52833 13.891 9.48495 13.8261 9.45535 13.7546C9.42576 13.6832 9.41052 13.6066 9.41052 13.5293C9.41052 13.4519 9.42576 13.3754 9.45535 13.3039C9.48495 13.2325 9.52833 13.1675 9.58301 13.1128L12.1086 10.5884H5.29408C5.13809 10.5884 4.98848 10.5265 4.87818 10.4162C4.76788 10.3059 4.70591 10.1562 4.70591 10.0003C4.70591 9.84426 4.76788 9.69466 4.87818 9.58436Z' fill='#C7C7C7')
                //- 箭頭 SVG

              //- YouTube 內嵌模式，只能裝 YouTube 內嵌連結
              //- button.link.d-flex.ml-auto.align-items-center(v-if="card.video",@click="youtubeModal(card.link)")
              //-   span 觀看更多
              //- 箭頭 SVG
              //- svg(width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg')
              //-   circle(cx='10' cy='10' r='9.3' fill='white' stroke='#C7C7C7' stroke-width='1.4')
              //-   path(d='M4.87818 9.58436C4.98848 9.47405 5.13809 9.41208 5.29408 9.41208H12.1086L9.58301 6.88766C9.47257 6.77722 9.41052 6.62743 9.41052 6.47124C9.41052 6.31505 9.47257 6.16526 9.58301 6.05481C9.69346 5.94437 9.84325 5.88232 9.99944 5.88232C10.1556 5.88232 10.3054 5.94437 10.4159 6.05481L13.9449 9.58383C13.9997 9.63847 14.0431 9.70337 14.0728 9.77483C14.1024 9.84628 14.1177 9.92289 14.1177 10.0003C14.1177 10.0776 14.1024 10.1542 14.0728 10.2257C14.0431 10.2971 13.9997 10.362 13.9449 10.4167L10.4159 13.9457C10.3612 14.0004 10.2963 14.0438 10.2248 14.0734C10.1534 14.103 10.0768 14.1182 9.99944 14.1182C9.9221 14.1182 9.84552 14.103 9.77407 14.0734C9.70262 14.0438 9.6377 14.0004 9.58301 13.9457C9.52833 13.891 9.48495 13.8261 9.45535 13.7546C9.42576 13.6832 9.41052 13.6066 9.41052 13.5293C9.41052 13.4519 9.42576 13.3754 9.45535 13.3039C9.48495 13.2325 9.52833 13.1675 9.58301 13.1128L12.1086 10.5884H5.29408C5.13809 10.5884 4.98848 10.5265 4.87818 10.4162C4.76788 10.3059 4.70591 10.1562 4.70591 10.0003C4.70591 9.84426 4.76788 9.69466 4.87818 9.58436Z' fill='#C7C7C7')
              //- 箭頭 SVG

</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

// for youtube modal
import { MODAL } from '@/store';
import { IS_OPEN, SET_IS_OPEN, SET_PAYLOAD } from '@/store/modules/modal';
import { mapMutations, mapState } from 'vuex';

import { uuid as youtubeModalUUID } from '../youtubeModal/index.vue';

export default {
  // name: 'Recommendation',

  components: { Carousel, Slide },

  data() {
    return {
      screenWidth: 0,
      // for youtube modal
      youtubeModalUUID,

      // 好評推薦名單
      cardList: [
        {
          tag: '家長分享', // 必填
          video: false,
          // 放圖片靜態資源
          thumb: require('@/assets/recommend/thumb/thumb-janie.png'),
          topic: '飛行海蒂 小饅頭', // 必填，藍色粗體那個標題
          name: 'Janie Tsai', // 必填
          age: 5, // 選填
          title: '孩子喜歡開口最重要', // 黑色粗體那個標題
          // 必填
          content:
            '每次上完課, 小饅頭都很期待下次哪時候要再上課, 還會問我：「媽媽, 今天要上課嗎?」沒有的話還會小小失落, 真的很喜歡上課耶, 完全與他們「最樂學的兒童線上英文」的品牌精神相符合！小孩喜歡、有動力繼續上真的是學習的關鍵, 爸媽的錢才會花 在刀口上。',
          link: 'https://heidi.com.tw/wuwow-junior/',
        },
        {
          tag: '體驗邀請', // 必填
          video: false,
          // 放圖片靜態資源
          thumb: require('@/assets/recommend/thumb/thumb-dean.png'),
          topic: '部落格個人品牌教練&顧問', // 必填，藍色粗體那個標題
          name: 'Dean', // 必填
          age: false, // 選填
          title: '課程、平台、師資各方面完善，值得推薦', // 黑色粗體那個標題
          // 必填
          content:
            '在評測體驗過多家兒童家教品牌後，WUWOW Jr 的優點包含：多元預約方式、老師互動能力佳、專為兒童打造的教材、客服專業、平台使用體驗佳...等等，在各方面算是非常完善，值得推薦給大家。',
          link: 'https://deanlife.blog/wuwow-junior-review/',
        },
        {
          tag: '家長分享', // 必填
          video: false,
          // 放圖片靜態資源
          thumb: require('@/assets/recommend/thumb/thumb-alex.png'),
          topic: '圓圓家的移動城堡 圓圓', // 必填，藍色粗體那個標題
          name: 'Alex Chen', // 必填
          age: 9, // 選填
          title: '量身定做最適合的線上英語教學，輕鬆活潑，學習超有趣', // 黑色粗體那個標題
          // 必填
          content:
            '除了外師也有雙語中師的線上教學，課程設計生動活潑引導孩子自然而然喜歡上英文，讓孩子不再恐懼開口說，教學內容按照孩子各階段英文能力量身訂做出專屬課程，搭配上百種生動活潑的教材，讓孩子愛上英文課! 整體上課感受非常符合他們「最樂學的兒童線上英文」的品牌精神。',
          link: 'https://vivi0010.pixnet.net/blog/post/232326823',
        },
        {
          tag: '學員心得', // 必填
          video: true,
          // 放圖片靜態資源
          thumb: require('@/assets/recommend/thumb/thumb-abby.png'), // 必填
          // 放 youtube embed 連結
          embed: 'https://www.youtube.com/embed/8sNQG-lbVrE',
          topic: '學員分享', // 必填，藍色粗體那個標題
          name: 'Abby Wu', // 必填
          age: 10, // 選填
          title: '從不敢開口到成為日常，樂學英文帶來巨大改變', // 黑色粗體那個標題
          // 必填
          content:
            '自從在 WUWOW Jr 學習，我覺得上英文是一件快樂的事，沒有壓力，自然而然比較敢開口，錯了也不會覺得害羞！',
          link: 'https://www.youtube.com/watch?v=8sNQG-lbVrE',
        },
      ],
    };
  },

  computed: {
    // for youtube modal
    ...mapState(MODAL, [IS_OPEN]),

    // for youtube modal
    youtubeModalIsOpen() {
      return this[IS_OPEN][youtubeModalUUID];
    },
  },

  mounted() {
    this.screenWidth = document.body.clientWidth;

    window.addEventListener('resize', function() {
      this.screenWidth = document.body.clientWidth;
    });
  },

  methods: {
    // for youtube modal
    ...mapMutations(MODAL, [SET_IS_OPEN, SET_PAYLOAD]),

    // for youtube modal
    youtubeModal(url) {
      this.setIsOpen({
        target: youtubeModalUUID,
        status: !this.youtubeModalIsOpen,
      });

      this.setPayload({
        target: youtubeModalUUID,
        payload: url,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#recommend {
  padding: 40px 16px;
  background: linear-gradient(0deg, #ceecff 0%, #ffffff 81.77%);

  .VueCarousel {
    margin: 0 auto;
    width: 75%;

    .VueCarousel-navigation-button,
    .VueCarousel-navigation-next {
      display: none;
    }
  }

  .VueCarousel-wrapper {
    align-self: center;
    // mobile first 手機板寬度
    width: 100%;
  }
}

.area-description {
  color: #b9b9b9;

  margin-bottom: 40px;

  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
}

.VueCarousel-dot--active {
  background-color: var(--main-color) !important;
}

// 卡片 layout
.slider-card {
  // mobile first 手機板寬度
  // width: 367px;
  margin-right: 16px;

  width: 100%;

  border-radius: 7px;
  padding: 0px 0px 24px 0px;

  background: #ffffff;
  box-shadow: 0px 6px 8px 0px #e4e4e440;

  letter-spacing: 0.1em;

  --text-major: #707070;

  .card-body {
    padding: 20px 16px 0;
  }
}

// 卡片縮圖
.thumb {
  border-radius: 10px;
  overflow: hidden;

  // width: 254px;
  height: 160px;

  position: relative;

  // 標籤
  span {
    z-index: 1;
    position: absolute;
    top: 10px;
    left: 10px;

    border-radius: 5px;
    background-color: var(--main-color);
    padding: 2px 6px;

    font-size: 12px;
    line-height: 19px;
    color: white;
  }

  // 播放按鈕
  button.play {
    border: none;
    background: none;

    z-index: 1;
    position: absolute;

    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;

    svg circle {
      transition: fill 0.65s ease-out;
    }

    &:hover {
      svg circle {
        fill: #f4fbff;
      }

      svg path {
        fill: var(--main-color);
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img,
  iframe {
    transition: transform 0.65s cubic-bezier(0.8, -0.6, 0.1, 1.4);

    &:hover {
      transform: scale(1.2);
    }
  }
}

// 卡片內容
.card-body {
  .card-title {
    height: 44px;
    margin: 10px 0 20px;

    &>* {
      margin-bottom: unset;
      font-weight: 600;

      font-size: 14px;
      line-height: 22px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .content {
    --content-line-height: 22px;
    margin-bottom: 15px;
    height: calc(var(--content-line-height) * 4);

    font-size: 14px;
    line-height: var(--content-line-height);

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.info {
  padding: 8px 21.5px;
  border-radius: 5px;
  background-color: #F4FBFF;

  --info-line-height: 17px;

  p {
    font-size: 14px;
    line-height: var(--info-line-height);

    margin-bottom: unset;
  }

  .topic {
    margin-bottom: 4px;
    height: calc(var(--info-line-height) * 2);

    color: var(--main-color);
    font-weight: 700;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .detail {
    color: var(--text-major);
    height: calc(var(--info-line-height) * 2);

    // 姓名
    p:first-child {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

// 觀看更多按鈕
.link {
  border: unset;
  padding: 0;
  background: none;

  font-size: 14px;
  color: #c7c7c7;
  letter-spacing: 0.1em;

  span {
    transition: color 0.35s ease-out;
  }

  svg {
    margin-left: 6px;

    circle,
    path {
      transition: fill 0.35s ease-out, stroke 0.35s ease-out;
    }
  }

  &:hover {
    text-decoration: none;

    // 文字
    span {
      color: var(--main-color);
    }

    // 箭頭
    svg {
      circle {
        stroke: var(--main-color);
        fill: var(--main-color);
      }

      path {
        fill: white;
      }
    }
  }
}

// RWD, mobile first
// tablet (bootstrap md)
@media screen and(min-width: 768px) {
  .area-description {
    font-size: 20px;
    line-height: 31px;
  }

  #recommend .VueCarousel {
    width: calc(90% + 16px);
  }

  #recommend .VueCarousel-wrapper {
    // width: 684px;
  }

  .slider-card {
    width: 214px;
  }
}

// desktop (bootstrap lg)
@media screen and(min-width: 992px) {
  .area-description {
    font-size: 22px;
    line-height: 34px;
  }

  #recommend .VueCarousel {
    width: calc(90% + 32px);
  }

  #recommend .VueCarousel-wrapper {
    width: 100%;
  }

  .slider-card {
    width: 204.5px;
  }
}

@media screen and(min-width: 1200px) {
  #recommend .VueCarousel {
    width: calc(90% + 16px);

    // 顯示輪撥按鈕
    .VueCarousel-navigation-button,
    .VueCarousel-navigation-next {
      display: block;
    }
  }

  .slider-card {
    width: 254px;
  }
}

@media screen and(min-width: 1600px) {
  .slider-card {
    width: 334px;
  }
}
</style>
