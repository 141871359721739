import _CHARACTER from '@/constants/character';
export const CHARACTER = 'character';

export const SET_CHARACTER = 'setCharacter';

const state = {
  [CHARACTER]: _CHARACTER.NONE,
};

const mutations = {
  [SET_CHARACTER]: (state, character) => {
    state[CHARACTER] = character;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
