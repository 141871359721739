// 引入 合約 API
import contractClass from '@/api/src/contract';
const contract = new contractClass();

const types = {
  ADD_VIP_CONTRACT_ELAPSED_TIME: 'ADD_VIP_CONTRACT_ELAPSED_TIME',
  ADD_VIP_CONTRACT_FEEDBACK_QUOTA: 'ADD_VIP_CONTRACT_FEEDBACK_QUOTA',
};

const state = {
  vipContractElapsedTime: {
    contract_end: '0001-01-01',
    contract_start: '9999-12-31',
  },

  vipContractFeedbackQuota: {
    amount: 0,
    month_amount: 0,
    day_amount: 0,
  },

  // 客戶合約陣列
  contractOwnHistory: [],
};

const mutations = {
  [types.ADD_VIP_CONTRACT_ELAPSED_TIME]: (state, data) => {
    state.vipContractElapsedTime = data;
  },

  [types.ADD_VIP_CONTRACT_FEEDBACK_QUOTA]: (state, data) => {
    state.vipContractFeedbackQuota = data;
  },

  setContractOwnHistory(state, data) {
    state.contractOwnHistory = data;
  },
};

const actions = {
  async addVipContractElapsedTime({ commit }, data) {
    await commit(types.ADD_VIP_CONTRACT_ELAPSED_TIME, data);
  },

  async addVipContractFeedbackQuota({ commit }, data) {
    await commit(types.ADD_VIP_CONTRACT_FEEDBACK_QUOTA, data);
  },

  async fetchContractOwnHistory({ commit }, query) {
    const result = await contract.getContractOwnHistory(query);
    commit('setContractOwnHistory', result);
    return result;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
