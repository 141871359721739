<template lang="pug">
//- b-modal(v-model="show" size="lg" centered hide-header hide-footer @change="close")
.trp-modal.py-5
  //- 電腦用
  .d-none.d-md-block.mt-xl-5
    .d-flex.flex-column.align-items-center
      h2.area-title.text-lioshutan TPR 肢體回應教學法
      h5.area-text.mb-3.text-lioshutan TOTAL PHYSICAL RESPONSE
      .d-flex.flex-column.align-items-center.mx-3
        p.w-75.w-xl-50.area-text.text-center.my-5 TPR 是由加州聖荷西州立大學心理學教授 James Asher 所創， 以行為學派為基礎的學習理論，把語言的學習視為一種刺激與反應的聯結。 主張兒童主要是經由肢體動作而非抽象的語言型式習得母語。
        img.w-75.w-xl-50(:src="file.tpr")
        p.w-75.w-xl-50.area-text.text-center.my-5 在實際語言教學上，老師會特別注重肢體的表達，就像是幼幼台的大哥哥大姊姊們一樣，利用誇張的手勢、表情以及嘴形，再加上教材中大量的圖片影像，抓住孩子的注意力，讓孩子更容易保持學習的專注。
  //- 手機用
  .d-block.d-md-none
    .w-100.px-3
      .d-flex.flex-column.align-items-center
        h3.text-lioshutan TPR 肢體回應教學法
        h5.mb-3.text-lioshutan TOTAL PHYSICAL RESPONSE
        .d-flex.flex-column.align-items-center.mx-3
          p.mt-2.mb-4.area-text TPR 是由加州聖荷西州立大學心理學教授 James Asher 所創， 以行為學派為基礎的學習理論，把語言的學習視為一種刺激與反應的聯結。 主張兒童主要是經由肢體動作而非抽象的語言型式習得母語。
          img.w-100(:src="file.tpr")
          p.my-4.area-text 在實際語言教學上，老師會特別注重肢體的表達，就像是幼幼台的大哥哥大姊姊們一樣，利用誇張的手勢、表情以及嘴形，再加上教材中大量的圖片影像，抓住孩子的注意力，讓孩子更容易保持學習的專注。
</template>

<script>
import { v4 } from 'uuid';

import tpr from '@/assets/lightbox_tpr.png';
import modalMixin from '@/mixins/modal';

import 'bootstrap/dist/css/bootstrap.css';

export const uuid = v4();

export default {
  mixins: [
    modalMixin,
  ],

  data: () => ({
    file: {
      tpr,
    },
  }),

  created() {
    this.initModal(uuid, {
      status: false,
      payload: null,
    });
  },

};
</script>
