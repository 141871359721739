var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": _vm.wuwow_meeting_center_download_windows_url
    }
  }, [_vm._v("WUWOW Meeting Center Download (Windows)")]), _c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": _vm.wuwow_meeting_center_download_mac_url
    }
  }, [_vm._v("WUWOW Meeting Center Download (MAC)")]), _c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": `${_vm.self_training_classroom_consultant_url}&ui=${_vm.user_id}`,
      "target": "_blank"
    }
  }, [_vm._v("Self-Training Classroom (As a Consultant)")]), _c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": `${_vm.self_training_classroom_vip_url}&ui=${_vm.user_id}`,
      "target": "_blank"
    }
  }, [_vm._v("Self-Training Classroom (As a VIP)")]), _c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": `${_vm.training_classroom_url}&ui=${_vm.user_id}`,
      "target": "_blank"
    }
  }, [_vm._v("Training Classroom")]), _c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": _vm.questionnaire_url,
      "target": "_blank"
    }
  }, [_vm._v("Questionnaire")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }