<template lang="pug">
div.d-flex.align-items-center.flex-column.text-white
  .d-flex.justify-content-center.align-items-center.wuwow-icon
    img.position-absolute.icon-border(:src="icon" width="100" height="100")
    .position-absolute.triangle
  p.h4 {{ message }}
</template>

<script>
import Icon from '@/assets/loading/wuwow-loading.svg';
import LoadingMessage from '@/api/src/loadingMessage';

export default {
  data: () => ({
    icon: Icon,
    message: '',
  }),
  created() {
    const loadingMessage = new LoadingMessage();
    this.message = loadingMessage.getMessage();
  },
};
</script>

<style scoped>
.wuwow-icon {
  width: 100px;
  height: 100px;
}
.icon-border {
  position:absolute;
  position:relative;
  width: 100px;
  height: 100px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  /* transform: rotate(3deg); */
   /* transform: rotate(0.3rad);/ */
   /* transform: rotate(3grad); */
   /* transform: rotate(.03turn);  */
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 12px 18px;
  border-color: transparent transparent transparent #deb866;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}
</style>
