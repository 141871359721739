var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('skeleton', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('template', {
    slot: "skeleton-template"
  }, [_c('skeleton-template')], 1), _c('template', {
    slot: "origin-template"
  }, [_c('div', {
    staticClass: "are-personal"
  }, [_c('div', {
    staticClass: "blk-personal-info"
  }, [_c('div', {
    staticClass: "student-account mb-2"
  }, [_vm._v("學員帳號")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('avatar', {
    attrs: {
      "avatarUrl": _vm.vip_account_info.profile_base.photo,
      "avatarRole": 'student'
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('span', {
    staticClass: "txt-personal-name"
  }, [_vm._v(_vm._s(_vm.vip_account_info.profile_base.english_name))]), _c('br'), _c('span', {
    staticClass: "txt-personal-level",
    class: _vm.userLevelClass
  }, [_vm._v("LV. " + _vm._s(_vm.vip_account_info.profile_base.level))])]), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm.role === _vm.parent ? _c('b-button', {
    staticClass: "change-account-btn",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.changeStudent
    }
  }, [_vm._v("切換")]) : _vm._e()], 1)], 1), _c('p', {
    staticClass: "txt-personal-info mt-4"
  }, [_c('contract-period', {
    attrs: {
      "vip_account_info": _vm.vip_account_info
    }
  })], 1)], 1), _c('feedback-quota', {
    attrs: {
      "vip_account_info": _vm.vip_account_info
    }
  }), _c('my-account-link', {
    attrs: {
      "role": _vm.role
    }
  }), _vm.role === _vm.student ? _c('a', {
    staticClass: "lnk-personal-logout",
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(_vm._s(_vm.$t('logout')))]) : _vm._e()], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }