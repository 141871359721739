import axios from '@/api/utils/axios-helper.js';

export default class VipContract{
  /**
   * 取得學員合約期間
   * @returns {Promise<Array>} 回傳學員合約期間
   */
  async getContractElapsedTime() {
    const response = await axios({
      url: '/v3/taichi-external/student/contract/elapsed-time',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得學員回饋紀錄
   * @returns {Promise<Array>} 回傳學員回饋紀錄
   */
  async getContractFeedbackQuota() {
    const response = await axios({
      url: '/v3/taichi-external/student/contract/feedback-quota',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得學員合約歷史紀錄
   * @returns {Promise<Array>} 回傳學員合約歷史紀錄
   */
  async getContractOwnHistory(query) {
    const response = await axios({
      url: '/v3/taichi-external/student/contract/own-history',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: query,
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 學員同意合約
   * @param coi: 合約id
   * @returns {Promise<Object>} 回傳送出成功失敗依照http code判斷
   */
  async checkOrderProduct(params) {
    const response = await axios({
      url: '/v3/taichi-external/student/contract/check-order-product',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 學員同意分享轉讓
   * @param vpai: 合約id, status: 3同意 4取消
   * @returns {Promise<Object>} 回傳送出成功失敗依照http code判斷
   */
  async confirmTransferProduct(params) {
    const response = await axios({
      url: '/vip/transfer_approved_vip_product',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: params,
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 學員同意暫停
   * @param vpai: 合約id, status: 3同意 4取消
   * @returns {Promise<Object>} 回傳送出成功失敗依照http code判斷
   */
  async confirmSuspensionProduct(params) {
    const response = await axios({
      url: '/vip/suspension_approved_vip_product',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: params,
      responseType: 'json',
    });
    return response.data;
  }

  async getContractIdentity() {
    const response = await axios({
      url: '/v3/human-resource/student/contract/identity',
      method: 'get',
      responseType: 'json',
      headers: { 'Cache-Control': 'no-cache' },
    });
    return response;
  }
}
