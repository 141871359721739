var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.renewalReminder ? _c('div', {
    staticClass: "modal-overlay"
  }, [_c('div', {
    staticClass: "custom-modal"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v("續約提醒")]), _vm.isGoogleIdentity ? [_vm._m(0)] : [_vm._m(1)], _c('img', {
    staticClass: "woman-happy-img",
    attrs: {
      "src": require("@/assets/woman-happy.svg"),
      "alt": "woman"
    }
  }), _c('img', {
    staticClass: "iphone-15-img",
    attrs: {
      "src": require("@/assets/iphone-15.svg"),
      "alt": "iphone15"
    }
  }), _c('div', {
    staticClass: "understand-btn-box"
  }, [_c('button', {
    staticClass: "btn understand-btn",
    on: {
      "click": _vm.understand
    }
  }, [_vm._v("已了解")])])], 2)]) : _vm._e(), _vm.contractRenewalSuccessful ? _c('div', {
    staticClass: "modal-overlay"
  }, [_c('div', {
    staticClass: "successful-modal"
  }, [_c('div', {
    staticClass: "successful-bg"
  }, [_c('img', {
    staticClass: "successful-title",
    attrs: {
      "src": require("../../../assets/renew-contract-title.svg")
    }
  }), _vm.isGoogleIdentity ? [_vm._m(2)] : [_vm._m(3)]], 2), _c('div', {
    staticClass: "understand-btn-box"
  }, [_c('button', {
    staticClass: "btn understand-btn",
    on: {
      "click": _vm.understand
    }
  }, [_vm._v("已了解")])])])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "content"
  }, [_vm._v("您的學習合約即將到期，除了把握時間學習，更要把握續約優惠~"), _c('br'), _c('br'), _c('span', [_vm._v("2024/1/7")]), _vm._v("前週年慶期間"), _c('br'), _vm._v("立即續約 領「"), _c('span', {
    staticClass: "new-light-coupon"
  }, [_vm._v("$NT1000")]), _vm._v(" 新光禮券」"), _c('br'), _c('br'), _vm._v("快聯繫您的服務專員了解內容！")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "content"
  }, [_vm._v("您家寶貝的學習合約即將到期，除了把握時間學習，更要把握續約優惠~"), _c('br'), _c('br'), _c('span', [_vm._v("2024/1/7")]), _vm._v("前週年慶期間"), _c('br'), _c('br'), _vm._v("購買指定方案送「"), _c('span', [_vm._v("iPhone 15")]), _vm._v("」"), _c('br'), _vm._v("續約再享獨家「"), _c('span', [_vm._v("YLE 劍橋兒童英語認證 全額贊助")]), _vm._v("」"), _c('br'), _c('br'), _vm._v("快聯繫您的服務專員了解內容！")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "content"
  }, [_vm._v("恭喜續約成功"), _c('br'), _c('br'), _vm._v("獲得"), _c('span', {
    staticClass: "new-light-coupon"
  }, [_vm._v(" $NT1000")]), _vm._v(" 新光禮券"), _c('br'), _c('br'), _vm._v("快前往您的信箱收信查看領獎辦法！"), _c('br'), _c('br'), _vm._v("信件主旨：【 WUWOW JUNIOR 】週年慶Google學員專屬續約獎金 領獎通知")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "content"
  }, [_vm._v("恭喜續約成功，享有「YLE 劍橋兒童英語認證」全額贊助資格，"), _c('br'), _c('br'), _vm._v("快前往您的信箱收信查看檢定贊助申請辦法！"), _c('br'), _c('br'), _vm._v("信件主旨：【 WUWOW JUNIOR】 恭喜完成續約，獲得YLE 劍橋兒童英語認證贊助資格")]);

}]

export { render, staticRenderFns }