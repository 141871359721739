var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "inline-flex"
    }
  }, [_c('li', {
    staticClass: "nav-item dropdown px-3"
  }, [_c('a', {
    ref: "javascript:;",
    staticClass: "nav-link dropdown-toggle",
    on: {
      "click": function ($event) {
        return _vm.toggleDropdown('center');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('center')))]), _c('div', {
    staticClass: "dropdown-menu",
    class: [_vm.state === 'center' ? ' d-block' : 'd-none']
  }, _vm._l(_vm.centerLists, function (item) {
    return _c('router-link', {
      key: item.name,
      staticClass: "dropdown-item",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]);
  }), 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }