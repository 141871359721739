import axios from '@/api/utils/axios-helper.js';

export default class Suitable {
  /**
   * 取得適性清單
   * @returns {Promise<Array>} 回傳所有適性資訊(圖片、名稱...)
   */
  async getSuitableList(params) {
    const response = await axios({
      url: '/v3/educational/course/suitable-analyse/list',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
      params: params,
    });
    return response;
  }
  // TODO Notice 告知後端需少於3個適性需回錯誤訊息 By Asa
  /**
   * 修改學員適性
   * @param type: 修改類型(interested_topic), parameter<array>: 所有選取的適性 需要3個以上
   * @returns {Promise<Object>} 回傳修改成功失敗依照http code判斷
   */
  async setSuitableList(params) {
    const response = await axios({
      url: '/v3/educational/course/suitable-analyse/list',
      method: 'patch',
      headers: { 'Cache-Control': 'no-cache' },
      data: params,
      responseType: 'json',
    });
    return response;
  }
}
