<template lang="pug">
b-modal(v-model="show" size="lg" centered hide-header hide-footer @change="close")
  iframe.w-100(
    height="500"
    :src="src"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  )
</template>

<script>
import { v4 } from 'uuid';

import modalMixin from '@/mixins/modal';

export const uuid = v4();

import 'bootstrap/dist/css/bootstrap.css';

export default {
  mixins: [
    modalMixin,
  ],
  props: {
    src: {
      type: String,
      // required: true,
      default: 'https://www.youtube.com/embed/hPr-Yc92qaY?autoplay=1',
    },
  },

  created() {
    this.initModal(uuid, {
      status: false,
      payload: 'https://www.youtube.com/embed/hPr-Yc92qaY?autoplay=1',
    });
  },

};
</script>
