var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "nav-item dropdown"
  }, [_c('a', {
    staticClass: "nav-link d-xl-flex align-items-center dropdown-toggle",
    on: {
      "click": function ($event) {
        return _vm.toggleDropdown('parent-account');
      }
    }
  }, [_c('avatar', {
    staticClass: "nav-avatar",
    attrs: {
      "avatarUrl": _vm.profileBase.avatar,
      "avatarRole": 'parent'
    }
  }), _c('div', {
    staticClass: "d-none d-xl-flex ml-2"
  }, [_c('span', {
    staticClass: "d-lg-down-none"
  }, [_vm._v(_vm._s(_vm.profileBase.english_name))]), _c('span', {
    staticClass: "parent-account-text mx-2"
  }, [_vm._v(_vm._s(_vm.$t('parent')) + _vm._s(_vm.$t('account')))])])], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.state === 'parent-account' ? _c('div', {
    staticClass: "dropdown-menu dropdown-menu-right d-block"
  }, [_c('div', {
    staticClass: "are-personal"
  }, [_c('p', {
    staticClass: "txt-personal-menu-title"
  }, [_vm._v(_vm._s(_vm.$t('parent')) + _vm._s(_vm.$t('account')))]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('avatar', {
    staticClass: "personal-avatar ml-4",
    attrs: {
      "avatarUrl": _vm.profileBase.avatar,
      "avatarRole": 'parent'
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "7"
    }
  }, [_c('div', {
    staticClass: "m-2"
  }, [_c('span', {
    staticClass: "txt-personal-name"
  }, [_vm._v(_vm._s(_vm.profileBase.chinese_name))])])])], 1), _c('my-account-link', {
    staticClass: "mt-2"
  }), _c('detail', {
    attrs: {
      "role": _vm.parent
    }
  })], 1)]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }