<template lang="pug">
ul.nav
  li.nav-item
    span.nav-link {{ $t('center') }}
    ul.subnav
      li.subnav-item(v-for="item in centerLists" :key="item.name")
        router-link.dropdown-item(:to="{ path: item.path }") {{ $t(item.name) }}
  //- 為了防止正常目錄在手機瀏覽器被蓋住(By 4年前寫的人)
  li(style="height: 40px;")
    p

</template>

<script>
import i18n from '@/components/nav/lang';
import navList from '@/components/nav/navList';
export default {
  name: 'CenterLeftNav',

  props: {
  },

  i18n,

  data() {
    return {
      centerLists: navList.center.centerLists,
    };
  },

  mounted() {},

  methods: {},
};
</script>
