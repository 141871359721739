import _ from 'lodash';
const $_ = _;
const toCamelCase = (params, callback) => {
  return $_.chain(params)
    .forIn((val, key, obj) => {
      const snakeStr = $_.camelCase(key);
      obj[snakeStr] = obj[key];
      if (snakeStr !== key) {
        delete obj[key];
      }
      callback(val);
    })
    .value();
};
export const changeCamelCase = (params) => {
  switch (true) {
    case $_.isArray(params):
      toCamelCase(params, changeCamelCase);
      break;
    case $_.isObject(params): {
      return toCamelCase(params, changeCamelCase);
    }
  }
};

export function setDafault(objData, preObject) {
  $_.forIn(objData, (val, key) => {
    if ($_.isNull(val)) {
      objData[key] = '';
    }
    switch (true) {
      case $_.isArray(val) && $_.isObject(val[0]):
        setDafault(setAllSameKeys(val));
        break;
      case $_.isObject(val):
        setDafault(objData[key]);
        break;
    }
  });
  return objData;
}

const setHavenotKeyDefaultData = (arrKeys, obj) => {
  arrKeys.forEach((key) => {
    if (!$_.hasIn(obj, key)) {
      obj[key] = '';
    }
  });
};

const setAllSameKeys = (arr) => {
  const preObjectKeys = Object.keys(arr[0]);
  let curObjectKeys;
  for (let i = 1; i < arr.length; i++) {
    curObjectKeys = Object.keys(arr[i]);
    switch (true) {
      case preObjectKeys.length > curObjectKeys.length:
        setHavenotKeyDefaultData(preObjectKeys, arr[i]);
        break;
      case preObjectKeys.length < curObjectKeys.length:
        setHavenotKeyDefaultData(curObjectKeys, arr[0]);
        setAllSameKeys(arr);
        break;
    }
  }
  return arr;
};
