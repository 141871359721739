export default {
  messages: {

    en: {
      attendClass: 'Classroom',
      auditClass: 'Classroom',
      bookClass: 'Book Class',
      book: 'Book',
      microCourse: '25mins Micro Course',
      instantClass: 'Instant Class',
      academyTalks: 'Academy Talks',
      viewConsultants: 'View Consultants',
      upgradeTest: 'Upgrade Test',
      setRecord: 'Set/Record',
      bookClassRecord: 'Booking record',
      suitable: 'Suitable',
      classRecord: 'Class Record',
      evaluations: 'Evaluations',
      learnRecords: 'WUWOW Learning Record',
      progressAnalysis: 'Progress Analysis',
      vipMgm: 'VIP MGM',
      guide: 'Guide',
      couponList: 'WuShare',
      couponCourse: 'Coupon Course',
      center: 'Center',
      centerMembers: 'Center Members',
      memberClassRecords: 'Member Class Records',
      studentContract: 'Student Contract',
      profile: 'Profile',
      schedule: 'Schedule',
      setTimeSlot: 'Set Time Slot',
      setRegularTimeSlot: 'Set Fixed Time Slot',
      materials: 'Materials',
      classRecords: 'Class Records',
      cloudClassroom: 'Cloud Classroom',
      training: 'Training',
      requireFavoriteConsultant: 'Book Class With Preferable Consultant',
      bookClassWithDate: 'Book Class By Date And Time',
      vipFixedSchedule: 'Fixed weekly schedule',
      // summerCampBookClass: 'Book Summercamp Class',
      vipClassRequest: 'Vip Class Request',
      editParentProfile: 'Edit Profile',
      class: 'Class',
      classRequest: 'Vip Class Request',
    },

    tw: {
      attendClass: '開始上課',
      auditClass: '開始旁聽',
      bookClass: '預約訂課',
      book: '訂課',
      microCourse: '25mins微課程',
      instantClass: '隨選隨上',
      academyTalks: '百家書苑',
      viewConsultants: '查看顧問',
      upgradeTest: '升級挑戰',
      setRecord: '紀錄/設定',
      bookClassRecord: '訂課紀錄',
      suitable: '適性調整',
      classRecord: '課程紀錄',
      evaluations: '課後評鑑',
      learnRecords: '學習軌跡',
      progressAnalysis: '進度分析',
      vipMgm: '推薦好友',
      guide: '新手上路',
      couponList: 'WuShare專區',
      couponCourse: '兌換課程',
      center: '中心',
      centerMembers: '成員',
      memberClassRecords: '教學紀錄',
      studentContract: '學員合約',
      profile: '個人資料',
      schedule: '行程表',
      setTimeSlot: '課程時間設定',
      setRegularTimeSlot: '固定課程時間設定',
      materials: '教材',
      classRecords: '課程紀錄',
      cloudClassroom: '雲端教室',
      training: '教育訓練',
      requireFavoriteConsultant: '指定顧問訂課',
      bookClassWithDate: '依照日期時間訂課',
      vipFixedSchedule: '規律訂課',
      // summerCampBookClass: '暑期挑戰總複習',
      vipClassRequest: '顧問課堂申請',
      editParentProfile: '編輯個人資料',
      class: '教室',
      classRequest: '學員請求課程',
    },

  },
};
