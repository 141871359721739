var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('socket-info-modal', {
    ref: "web-socket-info-modal",
    attrs: {
      "socketData": _vm.pusherData
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }