import axios from '@/api/utils/axios-helper.js';

export default class consultantsList {
  /**
    * 取得國籍選項
    * TODO: 這個先不串 因為不知道舊專案怎串 如果這樣塞進去會有401
    * @returns {Promise<Object>} teach.interested_topic_ids: 取得國籍選項
    */
  async queryNationalityList() {
    const response = await axios({
      url: 'https://it-lst-taichi-external.wuwow.tw/api/v2/consultants/nationality_list',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取得全部適性選項
    * @returns {Promise<Object>} interested_topics: 全部適性選項
    */
  async queryInterestList() {
    const response = await axios({
      url: '/v3/taichi-external/interested-topic/consultant-options',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取得授課老師清單
    * @param interestedTopicIds[]: 查詢的顧問擅長教的適性 (必填)
    * @param nationalityIds[]: 查詢的顧問國籍 (必填)
    * @param platform: 平台 (必填), 1:wuwow / 2:wuwow junior
    * @param perPage: 每頁幾筆 (必填)
    * @param keyword: 顧問關鍵字
    * @returns {Promise<Object>} teach.profile: 顧問的個人資料(照片、自我介紹...)
    * @returns {Promise<Object>} teach.interested_topic_ids: 顧問擅長教的適性ID
    */
  async queryConsultantList(params) {
    const response = await axios({
      url: '/v3/human-resource/teach',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取得授課老師評鑑分數(顯示在查看顧問的評分)
    * 固定帶查詢的月份
    * @param type: history 歷史 , order 指定 (必填)
    * @param rangeTimes[start_date]: 時間區間(起) (type:order時必填)
    * @param rangeTimes[end_date]: 時間區間(訖) (type:order時必填)
    * @param teacherIds[]: 指定查詢的顧問ID
    * @param platform: 平台 (必填), 1:wuwow / 2:wuwow junior
    * @returns {Promise<Object>} 老師評鑑分數(總分、平均分數...)
  */
  async queryConsultantScore(params) {
    const response = await axios({
      url: '/v3/educational/score/calculate',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取得學生對老師友好情況(查看顧問的愛心)
    * @param teacherIds[]: 指定查詢的顧問ID
    * @param platform: 平台 (必填), 1:wuwow / 2:wuwow junior
    * @returns {Promise<Object>} 學生是否喜愛顧問清單
  */
  async queryConsultantFavorable(params) {
    const response = await axios({
      url: '/v3/human-resource/favorable',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取得學生喜愛的顧問
    * @param platform: 平台 (必填), 1:wuwow / 2:wuwow junior
    * @returns {Promise<Object>} 學生喜愛的顧問列表
  */
  async queryLikesConsultant(params) {
    const response = await axios({
      url: '/v3/human-resource/likes',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取得顧問細項
    * @param
    * @returns {Promise<Object>} 學生喜愛的顧問列表(顧問大頭貼,適性,可教等級,分數,自我介紹)
  */
  async queryConsultantDetail(id) {
    const response = await axios({
      url: `/v3/taichi-external/consultant/profile?hb_consultant_id=${id}`,
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
    * 新增VIP喜歡的顧問
    * @param
    * @returns {Promise<Object>} 學生喜愛的顧問列表
  */
  async addVipLikeConsultant(params) {
    const response = await axios({
      url: '/vip/edit_vip_like_consultant',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: params,
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取消VIP喜歡的顧問
    * @param
    * @returns {Promise<Object>} 學生喜愛的顧問列表
  */
  async cancelVipLikeConsultant(params) {
    const response = await axios({
      url: '/vip/delete_vip_like_consultant',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: params,
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取得顧問timeslot
    * @param ui:顧問id
    * @param st:查詢起日
    * @param et:查詢迄日
    * @returns {Promise<Object>} 顧問timeslot
  */
  async queryConsultantTimeslots(params) {
    const response = await axios({
      url: '/v3/human-resource/teacher-timeslots',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取得授課老師清單分平台
    * @param platform: 平台
    * @param perPage: 資料筆數
    * @returns {Promise<Object>} 顯示平台所有可授課老師
  */
  async queryPlatformConsultantCanTeach(params) {
    const response = await axios({
      url: '/v3/human-resource/teach',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
    * 取得特定時段可上課顧問
    * @param platform: 平台
    * @param bookingTime: 上課時間 YYYY-MM-DD HH:mm:ss
    * @returns {Promise<Object>} 可上課顧問 timeslot, teacher_id
  */
  async querySpecificConsultantTimeslot(params) {
    const response = await axios({
      url: '/v3/human-resource/booking-timeslot',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
}
