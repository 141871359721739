import axios from '@/api/utils/axios-helper.js';

export default class Notification {
  /**
   * 取得近期公告清單
   * @returns {Promise<Array>} 回傳近期公告資訊清單
   */
  async getNotificationList() {
    const response = await axios({
      url: process.env.VUE_APP_BASE_WUWOW_EXTERNAL_API + '/api/v2/notification/show_index_notification',
      method: 'patch',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得公告內容
   * @param type: 公告類型, id: 公告id
   * @returns {Promise<Object>} 回傳指定公告內容
   */
  async getNotificationSpecificContent(params) {
    const response = await axios({
      url: '/v3/taichi-external/notification/content',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 設定取得
   * @param 所有key 1為開啟 0為關閉
   * @returns {Promise<Object>} 回傳設定成功失敗依照http code判斷
   */
  async getNotification() {
    const response = await axios({
      url: '/v3/taichi-external/student/info',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 設定綁訂狀態
   * @param 所有key 1為開啟 0為關閉
   * @returns {Promise<Object>} 回傳設定成功失敗依照http code判斷
   */
  async setNotification(data) {
    const response = await axios({
      url: '/v3/taichi-external/student/info',
      method: 'patch',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得綁訂 Line Notify Url
   * @returns {Promise<String<url>>} 回傳綁訂line notify url
   */
  async getLineNotifyUrl() {
    const response = await axios({
      // url: '/v3/taichi-external/notification/line-notify/authorize-url',
      url: '/v3/taichi-external/notification/line-notify/authorize-url?platform=wuwow_junior',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取消 Line Notify 通知綁訂
   * @returns {Promise<Object>} 回傳設定成功失敗依照http code判斷
   */
  async unbindLineNotify() {
    const response = await axios({
      url: '/v3/taichi-external/notification/line-notify/revoke-account',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    // 回傳整個 response 讓元件內部可以拿到 response.status 判斷狀態
    return response;
  }
  /**
   * 取得裝置設備訊息推撥
   * @returns {Promise<Object>} 回傳所有設定推撥裝置
   */
  async queryNotificationPushDevice() {
    const response = await axios({
      url: '/v3/taichi-external/notification/pushes',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 新增裝置設備訊息推撥
   * @param ty: 推撥類型(firebase), to: token(firebase token), nc: 名稱內容
   * @returns {Promise<Object>} 回傳新增成功失敗依照http code判斷
   */
  async addNotificationPushDevice(data) {
    const response = await axios({
      url: '/v3/taichi-external/notification/pushes',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 修改裝置設備訊息推撥
   * @param ty: 推撥類型(firebase), to: token(firebase token), nc: 名稱內容
   * @returns {Promise<Object>} 回傳修改成功失敗依照http code判斷
   */
  async modifyNotificationPushDevice(param) {
    const response = await axios({
      url: '/v3/taichi-external/notification/pushes/' + param.id,
      method: 'patch',
      headers: { 'Cache-Control': 'no-cache' },
      data: param,
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 刪除裝置設備訊息推撥
   * @param id: 推撥設備id
   * @returns {Promise<Object>} 回傳刪除成功失敗依照http code判斷
   */
  async deleteNotificationPushDevice(id) {
    const response = await axios({
      url: '/v3/taichi-external/notification/pushes/' + id,
      method: 'delete',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得裝置設備類型?!
   * @returns {Promise<Object>} 回傳裝置設備類型
   */
  async queryNotificationPushClasses() {
    const response = await axios({
      url: '/v3/taichi-external/notification/classes',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }

  /**
   * 取得輪播內容
   * @param platform: 平台類型, wuwow
   * @param noticeMethod: 類型, banner
   * @param categoryId: 公告類別, 1
   * @returns {Promise<Object>} 回傳指定公告內容
   * 目前參數寫死
   */
  async getBannerList() {
    const response = await axios({
      url: '/v3/message/notice/public-items',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: {
        platform: 'wuwow',
        noticeMethod: 'banner',
        categoryId: '1',
      },
      responseType: 'json',
    });
    return response.data;
  }
}
