// import axios from './../utils/axios-helper.js';

class LoadingMessage {
  getMessage() {
    const randomIndex = Math.floor(Math.random() * message.length) + 0;
    return message[randomIndex];
  }
}

const message = [
  'Patience is a virtue.',
  'Coffee makes life complete.',
  'Don’t be late for your classes!',
  'Those student analyses…',
  'Remember to submit analyses within 24 hours!! (or else…)',
  'Enter class 5 minutes early!',
  'Perform speed tests frequently to ensure things run smoothly.',
  'Being tardy = NO PAY!!!',
  'Spread love and happiness through laughter and smiles!',
  'Why the long face? ',
  'Don’t forget to smile!',
  'Falling asleep? Go for a short run!',
  'Time to brew a new pot of coffee.',
  'Refills? Yes, please!',
  'Take more classes! Your students need you!',
  'Laughter is contagious.',
  'You’ve got a beautiful smile; show it off!',
  'Corrections, corrections, corrections…',
  'Don’t consume too much starch in the evening; it’ll make you sleepy!',
  'Don’t forget to lock the camera view!',
  'Why is 6 afraid of 7? …because 7 ate 9.',
  'Enter the classroom 15 minutes early for the first class of the day to ensure everything is working!',
  'You’re on to something great here…',
  'Keep up the great work!',
  'Thanks for all your hard work!',
  'Keep tabs on all the students!',
  'Check your Line groups!',
  'Be kind.',
  'The consequences of drowsy teaching: lower ratings, less classes. (Uh oh!)',
  'Take a break; teach awake!',
  'Students giving you a hard time? Don’t worry, each class is only 25 minutes!',
  'Look alive! You’ll be on camera!',
];

export default LoadingMessage;
