<template lang="pug">
b-modal(v-model="show" size="lg" centered hide-header hide-footer @change="initDataAndClose")
  template(v-if="step === 1")
    button.btn.btn-link.jr-home-main-color.p-0.position-absolute(style="top: -30px; left: 0;" @click="close") < 返回帳號選擇
    .rounded.my-3.mx-2.d-flex.flex-column.align-items-center.justify-content-center
      h3.jr-home-main-color.mt-3.mb-5 確認資料
      .ratio.ratio-1x1.bg-dark.d-flex.flex-column.justify-content-center.align-items-center.rounded(style="width: 130px;")
        img.w-100.rounded(v-if="payload.kid.student.photo" :src="payload.kid.student.photo" :alt="payload.kid.student.chinese_name")
        img.w-100(v-else src="https://cdn2.wuwow.tw/images/kidpic.png" :alt="payload.kid.student.chinese_name")
      h5.mb-0.mt-3.jr-text-dark-color {{ payload.kid.student.chinese_name }}
      h6.text-dark.mt-2 Level {{ payload.kid.student.level }}
      .tablet-w-50.mobile-w-100
        .form-group.w-100.mb-5.mt-3
          select.form-control.mb-3(v-model="relation")
            option(value="" disabled selected hidden) 請選擇關係
            option(
              v-for="(data, index) in relationCollection" :key="index"
              :value="data"
            ) {{ $t(`relation_of_.${data}`) }}
          input.form-control(
            v-if="relation === RELATION.OTHER"
            v-model="other"
            placeholder="例：伯父、叔叔、阿姨、姑姑等等.."
          )
        button.btn.btn-primary.btn-block(
          :disabled="isOtherEmpty"
          @click="sendChangeRelationRequest"
        ) 完成
        button.jr-home-main-color.btn.btn-link.btn-block(@click="step = 2")
          font-awesome-icon(icon="trash")
          |  移除帳號
  template(v-if="step === 2")
    h3.mt-4.jr-home-main-color.text-center 確定要移除此帳號嗎？
    button.btn.btn-link.jr-home-main-color.p-0.position-absolute(style="top: -30px; left: 0;" @click="close") < 返回帳號選擇
    .rounded.my-3.mx-2.d-flex.flex-column.align-items-center.justify-content-center(style="height: 300px;")
      .ratio.ratio-1x1.bg-dark.d-flex.flex-column.justify-content-center.align-items-center.rounded(style="width: 130px;")
        img.w-100.rounded(v-if="payload.kid.student.photo" :src="payload.kid.student.photo" :alt="payload.kid.student.chinese_name")
        img.w-100(v-else src="https://cdn2.wuwow.tw/images/kidpic.png" :alt="payload.kid.student.chinese_name")
      h5.mb-0.mt-2.jr-text-dark-color {{ payload.kid.student.chinese_name }}
      h6.mt-1.text-dark Level {{ payload.kid.student.level }}
      .text-center.mt-4.w-75
        button.w-25.btn.btn-outline-primary.mr-3.px-0(@click="step = 1") 取消
        button.w-25.btn.btn-primary.ml-3.px-0(@click="sendDeleteRelationRequest") 確認

</template>

<script>
import RELATION from '@/constants/relation';

import {
  mapState,
} from 'vuex';
import { v4 } from 'uuid';
import sweetalert from 'sweetalert2';
import qs from 'qs';

import modalMixin from '@/mixins/modal';

export const uuid = v4();

import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';

export default {
  mixins: [
    modalMixin,
  ],

  data: () => ({
    RELATION,
    step: 1,
    relation: '',
    other: '',
    relationCollection: [],
  }),
  computed: {
    isOtherEmpty() {
      const isOtherEmpty = this.relation === RELATION.OTHER && this.other === '';
      return isOtherEmpty;
    },
    ...mapState({
      token: (state) => state.auth.token,
      payload: (state) => state.modal.payload[uuid],
    }),
  },

  watch: {
    payload: {
      handler() {
        this.relation = this.payload.kid.parent.relation;
        this.other = this.payload.kid.parent.relation_other;
        this.relationCollection = this.payload.relation;
      },
      deep: true,
    },
  },

  created() {
    this.initModal(uuid, {
      status: false,
      payload: {
        relation: [],
        kid: {
          parent: {
            id: 0,
            relation: '',
            relation_other: '',
          },
          student: {
            chinese_name: '',
            english_name: '',
            level: 0,
          },
        },
      },
    });
  },

  methods: {
    initDataAndClose() {
      this.close();

      this.step = 1;
      this.relation = '';
      this.relationCollection = [];
    },
    async sendDeleteRelationRequest() {
      try {
        await axios.delete(
          `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/relation/${this.payload.kid.parent.id}`,
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
        this.initDataAndClose();
        this.$emit('when-delete-success');
      } catch (error) {
        await sweetalert({
          title: '錯誤',
          type: 'error',
        });
      }
    },
    async sendChangeRelationRequest() {
      const data = qs.stringify({
        relation_other: this.other,
        relation: this.relation,
        id: this.payload.kid.parent.id,
      });

      try {
        await axios.patch(
          `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/relation`,
          data,
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
        this.initDataAndClose();
        this.$emit('when-change-success');
      } catch (error) {
        await sweetalert({
          title: '錯誤',
          type: 'error',
        });
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '@/styles/parent/modal.scss';
@media only screen and (min-width: map-get($grid-breakpoints, 'sm')) {
  .tablet-w-50 {
    width: 50% !important;
  }
}
@media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
  .mobile-w-100 {
    width: 100% !important;
  }
}
.btn-outline-primary,.btn-primary {
  &:hover {
    color: #fff;
    background-color: #005BBA;
    border-color: #005BBA;
  }
}
</style>
