// 先以 file name + Class 命名搬至 API 專案需細分再重新命名
import accountClass from '@/api/src/account';
import contractClass from '@/api/src/contract';
import { getDate } from '@/utils/moment';
import packingParams from '@/utils/packingParams.js';
import axios from '../utils/axios-helper.js';
const account = new accountClass();
const contract = new contractClass();

export default class Vip {
  getUserUuid() {
    return account.getUserUuid();
  }
  /**
   * @returns {Promise} 可使用
   * @desc 取得帳號可使用功能權限
   */
  getAccountAuthority() {
    return account.getAccountAuthority();
  }
  /**
   * @returns {Promise} Promise object represents | level:學員等級, chinese_name:中文姓名, english_name:英文姓名
   * , nickname:綽號, gender:性別, photo:學員大頭照
   * @desc Get vip's base profile info
   */
  getBaseProfile() {
    return account.getBaseProfile();
  }
  /**
   * @returns {Promise} Promise object represents | level:學員等級, chinese_name:中文姓名, english_name:英文姓名
   * , nickname:綽號, gender:性別, photo:學員大頭照
   * @desc Get vip's base profile info
   */
  getVipPointSuggest() {
    return account.getVipPointSuggest();
  }
  /**
   * @returns {Promise} Promise object represents |
   * @desc Get vip's Coomplete profile info
   */
  getCompleteProfile() {
    return account.getCompleteProfile();
  }
  /**
   * @returns {Promise} Promise object represents | balance_sessions:剩餘堂數, contract_start:合約開始日,
   * contract_end:合約結束日
   * @desc Get vip's contract time
   */
  getContractElapsedTime() {
    return contract.getContractElapsedTime();
  }
  /**
   * @returns {Promise} Promise object represents | amount: 回饋堂數, month_amount:回饋月數, day_amount:回饋天數
   * @desc Get vip's feedback session amounts and days.
   */
  async getContractFeedbackQuota() {
    const result = await contract.getContractFeedbackQuota();
    let month_amount = result.data.feedback_quota.month_amount,
      day_amount = result.data.feedback_quota.day_amount;
    // 超過30天算1個月
    month_amount = month_amount + Math.floor(day_amount / 30);
    day_amount = day_amount % 30;
    result.data.feedback_quota.month_amount = month_amount;
    result.data.feedback_quota.day_amount = day_amount;
    return result;
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's contract detail info
   */
  getContractOwnHistory(query) {
    return contract.getContractOwnHistory(query);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Check vip's order product
   */
  checkOrderProduct(params) {
    return contract.checkOrderProduct(params);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Confirm vip's transfer product
   */
  confirmTransferProduct(params) {
    return contract.confirmTransferProduct(params);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Confirm vip's suspension product
   */
  confirmSuspensionProduct(params) {
    return contract.confirmSuspensionProduct(params);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's own bd
   */
  getOwnBD() {
    return account.getOwnBD();
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Patch vip's profile
   */
  updateProfile(params) {
    const photoKey = 'photo';
    const updateParams = {
      photo: params.profile.photo,
      email: params.profile.email,
      education: params.education.education,
      country: params.address.country,
      province: params.address.province,
      postal_code: params.address.postal_code,
      region: params.address.region,
      city: params.address.city,
      gender: params.profile.gender, // F | M
      birthday: getDate(params.profile.birthday), // xxxx-xx-xx
      learning_target: params.profile.learning_target,
      home_phone: params.profile.home_phone,
      work_phone: params.profile.work_phone,
      old_pw: params.oldPassword,
      pw: params.newPassword,
      pw_confirmation: params.passwordConfirmation,
    };
    const formData = new FormData();
    // eslint-disable-next-line
    for ( let key in updateParams ) {
      if (key === photoKey){
        if (updateParams[key].size){
          formData.append(key, updateParams[key]);
        }
      } else if (updateParams[key] !== '' && updateParams[key] !== null){
        formData.append(key, updateParams[key]);
      }
    }

    return account.updateProfile(formData);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's faq
   */
  getFaqContent(params) {
    return account.getFaqContent(params);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's analysis outline
   */
  getAnalysisOutline() {
    return account.getAnalysisOutline();
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's learning analysis
   */
  getLearningAnalysis() {
    return account.getLearningAnalysis();
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's material analysis
   */
  getMaterialAnalysis() {
    return account.getMaterialAnalysis();
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's level classes count
   */
  getLevelClassesCount() {
    return account.getLevelClassesCount();
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's challenge Info
   */
  getChallengeInfo() {
    return account.getChallengeInfo();
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's learning passion
   */
  getLearningPassion() {
    return account.getLearningPassion();
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's Level Certificate
   */
  getLevelCertificate(params) {
    return account.getLevelCertificate(params);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's consultant analysis of specific class
   */
  getConsultantAnalysis(params) {
    return account.getConsultantAnalysis(params);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's video record of specific class
   */
  getClassVideo(params) {
    return account.getClassVideo(params);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Get vip's one on one class attach file
   */
  getClassAttachFile(params) {
    return account.getClassAttachFile(params);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Send vip's evaluatoin data
   */
  sendEvaluationData(params) {
    return account.sendEvaluationData(params);
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Send vip's un evaluatoin class
   */
  getUnEvaluationClass() {
    return account.getUnEvaluationClass();
  }
  /**
   * @returns {Promise} Promise object represents
   * @desc Send vip's week class record
   */
  getVipClassRecordsWeekly() {
    return account.getVipClassRecordsWeekly();
  }
  /**
   * 學員顧問排課提醒狀態
   * @param hbConsultantIds: 顧問id
   */
  async getVipRemindConsultant(params) {
    const response = await axios({
      url: '/v3/message/vip-remind-consultant',
      method: 'get',
      params,
      headers: { 'Content-Type': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 學員新增顧問排課提醒
   * @param hbConsultantIds: 顧問id
   */
  async createVipRemindConsultant(data) {
    const response = await axios({
      url: 'v3/message/vip-remind-consultant',
      method: 'post',
      data: packingParams(data),
      headers: { 'Content-Type': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 學員新增顧問排課提醒
   * @param hbConsultantIds: 顧問id
   */
  async delVipRemindConsultant(id) {
    const response = await axios({
      url: `v3/message/vip-remind-consultant/${id}`,
      method: 'delete',
      headers: { 'Content-Type': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得 token 資訊
   * @returns {Promise<Array>} 回傳身分、uuid、oldId
   */
  async getAccountTokenInformation() {
    const response = await axios({
      url: 'v3/lst-oauth/authorization/token-information',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }

  async getContractIdentity() {
    return contract.getContractIdentity();
  }
}
