const types = {
  SET_NOTIFICATION_PUSH_DEVICE_DATAS: 'SET_NOTIFICATION_PUSH_DEVICE_DATAS',
  CURRENT_NOTIFICATION_INFO: 'CURRENT_NOTIFICATION_INFO',
};

const state = {
  notificationPushDeviceDatas: [],
  currentNotificationInfo: {},
};

const mutations = {
  [types.SET_NOTIFICATION_PUSH_DEVICE_DATAS]: (state, data) => {
    state.notificationPushDeviceDatas = data;
  },

  [types.CURRENT_NOTIFICATION_INFO]: (state, data) => {
    state.currentNotificationInfo = data;
  },
};

const actions = {
  async setNotificationPushDeviceDatas({ commit }, data) {
    await commit(types.SET_NOTIFICATION_PUSH_DEVICE_DATAS, data);
  },

  setCurrentNotificationInfo({ commit }, data) {
    commit(types.CURRENT_NOTIFICATION_INFO, data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
