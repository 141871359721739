export default {
  INFO: {
    PRODUCT_NAME: 'WUWOW Jr',
    ADDRESS_1: '25F-2, No.360,Sec. 2, Taiwan Blvd.,',
    ADDRESS_2: 'No. 971, Sec. 2, Gongyi Rd., Nantun Dist., Taichung City',
    CONTACT_EMAIL: 'service@wuwowjr.tw',
    // CONTACT_PHONE: '(04)36098585',
    CONTACT_PHONE: '(04)37048989',
    LINE_ID: '@wuwowcs',
    LINE_URL: 'http://line.me/ti/p/~@wuwowcs',
    LINE_AT_ADD_URL: 'https://lin.ee/fsvIs3c',
    PM_EMAIL: 'jrpm@lioshutan.com',
    TS_EMAIL: 'ts@lioshutan.com',
    SERVICE_EMAIL: 'service@wuwowjr.tw',
    PM_NUMBER: '04-2323-6666 #301',
    // TS_NUMBER: '04-3609-8585',
    TS_NUMBER: '04-3704-8989',
    // SERVICE_NUMBER: '04-3609-8585',
    SERVICE_NUMBER: '04-3704-8989',
    JOIN_US_URL: 'https://www.104.com.tw/company/1a2x6bj1sw',
    COOPERATION_URL: 'service@wuwow.tw',
    PM_RECRUITMENT_URL: `${process.env.VUE_APP_LIOSHUTAN_OFFICIAL_URL}recruitment/pm_recruitment`,
  },
  MEDIA: {
    FB: {
      NAME: 'FB',
      URL: 'https://www.facebook.com/WUWOW.Junior',
    },
    MEDIUM: {
      NAME: 'MEDIUM',
      URL: 'https://medium.com/wuwow-%E9%83%A8%E8%90%BD%E6%A0%BC',
    },
    INSTAGRAM: {
      NAME: 'IG',
      URL: 'https://www.instagram.com/wuwow.online/',
    },
    YOUTUBE: {
      NAME: 'YOUTUBE',
      URL: 'https://www.youtube.com/channel/UCq5Pa-U6pMVrL3-5T17Y3PA',
    },
  },
};
