import { changeCamelCase, setDafault } from '@/utils/changFormat';
import lioshutanApi from '@/api';
import moment from 'moment';

const types = {
  SET_BOOK_CLASS_DATA_UPDATE: 'SET_BOOK_CLASS_DATA_UPDATE',
  SET_BOOK_CANLENDAR_DATA_UPDATE: 'SET_BOOK_CANLENDAR_DATA_UPDATE',
};

const defaultData = {
  contractType: '', // 合約類型(owner: 主約、receive:承接)
  createdAt: '',
  customizeClassesCompletionAt: '',
  customizeClassesCompletionStatus: '', // 客製化堂課程狀態 passed running faild
  customizeTips: '', // 訂刻畫面的提示
  failedAt: '', // 挑戰失敗時間
  failedWith: '',
  fortyClassesCompletionAt: '', // T1~上滿40堂活動結束狀態
  fortyClassesCompletionStatus: '', // T1~上滿40堂活動結束 passed running faild
  id: '',
  missionOneAt: '', // step 1 課程完成時間
  missionOneStatus: '', // step1 T1~T30 20堂課程狀態 passed running faild
  missionTwoAt: '', // step2 課程完成時間
  missionTwoStatus: '', // step2 T31~T60 20堂課程狀態 passed running faild
  note: '',
  passedAt: '', // 挑戰成功時間
  tips: '', // 小提示
  updatedAt: '',
  vipId: '',
};

const state = {
  summerRewardEventInfo: JSON.parse(localStorage.getItem('summerRewardEventInfo')) || defaultData,
  option: [],
  bookClassDataUpdate: false,
  bookcanlendarDataUpdate: false,
  hours24NotShow: '' || localStorage.getItem('hours24NotShow'),
  todayShowOnce: false || JSON.parse(localStorage.getItem('todayShowOnce')),
  bvModal: null,
  teacherWritedCustomize: false,
};

const mutations = {
  setSummerRewardEventInfo(state, SummerRewardEventInfo) {
    state.summerRewardEventInfo = { ...changeCamelCase(setDafault(SummerRewardEventInfo)) };
  },
  OPTIONS(state, data) {
    state.option = data;
  },
  TODAYSHOWONCE(state, data) {
    localStorage.setItem('todayShowOnce', JSON.stringify(data));
    state.todayShowOnce = data;
  },
  BVMODAL(state, data) {
    state.bvModal = data;
  },
  TEACHERWRITEDCUSTOMIZE(state, data) {
    state.teacherWritedCustomize = data;
  },
  [types.SET_BOOK_CLASS_DATA_UPDATE]: (state, bookClassDataUpdate) => {
    state.bookClassDataUpdate = bookClassDataUpdate;
  },
  [types.SET_BOOK_CANLENDAR_DATA_UPDATE]: (state, bookcanlendarDataUpdate) => {
    state.bookcanlendarDataUpdate = bookcanlendarDataUpdate;
  },
};

const actions = {
  async getSummerRewardOption({ commit, dispatch }) {
    try {
      const res = await lioshutanApi.summerCamp.getSummerRewardOption();
      commit('OPTIONS', changeCamelCase(res.data.data).summerCampOption);
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async setSummerRewardApply({ commit, dispatch }, data) {
    try {
      const res = await lioshutanApi.summerCamp.setSummerRewardApply(data);
      return res;
    } catch (e) {
      console.log(e);
    }
  },
  async getSummerRewardEventInfo({ commit, dispatch }) {
    try {
      const data = await lioshutanApi.summerCamp.getSummerRewardEventInfo();
      await commit('setSummerRewardEventInfo', data.data.data.summerRewardInfo);
      dispatch('setLocalStorage', data.data.data.summerRewardInfo);
    } catch (e) {
      console.log(e);
      commit('setSummerRewardEventInfo', defaultData);
      dispatch('setLocalStorage', '');
    }
  },
  async getSummerRewardEventMilestones({ commit, dispatch }) {
    try {
      const data = await lioshutanApi.summerCamp.getSummerRewardEventMilestones();
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async getBookingClassData({ commit, dispatch }, params) {
    try {
      const { data } = await lioshutanApi.summerCamp.getBookingClassData(params);
      // commit('TEACHERWRITEDCUSTOMIZE', data.status);
      return changeCamelCase(data);
    } catch (e) {
      console.log(e);
    }
  },
  async getRewardEventOverallPerformance({ commit, dispatch }) {
    try {
      const { data } = await lioshutanApi.summerCamp.getRewardEventOverallPerformance();
      console.log(data);
      commit('TEACHERWRITEDCUSTOMIZE', data.data.status);
      return changeCamelCase(data);
    } catch (e) {
      console.log(e);
    }
  },
  setLocalStorage({ commit }, data) {
    window.localStorage.setItem('summerRewardEventInfo', JSON.stringify(data));
  },
  summerNotice({ commit, state, dispatch }, data) {
    const today = moment().format('YYYY-MM-DD');
    const passed = state.summerRewardEventInfo.passedAt !== '';
    const failed = state.summerRewardEventInfo.failedAt !== '';
    const fortyClassesCompletionStatus = state.summerRewardEventInfo.fortyClassesCompletionStatus === 'passed' && state.summerRewardEventInfo.customizeClassesCompletionStatus === 'running';
    // customize 如果是 true
    if (state.todayShowOnce && !fortyClassesCompletionStatus) {
      return;
    }
    if (fortyClassesCompletionStatus && state.hours24NotShow === today) {
      // 如果正在挑戰 40天任務，但勾選了不在顯示，就不要談窗
      return;
    }
    if ([passed, failed, fortyClassesCompletionStatus].some(item => item)) {
      dispatch('openSummerCampModal');
    }
  },
  openSummerCampModal({ commit, state }) {
    state.bvModal.show('summerCampModal');
    commit('TODAYSHOWONCE', true);
  },
  closeSummerCampModal({ state }) {
    state.bvModal.hide('summerCampModal');
  },
  setModalDom({ commit }, data) {
    commit('BVMODAL', data);
  },
  setTodayShowOnce({ commit }, data) {
    commit('TODAYSHOWONCE', data);
  },
  async setBookClassDataUpdate({ commit }, data) {
    await commit(types.SET_BOOK_CLASS_DATA_UPDATE, data);
  },
  async setBookcanlendarDataUpdate({ commit }, data) {
    await commit(types.SET_BOOK_CANLENDAR_DATA_UPDATE, data);
  },
};

const getters = {
  getSummerRewardEventInfo(state) {
    return state.summerRewardEventInfo;
  },
  isUnboughtSummerCamp(state) {
    return Object
      .keys(state.summerRewardEventInfo)
      .every((key) => state.summerRewardEventInfo[key] === '');
  },
  fortyClassesCompletionStatus(state) {
    return state.summerRewardEventInfo.fortyClassesCompletionStatus === 'passed' && state.summerRewardEventInfo.customizeClassesCompletionStatus === 'running';
  },
  fortyClassesCompletionBtn(state) {
    const states = ['passed', 'running', 'faild'];
    return states.includes(state.summerRewardEventInfo.customizeClassesCompletionStatus);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
