var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-block d-md-none"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center mx-4"
  }, [_c('h3', {
    staticClass: "mobile-title mt-4 mb-2 text-lioshutan"
  }, [_vm._v("「語言的學習，來自於生活環境的培養。」")]), _vm._m(0), _c('img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.file.englishBG
    }
  })])]), _c('div', {
    staticClass: "d-none d-md-block d-lg-none"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center mx-4"
  }, [_vm._m(1), _c('img', {
    staticClass: "mt-4 w-75",
    attrs: {
      "src": _vm.file.englishBG
    }
  })])]), _c('div', {
    staticClass: "d-none d-md-none d-lg-block"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_vm._m(2), _c('img', {
    staticClass: "w-50",
    staticStyle: {
      "margin-top": "80px"
    },
    attrs: {
      "src": _vm.file.englishBG
    }
  })])]), _c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "bg-img pt-5"
  }, [_vm._m(3), _vm._m(4), _vm._m(5), _c('material'), _c('training')], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mt-4 mb-5 area-text"
  }, [_vm._v("對孩子來說，學習應是快樂且充滿成就感的， 抱著這樣的初衷，我們結合"), _c('span', {
    staticClass: "text-lioshutan"
  }, [_vm._v("WUWOW 線上英文")]), _vm._v("的成功經驗，以及 John M. Keller 教授所提出的"), _c('span', {
    staticClass: "text-lioshutan"
  }, [_vm._v("ARCS 動機學習模式")]), _vm._v("細心地開發出 WUWOW Jr 的課程平台。")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-75 d-flex flex-column align-items-center"
  }, [_c('h3', {
    staticClass: "my-4 text-lioshutan"
  }, [_vm._v("「語言的學習，來自於生活環境的培養。」")]), _c('p', {
    staticClass: "my-4 area-text"
  }, [_vm._v("對孩子來說，學習應是快樂且充滿成就感的， 抱著這樣的初衷，我們結合"), _c('span', {
    staticClass: "text-lioshutan"
  }, [_vm._v(" WUWOW 線上英文")]), _vm._v("的成功經驗，以及 John M. Keller 教授所提出的"), _c('span', {
    staticClass: "text-lioshutan"
  }, [_vm._v("ARCS 動機學習模式")]), _vm._v("細心地開發出 WUWOW Jr 的課程平台。")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-50 d-flex flex-column align-items-center"
  }, [_c('h3', {
    staticClass: "my-3 content-title text-lioshutan"
  }, [_vm._v("「語言的學習，來自於生活環境的培養。」")]), _c('p', {
    staticClass: "my-4 content-text"
  }, [_vm._v("對孩子來說，學習應是快樂且充滿成就感的， 抱著這樣的初衷，我們結合"), _c('span', {
    staticClass: "text-lioshutan mx-1"
  }, [_vm._v("WUWOW 線上英文")]), _vm._v("的成功經驗，以及 John M. Keller 教授所提出的"), _c('span', {
    staticClass: "text-lioshutan mx-1"
  }, [_vm._v("ARCS 動機學習模式")]), _vm._v("細心地開發出 WUWOW Jr 的課程平台。")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-block d-md-none"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center mb-5 mx-4"
  }, [_c('p', {
    staticClass: "area-english-title text-light"
  }, [_vm._v(":: Comparison ::")]), _c('h3', {
    staticClass: "area-title text-light"
  }, [_vm._v("與 WUWOW 有什麼差別呢？")]), _c('p', {
    staticClass: "area-text text-light"
  }, [_vm._v("雖然 WUWOW 已經是一個有效且經過驗證的教學平台，但我們知道，成人英文和兒童英文是兩種截然不同的領域，也因此，WUWOW Jr 除了導入 WUWOW 線上英文的精選全球師資、優勢學習系統之外，更針對台灣學子設計了全新教材、教學方式，為的就是帶給孩子最好的學習體驗，也提供家長一個最安心的英文學習平台。")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-none d-md-block d-lg-none px-5"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center mb-5 mx-4"
  }, [_c('p', {
    staticClass: "area-english-title text-light"
  }, [_vm._v(":: Comparison ::")]), _c('h3', {
    staticClass: "area-title text-light"
  }, [_vm._v("與 WUWOW 有什麼差別呢？")]), _c('p', {
    staticClass: "area-text text-light mt-3"
  }, [_vm._v("雖然 WUWOW 已經是一個有效且經過驗證的教學平台，但我們知道，成人英文和兒童英文是兩種截然不同的領域，也因此，WUWOW Jr 除了導入 WUWOW 線上英文的精選全球師資、優勢學習系統之外，更針對台灣學子設計了全新教材、教學方式，為的就是帶給孩子最好的學習體驗，也提供家長一個最安心的英文學習平台。")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-none d-md-none d-lg-block"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center mb-5"
  }, [_c('p', {
    staticClass: "area-english-title text-light"
  }, [_vm._v(":: Comparison ::")]), _c('h2', {
    staticClass: "area-title text-light mb-4"
  }, [_vm._v("與 WUWOW 有什麼差別呢？")]), _c('p', {
    staticClass: "area-text between-text text-light text-left"
  }, [_vm._v("雖然 WUWOW 已經是一個有效且經過驗證的教學平台，但我們知道，成人英文和兒童英文是兩種截然不同的領域，也因此，WUWOW Jr 除了導入 WUWOW 線上英文的精選全球師資、優勢學習系統之外，更針對台灣學子設計了全新教材、教學方式，為的就是帶給孩子最好的學習體驗，也提供家長一個最安心的英文學習平台。")])])]);

}]

export { render, staticRenderFns }