var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "nav"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        path: '/console/profiles'
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'user']
    }
  }), _vm._v(_vm._s(_vm.$t('profile')))], 1)], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('span', {
    staticClass: "nav-link"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'calendar']
    }
  }), _vm._v(_vm._s(_vm.$t('schedule')))], 1), _c('ul', {
    staticClass: "subnav"
  }, _vm._l(_vm.scheduleLists, function (item) {
    return _c('li', {
      key: item.name,
      staticClass: "subnav-item"
    }, [_c('router-link', {
      staticClass: "dropdown-item subnav-link",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))])], 1);
  }), 0)]), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        path: '/console/consultant/classRecords'
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "list"
    }
  }), _vm._v(_vm._s(_vm.$t('classRecords')))], 1)], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        path: '/console/classroom'
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "cloud"
    }
  }), _vm._v(_vm._s(_vm.$t('cloudClassroom')))], 1)], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        path: '/console/classroom/vip-requests'
      }
    }
  }, [_c('font-awesome-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "child"
    }
  }), _vm._v(_vm._s(_vm.$t('classRequest'))), _vm.vipNoReplyRequestsAmount > 0 ? _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.vipNoReplyRequestsAmount))]) : _vm._e()], 1)], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('span', {
    staticClass: "nav-link"
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": ['far', 'comment']
    }
  }), _vm._v(_vm._s(_vm.$t('training')))], 1), _c('ul', {
    staticClass: "subnav"
  }, _vm._l(_vm.trainingItems, function (item) {
    return _c('li', {
      key: item.item,
      staticClass: "subnav-item"
    }, [_c('a', {
      staticClass: "subnav-link",
      attrs: {
        "href": item.path,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.item))])]);
  }), 0)]), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticStyle: {
      "height": "40px"
    }
  }, [_c('p')]);

}]

export { render, staticRenderFns }