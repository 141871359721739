<template lang="pug">
  web-socket-info-modal(ref="web-socket-info-modal" :socketData="socketData")
</template>

<script>
import webSocketInfoModal from '@/components/notifyModal/webSocketInfoModal/index.vue';
import socketConstants from '@/constants/socket.js';
import storage from '@/utils/localStorage';
import api from '@lioshutan/api-package';
// import socket from '@/api/src/webSocket';

export default {
  name: 'SocketHandle',
  components: {
    webSocketInfoModal,
  },

  data(){
    return {
      socket: null,
      socketData: null,
    };
  },

  async created(){
    storage.token !== '' && storage.token !== 'null' ? await this.connetionToSocket() : '';
  },

  beforeDestroy() {
    // 離開後關閉socket連線
    this.closeWebSocket();
  },

  methods: {
    async connetionToSocket(){
      // new 新的 user socket
      this.socket = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .socket(storage.token, process.env.VUE_APP_BASE_SOCKET_URL);
      // 取得訊息(當後端傳訊息過來就執行)
      this.socket.onMessage(this.getSocketMessage);
    },

    getSocketMessage(message){
      const accessClassroomModal = this.$refs['web-socket-info-modal'].$refs[socketConstants.MODAL_REF.ACCESS_CLASSROOM];
      accessClassroomModal.hide();
      this.socketData = message;
      accessClassroomModal.show();
    },

    closeWebSocket(){
      this.socket.disconnect();
      // 清空socket與data
      this.socket = null;
      this.socketData = null;
    },

  },
};
</script>
