var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "nav"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        path: '/dojo/classRoom'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('auditClass')))])], 1), _vm.getCanOrderClass ? _c('li', {
    staticClass: "nav-item"
  }, [_c('div', {
    staticClass: "nav-link"
  }, [_vm._v(_vm._s(_vm.$t('bookClass')))]), _c('ul', {
    staticClass: "subnav"
  }, [_c('span', {
    staticClass: "subnav-link subnav-sort"
  }, [_vm._v(_vm._s(_vm.$t('book')))]), _vm._l(_vm.bookLists, function (item) {
    return _c('li', {
      key: item.name,
      staticClass: "subnav-item"
    }, [_c('router-link', {
      staticClass: "dropdown-item subnav-link",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))])], 1);
  }), _c('span', {
    staticClass: "subnav-link subnav-sort"
  }, [_vm._v(_vm._s(_vm.$t('setRecord')))]), _vm._l(_vm.setRecordLists, function (item) {
    return item.authorityTag ? _c('li', {
      key: item.name,
      staticClass: "subnav-item"
    }, [_c('router-link', {
      staticClass: "dropdown-item subnav-link",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))])], 1) : _vm._e();
  })], 2)]) : _vm._e(), _c('li', {
    staticClass: "nav-item"
  }, [_c('div', {
    staticClass: "nav-link"
  }, [_vm._v(_vm._s(_vm.$t('classRecord')))]), _c('ul', {
    staticClass: "subnav"
  }, _vm._l(_vm.classRecordLists, function (item) {
    return _c('li', {
      key: item.name,
      staticClass: "subnav-item"
    }, [item.authorityTag && item.name !== 'learnRecords' ? _c('router-link', {
      staticClass: "dropdown-item subnav-link",
      attrs: {
        "to": {
          path: item.path
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]) : _vm._e(), item.authorityTag && item.name === 'learnRecords' ? _c('a', {
      staticClass: "dropdown-item subnav-link",
      attrs: {
        "href": `${_vm.newJrUrl}${item.path}`
      }
    }, [_vm._v(_vm._s(_vm.$t(item.name)))]) : _vm._e()], 1);
  }), 0)]), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        path: '/dojo/vip_mgm'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('vipMgm')))])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    attrs: {
      "to": {
        path: '/dojo/guide'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('guide')))])], 1), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticStyle: {
      "height": "40px"
    }
  }, [_c('p')]);

}]

export { render, staticRenderFns }