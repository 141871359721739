var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "main-box",
    attrs: {
      "id": "敦煌教材"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "main-content"
  }, [_vm._m(1), _c('div', {
    staticClass: "main-right"
  }, [_c('div', {
    staticClass: "main-right-content"
  }, [_c('img', {
    staticClass: "main-right-img",
    attrs: {
      "src": require("@/assets/material_2023/video.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.youtubeModal(_vm.materialVideo);
      }
    }
  })])])]), _c('div', {
    staticClass: "bookcaseWrap"
  }, [_c('div', {
    staticClass: "bookcase-first"
  }, [_c('h3'), _c('ul', {
    staticClass: "cursor-pointer bookcase-books-top",
    on: {
      "click": function ($event) {
        return _vm.openMaterialModal('modalContentTwo');
      }
    }
  }, [_c('li', [_c('img', {
    staticClass: "bookcase-book-top",
    attrs: {
      "src": require('@/assets/material_2023/book1.jpg')
    }
  })]), _c('li', [_c('img', {
    staticClass: "bookcase-book-top",
    attrs: {
      "src": require('@/assets/material_2023/book2.jpg')
    }
  })]), _c('li', [_c('img', {
    staticClass: "bookcase-book-top",
    attrs: {
      "src": require('@/assets/material_2023/book3.jpg')
    }
  })]), _c('li', [_c('img', {
    staticClass: "bookcase-book-top",
    attrs: {
      "src": require('@/assets/material_2023/book4.jpg')
    }
  })]), _c('li', [_c('img', {
    staticClass: "bookcase-book-top",
    attrs: {
      "src": require('@/assets/material_2023/book5.jpg')
    }
  })]), _c('li', [_c('img', {
    staticClass: "bookcase-book-top",
    attrs: {
      "src": require('@/assets/material_2023/book6.jpg')
    }
  })])])]), _c('div', {
    staticClass: "bookcase-two"
  }, [_c('h3'), _c('ul', {
    staticClass: "cursor-pointer bookcase-books-bottom",
    on: {
      "click": function ($event) {
        return _vm.openMaterialModal('modalContentOne');
      }
    }
  }, [_c('li', [_c('img', {
    staticClass: "bookcase-book-bottom",
    attrs: {
      "src": require('@/assets/material_2023/book2-1.jpg')
    }
  })]), _c('li', [_c('img', {
    staticClass: "bookcase-book-bottom",
    attrs: {
      "src": require('@/assets/material_2023/book2-2.jpg')
    }
  })]), _c('li', [_c('img', {
    staticClass: "bookcase-book-bottom",
    attrs: {
      "src": require('@/assets/material_2023/book2-3.jpg')
    }
  })]), _c('li', [_c('img', {
    staticClass: "bookcase-book-bottom",
    attrs: {
      "src": require('@/assets/material_2023/book2-4.jpg')
    }
  })])])])])]), _c('transition', [_vm.material ? _c('div', {
    staticClass: "reasonModal",
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        _vm.material = false;
      }
    }
  }, [_c('div', {
    staticClass: "material-content"
  }, [_c('div', {
    staticClass: "material-dialog"
  }, [_c('span', {
    staticClass: "reason-close",
    on: {
      "click": function ($event) {
        _vm.material = false;
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "30",
      "height": "30",
      "viewbox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z",
      "fill": "white"
    }
  })])]), _vm.materialContent === 'modalContentOne' ? [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/modalContent-1.png')
    }
  })] : [_c('img', {
    attrs: {
      "src": require('@/assets/material_2023/modalContent-2.svg')
    }
  })]], 2)])]) : _vm._e()]), _c('youtube-modal', {
    attrs: {
      "src": _vm.youtubeUrl
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "class-title"
  }, [_c('span', {
    staticClass: "first-title"
  }, [_vm._v("::Textbook ::"), _c('br'), _vm._v("課程教材")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-left"
  }, [_c('div', {
    staticClass: "main-left-content"
  }, [_c('div', {
    staticClass: "left-icon"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/material_2023/Dunhuang.svg")
    }
  }), _c('img', {
    staticClass: "close-icon",
    attrs: {
      "src": require("@/assets/material_2023/close.svg")
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/material_2023/Caves.svg")
    }
  })]), _c('div', {
    staticClass: "line"
  }), _c('div', {
    staticClass: "left-content"
  }, [_c('span', [_vm._v("與深耕70年外語教育的敦煌教育集團合作，選用 CAVES KIDS 教材，主題內容多元豐富，涵蓋歷史、科技、藝術...，課後提供仿照劍橋兒童英檢題型及練習音檔，按部就班紮實學英文，同時跨領域培養孩子們 21世紀所需的知識能力與素養。")])])])]);

}]

export { render, staticRenderFns }