<template lang="pug">
div
  add-children-modal(
    @when-create-success="whenCreateSuccess"
  )
  edit-children-modal(
    @when-delete-success="whenDeleteSuccess"
    @when-change-success="whenChangeSuccess"
  )
  template(v-if="showMessage")
    .d-flex.justify-content-center.align-items-center.mt-5
      .message.text-center.bg-light.rounded-pill.w-75
        p.my-2 {{ message }}
  .text-center.my-5
    h3.jr-home-main-color 想關注哪位孩子的學習？
  .container
    .row
      .col-sm-4.col-md-3.col-6.px-0
        .kids-card
          .bg-light.rounded.d-flex.flex-column.justify-content-center.align-items-center(
            style="height: 300px;cursor: pointer;"
            @click="openAddChildrenModal"
          )
            .mb-3.d-flex.justify-content-center.align-items-center.w-50.ratio.ratio-1x1(
              style="border-width: 5px !important; border-color: black !important;"
            )
              img.add-kid(src="../../assets/login/add_kid_border.png")
              img.add-kid(src="../../assets/login/add_kid_icon.png")
            .card-title.jr-main-color 新增帳號
      .col-sm-4.col-md-3.col-6.px-0(v-for="(kid, index) in children" :key="`kid_${index}`")
        .kids-card
          .bg-light.rounded.d-flex.flex-column.align-items-center.justify-content-center(
            style="height: 300px;cursor: pointer;"
            @click="enterPlatform(kid)"
          )
            .ratio.ratio-1x1.bg-dark.d-flex.flex-column.justify-content-center.align-items-center.w-75
              img.w-100(
                v-if="kid.student.photo"
                :src="kid.student.photo"
                :alt="kid.student.chinese_name"
              )
              img.w-100(
                v-else
                src="https://cdn2.wuwow.tw/images/kidpic.png"
                :alt="kid.student.chinese_name"
              )
            .card-title.mb-0.mt-3.jr-main-color {{ kid.student.chinese_name }}
            h6.text-dark.mt-2 Level {{ kid.student.level }}
        .text-center
          button.btn-edit.btn.border-0(
            @click="openEditChildrenModal(kid)"
          )
            font-awesome-icon(icon="pen")
            |  編輯帳號
</template>

<script>
import sweetalert from 'sweetalert2';
import {
  mapActions,
  mapMutations,
} from 'vuex';
import {
  MODAL as MODAL_STORE,
} from '@/store/index';
import {
  SET_IS_OPEN, SET_PAYLOAD,
} from '@/store/modules/modal';
import axios from 'axios';
import addChildrenModal, {
  uuid as ADD_CHILDREN_MODAL_UUID,
} from './addChildrenModal/index.vue';
import editChildrenModal, {
  uuid as EDIT_CHILDREN_MODAL_UUID,
} from './editChildrenModal/index.vue';
import commonConstants from '@/constants/common';
import lioshutanApi from '@/api';

import {
  mapState,
} from 'vuex';

export default {
  components: {
    addChildrenModal,
    editChildrenModal,
  },
  data: () => ({
    showMessage: false,
    message: '',
    children: [],
    relation: [],
  }),
  computed: {
    ...mapState({
      token: (state) => state.auth.token,
    }),
  },

  async created() {
    this.getUrlInfo();
    document.querySelector('body').classList.add('layout-lioshutan');
    await this.loadChildren();
  },

  methods: {
    showUpAndShowOff(message) {
      this.showMessage = true;
      this.message = message;
      setTimeout(() => {
        console.log('done');
        this.showMessage = false;
      }, 3000);
    },
    async loadChildren() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_API}/v3/human-resource/parent/management`,
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
        // filter 當下不是這個平台的人
        this.children = response.data.data.management.filter((kid) => kid?.student?.platform === 2);
        // this.children = Array(100).fill(response.data.data.management[0]);
        this.relation = response.data.data.relationEnum;
      } catch (error) {
        await sweetalert({
          title: '錯誤<br>fda62ef3-58e5-4c01-82b0-97ef32c58cb3',
          type: 'error',
        });
      }
    },
    async whenCreateSuccess() {
      await this.loadChildren();
    },
    async whenDeleteSuccess() {
      await this.loadChildren();
      this.showUpAndShowOff('已成功移除');
    },
    async whenChangeSuccess() {
      await this.loadChildren();
      this.showUpAndShowOff('已成功編輯');
    },
    ...mapActions({
      enterPlatform(action, kid){
        console.log(kid);
        action('auth/setRole', commonConstants.ROLE.PARENT);
        action('parents/setVipId', kid.student.id);
        this.setLinkTo();
      },
    }),
    ...mapMutations({
      openAddChildrenModal(commit) {
        commit(`${MODAL_STORE}/${SET_IS_OPEN}`, {
          target: ADD_CHILDREN_MODAL_UUID,
          status: true,
        });
      },
      openEditChildrenModal(commit, kid) {
        console.log({ kid, relation: this.relation });
        commit(`${MODAL_STORE}/${SET_IS_OPEN}`, {
          target: EDIT_CHILDREN_MODAL_UUID,
          status: true,
        });
        commit(`${MODAL_STORE}/${SET_PAYLOAD}`, {
          target: EDIT_CHILDREN_MODAL_UUID,
          payload: { kid, relation: this.relation },
        });
      },
    }),
    async setAccountAuthorityToLocalStorage() {
      const accountAuthority = await lioshutanApi.vip.getAccountAuthority();
      // const accountAuthorityArray = accountAuthority.data.data.authority;
      const accountRole = accountAuthority.data.data.role;
      this.$store.dispatch('auth/setRole', accountRole);
      this.setUseLang(accountRole);
    },
    directToNewJr() {
      const url = new URL(window.location);
      const a = document.createElement('a');
      a.setAttribute('href', `${url.origin}/student/`);
      a.click();
    },
    setLinkTo() {
      // localStorage/'wuwow-junior-link'=>如果被401導出去 會記錄當前頁面
      // 家長選完要用的小孩身份後導頁 先檢查localStorage有沒有紀錄的頁面
      // 有的話導至該頁並清除localStorage 沒有的話導至/dojo
      const linkTo = localStorage.getItem('wuwow-junior-link');
      if (linkTo) {
        this.$router.push({ path: linkTo });
        localStorage.removeItem('wuwow-junior-link');
      } else {
        this.directToNewJr(); // 家長登入到新後台
        // this.$router.push({ path: '/dojo' });
      }
    },
    getUrlInfo() {
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);
      const token = params.get('wuwow_token');
      const role = params.get('account_role');
      if (token) {
        this.$store.dispatch('auth/setToken', token);
      }
      if (role) {
        this.$store.dispatch('auth/setRole', role);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {
  .col-6 {
    // background-color: green;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
  // .container {
  //   width: 100%;
  //   max-width: 100%;
  // }
}
.card-title {
  font-size: 17px;
  letter-spacing: 0.1em;
}
.ratio {
  position: relative;
  width: 100%;
}

.ratio-1x1 {
  aspect-ratio: 1 / 1;
}

.rounded {
  border-radius: var( --card-border-radius ) !important;
}
.add-kid {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.kids-card {
  border: 2px solid #ffffff00;
  border-radius: 10px;
  padding: 10px;
  margin: 15px 0;
  &:hover {
    border-color: var( --light-color );
    transition: .5s;
  }
}
.message {
  opacity: 0.6;
}
.btn-edit {
  font-size: 15px;
  color: var( --light-color );
  letter-spacing: 0.1em;
  &:hover {
    color: var( --home-main-color );
  }
}
</style>
