var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-lioshutan py-4"
  }, [_c('div', {
    staticClass: "d-block d-md-none py-4"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    on: {
      "click": function ($event) {
        return _vm.changeCharacter(_vm.CHARACTER.CHILDREN);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("學員登入")])]), _c('a', {
    staticClass: "text-decoration-none",
    on: {
      "click": function ($event) {
        return _vm.changeCharacter(_vm.CHARACTER.PARENT);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("家長登入")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": `mailto:${_vm.cooperationUrl}`,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("合作提案")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.joinUsUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("夥伴招募")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.pmRecruitmentUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("成為老師")])])]), _c('div', {
    staticClass: "text-light text-center contact"
  }, [_c('h5', [_vm._v("聯絡我們")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("Tel: 0800-885315")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "mailto:service@wuwowjr.tw"
    }
  }, [_vm._v("service@wuwowjr.tw")]), _c('div', {
    staticClass: "mt-4 d-flex justify-content-center"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "https://www.facebook.com/WUWOW.Junior",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "mr-1 init",
    attrs: {
      "src": _vm.file.fbSVG,
      "width": "50",
      "height": "50"
    }
  }), _c('img', {
    staticClass: "mr-1 hover",
    attrs: {
      "src": _vm.file.fbHoverSVG,
      "width": "50",
      "height": "50"
    }
  }), _c('p', [_vm._v("wuwow jr")])])])]), _vm._m(0), _vm._m(1)]), _c('div', {
    staticClass: "d-none d-md-block d-lg-none"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row py-4"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('a', {
    staticClass: "text-decoration-none",
    on: {
      "click": function ($event) {
        return _vm.changeCharacter(_vm.CHARACTER.CHILDREN);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("學員登入")])]), _c('a', {
    staticClass: "text-decoration-none",
    on: {
      "click": function ($event) {
        return _vm.changeCharacter(_vm.CHARACTER.PARENT);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("家長登入")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": `mailto:${_vm.cooperationUrl}`,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("合作提案")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.joinUsUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("夥伴招募")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.pmRecruitmentUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("成為老師")])])]), _c('div', {
    staticClass: "text-light text-center contact"
  }, [_c('h5', [_vm._v("聯絡我們")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("Tel: 0800-885315")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "mailto:service@wuwowjr.tw"
    }
  }, [_vm._v("service@wuwowjr.tw")]), _c('div', {
    staticClass: "mt-4 d-flex justify-content-center"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "https://www.facebook.com/WUWOW.Junior",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "mr-1 init",
    attrs: {
      "src": _vm.file.fbSVG,
      "width": "50",
      "height": "50"
    }
  }), _c('img', {
    staticClass: "mr-1 hover",
    attrs: {
      "src": _vm.file.fbHoverSVG,
      "width": "50",
      "height": "50"
    }
  }), _c('p', [_vm._v("wuwow jr")])])])])]), _vm._m(2)])])]), _c('div', {
    staticClass: "d-none d-md-none d-lg-block container"
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-around py-5"
  }, [_c('div', [_c('a', {
    staticClass: "text-decoration-none",
    on: {
      "click": function ($event) {
        return _vm.changeCharacter(_vm.CHARACTER.CHILDREN);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("學員登入")])]), _c('a', {
    staticClass: "text-decoration-none",
    on: {
      "click": function ($event) {
        return _vm.changeCharacter(_vm.CHARACTER.PARENT);
      }
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("家長登入")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": `mailto:${_vm.cooperationUrl}`,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("合作提案")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.joinUsUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("夥伴招募")])]), _c('a', {
    staticClass: "text-decoration-none",
    attrs: {
      "href": _vm.pmRecruitmentUrl,
      "target": "_blank"
    }
  }, [_c('h5', {
    staticClass: "mb-4 text-light"
  }, [_vm._v("成為老師")])])]), _c('div', {
    staticClass: "text-light contact"
  }, [_c('h5', [_vm._v("聯絡我們")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "tel:0800885315"
    }
  }, [_vm._v("Tel: 0800-885315")]), _c('a', {
    staticClass: "text-light",
    attrs: {
      "href": "mailto:service@wuwowjr.tw"
    }
  }, [_vm._v("service@wuwowjr.tw")]), _c('div', {
    staticClass: "mt-4 d-flex justify-content-left"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "https://www.facebook.com/WUWOW.Junior",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "mr-1 init",
    attrs: {
      "src": _vm.file.fbSVG,
      "width": "50",
      "height": "50"
    }
  }), _c('img', {
    staticClass: "mr-1 hover",
    attrs: {
      "src": _vm.file.fbHoverSVG,
      "width": "50",
      "height": "50"
    }
  }), _c('p', [_vm._v("wuwow jr")])])])]), _vm._m(3), _vm._m(4)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "d-flex flex-column align-items-center text-light py-5",
    attrs: {
      "href": "https://www.wuwow.tw/"
    }
  }, [_c('img', {
    staticClass: "w-50 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg"
    }
  }), _c('h6', [_vm._v("WUWOW")]), _c('h6', [_vm._v("最有效的線上英文")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "w-50 jr-logo mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg"
    }
  }), _c('h6', [_vm._v("WUWOW Junior")]), _c('h6', [_vm._v("專為孩子設計的線上英文")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-sm-6"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center text-light mb-3",
    attrs: {
      "href": "https://www.wuwow.tw/"
    }
  }, [_c('img', {
    staticClass: "w-75 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg"
    }
  }), _c('h6', [_vm._v("WUWOW")]), _c('h6', [_vm._v("最有效的線上英文")])]), _c('a', {
    staticClass: "d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "w-75 jr-logo mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg"
    }
  }), _c('h6', [_vm._v("WUWOW Junior")]), _c('h6', [_vm._v("專為孩子設計的線上英文")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "w-25 d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "https://www.wuwow.tw/"
    }
  }, [_c('img', {
    staticClass: "w-75 h-75 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg"
    }
  }), _c('h6', [_vm._v("WUWOW")]), _c('h6', [_vm._v("最有效的線上英文")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "w-25 d-flex flex-column align-items-center text-light",
    attrs: {
      "href": "/"
    }
  }, [_c('img', {
    staticClass: "w-75 h-75 mb-3",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg"
    }
  }), _c('h6', [_vm._v("WUWOW Junior")]), _c('h6', [_vm._v("專為孩子設計的線上英文")])]);

}]

export { render, staticRenderFns }