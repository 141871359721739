import { pickBy } from 'lodash';
import axios from '@/api/utils/axios-helper.js';
import { setFormdata, setParams } from '@/utils/index.js';
import studentRequestsClass from '@/api/src/vipRequests';
import platformConstants from '@/constants/platform.js';

const studentRequests = new studentRequestsClass();

export default class Consultant {
  // TODO MODIFY 回覆速度太慢 Dobby 會修改 By Asa
  /**
   * 取得目前老師清單
   * @returns {Promise<Object>} 回傳所有目前可上課老師資訊
   */
  async getConsultantList(params) {
    const response = await axios({
      url: '/v3/taichi-external/consultant/lists',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得老師個人資料
   * @returns {Promise<Object>} 老師個人所有資料
   */
  async getConsultantProfile() {
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/index',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 編輯老師個人資料
   * @param {object}: 老師個人資料
   * @returns {Promise<Object>} 是否修改成功
   */
  async updateConsultantProfile(params) {
    const fileKeys = ['idPictureFront', 'idPictureBack', 'resumeDoc', 'picture'];
    let defaultPaymentKeys = {
      englishName: 'englishName',
      chineseName: 'chineseName',
      gender: 'gender',
      birthday: 'birthday',
      newPassword: 'newPassword',
      confirmPassword: 'confirmPassword',
      email: 'email',
      workPhone: 'workPhone',
      healthCard: 'healthCard',
      identification: 'identification',
      localAddress: 'localAddress',
      secondAddress: 'secondAddress',
      nationality: 'nationality',
      occupation: 'occupation',
      education: 'education',
      schoolName: 'schoolName',
      major: 'major',
      selfIntroduction: 'selfIntroduction',
      learningSuggestion: 'learningSuggestion',
      introductionVideo: 'introductionVideo',
      idPictureFront: 'idPictureFront',
      idPictureBack: 'idPictureBack',
      resumeDoc: 'resumeDoc',
      picture: 'picture',
    };
    defaultPaymentKeys = pickBy(defaultPaymentKeys, (key) => {
      const isFileValue = params[key] instanceof File;
      // 如果是檔案格式需要檢查是否是File格式其餘都須送後端
      return fileKeys.includes(key) && isFileValue || !fileKeys.includes(key);
    });

    const formdata = setFormdata(defaultPaymentKeys, params);
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/update',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formdata,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得老師被學員喜愛與分數
   * @param st: personal_score 個人分數
   * @returns {Promise<Object>} 回傳老師1.被喜愛與討厭數 2.score本月平均分數
   */
  async getConsultantEvaluationScore(params) {
    const response = await axios({
      url: '/v3/taichi-external/consultant/evaluation-score',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得收款方式資料
   * @returns {Promise<Array>} 回傳老師收款方式 1.包含預設收款帳號 2.所有收款帳號資訊
   */
  async getConsultantPayment() {
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/hb-payment',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得老師證照資料
   * @returns {Promise<Array>} 回傳老師證照資訊
   */
  async getConsultantQualifications() {
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/qualifications',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得老師設備資料
   * @returns {Promise<Array>} 回傳老師所使用設備資訊
   */
  async getConsultantDevices() {
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/devices',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得老師網路速度
   * @returns {Promise<Array>} 回傳老師所在地網路速度
   */
  async getConsultantLocationsSpeed() {
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/locations',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 設定老師預設收款帳戶
   * @param pi: 收款帳戶id, dp: 開啟預設 > 1
   * @returns {Promise<Object>} 回傳修改成功失敗依照http code判斷
   */
  async setConsultantDefaultPayment(params) {
    // 處理資料api專案時另外拉出去做
    const defaultPaymentKeys = {
      paymentId: 'pi',
      dp: 'dp',
    };
    const formdata = setFormdata(defaultPaymentKeys, params);
    //
    const response = await axios({
      url: '/consultant/profiles/hb_payment',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formdata,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 新增老師設備
   * @param tp: 設備類型(筆電、手機...), br: 品牌(Acer、Apple...), md: 品牌型號, of:  作業系統(windows, ios...)
   * @param ov: 作業系統版號, bs: 瀏覽器, bv: 瀏覽器版號, ip: 電腦圖片(未確定是否需要此欄位), op 電腦螢幕圖片(未確定是否需要此欄位)
   * @returns {Promise<Object>} 回傳新增成功失敗依照http code判斷
   */
  async addConsultantDevice(params) {
    // 處理資料api專案時另外拉出去做
    const deviceKeys = {
      type: 'type',
      brand: 'brand',
      model: 'model',
      os_family: 'osFamily',
      os_version: 'osVersion',
      browser: 'browser',
      browser_version: 'browserVersion',
      computer_image_path: 'computerImagePath',
      os_screen_path: 'osScreenPath',
    };
    const data = setParams(deviceKeys, params);
    //
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/store-device',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 編輯老師設備
   * @param di: 設備id, tp: 設備類型(筆電、手機...), br: 品牌(Acer、Apple...), md: 品牌型號, of:  作業系統(windows, ios...)
   * @param ov: 作業系統版號, bs: 瀏覽器, bv: 瀏覽器版號, ip: 電腦圖片(未確定是否需要此欄位), op 電腦螢幕圖片(未確定是否需要此欄位)
   * @returns {Promise<Object>} 回傳修改成功失敗依照http code判斷
   */
  async editConsultantDevice(params) {
    // 處理資料api專案時另外拉出去做
    const deviceKeys = {
      device_id: 'deviceId',
      type: 'type',
      brand: 'brand',
      model: 'model',
      os_family: 'osFamily',
      os_version: 'osVersion',
      browser: 'browser',
      browser_version: 'browserVersion',
      computer_image_path: 'computerImagePath',
      os_screen_path: 'osScreenPath',
    };
    const data = setParams(deviceKeys, params);
    //
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/edit-device',
      method: 'put',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 刪除老師設備
   * @param di: 設備id
   * @returns {Promise<Object>} 回傳刪除成功失敗依照http code判斷
   */
  async removeConsultantDevice(params) {
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/destroy-device',
      method: 'delete',
      headers: { 'Cache-Control': 'no-cache' },
      data: params,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 新增老師證照
   * @param tp: 證照類型, nm: 證照名稱, image: 證照圖片
   * @returns {Promise<Object>} 回傳新增成功失敗依照http code判斷
   */
  async addConsultantCertificates(params) {
    // 處理資料api專案時另外拉出去做
    const certificatesKeys = {
      type: 'type',
      name: 'name',
      image: 'imageFile',
    };
    const formdata = setFormdata(certificatesKeys, params);
    //
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/store-qualifications',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formdata,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 編輯老師證照
   * @param tp: 證照類型, nm: 證照名稱, image: 證照圖片
   * @returns {Promise<Object>} 回傳修改成功失敗依照http code判斷
   */
  async editConsultantCertificates(params) {
    const certificatesKeys = {
      type: 'type',
      name: 'name',
      image: 'imageFile',
      qualification_id: 'qualificationId',
    };
    const formdata = setFormdata(certificatesKeys, params);
    //
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/upload-qualifications',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formdata,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 刪除老師證照
   * @param qi: 證照id
   * @returns {Promise<Object>} 回傳刪除成功失敗依照http code判斷
   */
  async removeConsultantCertificates(params) {
    const response = await axios({
      url: '/v3/taichi-external/consultant/profiles/delete-qualifications',
      method: 'delete',
      headers: { 'Cache-Control': 'no-cache' },
      data: params,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 新增老師帳戶
   * @param pn: 帳戶類型(taiwan_bank, paypal), bp: taiwan_bank 帳戶封面, pa: paypal email, bc: taiwan_bank 銀行號碼
   * @param bbc: taiwan_bank 銀行分行號碼, ba taiwan_bank 銀行帳號, eb: 判斷新增修改刪除(1: 新增修改, 0: 刪除)
   * @returns {Promise<Object>} 回傳新增成功失敗依照http code判斷
   */
  async addConsultantPayment(params) {
    // 處理資料api專案時另外拉出去做
    const paymentKeys = {
      image: 'bp',
      payment: 'pn',
      paypalEmail: 'pa',
      bankCode: 'bc',
      bankBranchCode: 'bbc',
      bankAccount: 'ba',
      eb: 'eb',
    };
    const formdata = setFormdata(paymentKeys, params);
    //
    const response = await axios({
      url: '/consultant/profiles/hb_payment',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formdata,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 編輯老師帳戶
   * @param pi: 帳戶id, pn: 帳戶類型(taiwan_bank, paypal), bp: taiwan_bank 帳戶封面, pa: paypal email, bc: taiwan_bank 銀行號碼
   * @param bbc: taiwan_bank 銀行分行號碼, ba taiwan_bank 銀行帳號, eb: 判斷新增修改刪除(1: 新增修改, 0: 刪除)
   * @returns {Promise<Object>} 回傳修改成功失敗依照http code判斷
   */
  async editConsultantPayment(params) {
    const paymentKeys = {
      id: 'pi',
      image: 'bp',
      payment: 'pn',
      paypalEmail: 'pa',
      bankCode: 'bc',
      bankBranchCode: 'bbc',
      bankAccount: 'ba',
      eb: 'eb',
    };
    if (params.image === null || params.image === ''){
      delete paymentKeys.image;
    }
    const formdata = setFormdata(paymentKeys, params);
    //
    const response = await axios({
      url: '/consultant/profiles/hb_payment',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formdata,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 刪除老師帳戶
   * @param pi: 帳戶id, eb: 判斷新增修改刪除(1: 新增修改, 0: 刪除)
   * @returns {Promise<Object>} 回傳刪除成功失敗依照http code判斷
   */
  async removeConsultantPayment(params) {
    // 處理資料api專案時另外拉出去做
    const paymentKeys = {
      id: 'pi',
      eb: 'eb',
    };
    const formdata = setFormdata(paymentKeys, params);
    //
    const response = await axios({
      url: '/consultant/profiles/hb_payment',
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formdata,
      responseType: 'json',
    });
    return response;
  }

  /**
   * 檢查老師帳戶使否有填寫
   * @param platform: 平台
   * @returns {Promise<Object>} 回傳true/false
   */
  async queryConsultantPaymentCheck() {
    const paymentKeys = {
      platform: platformConstants.WUWOW_JUNIOR,
    };
    const response = await axios({
      url: '/consultant/profiles/hb-payment-check',
      method: 'get',
      headers: { 'Content-Type': 'no-cache' },
      responseType: 'json',
      params: paymentKeys,
    });
    return response;
  }

  // get學生請求列表
  queryStudentRequestsList(query) {
    return studentRequests.queryStudentRequestsList(query);
  }
  // 同意學員的詢問
  approveAskConsultant(data) {
    return studentRequests.approveAskConsultant(data);
  }
  // 拒絕學員的詢問
  rejectAskConsultant(data) {
    return studentRequests.rejectAskConsultant(data);
  }
  // 老師被請求未處理數量
  getNotReplyAskConsultant() {
    return studentRequests.getNotReplyAskConsultant();
  }
}
