export default {
  vip: {
    classRoom: [
      {
        name: 'attendClass', authorityTag: true, path: '/dojo/classRoom',
      },
    ],
    bookLists: [
      {
        name: 'bookClassWithDate', authorityTag: true, path: '/dojo/book_class',
      },
      {
        name: 'requireFavoriteConsultant', authorityTag: true, path: '/dojo/consultants',
      },
      {
        name: 'vipFixedSchedule', authorityTag: true, path: '/dojo/schedule/fixed',
      },
    ],
    setRecordLists: [
      {
        name: 'bookClassRecord', authorityTag: true, path: '/dojo/bookClassRecord',
      },
    ],
    classRecordLists: [
      {
        name: 'evaluations', authorityTag: true, path: '/dojo/evaluations',
      },
      {
        // name: 'learnRecords', authorityTag: true, path: '/dojo/classRecords',
        name: 'learnRecords', authorityTag: true, path: '/student/learning-track',
      },
      {
        name: 'progressAnalysis', authorityTag: true, path: '/dojo/account/progressAnalysis',
      },
    ],
    mgm: [
      {
        name: 'vipMgm', authorityTag: true, path: '/dojo/vip_mgm',
      },
    ],
    guide: [
      {
        name: 'guide', authorityTag: true, path: '/dojo/guide',
      },
    ],
    // wushareList: [
    //   {
    //     name: 'couponList', authorityTag: true, path: '/dojo/wushare/coupon_list',
    //   },
    // ],
    // coupon: [
    //   {
    //     name: 'couponCourse', authorityTag: true, path: '/dojo/coupon',
    //   },
    // ],
    accountLists: [
      {
        name: 'my_contract', authorityTag: true, path: '/dojo/account/contracts',
      },
      {
        name: 'profile', authorityTag: true, path: '/dojo/account/profile',
      },
      {
        name: 'parentsZone', authorityTag: true, path: '/dojo/parents/profile',
      },
      {
        // 在新視窗打開首頁，並且打開家長註冊 modal
        name: 'register_parent_account', authorityTag: true, path: '/register-parent-account', target: 'blank',
      },
      {
        name: 'common_problem', authorityTag: true, path: '/dojo/faq',
      },
    ],
  },
  parent: {
    classRoom: [
      {
        name: 'auditClass', authorityTag: true, path: '/dojo/classRoom',
      },
    ],
    bookLists: [
      {
        name: 'bookClassWithDate', authorityTag: true, path: '/dojo/book_class',
      },
      {
        name: 'requireFavoriteConsultant', authorityTag: true, path: '/dojo/consultants',
      },
      {
        name: 'vipFixedSchedule', authorityTag: true, path: '/dojo/schedule/fixed',
      },
    ],
    setRecordLists: [
      {
        name: 'bookClassRecord', authorityTag: true, path: '/dojo/bookClassRecord',
      },
      {
        name: 'suitable', authorityTag: false, path: '/dojo/account/suitable',
      },
    ],
    classRecordLists: [
      {
        // name: 'learnRecords', authorityTag: true, path: '/dojo/classRecords',
        name: 'learnRecords', authorityTag: true, path: '/student/learning-track',
      },
      {
        name: 'progressAnalysis', authorityTag: true, path: '/dojo/account/progressAnalysis',
      },
    ],
    mgm: [
      {
        name: 'vipMgm', authorityTag: true, path: '/dojo/vip_mgm',
      },
    ],
    guide: [
      {
        name: 'guide', authorityTag: true, path: '/dojo/guide',
      },
    ],
    accountLists: [
      {
        name: 'editParentProfile', authorityTag: true, path: '/dojo/parents/profile/edit',
      },
    ],
  },
  consultant: {
    scheduleLists: [
      {
        name: 'setTimeSlot', authorityTag: true, path: '/console/consultant/schedule',
      },
      {
        name: 'setRegularTimeSlot', authorityTag: true, path: '/console/consultant/schedule/fixed',
      },
    ],
    classLists: [
      {
        name: 'classRecords', authorityTag: true, path: '/console/consultant/classRecords',
      },
      {
        name: 'cloudClassroom', authorityTag: true, path: '/console/classroom',
      },
      {
        name: 'vipClassRequest', authorityTag: true, path: '/console/classroom/vip-requests',
      },
    ],
  },
  center: {
    centerLists: [
      {
        name: 'centerMembers', authorityTag: true, path: '/console/centerManagement',
      },
      {
        name: 'memberClassRecords', authorityTag: true, path: '/console/memberClassRecords',
      },
    ],
  },
};
