<template lang="pug">
.jr-nav-bar(:class="(addFixedNavClass)?'fixed-nav-bar':''")
  //- 手機用的尺寸
  //- .d-block.d-xl-none.home-container.position-relative
  //- TODO: for 夏令營 2023 特別活動，記得刪
  .d-block.d-1600-none.home-container.position-relative
    //- TODO: END for 夏令營 2023 特別活動，記得刪
    .d-flex.justify-content-between.align-items-center
      button.bg-transparent.border-0.text-light.h2.m-0.mobile-size(
        @click="isLeftNavShow = true"
      )
        font-awesome-icon(icon="bars")
      a(href="/")
        img.jr-logo(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
      button.btn-link.border-0.h3.text-light.m-0.rounded-pill.mobile-size.p-0(
        @click="setIsOpen({target: modal.CHOICE_CHARACTER_UUID, status: !choiceCharacterModalIsOpen})"
      ) 登入
    .position-fixed.w-100.h-100.fixed-top(v-if="isLeftNavShow" @click="isLeftNavShow = false")
      .left-side-bar.h-100.w-75.bg-lioshutan
        .d-flex.flex-column.align-items-center.justify-content-center.pt-4
          router-link.children-nav.my-3(
            v-if="deadlinesAfter"
            :to="{ path: '/event/childrensday_2024' }"
          )
            img(:src="require('@/assets/childrensday2024/nav.png')", alt="classRoom")
            img(:src="require('@/assets/childrensday2024/nav-hover.png')", alt="classRoom")
          a.h2.text-decoration-none.text-light.my-3(
            @click="isLeftNavShow = false"
            href="#material"
          ) 課程特色
          a.h2.text-decoration-none.text-light.my-3(
            @click="isLeftNavShow = false"
            href="#consultants"
          ) 師資陣容
          a.h2.text-decoration-none.text-light.my-3(
            @click="isLeftNavShow = false"
            href="#arcs"
          ) ARCS教學模式
          a.h2.text-decoration-none.text-light.my-3(
            @click="isLeftNavShow = false"
            href="#recommend"
          ) 好評推薦

          //- 改為導到登陸頁裡的試讀頁
          //- a.h2.text-decoration-none.text-light.my-3(
          //-   @click="isLeftNavShow = false"
          //-   href="#reserve-class"
          //- ) 免費試讀
          router-link.h2.text-decoration-none.text-light.my-3(@click="isLeftNavShow = false",:to="{name:'event-trial'}") 免費試讀

          a.h2.text-decoration-none.text-light.my-3(
            href="https://core.newebpay.com/EPG/WUWOWJR/H4ptZd"
          ) 線上購買
          a.h2.text-decoration-none.text-light.my-3(
            @click="isLeftNavShow = false"
            :href="officialURL + 'recruitment/pm_recruitment'"
          ) 成為老師
          button.login-btn.border-0.h2.bg-light.text-lioshutan.m-0.rounded-pill.my-3(
            @click="setIsOpen({target: modal.CHOICE_CHARACTER_UUID, status: !choiceCharacterModalIsOpen})"
          ) 會員登入
        .d-flex.flex-column.align-items-center.justify-content-center
          .w-75
            hr.bg-light
        .d-flex.flex-column.align-items-center.justify-content-center
          h4.text-light Follow us on
          .d-flex.align-items-center.justify-content-center.w-100
            img.mr-2(:src="file.fbSVG" width="50" height="50")
            img.mx-2(:src="file.lineSVG" width="50" height="50")
            img.ml-2(:src="file.messengerSVG" width="50" height="50")
        .d-flex.flex-column.align-items-center.justify-content-center.mt-5
          p.text-light.mb-1 異業提案合作
          p.text-light.mb-1 service@wuwowjr.tw
          p.text-light.mb-1 Copyright 2022@LIOSHUTAN
          p.text-light.mb-1 All Rights Reserved
          p.text-light.mb-1 隱私權政策
  //- 手機用的尺寸 END~~

  //- 給電腦的尺寸
  //- .d-none.d-md-none.d-xl-block.container-fluid
  //- TODO: END for 夏令營 2023 特別活動，記得刪
  .d-none.d-md-none.d-xl-none.d-1600-block.container-fluid
    //- TODO: for 夏令營 2023 特別活動，記得刪
    .d-flex.justify-content-between.align-items-center.home-container
      a(href="/")
        img.jr-logo(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
      .d-flex.justify-content-between.align-items-center
        router-link.children-nav.my-3(
            v-if="deadlinesAfter"
            :to="{ path: '/event/childrensday_2024' }"
          )
            img(:src="require('@/assets/childrensday2024/nav.png')", alt="classRoom")
            img(:src="require('@/assets/childrensday2024/nav-hover.png')", alt="classRoom")
        a.text-decoration-none(href="#material")
          h5.under-line.text-light.m-0.mx-xl-3.mx-3 課程特色
        a.text-decoration-none(href="#consultants")
          h5.under-line.text-light.m-0.mx-xl-3.mx-3 師資陣容
        a.text-decoration-none(href="#arcs")
          h5.under-line.text-light.m-0.mx-xl-3.mx-3 ARCS教學模式
        a.text-decoration-none(href="#recommend")
          h5.under-line.text-light.m-0.mx-xl-3.mx-3 好評推薦

        //- 改為導到登陸頁裡的試讀頁
        //- a.text-decoration-none(href="#reserve-class")
        //-   h5.under-line.text-light.m-0.mx-xl-3.mx-3 免費試讀
        router-link.text-decoration-none(:to="{name:'event-trial'}")
          h5.under-line.text-light.m-0.mx-xl-3.mx-3 免費試讀

        a.text-decoration-none(href="https://core.newebpay.com/EPG/WUWOWJR/H4ptZd")
          h5.under-line.text-light.m-0.mx-xl-3.mx-3 線上購買
        a.text-decoration-none(:href="officialURL + 'recruitment/pm_recruitment'")
          h5.under-line.text-light.m-0.mx-xl-3.mx-3 成為老師
        button.login-btn.border-0.text-lioshutan.m-0.rounded-pill.px-4.py-1(
          @click="setIsOpen({target: modal.CHOICE_CHARACTER_UUID, status: !choiceCharacterModalIsOpen})"
        ) 會員登入
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { MODAL } from '@/store';
import { IS_OPEN, SET_IS_OPEN } from '@/store/modules/modal';

import fbSVG from '@/assets/icon_fb.svg';
import lineSVG from '@/assets/icon_line.svg';
import messengerSVG from '@/assets/icon_messenger.svg';

import { uuid as CHOICE_CHARACTER_UUID } from '../choiceCharacterModal/index.vue';
import moment from 'moment';

import 'bootstrap/dist/css/bootstrap.css';

export default {
  data: () => ({
    deadlines: '2024-04-15 00:00:00',
    deadlinesAfter: false,
    isLeftNavShow: false,
    modal: {
      CHOICE_CHARACTER_UUID,
    },
    file: {
      fbSVG,
      lineSVG,
      messengerSVG,
    },
    addFixedNavClass: false,
    // for 八周年慶按鈕使用，把 wuwow官網網址設為 env變數來使用
    portalURL: `${process.env.VUE_APP_WUWOW_PORTAL_URL}`,
    // 新版六書堂首頁
    officialURL: `${process.env.VUE_APP_LIOSHUTAN_OFFICIAL_URL}`,
  }),
  computed: {
    ...mapState({
      choiceCharacterModalIsOpen: (state) => state[MODAL][IS_OPEN][CHOICE_CHARACTER_UUID],
    }),
  },
  created() {
    window.addEventListener('scroll', this.handleScroll); // 增加監聽滾動
    this.deadlinesAfter = moment(new Date()).isBetween('2024-04-01 09:00:00', this.deadlines);
  },

  methods: {
    ...mapMutations(MODAL, [SET_IS_OPEN]),
    handleScroll() {
      const scrollY = window.scrollY;
      this.addFixedNavClass = scrollY > 100;
    },
  },
};
</script>
<style lang="scss" scoped>
.jr-nav-bar {
  background: linear-gradient(to right, #bfe1f0, #88c2e8);
  padding: 70px 0 40px 0;
  .jr-logo {
    height: 85px;
  }
  .login-btn {
    background-color: white;
    font-size: 25px;
  }
  .login-btn:hover {
    background-color: #3da0e8;
    color: white;
  }
}
.fixed-nav-bar {
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 10px 0;
}

//- TODO: for 夏令營 2023 特別活動，記得刪
.d-1600-none {
  @media (min-width: 1600px) {
    display: none !important;
  }
}

.d-1600-block {
  @media (min-width: 1600px) {
    display: block !important;
  }
}

a:has(.init, .hover) {
  .init {
    display: initial;
  }

  .hover {
    display: none;
  }

  &:hover {
    .init {
      display: none;
    }

    .hover {
      display: initial;
    }
  }
}

//- TODO: END for 夏令營 2023 特別活動，記得刪

@media screen and (max-width: 1200px) {
  .jr-nav-bar {
    .jr-logo {
      height: 70px;
    }
    .under-line {
      font-size: 22px;
    }
    .login-btn {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 992px) {
  .jr-nav-bar {
    padding: 5px 15px;

    .jr-logo {
      height: 70px;
    }

    button {
      font-size: 22px;
    }

    .left-side-bar {
      a {
        font-size: 24px;
      }

      .login-btn {
        padding: 10px 76px;
        color: #3da0e8;
      }
    }
  }
}
</style>
<style scoped>
.z-index-1 {
  z-index: -1;
}

.position-fixed.w-100.h-100.fixed-top::after {
  content: "";
  background-color: #000000d1;

  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -2;
}

.mobile-nav-toggle {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

button.mobile-size:active {
  background-color: rgba(108, 108, 108, 0.331) !important;
}

.mobile-size {
  width: 60px;
  height: 60px;
}

.under-line {
  font-size: 25px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.under-line::after {
  content: "";
  width: 0%;
  margin-bottom: -7px;
  height: 2px;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s linear;
}

.under-line:hover::after {
  width: 100%;
}
.children-nav {
  display: block;
  padding: 0 8px;
  color: #fff;
  text-decoration: none;
  background: transparent;
  img{
    aspect-ratio: 174/65;
    width: 100%;
    max-width: 174px;
  }
  img:first-child{
    display: block;
    }
    img:last-child{
    display: none;
  }
  &:hover{
    img:first-child{
      display: none;
    }
    img:last-child{
      display: block;
    }
  }
}
</style>
