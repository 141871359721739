export default {
  messages: {
    tw: {
      'back_to_official_web': '返回官網',
      'login_title': '立即登入',
      'wuwow_login_slogan': 'WUWOW線上英文，讓你走到哪就學到哪',
      'user_info_error_message': '帳號或密碼輸入錯誤',
      'login': '登入',
      'get_new_password': '索取新密碼',
      'free_trial': '申請免費試讀',
      'wrongPlatform': '平台登入錯誤',
      'platformCheckError': '登入平台檢查發生問題',
    },
    en: {
      'back_to_official_web': 'Official Website',
      'login_title': 'Login',
      'wuwow_login_slogan': 'WUWOW線上英文，讓你走到哪就學到哪',
      'user_info_error_message': 'Incorrect account or password',
      'login': 'Login',
      'get_new_password': 'New Password',
      'free_trial': 'Free Trial',
      'wrongPlatform': 'Wrong Platfrom!',
      'platformCheckError': 'Platfrom Checked Error',
    },
  },
};
