<template lang="pug">
.position-absolute(v-if="show")
  //- 給手機用的尺寸
  .d-block.d-md-none
    .fixed-top.vh-100.vw-100.d-flex.justify-content-center.align-items-center#login-container
      .position-absolute.vw-100.vh-100(
        @click="closeModal"
      )
      .login-modal.rounded-7.position-relative
        .position-absolute
        .text-center
          img.w-100.rounded-top-7(:src="file.loginBanner")
          .position-relative.text-center#login-content
            p.text-light.position-absolute.fixed-bottom.mb-2 從雲端出發，任何時間、任何地點都能上課
        .text-center.mt-4
          h3.text-lioshutan.my-3 立即登入
          p 登入 WUWOW Jr  打開世界的一扇窗！
        .mx-4
          .input-group.mb-3
            .input-group-prepend
              span.input-group-text.h-100
                font-awesome-icon(icon="user")
            input.form-control(v-model="loginForm.account" type="text" placeholder="輸入手機號碼")

          .input-group.mb-3
            .input-group-prepend
              span.input-group-text.h-100
                font-awesome-icon(icon="key")
            input.form-control(v-model="loginForm.password" :type="passwordType" placeholder="密碼")
            font-awesome-icon.showPasswordIcon.position-absolute(:icon="showPasswordIconName" @click="togglePasswordVisibility")

          .d-flex.justify-content-end.mb-3
            a.text-lioshutan(@click="openForgotPasswordModal") 忘記密碼？
          button.btn-block.border-0.rounded.bg-lioshutan.text-light.py-2.mb-3(@click="login" :disabled="logining")
            span(v-if="!logining")
              | 立即登入
            div(v-else-if="logining")
              b-spinner.mr-2(small)
              span 登入中...
          //- p.text-center 點擊我 申請免費試讀

        .teacher-icon
          img.w-75(src="@/assets/teacher.png")

  //- 給平板的尺寸
  .d-none.d-md-block.d-lg-none
    .fixed-top.vh-100.vw-100.d-flex.justify-content-center.align-items-center#login-container
      .position-absolute.vw-100.vh-100(
        @click="closeModal"
      )
      .login-modal.rounded-7.position-relative
        .position-absolute
        .login-modal-top.text-center
          img.w-100.h-100.rounded-top-7(:src="file.loginBanner")
          .position-relative.text-center#login-content
            p.text-light.position-absolute.fixed-bottom.mb-3.h5 從雲端出發，任何時間、任何地點都能上課
        .text-center.mt-5
          h3.text-lioshutan.my-3 立即登入
          p 登入 WUWOW Jr  打開世界的一扇窗！
        .mx-5
          .mx-5.px-5
            .input-group.mb-3
              .input-group-prepend
                span.input-group-text.h-100
                  font-awesome-icon(icon="user")
              input.form-control(v-model="loginForm.account" type="text" placeholder="輸入手機號碼")

            .input-group.mb-3
              .input-group-prepend
                span.input-group-text.h-100
                  font-awesome-icon(icon="key")
              input.form-control(v-model="loginForm.password" :type="passwordType" placeholder="密碼")
              font-awesome-icon.showPasswordIcon.position-absolute(:icon="showPasswordIconName" @click="togglePasswordVisibility")

            .d-flex.justify-content-end.mb-3
              a.text-lioshutan(@click="openForgotPasswordModal") 忘記密碼？
            button.btn-block.border-0.rounded.bg-lioshutan.text-light.py-2.mb-3(@click="login" :disabled="logining")
              span(v-if="!logining")
                | 立即登入
              div(v-else-if="logining")
                b-spinner.mr-2(small)
                span 登入中...
            //- p.text-center 點擊我 申請免費試讀
        .teacher-icon
            img(src="@/assets/teacher.png")

  //- 給電腦的尺寸
  .d-none.d-md-none.d-lg-block
    .fixed-top.vh-100.vw-100.d-flex.justify-content-center.align-items-center#login-container
      .position-absolute.vw-100.vh-100.z-index-1(
        @click="closeModal"
      )
      .login-modal.rounded-7.d-flex
        .h-100.w-65.rounded-left-7
          img.w-100.h-100.rounded-left-7(:src="file.loginBanner")
          .position-relative.text-center.rounded-bottom-left#login-content.desktop
            p.text-light.position-absolute.fixed-bottom.mb-3.h5 從雲端出發，任何時間、任何地點都能上課
        .h-100.w-35.px-3.position-relative
          .text-center.mt-5
            h3.text-lioshutan.my-3 立即登入
            p 登入 WUWOW Jr  打開世界的一扇窗！
          .mx-4
            .input-group.mb-3
              .input-group-prepend
                span.input-group-text.h-100
                  font-awesome-icon(icon="user")
              input.form-control(v-model="loginForm.account" type="text" placeholder="輸入手機號碼" @keyup.enter="login")

            .input-group.mb-3
              .input-group-prepend
                span.input-group-text.h-100
                  font-awesome-icon(icon="key")
              input.form-control(v-model="loginForm.password" :type="passwordType" placeholder="密碼" @keyup.enter="login")
              font-awesome-icon.showPasswordIcon.position-absolute(:icon="showPasswordIconName" @click="togglePasswordVisibility")

            .d-flex.justify-content-end.mb-3
              a.forget-btn(@click="openForgotPasswordModal") 忘記密碼？
            button.btn-block.border-0.rounded.bg-lioshutan.text-light.py-2.mb-3(@click="login" :disabled="logining")
              span(v-if="!logining")
                | 立即登入
              div(v-else-if="logining")
                b-spinner.mr-2(small)
                span 登入中...
          a.teacher-icon(@click="close()" href="/#reserve-class")
            img(src="@/assets/teacher.png")
</template>

<script>
import { loginFailedHandle } from '@/utils/auth';
import lioshutanApi from '@/api';
import api from '@lioshutan/api-package';
import { v4 } from 'uuid';
import {
  mapMutations,
} from 'vuex';
import {
  SET_IS_OPEN,
} from '@/store/modules/modal';
import {
  MODAL as MODAL_STORE,
} from '@/store/index';
import {
  uuid as FORGET_PASSWORD_MODAL_UUID,
} from '@/views/home/parent/forgotPasswordModal/index.vue';
import modalMixin from '@/mixins/modal';
import { setUseLangByRole } from '@/utils/lang';
// import commonConstants from '@/constants/common';
// import storage from '@/utils/localStorage';
// import CHARACTER from '@/constants/character';

import loginBanner from '@/assets/login-banner.png';

import 'bootstrap/dist/css/bootstrap.css';

export const uuid = v4();

export default {
  mixins: [
    modalMixin,
  ],

  data: () => ({
    file: {
      loginBanner,
    },
    loginForm: {
      account: '',
      password: '',
    },

    // Toggle Password Visibility
    isShowPassword: false,
    passwordType: 'password',
    showPasswordIconName: 'eye-slash',

    errorMessage: false,
    showPassword: false,
    logining: false,
  }),

  created() {
    this.initModal(uuid, {
      status: false,
      payload: null,
    });
  },

  methods: {
    ...mapMutations({
      openForgotPasswordModal(commit) {
        this.close();

        commit(`${MODAL_STORE}/${SET_IS_OPEN}`, {
          target: FORGET_PASSWORD_MODAL_UUID,
          status: true,
        });
      },
    }),
    closeModal() {
      this.close();
    },
    togglePasswordVisibility() {
      this.isShowPassword = !this.isShowPassword;
      this.passwordType = this.isShowPassword ? 'text' : 'password';
      this.showPasswordIconName = this.isShowPassword ? 'eye' : 'eye-slash';
    },
    async login() {
      try {
        this.logining = true;
        const result = await api
          .setDNS(process.env.VUE_APP_BASE_API)
          .auth()
          .login(this.loginForm);
        // const canLogin = await this.checkUserLogin();
        // if (canLogin){
        this.$store.dispatch('parents/removeVipId');
        this.$store.dispatch('auth/setToken', result.token);
        const accountRole = await this.setAccountAuthorityToLocalStorage();
        setUseLangByRole(accountRole);
        if (accountRole === 'teacher') {
          this.$router.push({ path: '/dojo' });
        } else {
          const res = await lioshutanApi.vip.getContractElapsedTime();
          this.$store.dispatch('vipAccount/setPlatform', res.data.platform);
          this.logining = false;
          if (res.data.platform) {
            const { now } = res.data.platform;
            if (now.id !== 2) {
              loginFailedHandle('detected');
              return;
            }
            this.directToNewJr();
          }
        }
        this.logining = false;
        // this.setLinkTo();
        // }
      } catch (error) {
        this.logining = false;
        const loginStatus = error.response.data.login_status;
        loginFailedHandle(loginStatus);
      }
    },
    directToNewJr() {
      const url = new URL(window.location);
      const a = document.createElement('a');
      a.setAttribute('href', `${url.origin}/student`);
      a.click();
    },
    setLinkTo() {
      // localStorage/'wuwow-junior-link'=>如果被401導出去 會記錄當前頁面
      // 登入後導頁 先檢查localStorage有沒有紀錄的頁面
      // 有的話導至該頁並清除localStorage 沒有的話導至/dojo
      const linkTo = localStorage.getItem('wuwow-junior-link');
      if (linkTo) {
        this.$router.push({ path: linkTo });
        localStorage.removeItem('wuwow-junior-link');
      } else {
        this.directToNewJr();
        // this.$router.push({ path: '/dojo' });
      }
    },
    // loginFailedHandle(loginStatus){
    //   switch (loginStatus){
    //     case LOGIN_STATUS.WRONG:
    //       swal.fire({
    //         icon: 'error',
    //         title: '',
    //         text: '密碼錯誤請再試一次',
    //         confirmButtonText: '再試一次',
    //         footer: '<a href="/?action=forgotPassword">忘記密碼?</a>',
    //       });
    //       break;
    //     case LOGIN_STATUS.NONE:
    //       alertHtmlMessage('', 'error', '查無此帳號，請重新確認', '再試一次');
    //       break;
    //     case LOGIN_STATUS.DETECTED:
    //       swal.fire({
    //         icon: 'warning',
    //         title: '',
    //         text: '已偵測到本組帳密為 WUWOW 帳戶，是否帶您進入WUWOW？',
    //         confirmButtonText: '再試一次',
    //         cancelButtonText: '登入WUWOW',
    //         showCancelButton: true,
    //       }).then((result) => {
    //         if (result.isDismissed){
    //           redirectWuwowLogin();
    //         }
    //       });
    //       break;
    //     default:
    //       alertHtmlMessage('', 'error', '登入錯誤', '再試一次');
    //       break;
    //   }
    // },
    // async checkUserLogin(){
    //   const params = {
    //     platform: commonConstants.CURRENT_PLATFORM,
    //   };
    //   try {
    //     const response = await lioshutanApi.common.checkLogin(params);
    //     if (!response.data.data.check_result){
    //       alertFailedMessage(this.$t('wrongPlatform'));
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   } catch (error) {
    //     alertFailedMessage(this.$t('platformCheckError'));
    //   }
    // },

    async setAccountAuthorityToLocalStorage(){
      const accountAuthority = await lioshutanApi.vip.getAccountAuthority();
      // const accountAuthorityArray = accountAuthority.data.data.authority;
      const accountRole = accountAuthority.data.data.role;
      this.$store.dispatch('auth/setRole', accountRole);
      return accountRole;
    },
  },

};
</script>

<style lang="scss" scoped>
.rounded-7 {
  border-radius: 7px;
}
.rounded-top-7 {
  border-radius: 7px 7px 0 0;
}
.rounded-left-7 {
  border-radius: 7px 0 0 7px;
}

#login-content::after {
  content: "";
  background: linear-gradient(to top, #88c2e8, #88c2e800);
  width: 100%;
  height: 25vh;
  bottom: 0;
  left: 0;
  position: absolute;
}

.desktop#login-content::after {
  content: "";
  background: linear-gradient(to top, #88c2e8, #88c2e800);
  width: 100%;
  height: 25vh;
  bottom: 0;
  left: 0;
  position: absolute;
  border-radius: 0 0 0 7px;
}

#login-container::after {
  content: "";
  background-color: rgba(96, 96, 96, 0.699);;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -100;
}

.login-banner {
  background-image: url(~@/assets/login-banner.png);
}

.z-index-1 {
  z-index: -1;
}
@keyframes fadein {
  0% {
    width: 70%;
    // transform: translateX(0px);
  }
  100% {
    width: 0%;
    // transform: translateX(-120px);
  }
}
.teacher-icon {
  position: absolute;
  bottom: -23px;
  right: 13px;
  // img {
  //   animation: fadein 2s ease;
  // }
  &::after {
    content: "";
    background-color: #FFFFFF;
    width: 0;
    height: 70%;
    position: absolute;
    right: 90px;
    top: 0;
    animation: fadein 2s ease;
  }
}

$max: 20;
@for $i from 1 through $max {
  .vw-#{$i * 5} {
    width: $i * 5vw;
  }
  .w-#{$i * 5} {
    width: $i * 5%;
  }
  .vh-#{$i * 5} {
    height: $i * 5vh;
  }
  .h-#{$i * 5} {
    height: $i * 5%;
  }
}

$teacher: 100px;

.teacher {
  bottom: -10px;
  right: -20px;
  height: $teacher;
  width: $teacher;

  background-image: url(~@/assets/teacher.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.login-modal {
  background-color: white;
  color: #8B8B8B;
  .input-group-text {
    background-color: white;
    color: #D1D1D1;
  }
  .form-control {
    border-left: none;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #959595;
  }
  .forget-btn {
    color: #88c2e8;
    cursor: pointer;
  }
}
.showPasswordIcon {
  cursor: pointer;
  top:50%;
  right:10px;
  font-size:15px;
  transform: translateY(-50%);
  z-index: 10;
}
@media screen and (min-width: 1200px) {
  .login-modal {
    width: 1048px;
    height: 500px;
  }
}
@media screen and (max-width: 1199px) {
  .login-modal {
    width: 900px;
    height: 425px;
  }
}
@media screen and (max-width: 991px) {
  .login-modal {
    width: 648px;
    height: 800px;
    .login-modal-top {
      height: 307px;
    }
  }
}
@media screen and (max-width: 767px) {
  .login-modal {
    width: 343px;
    height: 600px;
    .login-modal-top {
      height: 307px;
    }
  }
}
@media screen and (max-width: 414px) {
  .login-modal {
    .teacher-icon {
      bottom: -10px;
      right: -10px;
      display: flex;
      justify-content: end;
    }
  }
}
</style>
