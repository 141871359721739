<template lang="pug">
.nav-items
  li.nav-item.nav-item
    div(v-for="item in classRoom" :key="item.name")
      router-link.nav-link(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name)}}
  li.nav-item.dropdown(v-if="profile_base.is_enabled_vip_product && getCanOrderClass")
    a.nav-link.dropdown-toggle(
      href='javascript:;'
      @click="toggleDropdown('startClass')"
    ) {{ $t('bookClass')}}
    .dropdown-menu(:class="[state === 'startClass' ?' d-block' :'d-none']")
      .dropdown-sort(:class="[state?'mt-1' :'mt-5']") {{ $t('book')}}
      div(v-for="item in bookLists" :key="item.name")
        router-link.dropdown-item(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name) }}
      div(v-for="item in setRecordLists" :key="item.name")
        .dropdown-sort {{ $t('setRecord')}}
        router-link.dropdown-item(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name) }}
  li.nav-item.dropdown
    a.nav-link.dropdown-toggle(
      href='javascript:;'
      @click="toggleDropdown('classRecord')"
    ) {{ $t('classRecord')}}
    .dropdown-menu(:class="[state === 'classRecord' ?' d-block' :'d-none']")
      div(v-for="item in classRecordLists" :key="item.name")
        router-link.dropdown-item(v-if="item.authorityTag && item.name !== 'learnRecords'" :to="{ path: item.path }") {{ $t(item.name) }}
        a.dropdown-item(v-if="item.authorityTag && item.name === 'learnRecords'" :href="`${newJrUrl}${item.path}`") {{ $t(item.name) }}
      div
        a.dropdown-item(href="https://cws.cavesbooks.com.tw/index.aspx?id=h%2fyJTTRu%2bLjpHeEYZ6gbKA%3d%3d" target="_blank") P21音檔
      div
        a.dropdown-item(href="https://cws.cavesbooks.com.tw/index.aspx?id=Ootb88ICU73n%2b9F3YSL3ig%3d%3d" target="_blank") R21音檔
      div
        a.dropdown-item(href="https://www.cavesbooks.com.tw/EC/book_fair.aspx#3191" target="_blank") 魔法故事屋
  li.nav-item
    div(v-for="item in mgm" :key="item.name")
      router-link.nav-link(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name)}}
  li.nav-item
    div(v-for="item in guide" :key="item.name")
      router-link.nav-link(v-if="item.authorityTag" :to="{ path: item.path }") {{ $t(item.name)}}
</template>

<script>
import i18n from '@/components/nav/lang';
import navList from '@/components/nav/navList';
import lioshutanApi from '@/api';
import dropdowControlMixin from '@/components/nav/headerNav/mixin/dropdowControlMixin.vue';
export default {
  name: 'VipHeaderNav',

  components: {},

  mixins: [dropdowControlMixin],

  i18n,

  data() {
    return {
      newJrUrl: new URL(window.location).origin,

      classRoom: navList.vip.classRoom,

      bookLists: navList.vip.bookLists,

      setRecordLists: navList.vip.setRecordLists,

      classRecordLists: navList.vip.classRecordLists,

      mgm: navList.vip.mgm,

      guide: navList.vip.guide,

      wushareList: navList.vip.wushareList,

      coupon: navList.vip.coupon,

      profile_base: {
        english_name: 'English Name',
        photo: '',
      },
    };
  },
  computed: {
    getCanOrderClass() {
      return this.$store.getters['vipAccount/getCanOrderClass'];
    },
  },
  async mounted() {
    this.getStudentPorfile();
    await this.getVipPointSuggest();
  },
  methods: {
    getStudentPorfile() {
      lioshutanApi.vip.getBaseProfile().then((result) => {
        this.profile_base = { ...result.data.profile_base, ...result.data.customized_classroom_rule };
      });
    },
    async getVipPointSuggest() {
      if (localStorage.getItem('wuwow_token')){
        const res = await lioshutanApi.vip.getContractElapsedTime();
        this.$store.dispatch('vipAccount/setPlatform', res.data.platform);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// 暑期
@import "@/styles/layout/layout.scss";

.nav-bar .nav .nav-items .nav-item .nav-link {
  padding: 0 8px;

  @include media-breakpoint-up(xl) {
    padding: 0 25px;
  }
}
</style>
