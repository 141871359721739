var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "advertise"
  }, [_c('div', {
    staticClass: "d-block"
  }, [_c('div', {
    staticClass: "ads"
  }, [_c('div', {
    staticClass: "ad-container"
  }, [_c('carousel', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "perPage": 1,
      "paginationEnabled": true,
      "autoplay": true,
      "loop": true,
      "autoplayTimeout": 5000,
      "speed": 1000
    }
  }, _vm._l(_vm.slideContent, function (data, index) {
    return _c('slide', [_c('div', {
      staticClass: "ad-slide d-flex"
    }, [_c('div', {
      staticClass: "slide-content jr-light-bg"
    }, [_c('div', {
      staticClass: "slide-title jr-main-color"
    }, [_c('p', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(data.title1))]), data.title2 !== '' ? _c('p', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(data.title2))]) : _vm._e()]), _c('div', {
      staticClass: "slide-subtitle jr-main-color"
    }, [data.subtitle !== '' ? _c('p', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(data.subtitle))]) : _vm._e()]), _c('div', {
      staticClass: "slide-text"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(data.text)
      }
    })]), _c('div', {
      staticClass: "slide-btn d-flex justify-content-center align-items-center"
    }, [data.btnText ? _c('b-button', {
      staticClass: "margin-auto",
      attrs: {
        "pill": "",
        "variant": "primary",
        "target": data.btnTarget,
        "href": data.btnHref
      }
    }, [_vm._v(_vm._s(data.btnText))]) : _vm._e()], 1)]), _c('div', {
      staticClass: "slide-img"
    }, [_c('img', {
      attrs: {
        "src": data.img
      }
    })])])]);
  }), 1)], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }