import Vue from 'vue';
import App from '@/App.vue';
import i18n from '@/lang';
import router from '@/router';
import store from '@/store';

// sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// validate
import VeeValidate from 'vee-validate';
const validateConfig = {
  errorBagName: 'validateErrors',
  fieldsBagName: 'validateFields',
};
// bootstrap
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// fontawsome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// VueClipboard
import VueClipboard from 'vue-clipboard2';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import WuwowLoading from '@/components/wuwowLoading/index.vue';

import AsyncComputed from 'vue-async-computed';
Vue.use(AsyncComputed);

Vue.use(VeeValidate, validateConfig);

Vue.use(BootstrapVue);

library.add(fas);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// VueClipboard not work in
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// event bus，供登陸頁 modal 使用
Vue.prototype.$bus = new Vue();
Vue.config.productionTip = false;

const root = new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');

Vue.use(
  Loading,
  {
    canCancel: false,
    lockScroll: true,
    backgroundColor: '#000',
  },
  {
    default: root.$createElement(WuwowLoading),
  }
);
