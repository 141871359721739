<template lang="pug">
div
  //- 手機用
  .d-block.d-md-none
    .d-flex.flex-column.align-items-center.mx-4
      h3.mobile-title.mt-4.mb-2.text-lioshutan 「語言的學習，來自於生活環境的培養。」
      p.mt-4.mb-5.area-text
        | 對孩子來說，學習應是快樂且充滿成就感的，
        | 抱著這樣的初衷，我們結合
        span.text-lioshutan WUWOW 線上英文
        | 的成功經驗，以及 John M. Keller 教授所提出的
        //- b.btn.btn-link.d-contents.p-0(@click="setIsOpen({target: arcsModalUuid, status: !show})")
        span.text-lioshutan ARCS 動機學習模式
        | 細心地開發出 WUWOW Jr 的課程平台。
      img.w-100(:src="file.englishBG")

  //- 給平板的尺寸
  .d-none.d-md-block.d-lg-none
    .d-flex.flex-column.align-items-center.mx-4
      .w-75.d-flex.flex-column.align-items-center
        h3.my-4.text-lioshutan 「語言的學習，來自於生活環境的培養。」
        p.my-4.area-text
          | 對孩子來說，學習應是快樂且充滿成就感的，
          | 抱著這樣的初衷，我們結合
          span.text-lioshutan  WUWOW 線上英文
          | 的成功經驗，以及 John M. Keller 教授所提出的
          //- b.btn.btn-link.d-contents.p-0(@click="setIsOpen({target: arcsModalUuid, status: !show})")
          span.text-lioshutan ARCS 動機學習模式
          | 細心地開發出 WUWOW Jr 的課程平台。
      img.mt-4.w-75(:src="file.englishBG")

  //- 電腦用
  .d-none.d-md-none.d-lg-block
    .d-flex.flex-column.align-items-center
      .w-50.d-flex.flex-column.align-items-center
        h3.my-3.content-title.text-lioshutan 「語言的學習，來自於生活環境的培養。」
        p.my-4.content-text
          | 對孩子來說，學習應是快樂且充滿成就感的，
          | 抱著這樣的初衷，我們結合
          span.text-lioshutan.mx-1 WUWOW 線上英文
          | 的成功經驗，以及 John M. Keller 教授所提出的
          //- b.btn.btn-link.d-contents.p-0(@click="setIsOpen({target: arcsModalUuid, status: !show})")
          span.text-lioshutan.mx-1 ARCS 動機學習模式
          | 細心地開發出 WUWOW Jr 的課程平台。

      img.w-50(:src="file.englishBG" style="margin-top: 80px;")

  .w-100
    //- feature
    .bg-img.pt-5
      //- 手機用
      .d-block.d-md-none
        .d-flex.flex-column.align-items-center.mb-5.mx-4
          p.area-english-title.text-light :: Comparison ::
          h3.area-title.text-light 與 WUWOW 有什麼差別呢？
          p.area-text.text-light
            | 雖然 WUWOW 已經是一個有效且經過驗證的教學平台，但我們知道，成人英文和兒童英文是兩種截然不同的領域，也因此，WUWOW Jr 除了導入 WUWOW 線上英文的精選全球師資、優勢學習系統之外，更針對台灣學子設計了全新教材、教學方式，為的就是帶給孩子最好的學習體驗，也提供家長一個最安心的英文學習平台。

      //- 給平板的尺寸
      .d-none.d-md-block.d-lg-none.px-5
        .d-flex.flex-column.align-items-center.mb-5.mx-4
          p.area-english-title.text-light :: Comparison ::
          h3.area-title.text-light 與 WUWOW 有什麼差別呢？
          p.area-text.text-light.mt-3
            | 雖然 WUWOW 已經是一個有效且經過驗證的教學平台，但我們知道，成人英文和兒童英文是兩種截然不同的領域，也因此，WUWOW Jr 除了導入 WUWOW 線上英文的精選全球師資、優勢學習系統之外，更針對台灣學子設計了全新教材、教學方式，為的就是帶給孩子最好的學習體驗，也提供家長一個最安心的英文學習平台。

      //- 電腦用
      .d-none.d-md-none.d-lg-block
        .d-flex.flex-column.align-items-center.mb-5
          p.area-english-title.text-light :: Comparison ::
          h2.area-title.text-light.mb-4 與 WUWOW 有什麼差別呢？
          p.area-text.between-text.text-light.text-left
            | 雖然 WUWOW 已經是一個有效且經過驗證的教學平台，但我們知道，成人英文和兒童英文是兩種截然不同的領域，也因此，WUWOW Jr 除了導入 WUWOW 線上英文的精選全球師資、優勢學習系統之外，更針對台灣學子設計了全新教材、教學方式，為的就是帶給孩子最好的學習體驗，也提供家長一個最安心的英文學習平台。

      material

      training

</template>

<script>
import {
  mapMutations, mapState,
} from 'vuex';
import {
  MODAL,
} from '@/store';

import {
  SET_IS_OPEN,
  IS_OPEN,
} from '@/store/modules/modal';

import blueBG from '@/assets/blue_bg1.png';
import englishBG from '@/assets/blue_bg2.png';

import feature from './feature/index.vue';
import material from './material/index.vue';
import training from './training/index.vue';

import {
  uuid as arcsModalUuid,
} from '../arcsModal/index.vue';

import 'bootstrap/dist/css/bootstrap.css';

export default {
  components: {
    feature,
    material,
    training,
  },
  data: () => ({
    arcsModalUuid,
    file: {
      englishBG,
      blueBG,
    },
  }),
  computed: {
    ...mapState(MODAL, {
      arcsIsOpen: IS_OPEN,
    }),
    show: {
      get() {
        return this.arcsIsOpen[arcsModalUuid];
      },
      set() {
        // TODO: do nothing.
      },
    },
  },
  methods: {
    ...mapMutations(MODAL, [
      SET_IS_OPEN,
      IS_OPEN,
    ]),
  },
};
</script>
<style lang="scss" scoped>
.content-title {
  font-size: 32px;
}
.content-text {
  font-size: 20px;
  width: 80%;
}
.bg-img {
  background-image: -webkit-linear-gradient(top, #88c2e8, #ecf5f8);
}
.between-text {
  width: 950px;
}
// @media screen and ( max-width: 992px ){
//   .bg-img {
//     background-image: none;
//   }
// }
@media screen and ( max-width: 414px ){
  .mobile-title {
    line-height: 32px;
  }
  .bg-img {
    background-image: -webkit-linear-gradient(top, #8ec9ee, #88C2E8);
  }
}
</style>
