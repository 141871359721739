var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "size": "lg",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "change": _vm.close
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.currentStep === 1 ? _c('div', {
    staticClass: "row mx-0"
  }, [_c('div', {
    staticClass: "col-12 col-lg-8 p-0"
  }, [_c('div', {
    staticClass: "position-relative h-100 w-100"
  }, [_c('div', {
    staticClass: "gradient-bg w-100 h-25 position-absolute text-center",
    attrs: {
      "id": "login-content"
    }
  }, [_c('p', {
    staticClass: "text-light h5"
  }, [_vm._v("從雲端出發，任何時間、任何地點都能上課")])]), _c('img', {
    staticClass: "h-100 w-100 parent-part-img",
    attrs: {
      "src": _vm.file.parentLoginPic
    }
  })])]), _c('div', {
    staticClass: "col-12 col-lg-4 py-4"
  }, [_c('div', {
    staticClass: "parent-register-box h-100"
  }, [_c('h3', {
    staticClass: "mt-0 mb-3 parent-register-title"
  }, [_vm._v("家長註冊")]), _c('a', {
    staticClass: "back-login-link jr-home-main-color",
    on: {
      "click": function ($event) {
        return _vm.changeCharacter(_vm.CHARACTER.PARENT);
      }
    }
  }, [_vm._v("< 返回登入")]), _c('br'), _c('div', {
    staticClass: "register-note mt-2"
  }, [_vm._v("要有孩子帳號才可以註冊喔！")]), _c('div', {
    staticClass: "form-group register-form-group"
  }, [_c('label', {
    staticClass: "mt-2"
  }, [_vm._v("中文姓名")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.chineseName,
      expression: "form.chineseName"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "輸入中文姓名"
    },
    domProps: {
      "value": _vm.form.chineseName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "chineseName", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "mt-3"
  }, [_vm._v(" 英文姓名")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.englishName,
      expression: "form.englishName"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "輸入英文姓名"
    },
    domProps: {
      "value": _vm.form.englishName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "englishName", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "form-group mt-3 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mr-3"
  }, [_vm._v("性別")]), _c('div', {
    staticClass: "form-check form-check-inline"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.gender,
      expression: "form.gender"
    }],
    staticClass: "form-check-input",
    attrs: {
      "id": "register_male",
      "type": "radio",
      "value": "male"
    },
    domProps: {
      "checked": _vm._q(_vm.form.gender, "male")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "gender", "male");
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "register_male"
    }
  }, [_vm._v("男")])]), _c('div', {
    staticClass: "form-check form-check-inline"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.gender,
      expression: "form.gender"
    }],
    staticClass: "form-check-input",
    attrs: {
      "id": "register_female",
      "type": "radio",
      "value": "female"
    },
    domProps: {
      "checked": _vm._q(_vm.form.gender, "female")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.form, "gender", "female");
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "register_female"
    }
  }, [_vm._v("女")])])]), _c('label', [_vm._v(" 手機號碼")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.phone,
      expression: "form.phone"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "輸入常用手機號碼"
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "phone", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "mt-3"
  }, [_vm._v(" 電子信箱"), _c('span', {
    staticClass: "remind-text jr-home-main-color ml-1"
  }, [_vm._v("*為登入之帳號")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "輸入信箱帳號",
      "autocomplete": "off"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "mt-3"
  }, [_vm._v(" 密碼")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "placeholder": "輸入密碼",
      "autocomplete": "off"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "password", $event.target.value);
      }
    }
  }), _vm.form.password ? _c('div', [_c('div', {
    staticClass: "password-alert my-1",
    class: [_vm.passwordLengthChecked ? 'password-alert-check' : '']
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "check"
    }
  }), _vm._v("密碼設定長度至少為8個字元的字串")], 1), _c('div', {
    staticClass: "password-alert my-1",
    class: [_vm.passwordFormatChecked ? 'password-alert-check' : '']
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "check"
    }
  }), _vm._v("必須英數混和")], 1)]) : _vm._e(), _c('label', {
    staticClass: "mt-3"
  }, [_vm._v("再次輸入密碼")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.confirmPassword,
      expression: "form.confirmPassword"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "placeholder": "再次輸入密碼"
    },
    domProps: {
      "value": _vm.form.confirmPassword
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "confirmPassword", $event.target.value);
      }
    }
  }), _vm.form.confirmPassword ? _c('div', [_c('div', {
    staticClass: "password-alert my-1",
    class: [_vm.passwordChecked ? 'password-alert-check' : '']
  }, [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "check"
    }
  }), _vm._v("密碼需相同")], 1)]) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary w-100 mt-4",
    on: {
      "click": _vm.checkIsEmailAndPhoneRegister
    }
  }, [_vm._v("立 即 登 入")])])])])]) : _vm._e(), _vm.currentStep === 2 ? _c('div', {
    staticClass: "row"
  }, [_c('font-awesome-icon', {
    staticClass: "go-back-icon m-2",
    attrs: {
      "icon": "arrow-left"
    },
    on: {
      "click": function ($event) {
        return _vm.goBack(1);
      }
    }
  }), _c('div', {
    staticClass: "add-kid-account-box py-3"
  }, [_c('h3', {
    staticClass: "parent-register-title mb-4"
  }, [_vm._v("新增小孩帳號")]), _c('div', {
    staticClass: "add-kid-account-subtitle mb-4"
  }, [_vm._v("請輸入您的孩子 wuwow Jr 帳號")]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "input-group mb-3"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text h-100"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "user"
    }
  })], 1)]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.student.account,
      expression: "student.account"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "帳號"
    },
    domProps: {
      "value": _vm.student.account
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.student, "account", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text h-100"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "lock"
    }
  })], 1)]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.student.password,
      expression: "student.password"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "placeholder": "密碼"
    },
    domProps: {
      "value": _vm.student.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.student, "password", $event.target.value);
      }
    }
  })]), _c('button', {
    staticClass: "btn btn-primary w-100 mt-3",
    on: {
      "click": _vm.sendFindBindStudentInfoRequest
    }
  }, [_vm._v("完 成")])])])], 1) : _vm._e(), _vm.currentStep === 3 ? _c('div', {
    staticClass: "row"
  }, [_c('font-awesome-icon', {
    staticClass: "go-back-icon m-2",
    attrs: {
      "icon": "arrow-left"
    },
    on: {
      "click": function ($event) {
        return _vm.goBack(2);
      }
    }
  }), _c('div', {
    staticClass: "confirm-relation-box pt-3 pb-5"
  }, [_c('h3', {
    staticClass: "parent-register-title mb-5"
  }, [_vm._v("確認資料")]), _vm.studentInfo.photo ? _c('img', {
    staticClass: "mb-4",
    attrs: {
      "src": _vm.studentInfo.photo
    }
  }) : _c('img', {
    staticClass: "mb-4",
    attrs: {
      "src": "https://cdn2.wuwow.tw/images/kidpic.png"
    }
  }), _c('div', {
    staticClass: "student-name mb-2"
  }, [_vm._v(_vm._s(_vm.studentInfo.chinese_name) + " " + _vm._s(_vm.studentInfo.english_name))]), _c('div', {
    staticClass: "student-level mb-3"
  }, [_vm._v("Level " + _vm._s(_vm.studentInfo.level))]), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text h-100"
  }, [_vm._v("關係 |")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.relation,
      expression: "form.relation"
    }],
    staticClass: "form-control",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "relation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": "",
      "selected": "",
      "hidden": ""
    }
  }, [_vm._v("請選擇關係")]), _vm._l(_vm.relationCollection, function (relation, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": relation
      }
    }, [_vm._v(_vm._s(_vm.$t(`relation_of_.${relation}`)))]);
  })], 2)]), _vm.form.relation === _vm.RELATION.OTHER ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.other,
      expression: "form.other"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "例：伯父、叔叔、阿姨、姑姑等等.."
    },
    domProps: {
      "value": _vm.form.other
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "other", $event.target.value);
      }
    }
  }) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary w-100 mt-5",
    attrs: {
      "disabled": _vm.isRelationOrOtherEmptyOrIsLock
    },
    on: {
      "click": _vm.sendCreateParentAccountRequest
    }
  }, [_vm._v("完 成")])])], 1) : _vm._e(), _vm.currentStep === 4 ? _c('div', {
    staticClass: "row"
  }, [_c('font-awesome-icon', {
    staticClass: "go-back-icon m-2",
    attrs: {
      "icon": "arrow-left"
    },
    on: {
      "click": function ($event) {
        return _vm.goBack(3);
      }
    }
  }), _c('div', {
    staticClass: "confirm-email-box pt-3 pb-5"
  }, [_c('h3', {
    staticClass: "parent-register-title mb-5"
  }, [_vm._v("信箱驗證")]), _c('div', {
    staticClass: "subtitle mb-4"
  }, [_vm._v("請前往信箱收取驗證信件")]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "confirm-email-input-box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "confirm-email-input form-control",
    attrs: {
      "placeholder": "輸入註冊時信箱帳號"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "confirm-email-input-count"
  }, [_vm._v("(180s)")])]), _c('div', {
    staticClass: "d-flex mt-2"
  }, [_c('div', {
    staticClass: "info-icon mr-2"
  }, [_vm._v("i")]), _c('div', {
    staticClass: "danger-color"
  }, [_vm._v("如果尚未收到信件請前往查看垃圾信件匣")])]), _c('button', {
    staticClass: "btn request-btn w-100 mt-5",
    attrs: {
      "disabled": _vm.isCountDownSecondNotEqualZero
    },
    on: {
      "click": _vm.sendResendVerifyMailRequest
    }
  }, [_vm._v(_vm._s(`重 新 驗 證 (${_vm.countDownSecond}s)`))]), _c('div', {
    staticClass: "resend-note mt-3"
  }, [_vm._v("尚未收到信箱驗證？請點擊上方按鈕重新發送驗證")])])])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }