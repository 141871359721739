<template lang="pug">
li.nav-item.dropdown
  a.nav-link.d-xl-flex.align-items-center.dropdown-toggle(@click="toggleDropdown('parent-account')")
    avatar.nav-avatar(:avatarUrl="profileBase.avatar" :avatarRole="'parent'")
    .d-none.d-xl-flex.ml-2
      span.d-lg-down-none {{ profileBase.english_name }}
      span.parent-account-text.mx-2 {{ $t('parent') }}{{ $t('account') }}
  transition(name='fade')
    .dropdown-menu.dropdown-menu-right.d-block(v-if="state === 'parent-account'")
      .are-personal
        p.txt-personal-menu-title {{ $t('parent') }}{{ $t('account') }}
        b-row.mt-2
          b-col(cols="5")
            avatar.personal-avatar.ml-4(:avatarUrl="profileBase.avatar" :avatarRole="'parent'")
          b-col(cols="7")
            div.m-2
              span.txt-personal-name {{ profileBase.chinese_name }}
        my-account-link.mt-2
        detail(:role="parent")
</template>

<script type="text/javascript">
import vipAccount from '@/components/vip/account';
import myAccountLink from '@/components/parent/account/myAccountLink/index.vue';
import dropdowControlMixin from '@/components/nav/headerNav/mixin/dropdowControlMixin';
import lioshutanApi from '@/api';
import detail from '@/components/vip/account/detail/index.vue';
import commonConstants from '@/constants/common';
import avatar from '@/components/avatar/index.vue';

export default {
  name: 'ParentAccount',

  components: {
    detail,
    vipAccount,
    myAccountLink,
    avatar,
  },

  mixins: [dropdowControlMixin],

  data() {
    return {
      profileBase: {},
      parent: commonConstants.ROLE.PARENT,
    };
  },

  created(){
    this.getParentInfo();
  },

  methods: {
    async getParentInfo() {
      const response = await lioshutanApi.parent.getParentInfo();
      const baseProfile = response.data.data.parent;
      this.profileBase = baseProfile;
    },
  },
};
</script>
<style lang="scss" scoped>
.img-avatar{
  max-width: 35px;
}
.parent-account-text {
  border: 1px solid white;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 14px;
  padding: 1px 4px;
}
.dropdown-toggle:after {
  display: none;
}
.nav-avatar {
  border-radius: 50%;
}
.personal-avatar{
  height: 36px;
  width: 36px;
}

/deep/ .nav-avatar,
.member-overlap-item {
  height: 40px;
  width: 40px;
  padding: 3px;
  background: linear-gradient(180deg, #C0E0F4 2.63%, #E2C2E4 46.05%, #FBDECD 97.37%);
  img {
    border-radius: 50%;
    border: 3px solid var( --main-color );
  }
}
</style>
