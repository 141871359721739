<template lang="pug">
#material
  //- 給手機用的尺寸
  .d-block.d-md-none
    .home-container.mx-auto.mt-5.col-md-10.mt-100
      .d-flex.justify-content-center.my-5
        h3.d-inline-block.bg-light.text-lioshutan.rounded-pill.px-4.py-2.mb-5 專屬台灣學子設計
      .row.justify-content-center
        .col-md-3.border.material-shadow.material-rounded.mx-4.bg-light.card-mobile
          .card-content
            .card-body.p-0
              .d-flex.justify-content-center
                img.mb-3(:src="file.design1")
              h3.area-title.text-lioshutan.text-center
                | ARCS 教學流程
              .card-subtitle
                p 為了讓孩子在線上能學出興趣，我們採用 ARCS 動機學習理論設計教學，專注於建立孩子學習的成就感與熱忱。
        .col-md-3.border.material-shadow.material-rounded.mx-4.bg-light.card-mobile
          .card-content
            .card-body.p-0
              .d-flex.justify-content-center
                img.mb-3(:src="file.design2")
              h3.area-title.text-lioshutan.text-center
                | 貼近孩子的教材設計
              .card-subtitle
                p WUWOW Jr 教材內容皆選自於孩子生活貼近的事物，避開艱難生僻的題材，快速引起學習的興趣。
        .col-md-3.border.material-shadow.material-rounded.mx-4.bg-light.card-mobile
          .card-content
            .card-body.p-0
              .d-flex.justify-content-center
                img.mb-3(:src="file.design3")
              h3.area-title.text-lioshutan.text-center
                | 0 基礎也不擔心
              .card-subtitle
                p 透過 TPR 的互動教學模式，搭配專為零基礎學生設計的 Pre-class 教材，即使是 ABC 都不熟悉的孩子，也能在全英文的環境下展開學習。
              //- button.btn.btn-link(@click="setIsOpen({target: tprUuid, status: !tprIsOpen})")
              //-   h5.text-lioshutan 什麼是 TPR 教學方式？

  //- 給平板的尺寸
  .d-none.d-md-block.d-lg-none
    .home-container.mx-auto.mt-5.mt-100.p-3
      .col-md-12.d-flex.justify-content-center.my-5
        h2.d-inline-block.bg-light.text-lioshutan.rounded-pill.px-4.py-2 專屬台灣學子設計
      .row.justify-content-center
        .col-md-8.border.material-shadow.material-rounded.mx-4.bg-light.card-mobile
          .card-content
            .card-body.p-0
              .d-flex.justify-content-center
                img.mb-3(:src="file.design1")
              h2.text-lioshutan.text-center
                | ARCS 教學流程
              .card-subtitle
                p 為了讓孩子在線上能學出興趣，我們採用 ARCS 動機學習理論設計教學，專注於建立孩子學習的成就感與熱忱。
        .col-md-8.border.material-shadow.material-rounded.mx-4.bg-light.card-mobile
          .card-content
            .card-body.p-0
              .d-flex.justify-content-center
                img.mb-3(:src="file.design2")
              h2.text-lioshutan.text-center
                | 貼近孩子的教材設計
              .card-subtitle
                p WUWOW Jr 教材內容皆選自於孩子生活貼近的事物，避開艱難生僻的題材，快速引起學習的興趣。
        .col-md-8.border.material-shadow.material-rounded.mx-4.bg-light.card-mobile
          .card-content
            .card-body.p-0
              .d-flex.justify-content-center
                img.mb-3(:src="file.design3")
              h2.text-lioshutan.text-center
                | 0 基礎也不擔心
              .card-subtitle
                p 透過 TPR 的互動教學模式，搭配專為零基礎學生設計的 Pre-class 教材，即使是 ABC 都不熟悉的孩子，也能在全英文的環境下展開學習。
              //- button.btn.btn-link(@click="setIsOpen({target: tprUuid, status: !tprIsOpen})")
              //-   h5.text-lioshutan 什麼是 TPR 教學方式？

  //- 給電腦的尺寸
  .d-none.d-md-none.d-lg-block.home-container
    .mx-auto.mt-5.col-12.mt-100
      .d-flex.justify-content-center.my-5.py-5
        h2.area-title.d-inline-block.bg-light.text-lioshutan.rounded-pill.px-4.py-2 專屬台灣學子設計
      .row.justify-content-center
        .col-lg-4.material-rounded.my-5.px-3
          .card-content.ratio-1x1.bg-light.border.material-shadow
            .card-body.p-0
              .d-flex.justify-content-center
                img.mb-3(:src="file.design1")
              h3.area-content-title.text-lioshutan.text-center
                | ARCS 教學流程
              .card-subtitle.area-text
                p 為了讓孩子在線上能學出興趣，我們採用 ARCS 動機學習理論設計教學，專注於建立孩子學習的成就感與熱忱。
        .col-lg-4.material-rounded.my-5.px-3
          .card-content.ratio-1x1.bg-light.border.material-shadow
            .card-body.p-0
              .d-flex.justify-content-center
                img.mb-3(:src="file.design2")
              h3.area-content-title.text-lioshutan.text-center
                | 貼近孩子的教材設計
              .card-subtitle.area-text
                p WUWOW Jr 教材內容皆選自於孩子生活貼近的事物，避開艱難生僻的題材，快速引起學習的興趣。
        .col-lg-4.material-rounded.my-5.px-3
          .card-content.ratio-1x1.bg-light.border.material-shadow
            .card-body.p-0
              .d-flex.justify-content-center
                img.mb-3(:src="file.design3")
              h3.area-content-title.text-lioshutan.text-center
                | 0 基礎也不擔心
              .card-subtitle.area-text
                p 透過 TPR 的互動教學模式，搭配專為零基礎學生設計的 Pre-class 教材，即使是 ABC 都不熟悉的孩子，也能在全英文的環境下展開學習。
              //- button.btn.btn-link(@click="setIsOpen({target: tprUuid, status: !tprIsOpen})")
              //-   h5.text-lioshutan 什麼是 TPR 教學方式？
</template>

<script>
import {
  mapMutations, mapState,
} from 'vuex';
import {
  MODAL,
} from '@/store';
import {
  IS_OPEN,
  SET_IS_OPEN,
} from '@/store/modules/modal';

import design1 from '@/assets/design_1.png';
import design2 from '@/assets/design_2.png';
import design3 from '@/assets/design_3.png';

import { uuid as arcsUuid } from '../../arcsModal/index.vue';
import { uuid as tprUuid } from '../../tprModal/index.vue';

import 'bootstrap/dist/css/bootstrap.css';

export default {
  data: () => ({
    arcsUuid,
    tprUuid,
    file: {
      design1,
      design2,
      design3,
    },
  }),
  computed: {
    ...mapState(MODAL, [
      IS_OPEN,
    ]),
    arcsIsOpen() {
      return this[IS_OPEN][arcsUuid];
    },
    tprIsOpen() {
      return this[IS_OPEN][tprUuid];
    },
  },
  methods: {
    ...mapMutations(MODAL, [
      SET_IS_OPEN,
    ]),
  },
};
</script>

<style lang="scss" scoped>
.card-content {
  padding: 5px 25px;
  font-size: 20px;
}
.bg-light {
  background-color: white !important;
}
.material-shadow {
  box-shadow: 0px 3px 6px #00000029;
}
img {
  width: 200px;
  height: 200px;
}
.card-body {
  position: relative;
  top: -100px;
  max-height: 350px;
}
.card-subtitle {
  margin-top: 23px;
}
.material-rounded {
  border-radius: 7px;
}
@media screen and ( max-width: 992px ){
  .card-mobile {
    margin: 60px 0;
  }
}
@media screen and ( max-width: 414px ){
  .card-content {
    padding: 5px 10px;
    font-size: 20px;
  }
  .card-subtitle {
    font-size: 16px;
  }
  img {
    width: 170px;
    height: 170px;
  }
  // .card-mobile {
  //   margin: 60px 0;
  // }
}
</style>
