<template lang="pug">
  div
    a.dropdown-item(:href='wuwow_meeting_center_download_windows_url') WUWOW Meeting Center Download (Windows)
    a.dropdown-item(:href='wuwow_meeting_center_download_mac_url') WUWOW Meeting Center Download (MAC)
    a.dropdown-item(:href='`${self_training_classroom_consultant_url}&ui=${user_id}`' target='_blank') Self-Training Classroom (As a Consultant)
    a.dropdown-item(:href="`${self_training_classroom_vip_url}&ui=${user_id}`" target='_blank') Self-Training Classroom (As a VIP)
    a.dropdown-item(:href="`${training_classroom_url}&ui=${user_id}`" target='_blank') Training Classroom
    a.dropdown-item(:href='questionnaire_url' target='_blank') Questionnaire
</template>

<script type="text/javascript">
import lioshutanApi from '@/api';
import url from '@/constants/url';
export default {
  name: 'TrainingList',

  data() {
    return {
      // 顧問的 user id
      user_id: 0,
      // 網址
      wuwow_meeting_center_download_windows_url: url.CONSULTANT_TRIANING.WUWOW_MEETING_CENTER_DOWNLOAD_WINDOWS_URL,
      wuwow_meeting_center_download_mac_url: url.CONSULTANT_TRIANING.WUWOW_MEETING_CENTER_DOWNLOAD_MAC_URL,
      self_training_classroom_consultant_url: url.CONSULTANT_TRIANING.SELF_TRAINING_CLASSROOM_CONSULTANT_URL,
      self_training_classroom_vip_url: url.CONSULTANT_TRIANING.SELF_TRAINING_CLASSROOM_VIP_URL,
      training_classroom_url: url.CONSULTANT_TRIANING.TRAINING_CLASSROOM_URL,
      questionnaire_url: url.CONSULTANT_TRIANING.QUESTIONNAIRE_URL,
    };
  },

  computed: {},

  watch: {},

  async created() {
    // 取得顧問基本資料
    const consultantProfileRes = await lioshutanApi.consultant.getConsultantProfile();
    this.$store.dispatch('consultant/setBaseProfileDatas', consultantProfileRes.data.data.user);

    this.user_id = this.$store.state.consultant.baseProfileDatas.user_id;
  },

  methods: {

  },
};
</script>

<style lang="scss"></style>
