import axios from '@/api/utils/axios-helper.js';

export default class Common {
  /**
   * 判斷登入平台權限
   * @returns {Promise<Object>} 回傳角色可登入平台
   */
  async checkLogin(params) {
    const response = await axios({
      url: '/v3/taichi-external/user/check-login',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params: params,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得登入帳號角色
   * @returns {Promise<Array>} 角色ID
   */
  async getUserRole() {
    const response = await axios({
      url: '/v3/taichi-external/user/roles',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得台灣所有銀行號碼與名稱
   * @returns {Promise<Array>} bank_code: 銀行號碼與中英文名稱 bank_branch_code: 銀行分行與中英文名稱
   */
  async getBankCode() {
    const response = await axios({
      url: '/json/bank',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 取得伺服器時間
   * @returns {Promise<String>} xxxx-xx-xx xx:xx:xx
   */
  async getServerTime() {
    const response = await axios({
      url: '/server-time',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response.data;
  }
  /**
   * 設定媒體來源填單資料
   * @returns {Promise<String>} 是否新增成功
   */
  async setMediaSource(data) {
    const response = await axios({
      url: `${process.env.VUE_APP_BASE_MEDIA_URL}api/unlimited_roster`,
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 設定聯盟網
   * @returns {Promise<String>} 是否新增成功
   */
  async setAffiliates(params) {
    const response = await axios({
      url: `${process.env.VUE_APP_BASE_MEDIA_URL}api/affiliates/dispatch`,
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      params,
      responseType: 'json',
    });
    return response;
  }
  /**
   * 累計人數增加
   * @returns {Promise<String>} 是否新增成功
   */
  async addVisitAmount(data) {
    const response = await axios({
      url: '/v3/wuwow-portal/website/add-visit-amount',
      method: 'post',
      headers: { 'Cache-Control': 'no-cache' },
      data: data,
      responseType: 'json',
    });
    return response;
  }

  async getPlatformData(codename) {
    const response = await axios({
      url: `/v3/convenient/platform-basic/${codename}`,
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
  /**
   * 取得國家列表
   * @param {String} needPaginator - 是否啟用分頁 [0, 1]
   * @param {String} isEnable - 是否啟用 [0, 1]
   * @returns {Promise<countries:[{id, displayName, codename, isEnable}]>}
  */
  async getConvenientCountries(params) {
    const response = await axios({
      url: 'v3/convenient/countries',
      method: 'get',
      params,
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return response;
  }
}
