var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-5",
    attrs: {
      "id": "arcs"
    }
  }, [_c('div', {
    staticClass: "d-block d-sm-none"
  }, [_c('div', {
    staticClass: "home-container"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('img', {
    staticClass: "w-75",
    attrs: {
      "src": _vm.file.arcsMobile
    }
  })])])]), _c('div', {
    staticClass: "d-none d-sm-block"
  }, [_c('div', {
    staticClass: "home-container mb-5 p-5"
  }, [_vm._m(2), _vm._m(3), _c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('img', {
    staticClass: "w-100",
    attrs: {
      "src": _vm.file.arcs
    }
  })])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column align-items-center mx-3"
  }, [_c('h3', {
    staticClass: "text-lioshutan mb-2"
  }, [_vm._v("ARCS 動機學習模型")]), _c('p', {
    staticClass: "text-lioshutan mb-4"
  }, [_vm._v("by John Keller")]), _c('p', [_vm._v("WUWOW Jr 的設計理念，主旨在於提升孩子的學習動機，因此我們採用了美國約翰凱勒 (John Keller) 教授系統化的 ARCS 動機模型，ARCS 指的是 Attention (注意) Relevance (相關) Confidence (信心)、Satisfaction (滿足) 四個要素，模型強調想要引起學習者的動機，必須配合此四要素的運用，才能達到激勵學生學習的作用。")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column align-items-center my-5"
  }, [_c('h3', {
    staticClass: "text-lioshutan"
  }, [_vm._v("ARCS 如何應用於課程設計中？")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "w-100 w-xl-50 d-flex flex-column align-items-center"
  }, [_c('h2', {
    staticClass: "area-title text-lioshutan mb-2"
  }, [_vm._v("ARCS 動機學習模型")]), _c('h5', {
    staticClass: "area-text text-lioshutan mb-4"
  }, [_vm._v("by John Keller")]), _c('p', {
    staticClass: "area-text text-center"
  }, [_vm._v("WUWOW Jr 的設計理念，主旨在於提升孩子的學習動機，因此我們採用了美國約翰凱勒 (John Keller) 教授系統化的 ARCS 動機模型，ARCS 指的是 Attention (注意) Relevance (相關) Confidence (信心)、Satisfaction (滿足) 四個要素，模型強調想要引起學習者的動機，必須配合此四要素的運用，才能達到激勵學生學習的作用。")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column align-items-center my-3"
  }, [_c('h2', {
    staticClass: "area-title text-lioshutan my-xl-5 my-4"
  }, [_vm._v("ARCS 如何應用於課程設計中？")])]);

}]

export { render, staticRenderFns }