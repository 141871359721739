var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "nav-item dropdown"
  }, [_c('a', {
    staticClass: "nav-link dropdown-toggle",
    on: {
      "click": function ($event) {
        return _vm.switchMenu();
      }
    }
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": _vm.consultantProfile.picture,
      "alt": "User Image"
    }
  }), _c('span', {
    staticClass: "d-md-down-none"
  }, [_vm._v(_vm._s(_vm.consultantProfile.english_name))])]), _c('div', {
    staticClass: "dropdown-menu dropdown-menu-right",
    class: {
      'd-block': _vm.showMenu
    }
  }, [_c('div', {
    staticClass: "dropdown-list",
    on: {
      "click": _vm.logout
    }
  }, [_c('span', [_vm._v("Logout")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }