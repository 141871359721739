<template lang="pug">
div
  .modal-overlay(v-if="renewalReminder")
    .custom-modal
      h2.title 續約提醒
      template(v-if="isGoogleIdentity")
        p.content
          | 您的學習合約即將到期，除了把握時間學習，更要把握續約優惠~
          br
          br
          span 2024/1/7
          | 前週年慶期間
          br
          | 立即續約 領「
          span(class="new-light-coupon") $NT1000
          |  新光禮券」
          br
          br
          | 快聯繫您的服務專員了解內容！
      template(v-else)
        p.content
          | 您家寶貝的學習合約即將到期，除了把握時間學習，更要把握續約優惠~
          br
          br
          span 2024/1/7
          | 前週年慶期間
          br
          br
          | 購買指定方案送「
          span iPhone 15
          | 」
          br
          | 續約再享獨家「
          span YLE 劍橋兒童英語認證 全額贊助
          | 」
          br
          br
          | 快聯繫您的服務專員了解內容！
      img.woman-happy-img(src="@/assets/woman-happy.svg", alt="woman")
      img.iphone-15-img(src="@/assets/iphone-15.svg", alt="iphone15")
      div(class="understand-btn-box")
        button(@click="understand" class="btn understand-btn") 已了解

  .modal-overlay(v-if="contractRenewalSuccessful")
    .successful-modal
      div(class="successful-bg")
        img(src="../../../assets/renew-contract-title.svg" class="successful-title")
        template(v-if="isGoogleIdentity")
          p.content
            | 恭喜續約成功
            br
            br
            | 獲得
            span(class="new-light-coupon")  $NT1000
            |  新光禮券
            br
            br
            | 快前往您的信箱收信查看領獎辦法！
            br
            br
            | 信件主旨：【 WUWOW JUNIOR 】週年慶Google學員專屬續約獎金 領獎通知
        template(v-else)
          p.content
            | 恭喜續約成功，享有「YLE 劍橋兒童英語認證」全額贊助資格，
            br
            br
            | 快前往您的信箱收信查看檢定贊助申請辦法！
            br
            br
            | 信件主旨：【 WUWOW JUNIOR】 恭喜完成續約，獲得YLE 劍橋兒童英語認證贊助資格
      div(class="understand-btn-box")
        button(@click="understand" class="btn understand-btn") 已了解
</template>

<script>
// import { mapState, mapActions } from 'vuex';
import i18n from '@/components/parents/profileInfo/lang';
import moment from 'moment';
import lioshutanApi from '@/api';

export default {
  name: 'RemoveParentInfo',

  i18n,

  data() {
    return {
      renewalReminder: false,
      contractRenewalSuccessful: false,
      hasContractRenewalSuccessful: false,
      studentStatus: '',
      vipContractRenewalReminder: null,
      maxActivteDay: '',
      minActivteDay: '',
      nowToday: '',
      isGoogleIdentity: false, // google身份判斷
    };
  },

  computed: {
  },

  async created() {
    const result = await lioshutanApi.vip.getContractElapsedTime();
    this.isGoogleIdentity = result.data.is_google_contract;
    this.checkNowStatus();
    this.findNextSpecificDate();
  },

  methods: {
    // 2.若無續約成功，判斷有沒有可續約提醒
    async getVipContractRenewalReminde() {
      // 先判斷目前時間是否在 2024-01-07 23:59 之前
      const deadline = moment('2024-01-07 23:59', 'YYYY-MM-DD HH:mm');
      if (moment().isAfter(deadline)) {
        return; // 如果超過直接返回
      }

      // 判斷現在時間有沒有在時間範圍內
      const isCurrentDateValid = moment(this.nowToday).isBetween(this.minActivteDay, this.maxActivteDay, 'second');

      const res = await lioshutanApi.renewalReminder.getVipContractRenewalReminde({
        reminderType: this.isGoogleIdentity ? 'google_waiting_renewal' : 'waiting_renewal',
      });

      if (res.data.vipContractRenewalReminders.length > 0) {
        this.processReminders(res.data.vipContractRenewalReminders);
        if (this.studentStatus === 'waiting' && isCurrentDateValid) {
          this.renewalReminder = true;
        }
      }
    },
    // 1.判斷是否續約成功
    async getVipContractRenewal() {
      // 先判斷目前時間是否在 2024-01-07 23:59 之前
      const deadline = moment('2024-01-07 23:59', 'YYYY-MM-DD HH:mm');
      if (moment().isAfter(deadline)) {
        return; // 如果超過直接返回
      }

      // 判斷現在時間有沒有在時間範圍內
      const isCurrentDateValid = moment(this.nowToday).isBetween(this.minActivteDay, this.maxActivteDay, 'second');

      const res = await lioshutanApi.renewalReminder.getVipContractRenewalReminde({
        reminderType: this.isGoogleIdentity ? 'google_completed_renewal' : 'completed_renewal',
      });

      if (res.data.vipContractRenewalReminders.length > 0) {
        this.processReminders(res.data.vipContractRenewalReminders);
        if (this.studentStatus === 'waiting' && isCurrentDateValid) {
          this.contractRenewalSuccessful = true; // 顯示續約成功的彈窗
        }
      } else {
        this.hasNotContractRenewalSuccessful = true;
      }
    },
    async checkNowStatus() {
      await this.getVipContractRenewal();
      if (this.hasNotContractRenewalSuccessful === true){
        await this.getVipContractRenewalReminde();
      }
    },
    // 找到距離當前時間最近的已過期的提醒
    processReminders(reminders) {
      let closestReminder = null;
      let minDifference = Infinity; // 預設為無限大 確保任何時間都會比預設小
      const now = moment();

      // 拿當前的時間，跟後端提供的日期進行遍歷，找到最接近當前日期，但比當前日期還要早的時間
      reminders.forEach(reminder => {
        const reminderDate = moment(reminder.reminder_date);
        if (reminderDate.isBefore(now)) {
          const difference = now.diff(reminderDate);
          if (difference < minDifference) {
            minDifference = difference;
            closestReminder = reminder;
          }
        }
      });
      if (closestReminder) {
        this.studentStatus = closestReminder.student_status;
        this.vipContractRenewalReminder = closestReminder.id;
      }
    },
    async understand() {
      await lioshutanApi.renewalReminder.patchVipContractRenewalReminde({ studentStatus: 'closed' }, this.vipContractRenewalReminder);
      this.renewalReminder = false;
      this.contractRenewalSuccessful = false;
    },
    // 找到對應的日期，做後續判斷
    findNextSpecificDate() {
      // 将今天的日期格式化为 'YYYY-MM-DD' 格式
      this.nowToday = moment().format('YYYY-MM-DD');
      // 判断特定日期列表 (星期一)
      const specificDates = [
        '2023-11-27', '2023-12-04', '2023-12-11', '2023-12-18', '2023-12-25', '2024-01-04', '2024-01-08',
      ];

      // 找到最接近今天但比今天大的日期
      this.maxActivteDay = specificDates.find(date => moment(date).isSameOrAfter(this.nowToday));
      // 找到最接近今天但比今天小的日期
      this.minActivteDay = specificDates.reverse().find(date => moment(date).isSameOrBefore(this.nowToday));
      console.log('maxActivteDay', this.maxActivteDay);
      console.log('minActivteDay', this.minActivteDay);
    },
  },

};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.custom-modal {
  background: white;
  width: 90%;
  max-width: 870px;
  padding: 80px 60px 80px;
  border-radius: 10px;
  position: relative;
  // 其他自定義樣式
}

.title {
  text-align: center;
  color: #464646;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 2.4px;
  margin-bottom: 40px;
}

.content {
  color:#464646;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.6px;
  margin: 0 -20px;
  span {
    color:#216DDB;
  }
}

.understand-btn-box {
  display: flex;
  justify-content: center;
}

.understand-btn {
  width: 100%;
  max-width: 401px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 25px;
  background: #006BDA;
  color: #FFF;
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.84px;
  margin-top: 40px;

  &:hover {
    // background: #0069da;
    color: #fff;
  }
}

.woman-happy-img {
  position: absolute;
  width: 100px;
  bottom: 0;
  left: 0;

  @media (min-width: 1024px) {
    width: 200px;
  }
}

.iphone-15-img {
  position: absolute;
  height: 80px;
  top: 0;
  right: 0;

  @media (min-width: 1024px) {
    height: 120px;
  }
}

.successful-modal {
  background: white;
  width: 90%;
  max-width: 870px;
  padding-left:60px;
  padding-right: 60px;
  padding-bottom: 80px;
  border-radius: 10px;
  position: relative;

}
.successful-bg {
  padding-top: 80px;
  background-image: url('../../../assets/renew-contract-bg.svg');
  background-size: 100% auto; /* 背景图像宽度为100%，高度自动 */
  background-position: center center; /* 调整背景图像位置 */
  display: flex;
  flex-direction: column;
}

.successful-title {
  margin-bottom: 40px;
  height: 100px;
}

.new-light-coupon {
  font-weight: 700;
  font-size: 21px;
}
</style>
