var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout-lioshutan"
  }, [_c('v-header'), _c('div', {
    staticClass: "app-body"
  }, [_c('v-left'), _c('main', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('router-view')], 1)])], 1), _c('v-footer'), _vm.islogin ? _c('div', [_c('check-student-setting'), _c('operation-timed-out'), _c('socket-handle'), _c('pusher-handle'), _c('SupplementaryAgreement'), _c('RenewalReminder')], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }