var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "size": "lg",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "change": _vm.close
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('iframe', {
    staticClass: "w-100",
    attrs: {
      "height": "500",
      "src": _vm.src,
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      "allowfullscreen": ""
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }