export default {
  messages: {
    tw: {
      operationDelay: '操作逾時',
      operationDelayMessage: '已超過操作時間{overtime}分鐘，請重新登入',
    },
    en: {
      operationDelay: 'Operation timed out',
      operationDelayMessage: 'The operation time has exceeded {overtime} minutes, please log in again.',
    },
  },
};
