<template lang="pug">
  div
    .reg-login
      a.lnk-login-home(href="http://www.wuwow.tw")
        font-awesome-icon(icon="angle-left")
        |  {{ $t("back_to_official_web") }}
      .are-login-input
        h1.h1-login-title {{ $t("login_title") }}
        p.txt-login-desc {{ $t("wuwow_login_slogan") }}
        form
          .form-group
            input.form-control(
              v-model="loginForm.account"
              name="email"
              type="text"
              placeholder="Mobile Phone / Account Name"
              @keyup.enter="login"
            )
          .form-group.blk-login-password
            input.form-control(
              v-model="loginForm.password"
              name="password"
              :type="textOrPassword"
              placeholder="Password"
              @keyup.enter="login"
            )
            font-awesome-icon(
              :icon="eyeIcon"
              aria-hidden="true"
              @click="showPassword = !showPassword"
            )
          .my-2(v-if="errorMessage")
            span.text-danger
              strong {{ $t("user_info_error_message") }}
          .row.blk-login-links
            a.lnk-login-reset-pwd(href="https://www.wuwow.tw/trial/?m=wuwow_homepage")
              font-awesome-icon(icon="question-circle")
              |  {{ $t("get_new_password") }}
            a.lnk-login-trial(href="https://www.wuwow.tw/trial/?m=wuwow_homepage")
              font-awesome-icon(icon="gift")
              |  {{ $t("free_trial") }}
          .col-xs-12
            button.btn.btn-primary.btn-block.btn-login(type="button" @click="login") {{ $t("login") }}
</template>

<script>
// 套件內的API
import api from '@lioshutan/api-package';
// 專案內的API
import lioshutanApi from '@/api';
import { alertFailedMessage } from '@/utils/sweetAlert.js';
import i18n from '@/views/login/lang.js';
import commonConstants from '@/constants/common';
import { setUseLangByRole } from '@/utils/lang';
import { clearLocalStorage } from '@/composable/auth';

export default {
  name: 'Login',

  i18n,

  data() {
    return {
      loginForm: {
        account: '',
        password: '',
      },
      errorMessage: false,
      showPassword: false,
    };
  },

  computed: {
    eyeIcon() {
      return this.showPassword ? 'eye' : 'eye-slash';
    },
    textOrPassword() {
      return this.showPassword ? 'text' : 'password';
    },
  },

  mounted() {
    clearLocalStorage();
  },

  methods: {
    async login() {
      try {
        const result = await api
          .setDNS(process.env.VUE_APP_BASE_API)
          .auth()
          .login(this.loginForm);
        this.$store.dispatch('auth/setToken', result.token);
        // const canLogin = await this.checkUserLogin();
        // if (canLogin){
        const accountRole = await this.setAccountAuthorityToLocalStorage();
        setUseLangByRole(accountRole);
        if (accountRole === 'teacher') {
          this.$router.push({ path: '/dojo' });
        } else {
          this.directToNewJr(); // 家長登入到新後台
        }
        // this.directToNewJr(); // 家長登入到新後台
        // this.$router.push({ path: '/dojo' });
        // }
      } catch (error) {
        this.errorMessage = true;
      }
    },
    directToNewJr() {
      const url = new URL(window.location);
      const a = document.createElement('a');
      a.setAttribute('href', `${url.origin}/student/`);
      a.click();
    },

    async checkUserLogin(){
      const params = {
        platform: commonConstants.CURRENT_PLATFORM,
      };
      try {
        const response = await lioshutanApi.common.checkLogin(params);
        if (!response.data.data.check_result){
          alertFailedMessage(this.$t('wrongPlatform'));
          return false;
        } else {
          return true;
        }
      } catch (error) {
        alertFailedMessage(this.$t('platformCheckError'));
      }
    },

    async setAccountAuthorityToLocalStorage(){
      const accountAuthority = await lioshutanApi.vip.getAccountAuthority();
      // const accountAuthorityArray = accountAuthority.data.data.authority;
      const accountRole = accountAuthority.data.data.role;
      this.$store.dispatch('auth/setRole', accountRole);
      return accountRole;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/base/extension';
@import '@/views/login/login';
</style>
