import axios from '../utils/axios-helper.js';

export default class SummerCamp {
  async setSummerRewardApply(data) {
    const res = await axios({
      url: '/v3/summer-camp/apply',
      method: 'post',
      data,
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return res;
  }
  async getSummerRewardOption() {
    const res = await axios({
      url: '/v3/summer-camp/option',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return res;
  }
  async getSummerRewardEventInfo() {
    const res = await axios({
      url: '/v3/taichi-external/vip/summer-reward-event-info',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return res;
  }
  async getSummerRewardEventMilestones() {
    // 暑期里程碑
    const res = await axios({
      url: '/v3/taichi-external/vip/summer-reward-event-milestones',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return res;
  }
  async getBookingClassData(params) {
    const res = await axios({
      url: '/v3/educational/lesson/class-application-management',
      method: 'get',
      params,
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return res;
  }
  async getRewardEventOverallPerformance() {
    const res = await axios({
      url: '/v3/taichi-external/vip/summer-reward-event-overall-performance',
      method: 'get',
      headers: { 'Cache-Control': 'no-cache' },
      responseType: 'json',
    });
    return res;
  }
}
