import store from '@/store';
import router from '@/router';
import storage from '@/utils/localStorage';
// import api from '@lioshutan/api-package';

export async function logout() {
  clearLocalStorage();
  router.push({ path: '/' }); // 使用者登出後導回首頁
}

export async function teacherLogout() {
  clearLocalStorage();
  router.push({ path: '/login' });
}

export async function clearLocalStorage() {
  storage.removeToken();
  storage.removePusherInfo();
  store.dispatch('parents/removeVipId');
  store.dispatch('auth/removeToken');
  store.dispatch('auth/removeRole');
}
