var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center flex-column text-white"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center wuwow-icon"
  }, [_c('img', {
    staticClass: "position-absolute icon-border",
    attrs: {
      "src": _vm.icon,
      "width": "100",
      "height": "100"
    }
  }), _c('div', {
    staticClass: "position-absolute triangle"
  })]), _c('p', {
    staticClass: "h4"
  }, [_vm._v(_vm._s(_vm.message))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }