// 人事模組
// 先以 file name + Class 命名搬至 API 專案需細分再重新命名
import consultantsListClass from '@/api/src/consultantsList';

const consultantsList = new consultantsListClass();

export default class Personnel {
  /**
   * @desc 取得適性選項
   */
  queryNationalityList() {
    return consultantsList.queryNationalityList();
  }
  queryInterestList() {
    return consultantsList.queryInterestList();
  }
  queryConsultantList(params) {
    return consultantsList.queryConsultantList(params);
  }
  queryConsultantScore(params) {
    return consultantsList.queryConsultantScore(params);
  }
  queryConsultantFavorable(params) {
    return consultantsList.queryConsultantFavorable(params);
  }
  queryLikesConsultant(params) {
    return consultantsList.queryLikesConsultant(params);
  }
  queryConsultantDetail(id) {
    return consultantsList.queryConsultantDetail(id);
  }
  addVipLikeConsultant(params) {
    return consultantsList.addVipLikeConsultant(params);
  }
  cancelVipLikeConsultant(params) {
    return consultantsList.cancelVipLikeConsultant(params);
  }
  queryConsultantTimeslots(params) {
    return consultantsList.queryConsultantTimeslots(params);
  }
  queryPlatformConsultantCanTeach(params) {
    return consultantsList.queryPlatformConsultantCanTeach(params);
  }
  querySpecificConsultantTimeslot(params) {
    return consultantsList.querySpecificConsultantTimeslot(params);
  }
}
